import React, { Component } from "react";
import { Select, Row, Col, Modal } from "antd";
import { EMAIL_RECEIVED } from "../../../tools/patient-medical";
import _ from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { getlistStaff } from "../../../action/reportAction";
import { connect } from "react-redux";

const { Option } = Select;

/**
 *  Created by shengli.zhang on 12/23/24 9:25 PM
 */

const DOCTOR_IN_SITE = [];

class SiteContactItem extends Component {
  state = {
    data: {
      ...this.props.data,
      contactDoctor: _.isEmpty(this.props.data.contactDoctor)
        ? []
        : this.props.data.contactDoctor.split(","),
      emailReceive: _.isEmpty(this.props.data.emailReceive)
        ? []
        : this.props.data.emailReceive.split(",")
    }
  };

  onFormComplete = () => {
    if (this.state.data.contactDoctor.length === 0) {
      return;
    }
    if (typeof this.props.saveAction === "function") {
      this.props.saveAction({
        ...this.state.data,
        contactDoctor: this.state.data.contactDoctor.join(","),
        emailReceive: this.state.data.emailReceive.join(",")
      });
    }
  };

  handleContactChange = value => {
    if (value.length > 0)
      this.setState(
        {
          data: { ...this.state.data, contactDoctor: [value[value.length - 1]] }
        },
        this.onFormComplete
      );
  };

  handleEmailReceiveChange = value => {
    if (value.length === 0) {
      Modal.warning({
        title: "Save Contact Failed",
        content: "Please select a email receive.",
        centered: true
      });
      return;
    }
    this.setState(
      {
        data: { ...this.state.data, emailReceive: value }
      },
      this.onFormComplete
    );
  };

  render() {
    return (
      <Row gutter={[24, 24]}>
        <Col span={10}>
          <Select
            mode="tags"
            // value={this.state.contactDoctor}
            value={this.props.data.contactDoctor.split(",")}
            onChange={this.handleContactChange}
            style={{ width: "100%" }}
            placeholder="Contact Email"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp="children"
          >
            {this.props.listStaff
              .filter(s => s.siteId === this.props.siteId)
              .map((p, index) => (
                <Option value={p.email} key={index}>
                  {p.firstName} {p.lastName}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={12}>
          <Select
            mode="multiple"
            value={this.props.data.emailReceive.split(",")}
            onChange={this.handleEmailReceiveChange}
            style={{ width: "100%" }}
            placeholder="Email Received"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            optionFilterProp="children"
          >
            {EMAIL_RECEIVED.map((p, index) => {
              return (
                <Option value={p.value} key={index}>
                  {p.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col span={2}>
          <CloseOutlined
            onClick={() => {
              if (typeof this.props.deleteAction === "function") {
                this.props.deleteAction();
              }
            }}
            style={{ marginTop: 8 }}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProp = state => ({
  listStaff: state.patientsListStore.listStaff
});

const mapDispatchToProp = dispatch => ({
  getlistStaff: () => dispatch(getlistStaff())
});
export default connect(mapStateToProp, mapDispatchToProp)(SiteContactItem);
