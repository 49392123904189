import { Button, Checkbox, message, Modal as AntdModal, Select } from "antd";
import "antd/dist/antd.css";
import { Auth } from "aws-amplify";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Icon, Modal as SuModal, Button as SuButton } from "semantic-ui-react";
import { v1 as uuid } from "uuid";
import aws_exports from "../aws-exports-siren";
import ButtonSuccess from "../home/buttonSuccess";
import "../share/common.css";
import getPatchTrProps from "../tools/reactTablePatch";
import "./biMonthly.css";
import "./selectComponent.css";

const { Option } = Select;

export default class ReportNeedsReviewBase extends Component {
  constructor(props) {
    super(props);

    // parse url param
    let siteId = [];
    const queryParam = window.location.href.split("?")[1];
    if (queryParam) {
      let params = queryString.parse(queryParam);
      siteId = params.siteId.split("_");
    }

    this.state = {
      modalIsOpen: false,
      siteId,
      selectedPatients: [],
      tableData: []
    };
  }

  componentDidMount() {
    const { getlistClinicSites } = this.props;
    getlistClinicSites();
    if (this.state.siteId.length > 0) {
      this.setState({ modalIsOpen: true });

      const fmtSiteId = this.state.siteId.join("_");
      this.props
        .searchBillingPatients({ siteId: fmtSiteId })
        .then(data => {
          this.setState({
            tableData: data
          });
          // restore temporarily saved state
          if (this.constructor.selectedPatients) {
            this.setState({
              selectedPatients: this.constructor.selectedPatients
            });
            delete this.constructor.selectedPatients;
          }
        })
        .catch(e => {
          console.log("searchBillingPatients error: ", e);
        })
        .finally(() => {
          this.setState({ modalIsOpen: false });
        });
    }
  }

  makeRequestSearchPatient() {
    this.setState({
      selectedPatients: []
    });

    this.setState({ modalIsOpen: true });
    const fmtSiteId = this.state.siteId.join("_");
    this.props
      .searchBillingPatients({ siteId: fmtSiteId })
      .then(data => {
        this.setState({
          tableData: data
        });

        let currentPath = Control.path;
        if (currentPath.includes("?")) {
          currentPath = currentPath.split("?")[0];
        }
        Control.replace(currentPath + "?siteId=" + fmtSiteId);
      })
      .catch(e => {
        console.log("searchBillingPatients error: ", e);
      })
      .finally(() => {
        this.setState({ modalIsOpen: false });
      });
  }

  handleCheckAll = e => {
    const checked = e.target.checked;
    let nextSelected = [...this.state.selectedPatients];
    const { page, pageSize, sortedData } = this.tableInstance.state;
    const currentPageData = sortedData.slice(
      page * pageSize,
      (page + 1) * pageSize
    );
    if (checked) {
      nextSelected = _.union(
        nextSelected,
        currentPageData.map(item => item._index)
      );
      if (nextSelected.length > 25) {
        message.info("For best results, please reduce to 25 records or less.");
        return;
      }
    } else {
      nextSelected = _.difference(
        nextSelected,
        currentPageData.map(item => item._index)
      );
    }
    this.setState({
      selectedPatients: nextSelected
    });
  };

  handleCheck = (checked, index) => {
    let selectedPatients = [...this.state.selectedPatients];
    if (checked) {
      selectedPatients.push(index);
      if (selectedPatients.length > 25) {
        message.info("For best results, please reduce to 25 records or less.");
        return;
      }
    } else {
      selectedPatients = selectedPatients.filter(p => p !== index);
    }
    this.setState({
      selectedPatients
    });
  };

  searchPatients = () => {
    if (this.state.siteId.length) {
      this.makeRequestSearchPatient();
    }
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  gotoDoctorDetailPage(userId, id, date) {
    // save state temporaryly
    this.constructor.selectedPatients = this.state.selectedPatients;

    let currentRoute = Control.path;
    if (currentRoute.includes("?")) {
      currentRoute = currentRoute.split("?")[0];
    }
    // remove leading '/'
    currentRoute = currentRoute.substring(1);

    Control.go(
      `/MedicalDetailsHome?${currentRoute}/id=` +
        btoa(id) +
        "&userId=" +
        userId +
        "&startDate=" +
        date +
        "&endDate=" +
        date
    );
  }

  downloadCSV = async () => {
    if (!this.cptCode) {
      throw "cptCode not set";
    }
    if (this.state.siteId.length === 0) {
      AntdModal.warn({
        title: "Downloading Report Failed",
        content: "Please select siteId.",
        centered: true
      });
      return;
    }
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const cptCode = this.cptCode;
    const endpointInfo = _.filter(aws_exports.aws_cloud_logic_custom, {
      name: "dev-pdf-exporter"
    });
    const pdfEndpoint = endpointInfo[0].endpoint;
    const siteIds = this.state.siteId.join("_");
    const downloadUrl = `${pdfEndpoint}/admin/allUnBilledCSVReport?siteIds=${siteIds}&cptCode=${cptCode}&token=${token}&generatedDate=${moment().format(
      "YYYY-MM-DD"
    )}`;
    window.open(downloadUrl);
  };

  async startDownloading() {
    const { generateBillingReport } = this.props;
    const reportPatients = this.state.selectedPatients.map(index => {
      const patient = this.state.tableData[index];
      console.log(patient);
      if (patient.billedType === "99426-7") {
        return {
          sirenId: patient.sirenId,
          userId: patient.userId,
          type: "99426",
          billingDate: patient.billingKey
        };
      }
      return {
        sirenId: patient.sirenId,
        userId: patient.userId,
        type: patient.billedType,
        billingDate: patient.billingDate
      };
    });
    const session = await Auth.currentSession();
    const input = {
      siteIds: this.state.siteId.join("_"),
      source: "ADMIN",
      token: uuid(),
      doctorId: session.getIdToken().payload.email,
      reportPatients,
      generatedDate: moment().format("YYYY-MM-DD")
    };
    generateBillingReport(input);
  }

  handleGenerateReport = () => {
    if (this.state.selectedPatients.length === 0) {
      message.info(
        "Please select at least one patient record to download a report."
      );
      return;
    }

    if (this.state.selectedPatients.length > 25) {
      message.info("For best results, please reduce to 25 records or less.");
      return;
    }

    AntdModal.confirm({
      title: "Downloading Report",
      content:
        "To download the report, disable your pop-up blocker. It will take a few minutes for us to generate your report. You may continue using the Siren Dashboard and the download will start automatically when it is ready.",
      centered: true,
      onOk: async () => {
        await this.startDownloading();
      },
      onCancel: () => {}
    });
  };

  handleResetFilter = () => {
    this.tableInstance.setState({
      sorted: [],
      filtered: [],
      resized: []
    });
    this.setState({
      tableData: [],
      siteId: [],
      selectedPatients: []
    });

    let currentPath = Control.path;
    if (currentPath.includes("?")) {
      currentPath = currentPath.split("?")[0];
    }
    Control.replace(currentPath);
  };

  renderHeader() {
    return React.createElement(this.headerClass, {
      onResetFilter: this.handleResetFilter
    });
  }

  renderActionBar() {
    return (
      <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
        <span className="search_label">SiteID:</span>
        <Select
          mode="multiple"
          style={{ minWidth: 200 }}
          placeholder="Please select sites"
          value={this.state.siteId}
          onChange={value => {
            this.setState({
              siteId: value
            });
          }}
          onSelect={value => {
            // pre-select all child sites
            const nextSites = new Set(this.state.siteId);
            this.props.clinicSites
              .map(site => site.siteId)
              .filter(site => site.startsWith(value))
              .forEach(site => nextSites.add(site));

            // special rule. Bay Area
            if (value === "0136") {
              nextSites.add("0001");
              nextSites.add("0002");
              nextSites.add("0028");
              nextSites.add("0041");
            }
            this.setState({
              siteId: [...nextSites]
            });
          }}
          optionLabelProp="value"
          optionFilterProp="children"
        >
          {this.props.clinicSites.map(site => (
            <Option key={site.siteId} value={site.siteId}>
              {`${site.siteId} - ${site.name}`}
            </Option>
          ))}
        </Select>
        <Button
          onClick={this.searchPatients}
          style={{ marginLeft: 10 }}
          disabled={this.state.modalIsOpen}
        >
          Search
        </Button>
        <Button
          onClick={this.downloadCSV}
          style={{ marginLeft: 10, whiteSpace: "nowrap" }}
          disabled={this.state.modalIsOpen || this.state.tableData.length === 0}
        >
          Download CSV
        </Button>
        <Button
          onClick={this.handleGenerateReport}
          style={{ marginLeft: 10, whiteSpace: "nowrap" }}
          disabled={
            this.state.modalIsOpen ||
            this.state.tableData.length === 0 ||
            this.props.showGenerateDelay
          }
        >
          Generate Report
        </Button>
        {this.props.showGenerateDelay ? (
          <div
            style={{
              color: "red",
              marginLeft: 20,
              marginTop: 5,
              fontWeight: "bold",
              whiteSpace: "nowrap"
            }}
          >
            {"Generating Report in " + this.props.generateDelay}
          </div>
        ) : null}
      </div>
    );
  }

  // subclass should override
  renderTableProps() {
    return { data: [], columns: [] };
  }

  getSelectAll() {
    let selectAll = false;
    if (this.tableInstance) {
      selectAll = true;
      const { page, pageSize, sortedData } = this.tableInstance.state;
      const currentPageData = sortedData.slice(
        page * pageSize,
        (page + 1) * pageSize
      );
      if (currentPageData.length === 0) {
        selectAll = false;
      }
      currentPageData.forEach(row => {
        if (!this.state.selectedPatients.includes(row._index)) {
          selectAll = false;
        }
      });
    }

    return selectAll;
  }

  renderTable() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.userId,
        rowInfo.original.id,
        moment().format("YYYY-MM-DD")
      );
    });

    const selectAll = this.getSelectAll();

    const { data, columns } = this.renderTableProps(selectAll);

    return (
      <ReactTable
        loading={this.state.modalIsOpen}
        getTrProps={trProps}
        ref={ref => (this.tableInstance = ref)}
        onPageChange={pageIndex => {
          // render select all checkbox
          this.forceUpdate();
        }}
        onPageSizeChange={(pageSize, pageIndex) => {
          // render select all checkbox
          this.forceUpdate();
        }}
        data={data}
        filterable
        defaultFilterMethod={(filter, row) => {
          if (!row[filter.id]) {
            return false;
          }
          return row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        }}
        columns={columns}
        defaultPageSize={20}
        className="-striped -highlight"
        minRows={0}
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderActionBar()}
        {this.renderTable()}
      </div>
    );
  }
}

// Add these props from redux. Can NOT added from base class, or inherit hierarchy wrong
ReportNeedsReviewBase.propTypes = {
  username: PropTypes.string.isRequired,
  clinicSites: PropTypes.array.isRequired,
  showGenerateDelay: PropTypes.bool,
  generateDelay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getlistClinicSites: PropTypes.func.isRequired,
  searchBillingPatients: PropTypes.func.isRequired,
  generateBillingReport: PropTypes.func.isRequired,
  tableDefaultState: PropTypes.object.isRequired,
  updateTableDefaultState: PropTypes.func.isRequired
};

export class ReviewAllCheckbox extends Component {
  state = {
    open: false,
    loading: false,
    confirmSuccess: false
  };

  show = () => {
    let patients = this.props.patients;
    if (typeof this.props.patients === "function") {
      patients = this.props.patients();
    }
    if (patients.length === 0) {
      return;
    }
    this.setState({ open: true, loading: false, confirmSuccess: false });
  };

  handleConfirm = async () => {
    let patients = this.props.patients;
    if (typeof this.props.patients === "function") {
      patients = this.props.patients();
    }
    const tasks = patients.map(p => this.props.reviewTask(p));

    this.setState({
      loading: true
    });
    const results = await Promise.allSettled(tasks);
    this.setState({
      loading: false,
      confirmSuccess: true
    });

    const failed = results.filter(result => result.status === "rejected");
    if (failed.length > 0) {
      failed.forEach(task => {
        console.log("updateBilledStatus failed:", task.reason);
      });
    }

    setTimeout(() => {
      this.setState({
        open: false
      });
      if (typeof this.props.onFinish === "function") {
        this.props.onFinish(results);
      }
    }, 300);
  };

  handleCancel = () => this.setState({ open: false });

  render() {
    let patientCount = "N/A";
    if (typeof this.props.patients === "function") {
      patientCount = this.props.patients().length;
    } else {
      patientCount = this.props.patients.length;
    }
    return (
      <SuModal
        trigger={
          <Checkbox
            style={{ lineHeight: "28px" }}
            onClick={this.show}
            checked={this.state.open}
          />
        }
        open={this.state.open}
      >
        <SuModal.Header>Review All</SuModal.Header>
        <SuModal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div style={{ height: 80 }}>
              Are you sure you want to mark all displayed records (
              {patientCount}) as reviewed? This will move records to the
              Reviewed tab.
            </div>
          )}
        </SuModal.Content>
        <SuModal.Actions>
          <SuButton
            color="green"
            loading={this.state.loading}
            onClick={this.handleConfirm}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
          >
            <Icon name="checkmark" />
            Submit
          </SuButton>
          <SuButton
            basic
            color="red"
            onClick={this.handleCancel}
            className="removeButton"
          >
            <Icon name="remove" />
            Cancel
          </SuButton>
        </SuModal.Actions>
      </SuModal>
    );
  }
}

ReviewAllCheckbox.propTypes = {
  patients: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  reviewTask: PropTypes.func.isRequired,
  onFinish: PropTypes.func
};
