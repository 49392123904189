import React, { Component } from "react";
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Dropdown,
  Modal,
  Message
} from "semantic-ui-react";
import ButtonSuccess from "../../../home/buttonSuccess";
import _, { isEmpty } from "lodash";
import { searchHubList } from "../../../graphql/API";
import { Modal as AntdModal, notification } from "antd";
import { connect } from "react-redux";
import { registerHub } from "../../../action/boxAction";

class AddHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hubId: "",
      authCode: "",
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null,
      reqHubId: ""
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null
    });
  };

  close = () => {
    this.setState({
      open: false,
      hubId: "",
      authCode: "",
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null
    });
    if (typeof this.props.onClose === "function") {
      this.props.onClose(true);
    }
  };

  changeReqHubId = event => {
    this.setState({ reqHubId: event.target.value });
  };

  handleSearch = async () => {
    if (_.isEmpty(this.state.reqHubId)) {
      AntdModal.warning({
        title: "Oops",
        content: "Please input Hub Id.",
        centered: true
      });
      return;
    }
    try {
      searchHubList({ hubId: { eq: this.state.reqHubId } })
        .then(data => {
          console.log("data: ", data);
          if (data.items && data.items.length > 0) {
            const hubInfo = data.items[0];
            const hubId = hubInfo.hubId;
            const authCode = hubInfo.authcode;
            console.log("hubInfo: ", hubInfo);
            this.setState({ hubId, authCode });
          }
        })
        .catch(error => {
          console.log("error:", error);
        });
    } catch (err) {
      console.log(`Search hub(${this.state.reqHubId}) info error: `, err);
    }
  };

  handleKeyPress = async e => {
    // We pass the new value of the text when calling onAccept
    if (e.key === "Enter") {
      // hack reset page
      if (_.isEmpty(this.state.reqHubId)) {
        AntdModal.warning({
          title: "Oops",
          content: "Please input Hub Id.",
          centered: true
        });
        return;
      }
      try {
        searchHubList({ hubId: { eq: this.state.reqHubId } })
          .then(data => {
            console.log("data: ", data);
            if (data.items && data.items.length > 0) {
              const hubInfo = data.items[0];
              const hubId = hubInfo.hubId;
              const authCode = hubInfo.authcode;
              console.log("hubInfo: ", hubInfo);
              this.setState({ hubId, authCode });
            }
          })
          .catch(error => {
            console.log("error:", error);
          });
      } catch (err) {
        console.log(`Search hub(${this.state.reqHubId}) info error: `, err);
      }
    }
  };

  submit = () => {
    if (isEmpty(this.state.hubId) || isEmpty(this.state.authCode)) {
      notification.warn({
        message: "System Warning",
        description: "HubId or AuthCode can not be none."
      });
      return;
    }
    const creds = {
      userId: this.props.doctorDetails.userId,
      serial: this.state.hubId,
      authcode: this.state.authCode
    };
    registerHub(creds)
      .then(res => {
        if (res.status) {
          this.setState({
            confirmSuccess: true
          });
          // automatically close after success animation
          setTimeout(() => {
            this.setState({
              open: false
            });
          }, 500);
        } else {
          let desc = null;
          if (res.msg) {
            desc = res.msg;
          } else if (res.error) {
            if (res.error.msg) {
              desc = res.error.msg;
            } else if (res.error instanceof Error) {
              desc = res.error.message;
            } else {
              desc = JSON.stringify(res.error);
            }
          } else {
            desc = JSON.stringify(res);
          }
          notification.error({
            message: "System Error",
            description: desc
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        if (error instanceof Error) {
          return notification.error({
            message: "System Error",
            description: error.message
          });
        }
        // graphQL error
        if (error.errors) {
          notification.error({
            message: "System Error",
            description: error.errors[0].message
          });
        }
      });
  };

  render() {
    const { open } = this.state;

    return (
      <Modal
        trigger={
          <Button
            size="small"
            onClick={this.handleOpen}
            style={{ marginLeft: 20 }}
          >
            Add Hub
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Add Hub</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <div className="replace_input_box">
                <Input
                  placeholder="Input Hub Id"
                  className="replace_input_content"
                  onChange={this.changeReqHubId}
                  value={this.state.reqHubId.toUpperCase()}
                  onKeyPress={this.handleKeyPress}
                />
                <Button
                  size="small"
                  onClick={this.handleSearch}
                  className="replace_input_button"
                >
                  Load
                </Button>
              </div>
              <div className="replace_input_box">
                <Input
                  placeholder="Hub Id"
                  className="replace_input_content"
                  value={this.state.hubId}
                  disabled
                />
                <Input
                  placeholder="Auth Code"
                  className="replace_input_content"
                  value={this.state.authCode}
                  disabled
                />
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={this.submit}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Add
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.close()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails
});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(AddHub);
