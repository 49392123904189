import React, { Component } from "react";
import "../share/common.css";
import "./biMonthly.css";
import "./selectComponent.css";
import { DatePicker, notification } from "antd";
import { connect } from "react-redux";
import ReactTable from "react-table";
import moment from "moment";
import { listMonthlySummary } from "../graphql/API";
import { Button } from "semantic-ui-react";
import { generateMonthlySummary } from "../favorite/report-va-action";
import { v1 as uuid } from "uuid";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchYear: moment(),
      summary: [],
      yearOpen: false,
      loadingReport: false
    };
  }

  componentDidMount() {
    this.searchMonthlySummary(moment(this.state.searchYear).format("YYYY"));
  }

  searchMonthlySummary = year => {
    this.setState({ loadingReport: true });
    listMonthlySummary(year)
      .then(data => {
        this.setState({ loadingReport: false, summary: data });
        // console.log("data: ", data);
      })
      .catch(err => {
        // console.log("error: ", err);
        this.setState({ loadingReport: false });
        // graphQL error
        if (err.errors) {
          notification.error({
            message: "System Error",
            description: err.errors[0].message
          });
        }
      });
  };

  changeYear = value => {
    this.setState({ searchYear: value, yearOpen: false });
    this.searchMonthlySummary(moment(value).format("YYYY"));
  };

  toHoursAndMinutes = totalMinutes => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours && minutes) {
      return hours + "hrs " + minutes + "mins";
    } else if (hours) {
      return hours + "hrs";
    } else if (minutes) {
      return minutes + "mins";
    }
    return "N/A";
  };

  addThousandsSeparator = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  divideWithOneDecimal = (numerator, denominator) => {
    if (denominator === 0) {
      return "N/A";
    }
    return (numerator / denominator).toFixed(1);
  };

  handleGenerateMonthlySummary = async () => {
    const input = {
      year: moment(this.state.searchYear).format("YYYY"),
      token: uuid()
    };
    const { generateMonthlySummary } = this.props;
    generateMonthlySummary(input);
  };

  render() {
    return (
      <div className="device-list-container">
        <div
          className="search_patient_container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <DatePicker
            mode="year"
            format="YYYY"
            placeholder={"Please select year"}
            value={this.state.searchYear}
            open={this.state.yearOpen}
            onPanelChange={this.changeYear}
            onOpenChange={status => {
              this.setState({ yearOpen: true });
            }}
          />
          <Button
            onClick={this.handleGenerateMonthlySummary}
            style={{
              marginLeft: 30,
              whiteSpace: "nowrap",
              marginTop: 20,
              marginBottom: 20
            }}
            disabled={this.props.showGenerateVaDelay}
          >
            Generate Monthly Summary
          </Button>
          {this.props.showGenerateVaDelay ? (
            <div
              style={{
                color: "red",
                marginLeft: 20,
                marginTop: 5,
                fontWeight: "bold",
                whiteSpace: "nowrap"
              }}
            >
              {"Generating Monthly Summary in " + this.props.generateVaDelay}
            </div>
          ) : null}
        </div>
        <ReactTable
          loading={this.state.loadingReport}
          data={this.state.summary}
          filterable
          resolveData={data =>
            data.map(p => {
              const minuteworn = this.divideWithOneDecimal(p.minuteworn, 60);
              const medianMinuteworn = this.divideWithOneDecimal(
                p.medianMinuteworn,
                60
              );
              const month = moment(p.month).format("MM/YYYY");
              const steps = this.addThousandsSeparator(p.steps);
              const medianSteps = this.addThousandsSeparator(p.medianSteps);
              return {
                ...p,
                minuteworn,
                medianMinuteworn,
                month,
                steps,
                medianSteps
              };
            })
          }
          columns={[
            {
              Header: "Month",
              accessor: "month"
            },
            {
              Header: "Active Clinics",
              accessor: "activeSites"
            },
            {
              Header: "Active Patients",
              accessor: "onServicePatients"
            },
            {
              Header: "Pending Onboarding Patients",
              accessor: "pendingPatients"
            },
            {
              Header: "Paused Patients",
              accessor: "pausedPatients"
            },
            {
              Header: "Discontinued Patients",
              accessor: "offServicePatients"
            },
            {
              Header: "Avg Days Worn",
              accessor: "days"
            },
            {
              Header: "Median Days Worn",
              accessor: "medianDays"
            },
            {
              Header: "Avg Hours Worn",
              accessor: "minuteworn"
            },
            {
              Header: "Median Hours Worn",
              accessor: "medianMinuteworn"
            },
            {
              Header: "Avg Steps",
              accessor: "steps"
            },
            {
              Header: "Median Steps",
              accessor: "medianSteps"
            },
            {
              Header: "Avg Calls",
              accessor: "calls"
            },
            {
              Header: "Median Calls",
              accessor: "medianCalls"
            }
          ]}
          defaultSorted={[{ id: "month", desc: false }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  showGenerateVaDelay: state.monthlyReportStore.showGenerateVaDelay,
  generateVaDelay: state.monthlyReportStore.generateVaDelay
});
const mapDispatchToProp = dispatch => ({
  generateMonthlySummary: input => dispatch(generateMonthlySummary(input))
});
export default connect(mapStateToProp, mapDispatchToProp)(Summary);
