import { Modal as AntdModal, Layout } from "antd";
import Amplify, { Auth } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
// import IRBList from "../home/IRBList";
import { Route } from "react-keeper";
import { connect } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { Dropdown, Image, Menu } from "semantic-ui-react";
import packageJson from "../../package.json";
import About from "../about";
import { changeTempUnitState, clearUserGroup } from "../action/chartAction";
import {
  shipAuthData,
  updateEnv,
  listICD10Codes,
  listMedicalConditions
} from "../action/userGroupAction";
import aws_exports from "../aws-exports-siren";
import BoxManagement from "../BoxManagement/BoxList";
import HubManagement from "../BoxManagement/HubManagement";
import ReplaceSocks from "../BoxManagement/ReplaceSocks";
// import UserBoxMgmt from "../BoxManagement/UserBoxMgmt";
import Battery from "../chart/batteryCharts";
import TagBattery from "../chart/tagBatteryCharts";
import SockBattery from "../chart/sockBatteryCharts";
import TagBatteryEquipment from "../chart/tagBatteryChartsEquipment";
import FavoriteList from "../favorite/FavoriteList";
import DeletePatients from "../home/DeletePatientsList";
import DeveloperHome from "../home/DeveloperHome";
import DeveloperPupHome from "../home/DeveloperPupHome";
import NewPatients from "../home/NewPatientsList";
import ProfileSetting from "../home/ProfileSetting";
import logo from "../logo.png";
import BrokenMedicalDetails from "../MedicalDashboard/medicaldetails/BrokenMedicalDetails";
import MedicalDetailsHome from "../MedicalDashboard/medicaldetails/StaffMedicalDetails";
import LpnPatient from "../lpn-management/LpnPatient";
import InvoiceReport from "../report/InvoiceReport";
import ManagementSummary from "../report/ManagementSummary";
import Summary from "../report/summary";
import SiteSummary from "../report/monthly_site_summary";
import MonitoringNeedsReview from "../report/ReportNeedsReview99457_8";
import MonitoringReport from "../report/ReportCurrentMonth99457_8";
import MonitoringReviewed from "../report/ReportReviewed99457_8";
import RecordingNeedsReview from "../report/ReportNeedsReview99453_4";
import RecordingReport from "../report/ReportCurrentMonth99453_4";
import RecordingReviewed from "../report/ReportReviewed99453_4";
import ReportHistory from "../report/ReportHistory";
import ReportSetting from "../report/ReportSetting";
import ReportSite from "../report/ReportSite";
import SirenStaffing from "../report/SirenStaffing";
import UserReportTable from "../report/userReportTable";
import PatientLog from "../simulateSock/PatientLog";
import SimulateSock from "../simulateSock/SimulateSock";
import UpgradeApp from "../simulateSock/UpgradeApp";
import ProgramStatus from "../simulateSock/program-status";
import SirenSock from "../sockstatus/sockHomePage";
import sockstatus from "../sockstatus/sockstatus";
import LpnManagement from "../lpn-management/LpnManagement";
import SiteEMRManagement from "../SupportDashboard/account/site-emr";
import StaffList from "../staff/StaffList";
import message from "../tools/message-info";
import "./AlertWindow.css";
import "./App.css";
import { Mixpanel } from "../mixpanel/mixpanelAction";
import AdminUserManagement from "../SupportDashboard/account/AdminUserManagement";
import DMTTemplateManagement from "../medicaldetails/DMTTemplateManagement";
import SMSDripList from "./pages/sms-drip/SmsDrip";
import ShoeTagManagement from "../shoe-tag/ShoeTagManagement";
import PillBottleManagement from "../pill-bottle/PillBottleManagement";
import NewAccount from "./pages/new-account/NewAccount";
import CreatePatient from "./pages/new-account/create-patient";
import { updateAdminUserInfo } from "../action/registerAction";
import ICD10CodesManager from "./pages/icd-10-codes";
import MedicalConditionManager from "./pages/medical-condition";
import PCMReport from "../report/ReportCurrentMonth99426_7";
import PCMReviewed from "../report/ReportReviewed99426_7";
import PCMNeedsReview from "../report/ReportNeedsReview99426_7";
import SharedStopWatchLogManager, {
  initialStopWatchLogManager
} from "../tools/stopWatchLogManager";
import SignDemo from "./pages/sign-pdf/sign-demo";
import sharedWebsocket from "../tools/sharedWs";
import ReportReviewed_va from "../report/va/ReportReviewed_va";

const { Header, Content, Footer } = Layout;

const dev_version = packageJson.dev_version;
const dev_version_date = packageJson.dev_version_date;
Amplify.configure(aws_exports);
class App extends Component {
  async onLogoutClick() {
    try {
      this.props.clearUserGroup("");
      await Auth.signOut();
      // window.location.reload();
    } catch (e) {
      message.info("SignOut error: " + e);
    }
  }

  componentDidMount() {
    // check if user has verified
    const { authData } = this.props;
    if (!authData.attributes.email_verified) {
      AntdModal.warn({
        title: "Account Verification",
        content:
          "We've sent a verification code to the email address used to create your account. Email verification is required to access the Siren Dashboard and reset your password.",
        centered: true,
        onOk: async () => {
          this.onLogoutClick();
        },
        onCancel: () => { }
      });
      return;
    }

    this.props.initialICD10Codes();
    this.props.initialMedicalConditions();
    const env =
      aws_exports &&
        aws_exports.aws_cloud_logic_custom &&
        aws_exports.aws_cloud_logic_custom.length > 0
        ? aws_exports.aws_cloud_logic_custom[0].key.split("/")
        : [];
    this.props.updateEnv(env.includes("dev"));

    this.props.shipAuthData(authData);
    this.url = window.location.href;

    if (authData.attributes["custom:temp_unit"]) {
      const { changeTempUnit } = this.props;
      changeTempUnit(authData.attributes["custom:temp_unit"]);
    }

    let UpdateAdminStaffInput = {
      input: {
        id: authData.username,
        firstName: authData.attributes["given_name"],
        lastName: authData.attributes["family_name"],
        lastLogin: moment().format("YYYY-MM-DDTHH:mm:ss")
      }
    };
    const { signInUserSession } = authData;
    const payload = signInUserSession.idToken.payload;
    const group = payload["cognito:groups"] ? payload["cognito:groups"] : [];
    const isThirdParty = group.length === 1 && group.includes("third_party");
    if (!isThirdParty) {
      this.props.updateAdminUser(UpdateAdminStaffInput);
    }
    // Mixpanel.identify(session.getAccessToken().payload.username);
    // Mixpanel.track('Successful login');
    // Mixpanel.people.set({
    //   $first_name: "Zhang",
    //   $last_name: "Shengli",
    // });

    initialStopWatchLogManager(
      authData.signInUserSession.idToken.payload["cognito:groups"]
    );
    SharedStopWatchLogManager.uploadLocalLog();

    sharedWebsocket.initial();
  }

  renderNaivationBar() {
    const isAdmin = this.props.userGroups.includes("admin");
    const isDeveloper = this.props.userGroups.includes("developer");
    const showLpnManagement = isAdmin || isDeveloper;
    return (
      <Menu
        style={{
          display: this.url && this.url.includes("SirenSock") ? "none" : "flex"
        }}
      >
        <Menu.Item as="a" href="/#/" header>
          <Image size="mini" src={logo} style={{ marginRight: "1.5em" }} />
          SirenCare
        </Menu.Item>
        <Dropdown
          // data-step="1"
          // data-tip="The home page has new changes"
          item
          simple
          text="Patients"
        >
          <Dropdown.Menu>
            <Dropdown.Item as="a" href="/#/">
              Patient Overview
            </Dropdown.Item>
            {/*<Dropdown.Item as="a" href="/#/TrialHome">*/}
            {/*  Trial*/}
            {/*</Dropdown.Item>*/}
            <Dropdown.Item as="a" href="/#/NewPatients">
              Account Activation Status
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/DeletePatients">
              Deleted Patients
            </Dropdown.Item>
            {/*<Dropdown.Item as="a" href="/#/IRBList">*/}
            {/*  IRB*/}
            {/*</Dropdown.Item>*/}
            <Dropdown.Item as="a" href="/#/NewAccount">
              Create New Patient
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/SirenStaffing">
              Siren Staffing
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text="Inventory Management">
          <Dropdown.Menu>
            {/*<Dropdown.Item as="a" href="/#/UserBoxMgmt">*/}
            {/*  User Box List*/}
            {/*</Dropdown.Item>*/}
            <Dropdown.Item as="a" href="/#/BoxManagement">
              Siren Socks
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/HubManagement">
              Siren Hub
            </Dropdown.Item>
            {this.props.dev_env && (
              <Dropdown.Item as="a" href="/#/ShoeTagManagement">
                Siren Move
              </Dropdown.Item>
            )}
            {this.props.dev_env && (
              <Dropdown.Item as="a" href="/#/PillBottleManagement">
                Pill Bottle
              </Dropdown.Item>
            )}
            <Dropdown.Item as="a" href="/#/ReplaceSocks">
              Replace Socks
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text="Account Management">
          <Dropdown.Menu>
            <Dropdown.Item as="a" href="/#/AdminUserManagement">
              Admin Management
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/SiteManagement">
              Site Management
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/StaffList">
              Clinic Staff Management
            </Dropdown.Item>
            {showLpnManagement && (
              <Dropdown.Item as="a" href="/#/LpnManagement">
                Nurse Management
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text="Reports">
          <Dropdown.Menu>
            {/*<Dropdown.Item as="a" href="/#/CustomReport">*/}
            {/*  Weekly Clinic Report*/}
            {/*</Dropdown.Item>*/}
            {/*<Dropdown.Item as="a" href="/#/ManagementSummary">*/}
            {/*  Management Summary*/}
            {/*</Dropdown.Item>*/}
            {/*<Dropdown.Item as="a" href="/#/RecordingNeedsReview">*/}
            {/*  RPM: 99453 & 99454*/}
            {/*</Dropdown.Item>*/}
            {/*<Dropdown.Item as="a" href="/#/MonitoringNeedsReview">*/}
            {/*  RPM: 99457 & 99458*/}
            {/*</Dropdown.Item>*/}
            {/*<Dropdown.Item as="a" href="/#/PCMNeedsReview">*/}
            {/*  PCM: 99426 & 99427*/}
            {/*</Dropdown.Item>*/}
            {/*<Dropdown.Item as="a" href="/#/InvoiceReport">*/}
            {/*  Invoice Report*/}
            {/*</Dropdown.Item>*/}
            <Dropdown.Item as="a" href="/#/Summary">
              Summary
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/MonthlySiteSummary">
              Site Summary
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/VaReport">
              Monthly Patient Report
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text="New Features">
          <Dropdown.Menu>
            <Dropdown.Item as="a" href="/#/PupPatients">
              PU Patients
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/CreatePatient">
              Create PU Patient
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text="Tools">
          <Dropdown.Menu>
            <Dropdown.Item as="a" href="/#/SimulateSock">
              Simulate Sock
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/PatientLog">
              Patient Log
            </Dropdown.Item>
            {/*<Dropdown.Item as="a" href="/#/sms-drip">*/}
            {/*  SMS Drip*/}
            {/*</Dropdown.Item>*/}
            {isAdmin && (
              <>
                <Dropdown.Item as="a" href="/#/DMTTemplateManagement">
                  DMT Template
                </Dropdown.Item>
                <Dropdown.Item as="a" href="/#/UpgradeApp">
                  Upgrade App
                </Dropdown.Item>
                {/*<Dropdown.Item as="a" href="/#/ProgramStatus">*/}
                {/*  Program Status*/}
                {/*</Dropdown.Item>*/}
                <Dropdown.Item as="a" href="/#/icd-10-code">
                  ICD 10 Code Manager
                </Dropdown.Item>
                <Dropdown.Item as="a" href="/#/medical-condition">
                  Medical Condition Manager
                </Dropdown.Item>
                {process.env.REACT_APP_ENV === "dev" && (
                  <Dropdown.Item as="a" href="/#/sign-demo">
                    Sign Demo
                  </Dropdown.Item>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text="Favorite">
          <Dropdown.Menu>
            <Dropdown.Item as="a" href="/#/FavoriteList">
              Favorite Patients
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Menu position="right">
          <Menu.Item
            onContextMenu={e => {
              if (!this.rightClickVersion) {
                this.rightClickVersion = 1;
              } else {
                this.rightClickVersion++;
                if (this.rightClickVersion === 3) {
                  this.rightClickVersion = 0;
                  message.info("ENV:" + process.env.REACT_APP_ENV);
                  e.preventDefault();
                }
              }
            }}
          >
            Version : {dev_version}
            <br />
            Build Date : {dev_version_date}
          </Menu.Item>
          <Dropdown item simple text={this.props.authData.username}>
            <Dropdown.Menu>
              <Dropdown.Item>
                <ProfileSetting />
              </Dropdown.Item>
              <Dropdown.Item onClick={this.onLogoutClick.bind(this)}>
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }

  render() {
    const isThirdParty =
      this.props.userGroups.length === 1 &&
      this.props.userGroups.includes("third_party");
    if (!this.props.userId) {
      return <div></div>;
    }
    if (isThirdParty) {
      return this.third_party_layout();
    }
    return (
      <Layout style={{ backgroundColor: "white" }}>
        <Header
          style={{
            position: "fixed",
            width: "100%",
            height: 54,
            zIndex: 100,
            background: "#fff",
            padding: 0
          }}
        >
          {this.renderNaivationBar()}
        </Header>
        <Content style={{ backgroundColor: "white", marginTop: 64 }}>
          <div
            style={{
              background: "#fff",
              padding: 24,
              paddingTop: 0,
              minHeight: 280
            }}
            className="App"
          >
            <Route
              miss
              index
              cache="parent"
              path="/"
              component={DeveloperHome}
            />
            {/*<Route path="/IRBList" component={IRBList} />*/}
            {/*<Route cache="parent" path="/TrialHome" component={TrialHome} />*/}
            <Route
              cache="parent"
              path="/ReportSetting"
              component={ReportSetting}
            />
            <Route
              cache="parent"
              path="/ReportHistory"
              component={ReportHistory}
            />
            <Route cache="parent" path="/CustomReport" component={ReportSite} />
            <Route
              cache="parent"
              path="/SimulateSock"
              component={SimulateSock}
            />
            <Route cache="parent" path="/PatientLog" component={PatientLog} />
            <Route cache="parent" path="/UpgradeApp" component={UpgradeApp} />
            <Route
              cache="parent"
              path="/ProgramStatus"
              component={ProgramStatus}
            />
            <Route cache="parent" path="/NewPatients" component={NewPatients} />
            <Route path="/SirenStaffing" component={SirenStaffing} />
            <Route
              cache="parent"
              path="/DeletePatients"
              component={DeletePatients}
            />
            {/*<Route path="/UserBoxMgmt" component={UserBoxMgmt} />*/}
            <Route path="/BoxManagement" component={BoxManagement} />
            <Route path="/HubManagement" component={HubManagement} />
            <Route path="/PupPatients" component={DeveloperPupHome} />
            <Route path="/ShoeTagManagement" component={ShoeTagManagement} />
            <Route
              path="/PillBottleManagement"
              component={PillBottleManagement}
            />
            <Route path="/ReplaceSocks" component={ReplaceSocks} />
            <Route path="/sockstatus" component={sockstatus} />
            <Route path="/SirenSock" component={SirenSock} />
            <Route path="/MedicalDetailsHome" component={MedicalDetailsHome} />
            <Route path="/LpnPatient" component={LpnPatient} />
            <Route
              path="/BrokenMedicalDetails"
              component={BrokenMedicalDetails}
            />
            <Route path="/NewAccount" component={NewAccount} />
            <Route path="/CreatePatient" component={CreatePatient} />
            <Route
              path="/AdminUserManagement"
              component={AdminUserManagement}
            />
            <Route path="/LpnManagement" component={LpnManagement} />
            <Route path="/SiteManagement" component={SiteEMRManagement} />
            <Route path="/StaffList" component={StaffList} />
            <Route path="/About" component={About} />
            <Route path="/InvoiceReport" component={InvoiceReport} />
            <Route path="/VaReport" component={ReportReviewed_va} />
            <Route path="/UserReportTable" component={UserReportTable} />
            <Route path="/Battery" component={Battery} />
            <Route path="/TagBattery" component={TagBattery} />
            <Route path="/SockBattery" component={SockBattery} />
            <Route
              path="/TagBatteryEquipment"
              component={TagBatteryEquipment}
            />
            <Route path="/FavoriteList" component={FavoriteList} />
            {/*<Route path="/ManagementSummary" component={ManagementSummary} />*/}
            <Route path="/Summary" component={Summary} />
            <Route path="/MonthlySiteSummary" component={SiteSummary} />
            <Route
              cache
              path="/RecordingNeedsReview"
              component={RecordingNeedsReview}
            />
            <Route
              cache
              path="/RecordingReviewed"
              component={RecordingReviewed}
            />
            <Route cache path="/RecordingReport" component={RecordingReport} />
            <Route
              cache
              path="/MonitoringNeedsReview"
              component={MonitoringNeedsReview}
            />
            <Route
              cache
              path="/MonitoringReviewed"
              component={MonitoringReviewed}
            />
            <Route
              cache
              path="/MonitoringReport"
              component={MonitoringReport}
            />
            <Route cache path="/PCMReport" component={PCMReport} />
            <Route cache path="/PCMNeedsReview" component={PCMNeedsReview} />
            <Route cache path="/PCMReviewed" component={PCMReviewed} />
            <Route
              path="/DMTTemplateManagement"
              component={DMTTemplateManagement}
            />
            {/*<Route path="/sms-drip" component={SMSDripList} />*/}
            <Route path="/icd-10-code" component={ICD10CodesManager} />
            <Route
              path="/medical-condition"
              component={MedicalConditionManager}
            />
            <Route path="/sign-demo" component={SignDemo} />
          </div>
        </Content>
        <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
          <img src={logo} style={{ height: 40 }} /> © {moment().format("YYYY")}{" "}
          Siren Care | All rights reserved
        </Footer>
      </Layout>
    );
  }

  third_party_layout() {
    return (
      <Layout style={{ backgroundColor: "white" }}>
        <Header
          style={{
            position: "fixed",
            width: "100%",
            height: 54,
            zIndex: 100,
            background: "#fff",
            padding: 0
          }}
        >
          <Menu
            style={{
              display:
                this.url && this.url.includes("SirenSock") ? "none" : "flex"
            }}
          >
            <Menu.Item as="a" href="/#/" header>
              <Image size="mini" src={logo} style={{ marginRight: "1.5em" }} />
              SirenCare
            </Menu.Item>
            <Menu.Item as="a" href="/#/BoxManagement">
              Siren Socks
            </Menu.Item>

            <Menu.Menu position="right">
              <Menu.Item
                onContextMenu={e => {
                  if (!this.rightClickVersion) {
                    this.rightClickVersion = 1;
                  } else {
                    this.rightClickVersion++;
                    if (this.rightClickVersion === 3) {
                      this.rightClickVersion = 0;
                      message.info("ENV:" + process.env.REACT_APP_ENV);
                      e.preventDefault();
                    }
                  }
                }}
              >
                Version : {dev_version}
                <br />
                Build Date : {dev_version_date}
              </Menu.Item>
              <Dropdown item simple text={this.props.authData.username}>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <ProfileSetting />
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.onLogoutClick.bind(this)}>
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Menu>
        </Header>
        <Content style={{ backgroundColor: "white", marginTop: 64 }}>
          <div
            style={{
              background: "#fff",
              padding: 24,
              paddingTop: 0,
              minHeight: 280
            }}
            className="App"
          >
            <Route
              miss
              index
              cache="parent"
              path="/"
              component={DeveloperHome}
            />
            <Route path="/BoxManagement" component={BoxManagement} />
          </div>
        </Content>
        <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
          <img src={logo} style={{ height: 40 }} /> © {moment().format("YYYY")}{" "}
          Siren Care | All rights reserved
        </Footer>
      </Layout>
    );
  }
}

const mapStateToProp = state => ({
  userId: state.authGroupStore.profile.loginId,
  dev_env: state.patientsListStore.dev_env,
  userGroups: state.authGroupStore.session.userGroups
});
const mapDispatchToProp = dispatch => ({
  changeTempUnit: tempUnit => dispatch(changeTempUnitState(tempUnit)),
  shipAuthData: authData => dispatch(shipAuthData(authData)),
  clearUserGroup: userGroup => dispatch(clearUserGroup(userGroup)),
  updateEnv: env => dispatch(updateEnv(env)),
  updateAdminUser: input => dispatch(updateAdminUserInfo(input)),
  initialICD10Codes: () => dispatch(listICD10Codes),
  initialMedicalConditions: () => dispatch(listMedicalConditions)
});

export default withAuthenticator(
  connect(mapStateToProp, mapDispatchToProp)(App)
);
