import React, { Component } from "react";
import { Button } from "antd";
import _ from "lodash";
import "../../../report/patient-medical.css";
import MedicalPop from "../../../report/medicalPop";
import DividLine from "../../../report/dividLine";
import AddContact from "./add-contact";
import SiteContactItem from "./site-contact-item";

/**
 *  Created by shengli.zhang on 12/23/24 9:25 PM
 */

export default class SiteContact extends Component {
  state = {
    addAnotherContact: false,
    anotherContactsData: this.props.data || []
  };

  render() {
    return (
      <div>
        <div
          style={{
            fontWeight: "bold",
            marginBottom: 10,
            marginTop: 10,
            display: "flex",
            fontSize: 18
          }}
        >
          Another Contacts
          {/*<MedicalPop*/}
          {/*  title="Contacts"*/}
          {/*  content={*/}
          {/*    "Any new or changed symptoms, such as increased pain, redness, swelling, blisters, new openings or ingrown toenail reported by the patient. "*/}
          {/*  }*/}
          {/*/>*/}
        </div>
        <div>
          {this.state.anotherContactsData.map((data, index) => (
            <SiteContactItem
              style={{ marginLeft: 16 }}
              data={data}
              key={data.addTime}
              siteId={this.props.siteId}
              isEdit={this.props.isEdit}
              saveAction={data => {
                const nextanotherContactsData = [
                  ...this.state.anotherContactsData
                ];
                nextanotherContactsData.splice(index, 1, data);
                this.setState({ anotherContactsData: nextanotherContactsData });
                if (this.props.onContactChange) {
                  this.props.onContactChange(nextanotherContactsData);
                }
              }}
              deleteAction={() => {
                const nextanotherContactsData = [
                  ...this.state.anotherContactsData
                ];
                nextanotherContactsData.splice(index, 1);
                this.setState({ anotherContactsData: nextanotherContactsData });
                if (this.props.onContactChange) {
                  this.props.onContactChange(nextanotherContactsData);
                }
              }}
            />
          ))}
        </div>
        <div>
          {this.state.addAnotherContact && (
            <AddContact
              style={{
                marginTop: 8
              }}
              siteId={this.props.siteId}
              isEdit={this.props.isEdit}
              saveAction={data => {
                const nextanotherContactsData = [
                  ...this.state.anotherContactsData,
                  data
                ];
                this.setState({
                  anotherContactsData: nextanotherContactsData,
                  addAnotherContact: false
                });
                if (this.props.onContactChange) {
                  this.props.onContactChange(nextanotherContactsData);
                }
              }}
              removeAction={() => {
                this.setState({
                  addAnotherContact: false
                });
              }}
            />
          )}
        </div>
        <Button
          type="link"
          style={{
            marginLeft: -16
          }}
          onClick={() => {
            this.setState({
              addAnotherContact: true
            });
          }}
        >
          + Add another contact
        </Button>
        <DividLine />
      </div>
    );
  }
}
