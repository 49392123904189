import { Checkbox, Input, InputNumber, Select } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-tabs/style/react-tabs.css";
import { Button, Modal, Icon } from "semantic-ui-react";
import DividLine from "./dividLine";
import FootCondition from "./footCondition";
import FootSymptom from "./footSymptom";
import FootTreatment from "./footTreatment";
import PatientMedicalHistory from "./patientMedicalHistory";
import SpecialCasePop from "./specialCasePop";
import {
  ServiceOptionList,
  ServiceOptionList_PCM
} from "../tools/patient-medical";
import PatientICDCode from "./patientICDCode";
import { removeAllSpace } from "../tools/stringProcess";
import { isEmpty } from "lodash";
const { Option } = Select;
const cptCodes = [
  "99490 & 99439",
  "99426 & 99427",
  "99457 & 99458",
  "98980 & 98981"
];
const program2CPT = {
  RPM: "99457 & 99458",
  RTM: "98980 & 98981",
  PCM: "99426 & 99427",
  CCM: "99490 & 99439"
};
class Show99457Service extends Component {
  state = {
    open: false,
    diagnosisCodes: [],
    medicalCondition: [],
    footSpecific: [],
    footAmputation: []
  };

  handleOpen = () => {
    console.log("handle show open");
    this.props.isEdit(true);
    this.setState({
      open: true,
      diagnosisCodes: this.props.serviceInfo.diagnosisCodes
        ? this.props.serviceInfo.diagnosisCodes.split("_")
        : [],
      medicalCondition: this.props.serviceInfo.medicalCondition
        ? this.props.serviceInfo.medicalCondition.split(",")
        : [],
      footSpecific: this.props.serviceInfo.footSpecific
        ? this.props.serviceInfo.footSpecific.split(",")
        : [],
      footAmputation: this.props.serviceInfo.footAmputation
        ? this.props.serviceInfo.footAmputation.split(",")
        : []
    });
  };

  close = () => {
    this.props.isEdit(false);
    this.setState({
      open: false
    });
  };

  renderService = service => {
    return service.map((v, i) => (
      <div style={{ marginTop: 10 }} key={i}>
        <Checkbox value={v.option} style={{ width: "100%" }}>
          <strong
            style={{ paddingLeft: 4, paddingRight: 4, display: "inline-block" }}
          >
            {v.option}.
          </strong>
          <span
            style={{
              display:
                v.option === "D1" || v.option === "D2"
                  ? "inline"
                  : "inline-block",
              width: "calc(100% - 60px)",
              verticalAlign: "top"
            }}
          >
            {v.brief} <strong>{v.memo}</strong>
          </span>
        </Checkbox>
      </div>
    ));
  };

  renderDescSection(item, i) {
    return (
      <div style={{ marginTop: 15 }}>
        <div style={{ display: "flex" }}>
          <div>
            <div style={{ display: "flex" }}>
              <strong>Program</strong> <p style={{ color: "red" }}>*</p>
            </div>
            <Select
              value={item.program}
              onChange={value => this.handleProgramChange(value, i)}
              style={{ width: 200, marginTop: 10, marginBottom: 15 }}
              placeholder={"Select a program"}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
              disabled
            >
              {this.props.patientDetails &&
                this.props.patientDetails.program &&
                this.props.patientDetails.program.map((p, index) => {
                  return (
                    <Option value={p} key={index}>
                      {p}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div style={{ marginLeft: 20 }}>
            <div style={{ display: "flex" }}>
              <strong>CPT Codes</strong> <p style={{ color: "red" }}>*</p>
            </div>
            <Select
              value={item.cptCode ? item.cptCode : program2CPT[item.program]}
              style={{ width: 200, marginTop: 10, marginBottom: 15 }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
              disabled
            >
              {cptCodes.map((p, index) => {
                return (
                  <Option value={p} key={index}>
                    {p}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <div>
              <strong>Doctor Time</strong>
            </div>
            <InputNumber
              min={0}
              value={item.doctorTime}
              onChange={value => this.onDoctorTimeChanged(value, i)}
              style={{ width: 150, marginTop: 5 }}
              type={"number"}
              disabled
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <div>
              <strong>Nurse Time</strong>
            </div>
            <InputNumber
              min={0}
              value={item.nurseTime}
              onChange={value => this.onNurseTimeChanged(value, i)}
              style={{ width: 150, marginTop: 5 }}
              type={"number"}
              disabled
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <div>
              <strong>Total Time (mins)</strong>
            </div>
            <InputNumber
              min={0}
              value={item.doctorTime + item.nurseTime}
              style={{ width: 150, marginTop: 5 }}
              type={"number"}
              disabled
            />
          </div>
        </div>
        <div style={{ height: 40 }} />
        <strong>
          {item.program === "PCM"
            ? "Select the Principal Care Management (PCM) "
            : "Select the remote monitoring treatment management "}
          services provided:
        </strong>
        <Checkbox.Group
          disabled
          value={
            !isEmpty(item.rpmService)
              ? removeAllSpace(item.rpmService).split(",")
              : null
          }
        >
          {item.program === "PCM"
            ? this.renderService(ServiceOptionList_PCM)
            : this.renderService(ServiceOptionList)}
        </Checkbox.Group>
        <div style={{ marginTop: 15, display: "flex" }}>
          <strong>Details</strong> <p style={{ color: "red" }}>*</p>
        </div>
        <Input.TextArea
          autoSize={{
            minRows: 4
          }}
          value={item.description}
          onChange={e => this.onDescriptionChanged(e, i)}
          style={{ marginTop: 5 }}
          disabled
        />
        {item.addendums &&
          item.addendums.map((p, i) => {
            return (
              <div style={{ marginTop: 10 }} key={i}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 12
                    }}
                  >
                    Addendum
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      marginLeft: 15,
                      paddingTop: "4px",
                      fontStyle: "oblique"
                    }}
                  >
                    Added by {p.userName} on {p.addendumTime}
                  </div>
                </div>
                <Input.TextArea
                  autoSize={{
                    minRows: 4
                  }}
                  value={p.addendum}
                  disabled
                />
              </div>
            );
          })}
      </div>
    );
  }

  render() {
    let serviceProvided = this.props.serviceInfo.rpmService
      ? this.props.serviceInfo.rpmService.replace(/\s*/g, "").split(",")
      : [];
    let specialCaseReason = this.props.patientDetails.specialCaseReason;
    if (specialCaseReason) {
      specialCaseReason = specialCaseReason.split(",");
    }
    return (
      <Modal
        trigger={
          <Button size="mini" style={{ display: "none" }}>
            Preview
          </Button>
        }
        onOpen={this.handleOpen}
        onClose={this.close}
        open={this.state.open}
      >
        <Modal.Header>DMT Preview</Modal.Header>
        <Modal.Content scrolling style={{ padding: "1.5rem 3rem" }}>
          <div style={{ fontSize: 14 }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <div>
                      <strong>Service Date</strong>
                    </div>
                    <Input
                      disabled
                      value={moment(this.props.serviceInfo.serviceDate).format(
                        "ddd, Do MMM YYYY"
                      )}
                      style={{ width: 200, marginTop: 10 }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <div style={{ width: 200 }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginBottom: 10,
                        display: "flex"
                      }}
                    >
                      Special Case
                      <SpecialCasePop />
                    </div>
                    <Input
                      disabled
                      value={this.props.patientDetails.specialCase}
                      placeholder="N/A"
                    />
                  </div>
                  <div style={{ width: 400, marginLeft: 20 }}>
                    <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                      Reason
                    </div>
                    {Array.isArray(specialCaseReason) ? (
                      <Select
                        value={specialCaseReason}
                        style={{ width: "100%" }}
                        mode="multiple"
                        disabled
                      ></Select>
                    ) : (
                      <Input disabled value={specialCaseReason} />
                    )}
                  </div>
                </div>
                <DividLine />
                {serviceProvided.includes("E") && (
                  <FootSymptom
                    readOnly
                    data={this.props.serviceInfo.symptoms}
                  />
                )}
                <PatientMedicalHistory
                  readOnly
                  medicalCondition={this.state.medicalCondition}
                  footSpecific={this.state.footSpecific}
                  footAmputation={this.state.footAmputation}
                  preview={true}
                />
                <DividLine />
                <PatientICDCode
                  canEdit={false}
                  diagnosisCodes={this.state.diagnosisCodes}
                />
                <DividLine />
                {serviceProvided.includes("C") && (
                  <FootCondition serviceInfo={this.props.serviceInfo} />
                )}
                <FootTreatment serviceInfo={this.props.serviceInfo} />
                <div style={{ marginTop: 20 }}>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 12
                    }}
                  >
                    Notes
                  </div>
                  {this.props.serviceInfo.programNotes.map((item, i) => {
                    return (
                      <div key={i}>
                        {i > 0 && <DividLine />}
                        {this.renderDescSection(item, i)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button color="green" onClick={this.close}>
            <Icon name="checkmark" />
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  patientDetails: state.patientsListStore.doctorDetails
});

export default connect(mapStateToProp, null)(Show99457Service);
