import { Button, DatePicker, Select } from "antd";
import _ from "lodash";
import QRCode from "qrcode.react";
import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Icon, Popup } from "semantic-ui-react";
import DateInput from "../common/DateInput";
import { getExpireHubs, searchHubList } from "../graphql/API";
import {
  InputEnterKeyTriggerFilterComponent,
  transformHubTableParams
} from "../tools/reactTablePatch";
import "./BoxList.css";
import HubStatus from "./HubStatus";
import RegisterHub from "./registerHub";
import UnRegisterHub from "./unregisterHub";
import moment from "moment";
import { downloadCSV } from "../tools/downloadFile";

const { Option } = Select;

export default class HubManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      loading: false,
      totalDataSize: 0,
      exportMonth: moment().format("YYYY-MM"),
      exportLoading: false
    };

    // table state is not full controlled. filter, sort is controlled by table itself
    this.tableInstance = null;
    this.debounceRequest = false;
  }

  reqHubList = () => {
    this.debounceReqHubList();
    if (!this.debounceRequest) {
      this.debounceReqHubList.flush();
    }
  };

  debounceReqHubList = _.debounce(() => {
    const { filter, sort, pagination } = transformHubTableParams(
      this.tableInstance
    );

    this.rawReqHubList(filter, sort, pagination);
  }, 3000);

  rawReqHubList(filter, sort, pagination) {
    this.debounceRequest = false;
    this.setState({
      loading: true
    });

    searchHubList(filter, sort, pagination)
      .then(data => {
        this.setState({
          listData: data.items,
          totalDataSize: data.total
        });
      })
      .catch(error => {
        console.log("error:", error);
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  downloadReport = () => {
    this.setState({
      exportLoading: true
    });
    const ProgramValue2Name = {
      Pending: "Pending",
      ON_SERVICE: "On Service",
      NOT_ON_SERVICE: "Off Service"
    };
    const DeviceValue2Name = {
      ACTIVE: "Active",
      IDLE: "Idle",
      PAUSED: "Paused",
      NOT_ON_SERVICE: "Canceled"
    };
    getExpireHubs(this.state.exportMonth)
      .then(data => {
        this.setState({
          exportLoading: false
        });

        // ccid is string combined with numbers, add \t in tail, so it can display normal
        data = data.map(
          hub =>
            `"${hub.ccid}\t",${hub.hubId},${hub.sku || "HCUBR"},${
              hub.registered
            },${hub.hubLastSeen},${hub.lastSeen},${hub.canRegistered},${
              hub.sirenId
            },"${hub.siteId}\t",${hub.firstName},${hub.lastName},${
              hub.lastWornDay
            },${hub.accountServiceStatus},${hub.expireDate},${
              hub.rpmProgramStatus
                ? ProgramValue2Name[hub.rpmProgramStatus]
                : hub.rpmProgramStatus
            },${
              hub.rpmDeviceStatus
                ? DeviceValue2Name[hub.rpmDeviceStatus]
                : hub.rpmDeviceStatus
            }`
        );
        data.unshift(
          'ccid,hubId,sku,registration,hubLastSeen,hubDataLastSeen,"hub on service",sirenId,siteId,firstName,lastName,lastWornDay,accountServiceStatus,expireDate,rpmProgramStatus,rpmDeviceStatus'
        );
        downloadCSV(
          data.join("\n"),
          `expire-hubs-${this.state.exportMonth}.csv`
        );
      })
      .catch(error => {
        console.log("error:", error);
      });
  };

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        defaultValue={filter ? filter.value : null}
        onPressEnter={value => {
          this.debounceRequest = false;
          return onChange(value);
        }}
        onChange={value => {
          this.debounceRequest = true;
          return onChange(value);
        }}
      />
    );
  };

  render() {
    return (
      <div>
        <div style={{ textAlign: "left", marginBottom: 12 }}>
          <p>download the expired hub list</p>
          <DatePicker.MonthPicker
            onChange={(_, dateString) => {
              this.setState({ exportMonth: dateString });
            }}
            placeholder="Select month"
          />
          <Button
            type="primary"
            onClick={this.downloadReport}
            style={{ marginLeft: 12 }}
            loading={this.state.exportLoading}
          >
            Export
          </Button>
        </div>
        <ReactTable
          className="-striped -highlight"
          minRows={0}
          manual
          loading={this.state.loading}
          defaultPageSize={20}
          filterable
          FilterComponent={InputEnterKeyTriggerFilterComponent({
            onChange: () => {
              this.debounceRequest = true;
            },
            onPressEnter: () => {
              this.debounceRequest = false;
            }
          })}
          defaultSorted={[{ id: "registeredAt", desc: true }]}
          pages={
            !this.tableInstance
              ? -1
              : Math.ceil(
                  this.state.totalDataSize / this.tableInstance.state.pageSize
                )
          }
          onFetchData={(_, instance) => {
            // there are some other callbacks, like onPageChange onPageSizeChange etc,
            // we don't care them. this callback is enough
            this.tableInstance = instance;

            this.reqHubList();
          }}
          data={this.state.listData}
          resolveData={data =>
            data.map(hub => {
              const hubType = hub
                ? hub.hubId.includes("H2G") === true
                  ? "2G"
                  : hub.hubId.includes("H4G") === true
                  ? "4G"
                  : hub.hubId.includes("H4G") === false &&
                    hub.hubId.includes("H2G") === false
                  ? "Wifi"
                  : ""
                : "";

              let sku = hub.sku || "HCUBR";

              const korestatus = hub.korestatus
                ? this.capitalizeFirstLetter(hub.korestatus)
                : hub.korestatus;

              return {
                ...hub,
                hubType,
                sku,
                korestatus
              };
            })
          }
          columns={[
            {
              Header: "Hub ID",
              accessor: "hubId"
            },
            {
              Header: "SKU",
              accessor: "sku"
            },
            {
              Header: "Auth Code",
              accessor: "authcode"
            },
            {
              Header: "Registration Status",
              accessor: "registered",
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value={"true"}>Yes</Option>
                  <Option value={"false"}>No</Option>
                </Select>
              ),
              Cell: props => {
                if (typeof props.value === "boolean") {
                  return props.value ? "Yes" : "No";
                }
                return "N/A";
              }
            },
            {
              Header: "Registration Date",
              accessor: "registeredAt",
              Filter: this.dateInputComponent,
              Cell: props => {
                if (props.value) {
                  return props.value.substring(0, 10);
                }
              }
            },
            {
              Header: "Siren ID",
              accessor: "sirenId",
              sortable: false,
              filterable: false
            },
            {
              Header: "Name",
              accessor: "name",
              sortable: false,
              filterable: false
            },
            {
              Header: "Manufacture Date",
              accessor: "createdAt",
              Filter: this.dateInputComponent,
              Cell: props => {
                if (props.value) {
                  return props.value.substring(0, 10);
                }
              }
            },
            {
              Header: "SIM Status",
              accessor: "korestatus",
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                  <Option value="new">New</Option>
                  <Option value="schedule">Schedule</Option>
                </Select>
              )
            },
            {
              Header: "CCID",
              accessor: "koreccid",
              Cell: props => {
                return (
                  <Popup
                    trigger={
                      <p className="sr-text-ellipsis">
                        {props.original.koreccid}
                      </p>
                    }
                  >
                    <p>{props.original.koreccid}</p>
                  </Popup>
                );
              }
            },
            {
              Header: "Hub Type",
              accessor: "hubType",
              sortable: false,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .includes(input.toLowerCase()) === true
                    );
                  }}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  {/*<Option value="Wifi">Wifi</Option>*/}
                  {/*<Option value="2G">2G</Option>*/}
                  <Option value="4G">4G</Option>
                  {/*<Option value="Wifi, 2G">Wifi, 2G</Option>*/}
                  {/*<Option value="Wifi, 4G">Wifi, 4G</Option>*/}
                  {/*<Option value="2G, 4G">2G, 4G</Option>*/}
                  {/*<Option value="Wifi, 2G, 4G">Wifi, 2G, 4G</Option>*/}
                </Select>
              )
            },
            {
              Header: "Firmware Version",
              accessor: "fwVersion"
            },
            {
              Header: "On Service",
              accessor: "canRegistered",
              sortable: false,
              Cell: props => {
                return (
                  <HubStatus
                    hubId={props.original.hubId}
                    canRegistered={props.original.canRegistered || "Yes"}
                    canRegisteredReason={props.original.canRegisteredReason}
                    onChange={result => {
                      const nextListData = [...this.state.listData];
                      const item = nextListData.find(
                        item => item.hubId === result.hubId
                      );
                      item.canRegistered = result.canRegistered;
                      item.canRegisteredReason = result.canRegisteredReason;

                      this.setState({
                        listData: nextListData
                      });
                    }}
                  />
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "Actions",
              accessor: "",
              sortable: false,
              filterable: false,
              Cell: props => {
                return (
                  <div className="boxlistdiv">
                    {props.original.registered ? (
                      <UnRegisterHub
                        item={props.original}
                        onChange={result => {
                          const nextListData = [...this.state.listData];
                          const item = nextListData.find(
                            item => item.hubId === result.serial
                          );
                          item.registered = false;

                          this.setState({
                            listData: nextListData
                          });
                        }}
                      />
                    ) : (
                      <RegisterHub
                        item={props.original}
                        onChange={result => {
                          const nextListData = [...this.state.listData];
                          const item = nextListData.find(
                            item => item.hubId === result.serial
                          );
                          item.registered = true;
                          item.userId = result.userId;

                          this.setState({
                            listData: nextListData
                          });
                        }}
                      />
                    )}
                  </div>
                );
              }
            },
            {
              Header: "QR Code",
              accessor: "",
              sortable: false,
              filterable: false,
              Cell: props => {
                return (
                  <div className="boxlistdiv">
                    <Popup
                      trigger={
                        <Icon as="i" name="qrcode" className="view-more-eye" />
                      }
                      position="right center"
                      hoverable
                      flowing
                    >
                      <Popup.Header>
                        QR Code for {props.original.hubId}
                      </Popup.Header>
                      <Popup.Content>
                        <QRCode
                          value={
                            props.original.hubId + "&" + props.original.authcode
                          }
                          size={128}
                          bgColor={"#ffffff"}
                          fgColor={"#000000"}
                          level={"L"}
                        />
                      </Popup.Content>
                    </Popup>
                  </div>
                );
              }
            }
          ]}
        />
      </div>
    );
  }
}
