import React, { Component } from "react";
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Dropdown,
  Modal,
  Message
} from "semantic-ui-react";
import ButtonSuccess from "../../../home/buttonSuccess";
import _, { isEmpty } from "lodash";
import { Modal as AntdModal, notification } from "antd";
import { getBoxInfo, registerSock } from "../../../action/boxAction";
import { createNote } from "../../../action/DoctorNoteAction";
import { connect } from "react-redux";

class AddBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      boxId: "",
      authCode: "",
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null,
      reqBoxId: ""
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null
    });
  };

  close = () => {
    this.setState({
      open: false,
      boxId: "",
      authCode: "",
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null
    });
    if (typeof this.props.onClose === "function") {
      this.props.onClose(true);
    }
  };

  changeReqBoxId = event => {
    this.setState({ reqBoxId: event.target.value });
  };

  handleSearch = async () => {
    if (_.isEmpty(this.state.reqBoxId)) {
      AntdModal.warning({
        title: "Oops",
        content: "Please input Box Id.",
        centered: true
      });
      return;
    }
    try {
      const res = await getBoxInfo(this.state.reqBoxId);
      if (res.data.lambdaSearchBoxes.items.length > 0) {
        if (res.data.lambdaSearchBoxes.items[0].registered) {
          AntdModal.info({
            title: "Oops",
            content: `Box ${this.state.reqBoxId} has been registered, please unregister it first.`,
            centered: true
          });
          return;
        }
        const boxId = res.data.lambdaSearchBoxes.items[0].id;
        const reqBoxInfo = res.data.lambdaSearchBoxes.items[0].socks;
        const size = res.data.lambdaSearchBoxes.items[0].size;
        const color = res.data.lambdaSearchBoxes.items[0].color;
        const authCode = res.data.lambdaSearchBoxes.items[0].authCode;
        this.setState({ reqBoxInfo, color, size, boxId, authCode });
      }
    } catch (err) {
      console.log(`Search box(${this.state.reqBoxId}) info error: `, err);
    }
  };

  handleKeyPress = async e => {
    // We pass the new value of the text when calling onAccept
    if (e.key === "Enter") {
      // hack reset page
      const res = await getBoxInfo(this.state.reqBoxId);
      if (res.data.lambdaSearchBoxes.items.length > 0) {
        if (res.data.lambdaSearchBoxes.items[0].registered) {
          AntdModal.info({
            title: "Oops",
            content: `Box ${this.state.reqBoxId} has been registered, please unregister it first.`,
            centered: true
          });
          return;
        }
        const boxId = res.data.lambdaSearchBoxes.items[0].id;
        const reqBoxInfo = res.data.lambdaSearchBoxes.items[0].socks;
        const size = res.data.lambdaSearchBoxes.items[0].size;
        const color = res.data.lambdaSearchBoxes.items[0].color;
        const authCode = res.data.lambdaSearchBoxes.items[0].authCode;
        this.setState({ reqBoxInfo, color, size, boxId, authCode });
      }
    }
  };

  submit = () => {
    if (isEmpty(this.state.boxId) || isEmpty(this.state.authCode)) {
      notification.warn({
        message: "System Warning",
        description: "BoxId or AuthCode can not be none."
      });
      return;
    }
    const creds = {
      boxId: this.state.boxId,
      authCode: this.state.authCode,
      userId: this.props.doctorDetails.userId
    };
    registerSock(creds)
      .then(res => {
        if (res.success) {
          this.setState({ confirmSuccess: true });
          // automatically close after success animation
          setTimeout(() => {
            this.setState({
              open: false
            });
          }, 500);
        } else {
          let desc = null;
          if (res.msg) {
            desc = res.msg;
          } else if (res.error) {
            if (res.error.msg) {
              desc = res.error.msg;
            } else if (res.error instanceof Error) {
              desc = res.error.message;
            } else {
              desc = JSON.stringify(res.error);
            }
          } else {
            desc = JSON.stringify(res);
          }
          notification.error({
            message: "System Error",
            description: desc
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        if (error instanceof Error) {
          return notification.error({
            message: "System Error",
            description: error.message
          });
        }
        // graphQL error
        if (error.errors) {
          notification.error({
            message: "System Error",
            description: error.errors[0].message
          });
        }
      });
  };

  render() {
    const { open } = this.state;

    return (
      <Modal
        trigger={
          <Button size="small" onClick={this.handleOpen}>
            Add Box
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Add Box</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <div className="replace_input_box">
                <Input
                  placeholder="Input Box Id"
                  className="replace_input_content"
                  onChange={this.changeReqBoxId}
                  value={this.state.reqBoxId.toUpperCase()}
                  onKeyPress={this.handleKeyPress}
                />
                <Button
                  size="small"
                  onClick={this.handleSearch}
                  className="replace_input_button"
                >
                  Load
                </Button>
              </div>

              <div className="replace_input_box">
                <Input
                  placeholder="Box Id"
                  className="replace_input_content"
                  value={this.state.boxId}
                  disabled
                />
                <Input
                  placeholder="Auth Code"
                  className="replace_input_content"
                  value={this.state.authCode}
                  disabled
                />
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={this.submit}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Add
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.close()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails
});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(AddBox);
