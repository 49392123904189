import React, { Component } from "react";
import { Select, Input, Modal, Button, Row, Col } from "antd";
import { EMAIL_RECEIVED } from "../../../tools/patient-medical";
import "../../../report/patient-medical.css";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { getlistStaff } from "../../../action/registerAction";
import { connect } from "react-redux";

/**
 *  Created by shengli.zhang on 12/23/24 9:25 PM
 */
const { Option } = Select;

const DOCTOR_IN_SITE = [];

class AddContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactDoctor: [],
      emailReceive: []
    };
  }

  handleContactChange = value => {
    console.log("handleContactChange value: ", value);
    if (value.length > 0)
      this.setState({ contactDoctor: [value[value.length - 1]] });
    else this.setState({ contactDoctor: value });
  };

  handleEmailReceiveChange = value => {
    if (this.checkContact(value)) {
      this.setState({ emailReceive: value });
    }
  };

  checkContact = selectEmailReceive => {
    if (this.state.contactDoctor.length === 0) {
      Modal.warning({
        title: "Save Contact Failed",
        content: "Please select or input contact.",
        centered: true
      });
      return false;
    }
    if (selectEmailReceive.length === 0) {
      Modal.warning({
        title: "Save Contact Failed",
        content: "Please select email receive.",
        centered: true
      });
      return false;
    }
    return true;
  };

  saveContact = () => {
    if (this.state.contactDoctor.length === 0) {
      return;
    }
    if (this.props.saveAction) {
      this.props.saveAction({
        addTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        contactDoctor: this.state.contactDoctor.join(","),
        emailReceive: this.state.emailReceive.join(",")
      });
    }
    this.clearContact();
  };

  clearContact = () => {
    this.setState({
      contactDoctor: [],
      emailReceive: []
    });
  };

  removeContact = () => {
    if (this.props.removeAction) {
      this.clearContact();
      this.props.removeAction();
    }
  };

  render() {
    return (
      <Row gutter={[24, 24]}>
        <Col span={10}>
          <Select
            mode="tags"
            value={this.state.contactDoctor}
            onChange={this.handleContactChange}
            style={{ width: "100%" }}
            placeholder="Contact Email"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp="children"
          >
            {this.props.listStaff
              .filter(s => s.siteId === this.props.siteId)
              .map((p, index) => (
                <Option value={p.email} key={index}>
                  {p.firstName} {p.lastName}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={12}>
          <Select
            mode="multiple"
            value={this.state.emailReceive}
            onChange={this.handleEmailReceiveChange}
            style={{ width: "100%" }}
            placeholder="Email Received"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onBlur={event => this.saveContact()}
            showSearch
            optionFilterProp="children"
          >
            {EMAIL_RECEIVED.map((p, index) => {
              return (
                <Option value={p.value} key={index}>
                  {p.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col span={2}>
          <CloseOutlined
            onClick={this.removeContact}
            style={{ marginTop: 8 }}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProp = state => ({
  listStaff: state.patientsListStore.listStaff
});

const mapDispatchToProp = dispatch => ({
  getlistStaff: () => dispatch(getlistStaff())
});
export default connect(mapStateToProp, mapDispatchToProp)(AddContact);
