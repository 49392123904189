import { Divider, Select, Tag, Popover } from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import moment from "moment";
import moment_tz from "moment-timezone";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Icon, Input } from "semantic-ui-react";
import { getlistClinicSites, getlistStaff } from "../action/registerAction";
import DateInput from "../common/DateInput";
import { patientListAPI } from "../graphql/API";
import ChangeDispensedDay from "../report/changeDispensedDay";
import "../share/common.css";
import "../share/select.css";
import { formatNumber } from "../tools/phoneFormat";
import getPatchTrProps, {
  InputEnterKeyTriggerFilterComponent,
  transformPatientTableParams
} from "../tools/reactTablePatch";
import "./DeviceList.css";
import EditCompliance from "./EditCompliance";
import SelectGroup from "./selectGroup";
import SendMess from "./SendMess";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { Auth } from "aws-amplify";
import RpmDeviceStatus from "./RpmDeviceStatus";
import RtmDeviceStatus from "./RtmDeviceStatus";
import CircleColor from "./circle-color";
import ProgramStatusDesc from "../tools/program-status-desc";
import DeviceStatusDesc from "../tools/device-status-desc";
import {
  COLOR,
  ProgramOptions,
  DeviceOptions,
  PROGRAM_DESC
} from "../constant/programDeviceStatus";
import {
  SortAscendingOutlined,
  SortDescendingOutlined
} from "@ant-design/icons";
import ChangePatientIRB from "./changePatientIRB";

const DateFormat = "YYYY-MM-DD";
const OPEN_NEW_WINDOW = require("../img/open_new_window.png");

class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: "",
      patientLoading: false,
      patientData: [],
      patientTotalSize: 0,
      patientPageSize: 20,
      patientPageNum: 0,
      patientError: null,
      rowClickedData: null,
      userGroup: [],
      rpmSort: null,
      rtmSort: null,
      pcmSort: null,
      ccmSort: null,
      socksSort: null,
      moveSort: null
    };

    // table state is not full controlled. filter, sort is controlled by table itself
    this.tableInstance = null;
    this.debounceRequest = false;
  }

  componentWillMount() {
    const { getlistClinicSites, getlistStaff } = this.props;
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }
    if (this.props.listStaff.length === 0) {
      getlistStaff();
    }
  }

  componentDidMount = async () => {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    this.setState({ userGroup: groups });
  };

  reqPatientList = () => {
    this.debounceReqPatientList();
    if (!this.debounceRequest) {
      this.debounceReqPatientList.flush();
    }
  };

  debounceReqPatientList = _.debounce(() => {
    const { filter, sort, pagination } = transformPatientTableParams(
      this.tableInstance
    );

    this.rawReqPatientList(filter, sort, pagination);
  }, 3000);

  rawReqPatientList(filter, sort, pagination) {
    this.debounceRequest = false;
    this.setState({
      patientLoading: true,
      patientError: null
    });

    patientListAPI(this.state.searchStr, filter, sort, pagination)
      .then(data => {
        let patiens = data.data.lambdaSearchPatients.items;
        let totalSize = data.data.lambdaSearchPatients.total;

        this.setState({
          patientData: patiens,
          patientTotalSize: totalSize,
          patientPageNum: pagination.page,
          patientPageSize: pagination.size
        });
      })
      .catch(error => {
        this.setState({
          patientError: error
        });
      })
      .finally(() => {
        this.setState({
          patientLoading: false
        });
      });
  }

  gotoDoctorDetailPage(id, userId, date) {
    if (!date) {
      date = moment().format("YYYY-MM-DD");
    }
    Control.go(
      "/MedicalDetailsHome?id=" +
        btoa(id) +
        "&userId=" +
        userId +
        "&startDate=" +
        date +
        "&endDate=" +
        date,
      {
        patientList: this.state.patientData.map(p => ({
          id: p.id,
          userId: p.userId
        }))
      }
    );
  }

  openPatientDetailNewWindow = (id, userId, date) => {
    const goUrl = `${window.location.href}MedicalDetailsHome?id=${btoa(
      id
    )}&userId=${userId}&startDate=${date}&endDate=${date}`;
    window.open(goUrl);
  };

  gotoSupportDetailPage(userId, userTimezone) {
    Control.go("/UserStatus?userId=" + userId + "&timeZone=" + userTimezone);
  }
  gotoStatisticsPage(id, userId, userTimezone, hubType) {
    // Control.go(
    //   "/sockstatus?id=" +
    //     btoa(id) +
    //     "&userId=" +
    //     userId +
    //     "&timeZone=" +
    //     userTimezone +
    //     "&hubType=" +
    //     hubType
    // );

    Control.go(
      "/MedicalDetailsHome?id=" +
        btoa(id) +
        "&userId=" +
        userId +
        "&startDate=" +
        moment().format("YYYY-MM-DD") +
        "&endDate=" +
        moment().format("YYYY-MM-DD") +
        "&tab=3",
      {
        patientList: this.state.patientData.map(p => ({
          id: p.id,
          userId: p.userId
        }))
      }
    );
  }

  handleKeyPress = e => {
    // We pass the new value of the text when calling onAccept
    if (e.key === "Enter") {
      // hack reset page
      this.tableInstance.state.page = 0;
      this.reqPatientList();
    }
  };

  programSort = program => {
    const nextSort = {
      asc: "desc",
      desc: null
    };
    let rpmSort = null,
      rtmSort = null,
      pcmSort = null,
      ccmSort = null;
    switch (program) {
      case "RPM":
        rpmSort = !this.state.rpmSort ? "asc" : nextSort[this.state.rpmSort];
        break;
      case "RTM":
        rtmSort = !this.state.rtmSort ? "asc" : nextSort[this.state.rtmSort];
        break;
      case "PCM":
        pcmSort = !this.state.pcmSort ? "asc" : nextSort[this.state.pcmSort];
        break;
      case "CCM":
        ccmSort = !this.state.ccmSort ? "asc" : nextSort[this.state.ccmSort];
        break;
      default:
        break;
    }
    this.setState(
      {
        rpmSort,
        rtmSort,
        pcmSort,
        ccmSort
      },
      () => {
        this.tableInstance.sortColumn({
          id: `${program.toLowerCase()}ProgramStatus`
        });
      }
    );
  };

  deviceSort = device => {
    const deviceName2Code = {
      socks: "rpm",
      move: "rtm"
    };
    const nextSort = {
      asc: "desc",
      desc: null
    };
    let socksSort = null,
      moveSort = null;
    switch (device) {
      case "Socks":
        socksSort = !this.state.socksSort
          ? "asc"
          : nextSort[this.state.socksSort];
        break;
      case "Move":
        moveSort = !this.state.moveSort ? "asc" : nextSort[this.state.moveSort];
        break;
      default:
        break;
    }
    this.setState(
      {
        socksSort,
        moveSort
      },
      () => {
        this.tableInstance.sortColumn({
          id: `${deviceName2Code[device.toLowerCase()]}DeviceStatus`
        });
      }
    );
  };

  getSockRegisterDay = socks => {
    socks.sort(function(a, b) {
      return (a.lastSeen === null) - (b.lastSeen === null);
    });
    socks.reverse();
    return _.isEmpty(socks[0].createdAt)
      ? ""
      : moment(socks[0].createdAt).format(DateFormat);
  };

  getDoctorName = doctorId => {
    const doctor = this.props.listStaff.find(e => e.email === doctorId);
    if (!doctor) {
      return "";
    }

    let name = `${doctor.firstName} ${doctor.lastName}`;
    name = name.trim();
    if (doctor.isPrescribing && !name.startsWith("Dr.")) {
      name = "Dr. " + name;
    }

    return name;
  };

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        defaultValue={filter ? filter.value : null}
        onPressEnter={value => {
          this.debounceRequest = false;
          return onChange(value);
        }}
        onChange={value => {
          this.debounceRequest = true;
          return onChange(value);
        }}
      />
    );
  };

  aweContextMenu = () => {
    return (
      <ContextMenu
        id="menu-id"
        style={{
          backgroundColor: "rgba(45,45,52,0.9)",
          width: 241,
          borderRadius: 5
        }}
        onHide={() => {
          this.setState({ rowClickedData: null });
        }}
      >
        <MenuItem
          onClick={() => {
            this.state.rowClickedData !== null &&
              this.openPatientDetailNewWindow(
                this.state.rowClickedData.id,
                this.state.rowClickedData.userId,
                moment().format(DateFormat)
              );
            this.setState({ rowClickedData: null });
          }}
        >
          <div
            style={{
              textAlign: "left",
              color: "white",
              marginLeft: 10,
              marginTop: 8,
              height: 30,
              cursor: "pointer"
            }}
          >
            <img
              src={OPEN_NEW_WINDOW}
              style={{ width: 15, height: 15, marginRight: 10 }}
            />
            Open in new window
          </div>
        </MenuItem>
      </ContextMenu>
    );
  };

  render() {
    const { Option } = Select;
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.id,
        rowInfo.original.userId,
        moment().format(DateFormat)
      );
    });
    return (
      <div className="device-list-container">
        <div className="search_patient_container">
          <Input
            placeholder="Search..."
            type="text"
            onChange={e => this.setState({ searchStr: e.target.value })}
            value={this.state.searchStr || ""}
            onKeyPress={this.handleKeyPress}
          />
          <Icon
            name="search"
            size="small"
            className="search_icon"
            onClick={e => {
              this.tableInstance.state.page = 0;
              this.reqPatientList();
            }}
          />
        </div>
        {/*{this.state.patientError && (*/}
        {/*  <Alert*/}
        {/*    message={JSON.stringify(this.state.patientError)}*/}
        {/*    type="error"*/}
        {/*    closable*/}
        {/*  />*/}
        {/*)}*/}
        <ContextMenuTrigger id="menu-id">
          <ReactTable
            id="developerHomeTable"
            className="-striped -highlight"
            getTrProps={trProps}
            getTdProps={(state, rowInfo) => {
              return {
                onContextMenu: () => {
                  this.setState({
                    rowClickedData: rowInfo.original
                  });
                }
              };
            }}
            showPaginationTop={true}
            loading={this.state.patientLoading}
            filterable
            FilterComponent={InputEnterKeyTriggerFilterComponent({
              onChange: () => {
                this.debounceRequest = true;
              },
              onPressEnter: () => {
                this.debounceRequest = false;
              }
            })}
            manual
            defaultSorted={[{ id: "lastSeen", desc: true }]}
            onSortedChange={newSorted => {
              if (!newSorted[0].id.endsWith("ProgramStatus")) {
                this.setState({
                  rpmSort: null,
                  rtmSort: null,
                  pcmSort: null,
                  ccmSort: null
                });
              }
              if (!newSorted[0].id.endsWith("DeviceStatus")) {
                this.setState({
                  socksSort: null,
                  moveSort: null
                });
              }
            }}
            pages={
              !this.tableInstance
                ? -1
                : Math.ceil(
                    this.state.patientTotalSize /
                      this.tableInstance.state.pageSize
                  )
            }
            data={this.state.patientData}
            onFetchData={(_, instance) => {
              // there are some other callbacks, like onPageChange onPageSizeChange etc,
              // we don't care them. this callback is enough
              this.tableInstance = instance;

              this.reqPatientList();
            }}
            resolveData={data =>
              data.map(p => {
                const lastSeen = p.dataLastSeen
                  ? p.dataLastSeen.split("T")[0]
                  : "";
                const timeWorn =
                  p.timeWorn > 0
                    ? Math.floor(p.timeWorn / 60) +
                      "h" +
                      ((p.timeWorn % 60) * 100) / 100 +
                      "m"
                    : "";
                const allPhone = p.phone;
                const phone = !_.isEmpty(allPhone)
                  ? formatNumber(allPhone).trim()
                  : "";
                const overallStatus =
                  p.overallStatus === "NORMAL" ? "GREEN" : p.overallStatus;
                const newgroup = !_.isEmpty(p.siteId) ? p.siteId : p.group;
                const email = p.email && p.email.includes("@") ? p.email : "";
                const accountStatus = !_.isEmpty(p.accountStatus)
                  ? p.accountStatus
                  : "ACTIVE";
                const createdAt = p.createdAt
                  ? moment_tz.tz(p.createdAt, p.userTimezone).format(DateFormat)
                  : "";
                const dispensedDay = p.dispensedDay
                  ? p.dispensedDay
                  : p.socks.length > 0
                  ? this.getSockRegisterDay(p.socks)
                  : "";
                const dob = !_.isEmpty(p.birthday)
                  ? moment(p.birthday).format(DateFormat)
                  : "";
                const appInitialized = p.appInitialized
                  ? p.appInitialized
                  : "No";
                const patientIRB =
                  p.patientIRB && p.patientIRB === "Accepted" ? "Yes" : "No";
                const hubLastSeen = p.hubLastSeen
                  ? moment_tz
                      .tz(p.hubLastSeen, p.userTimezone)
                      .format(DateFormat)
                  : "";
                return {
                  ...p,
                  lastSeen,
                  timeWorn,
                  phone,
                  newgroup,
                  overallStatus,
                  email,
                  dob,
                  accountStatus,
                  createdAt,
                  dispensedDay,
                  appInitialized,
                  patientIRB,
                  hubLastSeen
                };
              })
            }
            columns={[
              {
                Header: "SiteID",
                accessor: "newgroup",
                sortable: false,
                Filter: ({ filter, onChange }) => (
                  <Select
                    className="select_common_style"
                    onChange={onChange}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="all">All</Option>
                    {this.props.clinicSites.map((gr, i) => {
                      return (
                        <Option value={gr.siteId} key={"gr" + i}>
                          {gr.siteId}
                        </Option>
                      );
                    })}
                  </Select>
                ),
                Cell: props => {
                  return (
                    <div onClick={e => e.stopPropagation()}>
                      {props.original.siteId}
                      {!this.state.userGroup.includes("staff") && (
                        <SelectGroup item={props.original} />
                      )}
                    </div>
                  );
                }
              },
              {
                Header: "SiteName",
                accessor: "siteName"
              },
              {
                Header: "SirenID",
                accessor: "sirenId",
                maxWidth: 60
              },
              {
                Header: "Prescribing Doctor",
                accessor: "doctorId",
                minWidth: 140,
                Cell: props => this.getDoctorName(props.value)
              },
              {
                Header: "PatientID",
                accessor: "patientId"
              },
              {
                Header: "First Name",
                accessor: "firstName"
              },
              {
                Header: "Last Name",
                accessor: "lastName"
              },
              {
                Header: "DOB",
                accessor: "dob",
                Filter: this.dateInputComponent
              },
              {
                Header: "Phone",
                accessor: "phone"
              },
              // {
              //   Header: "Email",
              //   accessor: "email"
              // },
              // {
              //   Header: "Account Created",
              //   accessor: "createdAt",
              //   minWidth: 100,
              //   Filter: this.dateInputComponent
              // },
              // {
              //   Header: "App Activated",
              //   accessor: "appInitialized",
              //   maxWidth: 72,
              //   Filter: ({ filter, onChange }) => (
              //     <Select
              //       className="select_common_style"
              //       onChange={onChange}
              //       style={{ width: "100%" }}
              //       value={filter ? filter.value : "all"}
              //       filterOption={(input, option) =>
              //         option.props.children
              //           .toLowerCase()
              //           .indexOf(input.toLowerCase()) >= 0
              //       }
              //       showSearch
              //       optionFilterProp="children"
              //     >
              //       <Option value="all">All</Option>
              //       <Option value="Yes">Yes</Option>
              //       <Option value="No">No</Option>
              //     </Select>
              //   )
              // },
              // {
              //   Header: "Registry Consent",
              //   accessor: "patientIRB",
              //   maxWidth: 72,
              //   Filter: ({ filter, onChange }) => (
              //     <Select
              //       className="select_common_style"
              //       onChange={onChange}
              //       style={{ width: "100%" }}
              //       value={filter ? filter.value : "all"}
              //       filterOption={(input, option) =>
              //         option.props.children
              //           .toLowerCase()
              //           .indexOf(input.toLowerCase()) >= 0
              //       }
              //       showSearch
              //       optionFilterProp="children"
              //     >
              //       <Option value="all">All</Option>
              //       <Option value="Yes">Yes</Option>
              //       <Option value="No">No</Option>
              //     </Select>
              //   ),
              //   Cell: props => {
              //     return (
              //       <div onClick={e => e.stopPropagation()}>
              //         {props.original.patientIRB}
              //         {
              //           <ChangePatientIRB
              //             userId={props.original.userId}
              //             from="HomePage"
              //             id={props.original.id}
              //             patientIRB={props.original.patientIRB}
              //             updatePatientIRBInfo={({ patientIRB, id }) => {
              //               let patientList = this.state.patientData.map(p => {
              //                 if (id === p.id) {
              //                   return {
              //                     ...p,
              //                     patientIRB
              //                   };
              //                 }
              //                 return p;
              //               });
              //               this.setState({ patientData: patientList });
              //               console.log(
              //                 "receive update patient irb: ",
              //                 patientIRB,
              //                 " id is: ",
              //                 id
              //               );
              //             }}
              //           />
              //         }
              //       </div>
              //     );
              //   }
              // },
              // {
              //   Header: "Socks Dispensed Date",
              //   accessor: "dispensedDay",
              //   minWidth: 130,
              //   Filter: this.dateInputComponent,
              //   Cell: props => {
              //     return (
              //       <div onClick={e => e.stopPropagation()}>
              //         {props.original.dispensedDay}
              //         <ChangeDispensedDay
              //           userId={props.original.userId}
              //           dispensedDay={props.original.dispensedDay}
              //           from="HomePage"
              //           id={props.original.id}
              //         />
              //       </div>
              //     );
              //   }
              // },
              {
                style: { cursor: "pointer" },
                Header: "Medical Status",
                accessor: "overallStatus",
                Cell: props => {
                  return (
                    <div onClick={e => e.stopPropagation()}>
                      <span
                        className="spanonclick"
                        onClick={() =>
                          this.gotoDoctorDetailPage(
                            props.original.id,
                            props.original.userId,
                            props.original.lastSeen
                          )
                        }
                      >
                        {props.original.overallStatus}
                      </span>
                    </div>
                  );
                },
                Filter: ({ filter, onChange }) => (
                  <Select
                    className="select_common_style"
                    onChange={onChange}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="all">All</Option>
                    <Option value="GREEN">GREEN</Option>
                    <Option value="RED">RED</Option>
                    <Option value="YELLOW">YELLOW</Option>
                  </Select>
                )
              },
              {
                Header: "Time Worn(Avg last 7 days)",
                accessor: "timeWorn"
              },
              {
                Header: "Last Date Worn",
                accessor: "lastSeen",
                Filter: this.dateInputComponent
              },
              {
                Header: "Hub Last Seen",
                accessor: "hubLastSeen",
                Filter: this.dateInputComponent
              },
              // {
              //   Header: "App Version",
              //   accessor: "appVersion"
              // },
              {
                style: { cursor: "pointer" },
                Header: "Sock Status",
                accessor: "sockStatus",
                Cell: props => {
                  return (
                    <div onClick={e => e.stopPropagation()}>
                      <span
                        className="spanonclick"
                        onClick={() =>
                          this.gotoStatisticsPage(
                            props.original.id,
                            props.original.userId,
                            props.original.userTimezone,
                            props.original.hubType
                          )
                        }
                      >
                        {props.original.sockStatus}
                      </span>
                    </div>
                  );
                },
                Filter: ({ filter, onChange }) => (
                  <Select
                    className="select_common_style"
                    onChange={onChange}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="all">All</Option>
                    <Option value="Low Battery">Low Battery</Option>
                    <Option value="Broken">Broken</Option>
                    <Option value="Low Battery, Broken">
                      Low Battery, Broken
                    </Option>
                    <Option value="OK">OK</Option>
                  </Select>
                )
              },
              // {
              //   style: { cursor: "pointer" },
              //   Header: "Hub Type",
              //   accessor: "hubType",
              //   Cell: props => {
              //     return (
              //       <div onClick={e => e.stopPropagation()}>
              //         <span
              //           className="spanonclick"
              //           onClick={() =>
              //             this.gotoStatisticsPage(
              //               props.original.id,
              //               props.original.userId,
              //               props.original.userTimezone,
              //               props.original.hubType
              //             )
              //           }
              //         >
              //           {props.original.hubType}
              //         </span>
              //       </div>
              //     );
              //   },
              //   Filter: ({ filter, onChange }) => (
              //     <Select
              //       className="select_common_style"
              //       onChange={onChange}
              //       style={{ width: "100%" }}
              //       value={filter ? filter.value : "all"}
              //       filterOption={(input, option) =>
              //         option.props.children
              //           .toLowerCase()
              //           .indexOf(input.toLowerCase()) >= 0
              //       }
              //       showSearch
              //       optionFilterProp="children"
              //     >
              //       <Option value="all">All</Option>
              //       <Option value="None">None</Option>
              //       <Option value="Wifi">Wifi</Option>
              //       <Option value="2G">2G</Option>
              //       <Option value="4G">4G</Option>
              //       <Option value="Wifi, 2G">Wifi, 2G</Option>
              //       <Option value="Wifi, 4G">Wifi, 4G</Option>
              //       <Option value="2G, 4G">2G, 4G</Option>
              //       <Option value="Wifi, 2G, 4G">Wifi, 2G, 4G</Option>
              //     </Select>
              //   )
              // },
              // {
              //   Header: "Send Message",
              //   sortable: false,
              //   filterable: false,
              //   minWidth: 130,
              //   Cell: props => {
              //     return (
              //       <div onClick={e => e.stopPropagation()}>
              //         <SendMess
              //           userId={props.original.userId}
              //           phone={props.original.phone}
              //           email={props.original.email}
              //         />
              //       </div>
              //     );
              //   }
              // },
              // {
              //   style: { cursor: "pointer" },
              //   Header: "Review Compliance",
              //   sortable: false,
              //   filterable: false,
              //   minWidth: 124,
              //   Cell: props => {
              //     return (
              //       <div onClick={e => e.stopPropagation()}>
              //         <EditCompliance
              //           id={props.original.id}
              //           complianceId={props.original.userId}
              //           reviewed={props.original.complianceReviewReq}
              //           compliancezone={props.original.userTimezone}
              //         />
              //       </div>
              //     );
              //   }
              // },
              {
                style: {
                  cursor: "pointer",
                  textAlign: "left",
                  paddingLeft: 10
                },
                Header: (
                  <>
                    <Popover placement="top" content={<ProgramStatusDesc />}>
                      Program Status
                    </Popover>
                    {/*<div*/}
                    {/*  style={{ cursor: "pointer", marginTop: 6 }}*/}
                    {/*  title="sort by program status"*/}
                    {/*>*/}
                    {/*  {["RPM", "RTM", "PCM", "CCM"].map((program, index) => {*/}
                    {/*    const stateKey = `${program.toLowerCase()}Sort`;*/}
                    {/*    return (*/}
                    {/*      <React.Fragment key={program}>*/}
                    {/*        {index ? <Divider type="vertical" /> : null}*/}
                    {/*        <span onClick={() => this.programSort(program)}>*/}
                    {/*          {program}*/}
                    {/*          <SortAscendingOutlined*/}
                    {/*            style={{*/}
                    {/*              display:*/}
                    {/*                this.state[stateKey] === "asc"*/}
                    {/*                  ? "inline-block"*/}
                    {/*                  : "none"*/}
                    {/*            }}*/}
                    {/*          />*/}
                    {/*          <SortDescendingOutlined*/}
                    {/*            style={{*/}
                    {/*              display:*/}
                    {/*                this.state[stateKey] === "desc"*/}
                    {/*                  ? "inline-block"*/}
                    {/*                  : "none"*/}
                    {/*            }}*/}
                    {/*          />*/}
                    {/*        </span>*/}
                    {/*      </React.Fragment>*/}
                    {/*    );*/}
                    {/*  })}*/}
                    {/*</div>*/}
                  </>
                ),
                accessor: "programStatus",
                minWidth: 150,
                sortable: false,
                Cell: props => {
                  return (
                    <div>
                      {props.original.rpmProgramStatus && (
                        <Tag
                          color={COLOR[props.original.rpmProgramStatus]}
                          key={"RPM"}
                        >
                          {/*{"RPM"}*/}
                          {PROGRAM_DESC[props.original.rpmProgramStatus]}
                        </Tag>
                      )}
                      {/*{props.original.rtmProgramStatus && (*/}
                      {/*  <Tag*/}
                      {/*    color={COLOR[props.original.rtmProgramStatus]}*/}
                      {/*    key={"RTM"}*/}
                      {/*  >*/}
                      {/*    {"RTM"}*/}
                      {/*  </Tag>*/}
                      {/*)}*/}
                      {/*{props.original.pcmProgramStatus && (*/}
                      {/*  <Tag*/}
                      {/*    color={COLOR[props.original.pcmProgramStatus]}*/}
                      {/*    key={"PCM"}*/}
                      {/*  >*/}
                      {/*    {"PCM"}*/}
                      {/*  </Tag>*/}
                      {/*)}*/}
                      {/*{props.original.ccmProgramStatus && (*/}
                      {/*  <Tag*/}
                      {/*    color={COLOR[props.original.ccmProgramStatus]}*/}
                      {/*    key={"CCM"}*/}
                      {/*  >*/}
                      {/*    {"CCM"}*/}
                      {/*  </Tag>*/}
                      {/*)}*/}
                    </div>
                  );
                },
                Filter: ({ filter, onChange }) => (
                  <Select
                    mode="multiple"
                    onChange={onChange}
                    style={{ width: "100%" }}
                    value={filter && filter.value}
                    placeholder="All"
                    showSearch
                    optionLabelProp="label"
                  >
                    {ProgramOptions.map(e => (
                      <Option
                        value={e.value}
                        key={e.value}
                        label={<Tag color={e.color}>{e.tagText}</Tag>}
                      >
                        <CircleColor color={e.color} />
                        {e.text}
                      </Option>
                    ))}
                  </Select>
                )
              },
              {
                style: {
                  textAlign: "left",
                  paddingLeft: 15
                },
                Header: (
                  <>
                    <Popover placement="topLeft" content={<DeviceStatusDesc />}>
                      Device Status
                    </Popover>
                    {/*<div*/}
                    {/*  style={{ cursor: "pointer", marginTop: 6 }}*/}
                    {/*  title="sort by device status"*/}
                    {/*>*/}
                    {/*  {["Socks", "Move"].map((device, index) => {*/}
                    {/*    const stateKey = `${device.toLowerCase()}Sort`;*/}
                    {/*    return (*/}
                    {/*      <React.Fragment key={device}>*/}
                    {/*        {index ? <Divider type="vertical" /> : null}*/}
                    {/*        <span onClick={() => this.deviceSort(device)}>*/}
                    {/*          {device}*/}
                    {/*          <SortAscendingOutlined*/}
                    {/*            style={{*/}
                    {/*              display:*/}
                    {/*                this.state[stateKey] === "asc"*/}
                    {/*                  ? "inline-block"*/}
                    {/*                  : "none"*/}
                    {/*            }}*/}
                    {/*          />*/}
                    {/*          <SortDescendingOutlined*/}
                    {/*            style={{*/}
                    {/*              display:*/}
                    {/*                this.state[stateKey] === "desc"*/}
                    {/*                  ? "inline-block"*/}
                    {/*                  : "none"*/}
                    {/*            }}*/}
                    {/*          />*/}
                    {/*        </span>*/}
                    {/*      </React.Fragment>*/}
                    {/*    );*/}
                    {/*  })}*/}
                    {/*</div>*/}
                  </>
                ),
                accessor: "deviceStatus",
                minWidth: 120,
                sortable: false,
                Cell: props => {
                  return (
                    <div onClick={e => e.stopPropagation()}>
                      {props.original.rpmDeviceStatus && (
                        <RpmDeviceStatus
                          deviceStatus={props.original.rpmDeviceStatus}
                          userId={props.original.userId}
                          id={props.original.id}
                          userInfo={props.original}
                          onChange={status => {
                            let patientData = [...this.state.patientData];
                            patientData[props.index].rpmDeviceStatus = status;
                            this.setState({ patientData });
                          }}
                        />
                      )}
                      {/*{props.original.rtmDeviceStatus && (*/}
                      {/*  <RtmDeviceStatus*/}
                      {/*    deviceStatus={props.original.rtmDeviceStatus}*/}
                      {/*    userId={props.original.userId}*/}
                      {/*    id={props.original.id}*/}
                      {/*    userInfo={props.original}*/}
                      {/*    onChange={status => {*/}
                      {/*      let patientData = [...this.state.patientData];*/}
                      {/*      patientData[props.index].rtmDeviceStatus = status;*/}
                      {/*      this.setState({ patientData });*/}
                      {/*    }}*/}
                      {/*  />*/}
                      {/*)}*/}
                    </div>
                  );
                },
                Filter: ({ filter, onChange }) => (
                  <Select
                    mode="multiple"
                    onChange={onChange}
                    style={{ width: "100%" }}
                    value={filter && filter.value}
                    showSearch
                    placeholder="All"
                    optionLabelProp="label"
                  >
                    {DeviceOptions.map(e => (
                      <Option
                        value={e.value}
                        key={e.value}
                        label={<Tag color={e.color}>{e.tagText}</Tag>}
                      >
                        <CircleColor color={e.color} />
                        {e.text}
                      </Option>
                    ))}
                  </Select>
                )
              }
              // {
              //   style: { cursor: "pointer" },
              //   accessor: "accountStatus",
              //   Header: "Account Status",
              //   maxWidth: 80,
              //   Cell: props => {
              //     return (
              //       <div onClick={e => e.stopPropagation()}>
              //         <AccountStatus
              //           accountStatus={props.original.accountStatus}
              //           userId={props.original.userId}
              //           id={props.original.id}
              //           userInfo={props.original}
              //           onChange={status => {
              //             let patientData = [...this.state.patientData];
              //             patientData[props.index].accountStatus = status;
              //             this.setState({ patientData });
              //           }}
              //         />
              //       </div>
              //     );
              //   },
              //   Filter: ({ filter, onChange }) => (
              //     <Select
              //       className="select_common_style"
              //       onChange={onChange}
              //       style={{ width: "100%" }}
              //       value={filter ? filter.value : "all"}
              //       filterOption={(input, option) =>
              //         option.props.children
              //           .toLowerCase()
              //           .indexOf(input.toLowerCase()) >= 0
              //       }
              //       showSearch
              //       optionFilterProp="children"
              //     >
              //       <Option value="all">All</Option>
              //       <Option value="PAUSED">PAUSED</Option>
              //       <Option value="ACTIVE">ACTIVE</Option>
              //     </Select>
              //   )
              // }
            ]}
            minRows={0}
          />
        </ContextMenuTrigger>
        {this.aweContextMenu()}
      </div>
    );
  }
}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  listStaff: state.patientsListStore.listStaff
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getlistStaff: () => dispatch(getlistStaff())
});
export default connect(mapStateToProp, mapDispatchToProp)(DeviceList);
