import {
  Alert,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Popover,
  Row,
  Select
} from "antd";
import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { validatePhone } from "../../../tools/phoneFormat";
import NewAccountStep from "./NewAccountStep";
import { MEDICAL_GENERAL } from "../../../tools/patient-medical";

const { Option } = Select;

class NewAccountStep5 extends NewAccountStep {
  static Name = "Confirm Order";

  state = {
    doctorId: this.props.doctorId,
    firstName: this.props.firstName,
    firstNameStatus: "success",
    lastName: this.props.lastName,
    lastNameStatus: "success",
    phone: this.props.phone,
    phoneStatus: "success",
    email: this.props.email,
    emailStatus: "success",
    shipAddress: this.props.shipAddress,
    dispenseAddress2: this.props.dispenseAddress2,
    shipCity: this.props.shipCity,
    shipState: this.props.shipState,
    shipZip: this.props.shipZip,
    dispenseComment: this.props.dispenseComment
  };

  validateForm() {
    let pass = true;
    if (!this.state.firstName) {
      this.setState({
        firstNameStatus: "error"
      });
      pass = false;
    }
    if (!this.state.lastName) {
      this.setState({
        lastNameStatus: "error"
      });
      pass = false;
    }
    if (!validatePhone(this.state.phone)) {
      this.setState({
        phoneStatus: "error"
      });
      pass = false;
    }
    if (this.state.email && !this.validateEmail(this.state.email)) {
      this.setState({
        emailStatus: "error"
      });
      pass = false;
    }
    return pass;
  }

  submitForm() {
    if (typeof this.props.onSubmitForm === "function") {
      const copyState = { ...this.state };
      delete copyState.firstNameStatus;
      delete copyState.lastNameStatus;
      delete copyState.phoneStatus;
      delete copyState.emailStatus;
      const doctor = this.props.listStaff.find(
        staff => staff.email === copyState.doctorId
      );
      copyState.doctorName = `${doctor.firstName} ${doctor.lastName}`;

      this.props.onSubmitForm(this.constructor.Name, copyState);
    }
  }

  validateEmail(email) {
    const reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  }

  render() {
    let medicalHistory = "No available medical history yet.";
    if (this.props.medicalConditions.includes("Other")) {
      const tmp = this.props.medicalConditions
        .filter(item => item !== "Other")
        .map(v => MEDICAL_GENERAL.find(item => item.value === v).name);
      tmp.push(`Other (${this.props.otherDisease})`);
      medicalHistory = tmp.join(", ");
    } else if (this.props.medicalConditions.length > 0) {
      medicalHistory = this.props.medicalConditions
        .map(v => MEDICAL_GENERAL.find(item => item.value === v).name)
        .join(", ");
    }
    return (
      <div style={{ width: 800, margin: "auto", marginTop: 40 }}>
        <Form>
          <div className="form-section">
            <Divider orientation="left">
              Review and Confirm Your Prescription
            </Divider>
            <p>
              Please take a moment to review the following information for
              accuracy. Input errors may cause a delay in account pairing.
              <b> Order cannot be changed after it has been submitted.</b>
            </p>
            <p>
              <b>Note:</b> The patient’s Siren app username is their Cell Phone
              Number. If the Cell Phone number is incorrect, clinics will be
              required to create a new patient account.
            </p>
          </div>
          <div className="form-section">
            <Divider orientation="left">Enrollment Criteria</Divider>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Doctor"
                  required
                  hasFeedback
                  validateStatus={this.state.doctorStatus}
                  help={
                    this.state.doctorStatus === "error"
                      ? "Please select doctor"
                      : ""
                  }
                  className="form-item"
                >
                  <Select
                    size="large"
                    value={this.state.doctorId}
                    onChange={value => {
                      this.setState({
                        doctorId: value,
                        doctorStatus: "success"
                      });
                    }}
                    showSearch
                    optionFilterProp="children"
                  >
                    {this.props.listStaff
                      .filter(item =>
                        this.props.site
                          ? item.isPrescribing &&
                            item.siteId
                              .split("-")
                              .includes(this.props.site.siteId)
                          : false
                      )
                      .map(doctor => (
                        <Option value={doctor.email} key={doctor.email}>
                          {doctor.isPrescribing &&
                          !doctor.firstName.includes("Dr.")
                            ? "Dr. " + doctor.firstName + " " + doctor.lastName
                            : doctor.firstName + " " + doctor.lastName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="form-section">
            <Divider orientation="left">Patient Information</Divider>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  label="First Name"
                  required
                  className="form-item"
                  validateStatus={this.state.firstNameStatus}
                  help={
                    this.state.firstNameStatus === "error"
                      ? "Please input FirstName"
                      : ""
                  }
                >
                  <Input
                    size="large"
                    value={this.state.firstName}
                    onChange={e =>
                      this.setState({
                        firstName: e.target.value,
                        firstNameStatus: "success"
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Last Name"
                  required
                  className="form-item"
                  validateStatus={this.state.lastNameStatus}
                  help={
                    this.state.lastNameStatus === "error"
                      ? "Please input LastName"
                      : ""
                  }
                >
                  <Input
                    size="large"
                    value={this.state.lastName}
                    onChange={e =>
                      this.setState({
                        lastName: e.target.value,
                        lastNameStatus: "success"
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Email"
                  className="form-item"
                  validateStatus={this.state.emailStatus}
                  help={
                    this.state.emailStatus === "error"
                      ? "Invalid email format"
                      : ""
                  }
                >
                  <Input
                    size="large"
                    onChange={e => this.setState({ email: e.target.value })}
                    value={this.state.email}
                    type="email"
                  />
                </Form.Item>
              </Col>
              <div style={{ clear: "both" }}></div>
              <Col span={8}>
                <Form.Item
                  label="Username"
                  required
                  className="form-item"
                  validateStatus={this.state.phoneStatus}
                  help={
                    this.state.phoneStatus === "error"
                      ? "Invalid phone number"
                      : ""
                  }
                >
                  <ReactPhoneInput
                    inputClass="phone-input"
                    buttonClass="phone-button"
                    inputExtraProps={{
                      name: "Cell phone",
                      required: true,
                      autoFocus: false
                    }}
                    defaultCountry={"us"}
                    value={this.state.phone}
                    onChange={value =>
                      this.setState({
                        phone: value,
                        phoneStatus: "success"
                      })
                    }
                    disableAreaCodes
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ paddingBottom: 0 }}>
                <span style={{ marginRight: 8 }}>Shipping Address</span>
                <Popover
                  content={
                    <Alert
                      banner
                      message="Required for shipping and/or replacements. No P.O. Box deliveries"
                      type="warning"
                    />
                  }
                >
                  <Icon type="info-circle" style={{ color: "#faad14" }} />
                </Popover>
              </Col>
              <Col span={12}>
                <Input
                  size="large"
                  placeholder="Address"
                  onChange={e => this.setState({ shipAddress: e.target.value })}
                  value={this.state.shipAddress}
                />
              </Col>
              <Col span={6}>
                <Input
                  size="large"
                  placeholder="City"
                  onChange={e => this.setState({ shipCity: e.target.value })}
                  value={this.state.shipCity}
                />
              </Col>
              <Col span={3}>
                <Input
                  size="large"
                  placeholder="State"
                  onChange={e => this.setState({ shipState: e.target.value })}
                  value={this.state.shipState}
                />
              </Col>
              <Col span={3}>
                <Input
                  size="large"
                  placeholder="Zip"
                  onChange={e => this.setState({ shipZip: e.target.value })}
                  value={this.state.shipZip}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24} style={{ paddingBottom: 0 }}>
                <span style={{ marginRight: 8 }}>Street Address 2</span>
                {/*<Popover*/}
                {/*  content={*/}
                {/*    <Alert*/}
                {/*      banner*/}
                {/*      message="Required for shipping and/or replacements. No P.O. Box deliveries"*/}
                {/*      type="warning"*/}
                {/*    />*/}
                {/*  }*/}
                {/*>*/}
                {/*  <Icon type="info-circle" style={{ color: "#faad14" }} />*/}
                {/*</Popover>*/}
              </Col>
              <Col span={24}>
                <Input
                  size="large"
                  placeholder="Street Address 2"
                  onChange={e =>
                    this.setState({ dispenseAddress2: e.target.value })
                  }
                  value={this.state.dispenseAddress2}
                />
              </Col>
            </Row>
          </div>
          <div className="form-section">
            <Divider orientation="left">Medical History</Divider>
            <Row gutter={16}>
              <Col span={24}>{medicalHistory}</Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProp = state => ({
  listStaff: state.patientsListStore.listStaff,
  medicalConditionList: state.app.medicalConditions.map(item => ({
    name: item.value,
    value: item.condition
  }))
});

export default connect(mapStateToProp)(NewAccountStep5);
