import { Modal, Modal as AntdModal } from "antd";
import { API, graphqlOperation } from "aws-amplify/lib/index";
import _ from "lodash";
import _api from "../graphql/_api";
import message from "../tools/message-info";
import modalInfo from "../tools/module-info";
import * as TYPES from "../types/types";
import { updateUserGroupsState } from "./chartAction";

export function createDoctorAccount(CreateDoctorInput, self) {
  return async (dispatch, getState) => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.registerDoctorAccount(), CreateDoctorInput)
      );
      if (newEvent.data.createDoctor.status) {
        self.setState({ addStaffSuccess: true });
        let StaffList = getState().patientsListStore.listStaff;
        const newStaff = _.clone(StaffList);
        newStaff.push(CreateDoctorInput.input);
        dispatch(changelistStaff(newStaff));
      } else {
        AntdModal.warning({
          title: "Create Staff Failed",
          content: newEvent.data.createDoctor.message,
          centered: true
        });
      }
    } catch (err) {
      AntdModal.warning({
        title: "Create Staff Error",
        content: JSON.stringify(err),
        centered: true
      });
      console.log("createDoctorAccount error: ", err);
    }
  };
}
export function updateStaff(newStaffInput) {
  return async (dispatch, getState) => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateDoctor(), newStaffInput)
      );
      if (newEvent.data.updateDoctor.status) {
        const StaffInputValue = _.clone(newStaffInput);
        StaffInputValue.input.doctorRole = "STAFF_CLINICAL";
        let StaffList = getState().patientsListStore.listStaff;
        const newStaffInfo = _.clone(StaffList);
        const doc = _.filter(newStaffInfo, function(t) {
          return t.email === StaffInputValue.input.email;
        });
        if (doc.length > 0 && doc[0].lastLogin) {
          StaffInputValue.input.lastLogin = doc[0].lastLogin;
        }
        _.remove(newStaffInfo, {
          email: StaffInputValue.input.email
        });
        newStaffInfo.push(StaffInputValue.input);
        const staffInfoAfterDel = newStaffInfo;
        dispatch(changelistStaff(staffInfoAfterDel));
      } else {
        message.info(newEvent.data.updateDoctor.message);
      }
    } catch (err) {
      console.log("updateStaff error: ", err);
    }
  };
}

export function changeAdminStaffStatus(input, self) {
  return async dispatch => {
    try {
      self.setState({ loading: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAdminUser(), { input })
      );
      self.setState({ loading: false });
      if (newEvent.data.updateAdminStaff.status) {
        const listAdminStaff = _.clone(self.props.listAdminStaff);
        const listAdminDeletedStaff = _.clone(self.props.listAdminDeletedStaff);
        if (input.status === "DELETED") {
          const adminStaff = _(listAdminStaff).find({
            id: input.id
          });
          _.remove(listAdminStaff, { id: adminStaff.id });
          listAdminDeletedStaff.push(adminStaff);
        } else if (input.status === "REINSTATE") {
          const adminStaff = _(listAdminDeletedStaff).find({ id: input.id });
          _.remove(listAdminDeletedStaff, { id: adminStaff.id });
          listAdminStaff.push(adminStaff);
        }
        dispatch(changeListAdminUsersStatus(listAdminStaff));
        dispatch(changeListAdminDeletedUsersStatus(listAdminDeletedStaff));
      } else {
        AntdModal.warn({
          title: `${
            input.status === "DELETED" ? "Deleted" : "ReInstate"
          } Admin User Failed`,
          content: newEvent.data.updateAdminStaff.message,
          centered: true
        });
      }
    } catch (e) {
      self.setState({ loading: false });
      console.log("update admin staff status error: ", e);
      AntdModal.warn({
        title: `${
          input.status === "DELETED" ? "Deleted" : "ReInstate"
        } Admin User Failed`,
        content: JSON.stringify(e),
        centered: true
      });
    }
  };
}

export function changeStaffStatus(input, self) {
  return async dispatch => {
    try {
      self.setState({ modalIsOpen: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateDoctor(), { input })
      );
      self.setState({ modalIsOpen: false });
      if (newEvent.data.updateDoctor.status) {
        const listStaff = _.clone(self.props.listStaff);
        const deletedListStaff = _.clone(self.props.deletedListStaff);
        if (input.status === "DELETED") {
          const staff = _(listStaff).find({ email: input.email });
          _.remove(listStaff, { email: staff.email });
          deletedListStaff.push(staff);
        } else if (input.status === "REINSTATE") {
          const staff = _(deletedListStaff).find({ email: input.email });
          _.remove(deletedListStaff, { email: staff.email });
          listStaff.push(staff);
        }
        dispatch(changelistStaff(listStaff));
        dispatch(changeDeletedListStaff(deletedListStaff));
      } else {
        AntdModal.warn({
          title: `${
            input.status === "DELETED" ? "Deleted" : "ReInstate"
          } Doctor Failed`,
          content: newEvent.data.updateDoctor.message,
          centered: true
        });
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      AntdModal.warn({
        title: `${
          input.status === "DELETED" ? "Deleted" : "ReInstate"
        } Doctor Error`,
        content: JSON.stringify(e),
        centered: true
      });
      console.log(`change staff status error ${JSON.stringify(e)}`);
    }
  };
}

export function sendTaskToTest(TestTaskInput, self) {
  return async dispatch => {
    try {
      // console.log("TestTaskInput: ", JSON.stringify(TestTaskInput));
      self.setState({ modalIsOpen: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.sendTestTask(), TestTaskInput)
      );
      if (newEvent) {
        self.setState({ modalIsOpen: false, open: false });
      }
    } catch (err) {
      self.setState({ modalIsOpen: false });
      console.log("sendTaskToTest error: ", err);
    }
  };
}

export function createSiteId(CreateSiteIdInput, self) {
  return async dispatch => {
    try {
      self.setState({ disabledCreate: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.createClinicSiteAccount(), CreateSiteIdInput)
      );
      if (newEvent) {
        let siteIds = self.props.clinicSites;
        const newSites = _.clone(siteIds);
        newSites.push({
          ...newEvent.data.createClinicSite,
          hubContract: CreateSiteIdInput.input.hubContract
        });
        dispatch(changelistClinicSites(newSites));
        self.setState({
          addSiteSuccess: true,
          city: "",
          siteState: "",
          zip: "",
          address: "",
          phone: "",
          fax: "",
          siteNPI: "",
          siteNPIs: [],
          contact: "",
          serviceDayType: "DISPENSED_DAY",
          ifAutoReport: false,
          parentSiteId: "",
          newSiteNPI: "",
          disabledCreate: false
        });
        self.refreshNewSite();
      } else {
        message.info(newEvent.data.createClinicSite.message);
      }
    } catch (err) {
      message.info(err);
      self.setState({ disabledCreate: false });
      console.log("createSiteId error: ", err);
    }
  };
}
export function updateSiteId(updateSiteInput, self) {
  return async dispatch => {
    try {
      self.setState({ confirmSuccess: true, modalIsOpen: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateClinicSite(), updateSiteInput)
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        let newSites = _.clone(self.props.clinicSites);
        _.forEach(newSites, function(item) {
          if (item.siteId === updateSiteInput.input.siteId) {
            item.autoDoctorReport = updateSiteInput.input.autoDoctorReport;
            item.serviceDate = updateSiteInput.input.serviceDate;
            item.EMRSystem = updateSiteInput.input.EMRSystem;
            if (!_.isEmpty(updateSiteInput.input.name)) {
              item.name = updateSiteInput.input.name;
            }
            if (!_.isEmpty(updateSiteInput.input.address)) {
              item.address = updateSiteInput.input.address;
            }
            if (!_.isEmpty(updateSiteInput.input.phone)) {
              item.phone = updateSiteInput.input.phone;
            }
            if (!_.isEmpty(updateSiteInput.input.contact)) {
              item.contact = updateSiteInput.input.contact;
            }
            if (!_.isEmpty(updateSiteInput.input.IRBConsent)) {
              item.IRBConsent = updateSiteInput.input.IRBConsent;
            }
            if (!_.isEmpty(updateSiteInput.input.hubContract)) {
              item.hubContract = updateSiteInput.input.hubContract;
            }
            if (!_.isEmpty(updateSiteInput.input.onStaffing)) {
              item.onStaffing = updateSiteInput.input.onStaffing;
            }
            if (!_.isEmpty(updateSiteInput.input.tag)) {
              item.tag = updateSiteInput.input.tag;
            }
          }
        });
        dispatch(changelistClinicSites(newSites));
        self.handleCancel();
      } else {
        message.info(newEvent.data.updateClinicSite.message);
      }
    } catch (err) {
      message.info(err);
      self.setState({ modalIsOpen: false });
      console.log("updateSiteId error: ", err);
    }
  };
}

export function changeSiteStatus(input, self) {
  return async dispatch => {
    try {
      self.setState({ loading: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateClinicSite(), { input })
      );
      self.setState({ loading: false });
      if (newEvent) {
        const listClinicSites = _.clone(self.props.clinicSites);
        const listClinicSitesDeleted = _.clone(self.props.clinicSitesDeleted);
        if (input.status === "DELETED") {
          const clinicSite = _(listClinicSites).find({
            siteId: input.siteId
          });
          _.remove(listClinicSites, { siteId: clinicSite.siteId });
          listClinicSitesDeleted.push(clinicSite);
        } else if (input.status === "REINSTATE") {
          const clinicSite = _(listClinicSitesDeleted).find({
            siteId: input.siteId
          });
          _.remove(listClinicSitesDeleted, { siteId: clinicSite.siteId });
          listClinicSites.push(clinicSite);
        }
        dispatch(changelistClinicSites(listClinicSites));
        dispatch(changelistDeletedClinicSites(listClinicSitesDeleted));
      }
    } catch (e) {
      self.setState({ loading: false });
      AntdModal.warning({
        title:
          input.status === "DELETED"
            ? `Delete Site ${input.siteId} Failed`
            : `Reinstate Site ${input.siteId} Failed`,
        content: JSON.stringify(e),
        centered: true
      });
    }
  };
}

function compareSiteId(a, b) {
  if (a.siteId < b.siteId) return -1;
  if (a.siteId > b.siteId) return 1;
  return 0;
}
export function getlistClinicSites() {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listClinicSites())
      );
      if (newEvent) {
        dispatch(
          changelistClinicSites(
            newEvent.data.listClinicSitesNew.items.sort(compareSiteId)
          )
        );
      }
    } catch (e) {
      console.log("getlistClinicSites error: ", e);
    }
  };
}

export function getlistDeletedClinicSites() {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listClinicSites(), { status: "DELETED" })
      );
      if (newEvent) {
        dispatch(
          changelistDeletedClinicSites(
            newEvent.data.listClinicSitesNew.items.sort(compareSiteId)
          )
        );
      }
    } catch (e) {
      console.log("getlistClinicSites error: ", e);
    }
  };
}

export function getInvoicelistClinicSites(self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listClinicSites())
      );
      if (newEvent) {
        // self.getInvoicePatient(
        //   newEvent.data.listClinicSitesNew.items.sort(compareSiteId)[0].siteId
        // );
        // self.setState({
        //   siteId: [
        //     newEvent.data.listClinicSitesNew.items.sort(compareSiteId)[0].siteId
        //   ]
        // });
        dispatch(
          changelistClinicSites(
            newEvent.data.listClinicSitesNew.items.sort(compareSiteId)
          )
        );
      }
    } catch (e) {
      console.log("getlistClinicSites error: ", e);
    }
  };
}

export function getDeleteSite(siteId, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteClinicSite(), { input: { siteId } })
      );
      if (newEvent) {
        self.setState({ open: false });
        const tmpSites = _.clone(self.props.clinicSites);
        _.remove(tmpSites, {
          siteId: siteId
        });
        const itemsAfterDel = tmpSites;
        dispatch(changelistClinicSites(itemsAfterDel));
      }
    } catch (err) {
      console.log("getDeleteSite exception: ", err);
    }
  };
}

export function changelistClinicSites(clinicSites) {
  return {
    type: TYPES.CLINIC_SITES,
    text: clinicSites
  };
}
export function changelistDeletedClinicSites(clinicSites) {
  return {
    type: TYPES.CLINIC_SITES_DELETED,
    text: clinicSites
  };
}
export function getlistStaff() {
  return async dispatch => {
    try {
      const filter = {
        status: { ne: "DELETED" }
      };
      const newEvent = await API.graphql(
        graphqlOperation(_api.listStaff(), { filter })
      );
      if (newEvent) {
        dispatch(
          changelistStaff(newEvent.data.listStaff.items.sort(compareSiteId))
        );
      }
    } catch (e) {
      console.log("getlistStaff error: ", e);
    }
  };
}

export function getDeletedListStaff() {
  return async dispatch => {
    try {
      const filter = {
        status: { eq: "DELETED" }
      };
      const newEvent = await API.graphql(
        graphqlOperation(_api.listStaff(), { filter })
      );
      if (newEvent) {
        dispatch(
          changeDeletedListStaff(
            newEvent.data.listStaff.items.sort(compareSiteId)
          )
        );
      }
    } catch (e) {
      console.log("getlistStaff error: ", e);
    }
  };
}

export function updateListStaff(listStaff) {
  return dispatch => {
    dispatch(changelistStaff(listStaff));
  };
}

export function changelistStaff(listStaff) {
  return {
    type: TYPES.LIST_STAFF,
    text: listStaff
  };
}

export function changeDeletedListStaff(deletedListStaff) {
  return {
    type: TYPES.DELETED_LIST_STAFF,
    text: deletedListStaff
  };
}

export function getDeleteStaff(email, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteDoctor(), { input: { email } })
      );
      if (newEvent) {
        if (newEvent.data.deleteDoctor.status) {
          self.setState({ open: false });
          const tmpSites = _.clone(self.props.deletedListStaff);
          _.remove(tmpSites, {
            email: email
          });
          dispatch(changeDeletedListStaff(tmpSites));
        } else {
          // message.warn(newEvent.data.deleteDoctor.message);
          AntdModal.warn({
            title: "Delete Doctor Failed",
            content: newEvent.data.deleteDoctor.message,
            centered: true
          });
        }
      }
    } catch (err) {
      AntdModal.warn({
        title: "Delete Doctor Failed",
        content: err,
        centered: true
      });
      console.log("getDeleteStaff exception: ", err);
    }
  };
}

export function resetPwd(newPwdInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.resetStaffPassword(), newPwdInput)
      );
      if (newEvent.data.resetStaffPassword.status) {
        self.setState({ newPwd: "", open: false });
      } else {
        message.info(newEvent.data.resetStaffPassword.message);
      }
    } catch (err) {
      console.log("resetPwd error: ", err);
    }
  };
}

export function changeRightToe() {
  return dispatch => {
    dispatch(changeRightToeState());
  };
}

function changeRightToeState() {
  return {
    type: TYPES.SEL_RIGHT_TOE
  };
}

export function changeRightMts() {
  return dispatch => {
    dispatch(changeRightMtsState());
  };
}

function changeRightMtsState() {
  return {
    type: TYPES.SEL_RIGHT_MTS
  };
}

export function changeRightArch() {
  return dispatch => {
    dispatch(changeRightArchState());
  };
}

function changeRightArchState() {
  return {
    type: TYPES.SEL_RIGHT_ARCH
  };
}

export function changeRightHeel() {
  return dispatch => {
    dispatch(changeRightHeelState());
  };
}

function changeRightHeelState() {
  return {
    type: TYPES.SEL_RIGHT_HEEL
  };
}

export function changeLeftToe() {
  return dispatch => {
    dispatch(changeLeftToeState());
  };
}

function changeLeftToeState() {
  return {
    type: TYPES.SEL_LEFT_TOE
  };
}

export function changeLeftMts() {
  return dispatch => {
    dispatch(changeLeftMtsState());
  };
}

function changeLeftMtsState() {
  return {
    type: TYPES.SEL_LEFT_MTS
  };
}

export function changeLeftArch() {
  return dispatch => {
    dispatch(changeLeftArchState());
  };
}

function changeLeftArchState() {
  return {
    type: TYPES.SEL_LEFT_ARCH
  };
}

export function changeLeftHeel() {
  return dispatch => {
    dispatch(changeLeftHeelState());
  };
}

function changeLeftHeelState() {
  return {
    type: TYPES.SEL_LEFT_HEEL
  };
}

export function getAllListClinicSites() {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listClinicSites())
      );
      if (newEvent) {
        let allSites = newEvent.data.listClinicSitesNew.items.sort(
          compareSiteId
        );
        dispatch(changeAllListClinicSites(allSites));
      }
    } catch (e) {
      console.log("getlistClinicSites error: ", e);
    }
  };
}
export function changeAllListClinicSites(clinicSites) {
  return {
    type: TYPES.ALL_CLINIC_SITES,
    text: clinicSites
  };
}
export function compareFirstName(a, b) {
  if (a.firstName < b.firstName) return -1;
  if (a.firstName > b.firstName) return 1;
  return 0;
}
export async function getlistSummaryStaff(dispatch) {
  let siteIds = [];
  let filter = {};
  if (siteIds.length) {
    let or = siteIds.map(p => {
      return { siteId: { contains: p } };
    });
    Object.assign(filter, { or });
  }
  if (_.isEmpty(filter)) {
    filter = null;
  }
  const newEvent = await API.graphql(
    graphqlOperation(_api.listStaff(), {
      filter
    })
  );
  if (newEvent) {
    const allDoctors = _.filter(newEvent.data.listStaff.items, {
      isPrescribing: true
    });
    dispatch(changelistStaff(allDoctors.sort(compareFirstName)));
  }
}
export function createAdminAccount(CreateAdminInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.registerAdminAccount(), CreateAdminInput)
      );
      self.setState({ openModal: false });
      if (newEvent.data.createAdminDoctor.status) {
        self.setState({ showUserPaw: true });
        self.props.refreshList();
        // modalInfo.success(
        //   "Create Admin Account Success",
        //   `Create account ${CreateAdminInput.input.email} success.`
        // );
      } else {
        modalInfo.error(
          "Create Admin Account Failed",
          newEvent.data.createAdminDoctor.message
        );
      }
    } catch (err) {
      self.setState({ openModal: false });
      modalInfo.error("Create Admin Account Failed", JSON.stringify(err));
    }
  };
}

export function compareGroup(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function checkPatients(siteId, serviceDate, self) {
  return async dispatch => {
    try {
      let filter = {
        group: {
          ne: "TRIAL_SILENCE"
        },
        siteId: {
          eq: siteId
        },
        accountStatus: {
          ne: "DELETED"
        }
      };
      let sort = {
        field: "sirenId",
        direction: "asc"
      };
      const newEvent = await API.graphql(
        graphqlOperation(_api.checkAllPatientsInSite(), {
          searchStr: "",
          filter,
          sort,
          limit: 5
        })
      );
      // console.log(`newEvent: ${JSON.stringify(newEvent)}`);
      if (newEvent) {
        let patientList = newEvent.data.lambdaSearchPatients.items;
        if (patientList.length > 0) {
          AntdModal.warn({
            title: "Delete Site Failed",
            content: `There are patients assigned to site ${siteId}. Remove patient assignments to delete the site.`,
            centered: true
          });
        } else {
          self.handleTempDeleteSite(siteId, serviceDate);
        }
      }
    } catch (e) {
      console.log(`checkPatients error: ${e}`);
    }
  };
}

export function listAdminUsers() {
  return async dispatch => {
    const newEvent = await API.graphql(graphqlOperation(_api.listAdminUsers()));

    dispatch(changeListAdminUsersStatus(newEvent.data.listAdminStaff.items));
  };
}

export function listAdminDeletedStaff() {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listAdminUsers(), { status: "DELETED" })
      );
      dispatch(
        changeListAdminDeletedUsersStatus(newEvent.data.listAdminStaff.items)
      );
    } catch (e) {
      console.log("get deleted admin user list error: ", e);
    }
  };
}

export function getlistClinicSitesPatientLPN(self, siteId, doctorId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listClinicSites())
      );
      if (newEvent) {
        dispatch(
          changelistClinicSites(
            newEvent.data.listClinicSitesNew.items.sort(compareSiteId)
          )
        );
        if (newEvent.data.listClinicSitesNew.items.length > 0) {
          // self.getCurrentSiteStaff(
          //   newEvent.data.listClinicSitesNew.items.sort(compareSiteId)[0].siteId
          // );

          const onStaffing = newEvent.data.listClinicSitesNew.items.sort(
            compareSiteId
          )[0].onStaffing;
          if (!onStaffing || onStaffing === "Yes") {
            self.props.listLPNs(
              self,
              newEvent.data.listClinicSitesNew.items.sort(compareSiteId)[0]
                .siteId
            );
          }
          if (self.getStaffList) {
            self.getStaffList(
              newEvent.data.listClinicSitesNew.items.sort(compareSiteId)[0]
                .siteId
            );
          }
        }
      }
    } catch (e) {
      console.log("getlistClinicSites error: ", e);
    }
  };
}

export function listLPNs(self, siteId, type) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listAdminUsers())
      );
      if (newEvent) {
        if (siteId) {
          const sirenStaff = _.compact(
            _.filter(newEvent.data.listAdminStaff.items, function(t) {
              return (
                t.group === "staff" && t.patientCount < parseInt(t.patientLoad)
              );
            }).map(p => {
              if (
                p.primarySites &&
                JSON.parse(p.primarySites).includes(siteId)
              ) {
                const rate = parseFloat(
                  p.patientCount / parseInt(p.patientLoad)
                );
                return { ...p, rate };
              }
              return "";
            })
          );
          const preStaff = _.minBy(sirenStaff, "rate");
          if (preStaff) {
            if (type === "ACCOUNT_SERVICE_STATUS") {
              console.log("not list admin staff: ", preStaff.id);
              self.setState({ LPN: preStaff.id });
            }
          }
        }
        dispatch(
          changeListAdminUsersStatus(newEvent.data.listAdminStaff.items)
        );
      }
    } catch (e) {
      console.log(`get list admin users error: ${JSON.stringify(e)}`);
    }
  };
}

export async function listLPNForPatients(dispatch, getState) {
  const userId = getState().authGroupStore.profile.loginId;
  const newEvent = await API.graphql(graphqlOperation(_api.listAdminUsers()));

  dispatch(changeListAdminUsersStatus(newEvent.data.listAdminStaff.items));

  const currentLPN = newEvent.data.listAdminStaff.items.find(
    e => e.id === userId
  );
  if (currentLPN) {
    dispatch(updateUserGroupsState(currentLPN.group));
  }
}

function changeListAdminUsersStatus(listUsers) {
  return {
    type: TYPES.LIST_ADMIN_STAFF,
    text: listUsers
  };
}

function changeListAdminDeletedUsersStatus(listDeletedUsers) {
  return {
    type: TYPES.LIST_ADMIN_DELETED_STAFF,
    text: listDeletedUsers
  };
}

const roleMap = {
  DEVELOPER: "developer",
  ADMIN: "admin",
  SUPPORT: "support",
  SALES: "sales",
  STAFF: "staff"
};

export function updateLPNFilter(input) {
  return async dispatch => {
    const newEvent = await API.graphql(
      graphqlOperation(_api.updateAdminStaffFilter(), input)
    );
    if (newEvent.data.updateAdminStaffFilter.status) {
      dispatch({
        type: TYPES.UPDATE_LPN_FILTER,
        id: input.input.id,
        filter: input.input.filterColumns
      });
    }
  };
}

export function updateAdminUserInfo(input) {
  return async (dispatch, getState) => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAdminUser(), input)
      );
      if (newEvent) {
        if (newEvent.data.updateAdminStaff.status) {
          let userList = [...getState().patientsListStore.listAdminStaff];
          let userInfo = _(userList).find({
            id: input.input.id
          });
          if (userInfo) {
            if (input.input.firstName) {
              userInfo.firstName = input.input.firstName;
            }
            if (input.input.lastName) {
              userInfo.lastName = input.input.lastName;
            }
            if (input.input.phoneNumber) {
              userInfo.phone = input.input.phoneNumber;
            }
            if (input.input.group) {
              userInfo.group = roleMap[input.input.group];
            }
            if (input.input.primarySites) {
              userInfo.primarySites = input.input.primarySites;
            }
            if (input.input.EMRSystem) {
              userInfo.EMRSystem = input.input.EMRSystem;
            }
            if (input.input.backupFor) {
              userInfo.backupFor = input.input.backupFor;
            }
            if (input.input.patientLoad) {
              userInfo.patientLoad = input.input.patientLoad;
            }
            if (input.input.lastLogin) {
              userInfo.lastLogin = input.input.lastLogin;
            }
            dispatch(changeListAdminUsersStatus(userList));
          }
        } else {
          modalInfo.error(
            "Update Admin User Failed",
            newEvent.data.updateAdminStaff.message
          );
        }
      }
    } catch (e) {
      modalInfo.error("Update Admin User Error", JSON.stringify(e));
      console.log("update admin user error:", e);
    }
  };
}

export function getDeleteAdminStaff(email, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteAdminDoctor(), { input: { email } })
      );
      if (newEvent) {
        self.setState({ open: false });
        dispatch(
          changeListAdminUsersStatus(
            _.reject(self.props.listAdminStaff, { id: email })
          )
        );
      }
    } catch (e) {
      modalInfo.error("Delete Admin User Error", JSON.stringify(e));
      console.log(`delete admin user error: ${JSON.stringify(e)}`);
    }
  };
}

export function createPupPatient(input, self) {
  return async dispatch => {
    try {
      self.setState({ loading: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.createPupPatient(), { input })
      );
      if (newEvent) {
        console.log(`create pup patient success, ${JSON.stringify(newEvent)}`);
        self.setState({ loading: false });
        AntdModal.info({
          title: "Create Patient",
          content: `Create Patient ${self.state.firstName} ${
            self.state.lastName
          } ${
            newEvent.data.createPupPatient.status ? "Success" : "Failed"
          }, ${JSON.stringify(newEvent.data.createPupPatient.message)}.`,
          centered: true
        });
      }
    } catch (e) {
      console.log("create pup patient error: ", e);
      self.setState({ loading: false });
      AntdModal.error({
        title: "Create Patient",
        content: `Create Patient ${self.state.firstName} ${
          self.state.lastName
        } Failed, ${JSON.stringify(e)}.`,
        centered: true
      });
    }
  };
}
