import { Popover, Select, Tag } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { searchGlobalFavoriteListInfo } from "../action/patientAction";
import {
  COLOR,
  DeviceOptions,
  ProgramOptions
} from "../constant/programDeviceStatus";
import CircleColor from "../home/circle-color";
import "../home/DeviceList.css";
import EditCompliance from "../home/EditCompliance";
import RpmDeviceStatus from "../home/RpmDeviceStatus";
import RtmDeviceStatus from "../home/RtmDeviceStatus";
import SendMess from "../home/SendMess";
import ChangeDispensedDay from "../report/changeDispensedDay";
import "../share/common.css";
import "../share/select.css";
import DeviceStatusDesc from "../tools/device-status-desc";
import { formatNumber } from "../tools/phoneFormat";
import ProgramStatusDesc from "../tools/program-status-desc";
import getPatchTrProps from "../tools/reactTablePatch";
import { Icon, Input, Button } from "semantic-ui-react";
import { Auth } from "aws-amplify";
import { v1 as uuid } from "uuid";
import { generateBillingReportVa } from "./report-va-action";

const { Option } = Select;

class FavoriteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      tableData: []
    };
  }

  componentDidMount() {
    if (this.props.favIds.length > 0) {
      this.searchPatient();
    }
  }

  searchPatient = () => {
    this.setState({
      modalIsOpen: true
    });
    searchGlobalFavoriteListInfo("", this.props.favIds)
      .then(data => {
        this.setState({
          tableData: data
        });
      })
      .finally(() => {
        this.setState({
          modalIsOpen: false
        });
      });
  };

  gotoDoctorDetailPage(id, userId, date) {
    Control.go(
      "/MedicalDetailsHome?FavoriteList/id=" +
        btoa(id) +
        "&userId=" +
        userId +
        "&startDate=" +
        date +
        "&endDate=" +
        date
    );
  }
  gotoSupportDetailPage(userId, userTimezone) {
    Control.go("/UserStatus?userId=" + userId + "&timeZone=" + userTimezone);
  }
  gotoStatisticsPage(id, userId, userTimezone, hubType) {
    Control.go(
      "/sockstatus?FavoriteList/id=" +
        id +
        "&userId=" +
        userId +
        "&timeZone=" +
        userTimezone +
        "&hubType=" +
        hubType
    );
  }

  handleGenerateReport = async () => {
    const { generateBillingReportVa } = this.props;
    const session = await Auth.currentSession();
    const input = {
      siteIds: "",
      source: "ADMIN",
      token: uuid(),
      doctorId: session.getIdToken().payload.email,
      reportPatients: [
        {
          sirenId: 2754,
          userId: "us-west-2:61ee3f63-870d-44e1-8b2c-b92513bd2d76",
          reportMonth: "2021-09"
        }
      ],
      generatedDate: moment().format("YYYY-MM-DD"),
      isVaReport: true
    };
    generateBillingReportVa(input);
  };

  render() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.id,
        rowInfo.original.userId,
        moment().format("YYYY-MM-DD")
      );
    });
    return (
      <div>
        <div className="search_patient_container">
          <Button
            onClick={this.handleGenerateReport}
            style={{
              marginLeft: 30,
              whiteSpace: "nowrap",
              marginTop: 20,
              marginBottom: 20
            }}
            disabled={this.props.showGenerateVaDelay}
          >
            Generate VA Report
          </Button>
          {this.props.showGenerateVaDelay ? (
            <div
              style={{
                color: "red",
                marginLeft: 20,
                marginTop: 5,
                fontWeight: "bold",
                whiteSpace: "nowrap"
              }}
            >
              {"Generating Report in " + this.props.generateVaDelay}
            </div>
          ) : null}
        </div>
        <ReactTable
          getTrProps={trProps}
          loading={this.state.modalIsOpen}
          data={this.state.tableData.map(p => {
            const lastSeen = p.dataLastSeen ? p.dataLastSeen.split("T")[0] : "";
            const timeWorn =
              p.timeWorn > 0
                ? Math.floor(p.timeWorn / 60) +
                  "h" +
                  ((p.timeWorn % 60) * 100) / 100 +
                  "m"
                : "";
            const allPhone = p.phone;
            const phone = !isEmpty(allPhone)
              ? formatNumber(allPhone).trim()
              : "";
            const fullName =
              p.firstName && p.lastName
                ? p.firstName + " " + p.lastName
                : p.givenName;
            const overallStatus =
              p.overallStatus === "NORMAL" ? "GREEN" : p.overallStatus;
            const newgroup = p.group;
            const email = p.email && p.email.includes("@") ? p.email : "";
            const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
            const dispensedDay = p.dispensedDay ? p.dispensedDay : "";
            return {
              ...p,
              lastSeen,
              timeWorn,
              phone,
              newgroup,
              overallStatus,
              fullName,
              email,
              createdAt,
              dispensedDay
            };
          })}
          filterable
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) {
              return false;
            }
            return row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          }}
          columns={[
            {
              Header: "SirenID",
              accessor: "sirenId",
              maxWidth: 60
            },
            {
              Header: "SiteID",
              accessor: "siteId"
            },
            {
              Header: "Site Name",
              accessor: "siteName"
            },
            {
              Header: "Created Day",
              accessor: "createdAt",
              minWidth: 100
            },
            {
              Header: "Dispensed Day",
              accessor: "dispensedDay",
              minWidth: 130,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    {props.original.dispensedDay}
                    <ChangeDispensedDay
                      userId={props.original.userId}
                      dispensedDay={props.original.dispensedDay}
                      from="HomePage"
                    />
                  </div>
                );
              }
            },
            {
              Header: "Phone",
              accessor: "phone"
            },
            {
              Header: "Email",
              accessor: "email"
            },
            {
              Header: "Full Name",
              accessor: "fullName"
            },
            {
              style: { cursor: "pointer" },
              Header: "Medical Status",
              accessor: "overallStatus",
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <span
                      className="spanonclick"
                      onClick={() =>
                        this.gotoDoctorDetailPage(
                          props.original.id,
                          props.original.userId,
                          props.original.lastSeen
                        )
                      }
                    >
                      {props.original.overallStatus}
                    </span>
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="GREEN">GREEN</Option>
                  <Option value="RED">RED</Option>
                  <Option value="YELLOW">YELLOW</Option>
                </Select>
              )
            },
            {
              Header: "Time Worn(Avg last 7 days)",
              accessor: "timeWorn",
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                if (isEmpty(a) && !isEmpty(b)) {
                  return 1;
                }
                if (isEmpty(b)) {
                  return -1;
                }
                if (
                  a.includes("h") &&
                  a.includes("m") &&
                  b.includes("h") &&
                  b.includes("m")
                ) {
                  const atime1 = parseInt(a.split("h")[0], 10);
                  const btime1 = parseInt(b.split("h")[0], 10);
                  const atime2 = parseInt(a.split("h")[1]);
                  const btime2 = parseInt(b.split("h")[1]);
                  const atime = atime1 * 60 + atime2;
                  const btime = btime1 * 60 + btime2;
                  return atime > btime ? -1 : 1;
                } else {
                  return -1;
                }
              }
            },
            {
              style: { cursor: "pointer" },
              Header: "Sock Status",
              accessor: "sockStatus",
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <span
                      className="spanonclick"
                      onClick={() =>
                        this.gotoStatisticsPage(
                          props.original.id,
                          props.original.userId,
                          props.original.userTimezone,
                          props.original.hubType
                        )
                      }
                    >
                      {props.original.sockStatus}
                    </span>
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="Low Battery">Low Battery</Option>
                  <Option value="Broken">Broken</Option>
                  <Option value="Low Battery, Broken">
                    Low Battery, Broken
                  </Option>
                  <Option value="OK">OK</Option>
                </Select>
              )
            },
            {
              style: { cursor: "pointer" },
              Header: "Hub Type",
              accessor: "hubType",
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <span
                      className="spanonclick"
                      onClick={() =>
                        this.gotoStatisticsPage(
                          props.original.id,
                          props.original.userId,
                          props.original.userTimezone,
                          props.original.hubType
                        )
                      }
                    >
                      {props.original.hubType}
                    </span>
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="Wifi">Wifi</Option>
                  <Option value="2G">2G</Option>
                  <Option value="4G">4G</Option>
                  <Option value="Wifi, 2G">Wifi, 2G</Option>
                  <Option value="Wifi, 4G">Wifi, 4G</Option>
                  <Option value="2G, 4G">2G, 4G</Option>
                  <Option value="Wifi, 2G, 4G">Wifi, 2G, 4G</Option>
                </Select>
              )
            },
            {
              Header: "Last Date Worn",
              accessor: "lastSeen",
              filterable: false
            },
            {
              Header: "Send Message",
              sortable: false,
              filterable: false,
              minWidth: 130,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <SendMess
                      userId={props.original.userId}
                      phone={props.original.phone}
                      email={props.original.email}
                    />
                  </div>
                );
              }
            },
            {
              style: { cursor: "pointer" },
              Header: "Review Compliance",
              sortable: false,
              filterable: false,
              minWidth: 124,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <EditCompliance
                      complianceId={props.original.userId}
                      reviewed={props.original.complianceReviewReq}
                      compliancezone={props.original.userTimezone}
                    />
                  </div>
                );
              }
            },
            {
              style: {
                cursor: "pointer",
                textAlign: "left",
                paddingLeft: 10
              },
              Header: (
                <Popover placement="top" content={<ProgramStatusDesc />}>
                  Program Status
                </Popover>
              ),
              accessor: "programStatus",
              minWidth: 220,
              sortable: true,
              Cell: props => {
                return (
                  <div>
                    {props.original.rpmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.rpmProgramStatus]}
                        key={"RPM"}
                      >
                        {"RPM"}
                      </Tag>
                    )}
                    {props.original.rtmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.rtmProgramStatus]}
                        key={"RTM"}
                      >
                        {"RTM"}
                      </Tag>
                    )}
                    {props.original.pcmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.pcmProgramStatus]}
                        key={"PCM"}
                      >
                        {"PCM"}
                      </Tag>
                    )}
                    {props.original.ccmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.ccmProgramStatus]}
                        key={"CCM"}
                      >
                        {"CCM"}
                      </Tag>
                    )}
                  </div>
                );
              },
              filterMethod: (filter, row) => {
                if (filter.value && filter.value.length === 0) {
                  return true;
                }
                for (let i = 0; i < filter.value.length; i++) {
                  const item = filter.value[i];
                  const [program, status] = item.split(":");
                  if (
                    row._original[`${program.toLowerCase()}ProgramStatus`] ===
                    status
                  ) {
                    return true;
                  }
                }
                return false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  mode="multiple"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter && filter.value}
                  placeholder="All"
                  showSearch
                  optionLabelProp="label"
                >
                  {ProgramOptions.map(e => (
                    <Option
                      value={e.value}
                      key={e.value}
                      label={<Tag color={e.color}>{e.tagText}</Tag>}
                    >
                      <CircleColor color={e.color} />
                      {e.text}
                    </Option>
                  ))}
                </Select>
              )
            },
            {
              style: {
                textAlign: "left",
                paddingLeft: 15
              },
              Header: (
                <Popover placement="topLeft" content={<DeviceStatusDesc />}>
                  Device Status
                </Popover>
              ),
              accessor: "deviceStatus",
              minWidth: 200,
              sortable: true,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    {props.original.rpmDeviceStatus && (
                      <RpmDeviceStatus
                        deviceStatus={props.original.rpmDeviceStatus}
                        userId={props.original.userId}
                        id={props.original.id}
                        userInfo={props.original}
                        onChange={status => {
                          let tableData = [...this.state.tableData];
                          tableData[props.index].rpmDeviceStatus = status;
                          this.setState({ tableData });
                        }}
                      />
                    )}
                    {props.original.rtmDeviceStatus && (
                      <RtmDeviceStatus
                        deviceStatus={props.original.rtmDeviceStatus}
                        userId={props.original.userId}
                        id={props.original.id}
                        userInfo={props.original}
                        onChange={status => {
                          let tableData = [...this.state.tableData];
                          tableData[props.index].rtmDeviceStatus = status;
                          this.setState({ tableData });
                        }}
                      />
                    )}
                  </div>
                );
              },
              filterMethod: (filter, row) => {
                if (filter.value && filter.value.length === 0) {
                  return true;
                }
                for (let i = 0; i < filter.value.length; i++) {
                  const item = filter.value[i];
                  const [program, status] = item.split(":");
                  if (
                    row._original[`${program.toLowerCase()}DeviceStatus`] ===
                    status
                  ) {
                    return true;
                  }
                }
                return false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  mode="multiple"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter && filter.value}
                  showSearch
                  placeholder="All"
                  optionLabelProp="label"
                >
                  {DeviceOptions.map(e => (
                    <Option
                      value={e.value}
                      key={e.value}
                      label={<Tag color={e.color}>{e.tagText}</Tag>}
                    >
                      <CircleColor color={e.color} />
                      {e.text}
                    </Option>
                  ))}
                </Select>
              )
            }
          ]}
          defaultSorted={[{ id: "lastSeen", desc: true }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  favIds: state.authGroupStore.session.favorites,
  showGenerateVaDelay: state.monthlyReportStore.showGenerateVaDelay,
  generateVaDelay: state.monthlyReportStore.generateVaDelay
});

const mapDispatchToProp = dispatch => ({
  generateBillingReportVa: input => dispatch(generateBillingReportVa(input))
});

export default connect(mapStateToProp, mapDispatchToProp)(FavoriteList);
