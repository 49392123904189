import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Icon, Input } from "semantic-ui-react";
import {
  getLogFromS3,
  getPatientUserId,
  fetchPatientLogs
} from "../action/SimulateAction";
import Progress from "../home/progress";
import "./PatientLog.css";
import "./simulateSock.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};

class PatientLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      searchSirenId: null,
      patient: null,
      patientInfo: null,
      userId: "",
      log: "",
      isLoading: false,
      expandedFolders: new Set(),
      drawerOpen: false
    };
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  searchSirenIdChanged = event => {
    this.setState({ searchSirenId: event.target.value });
  };

  searchPatient = async () => {
    const { getPatientUserId } = this.props;
    this.setState({ isLoading: true });
    try {
      await getPatientUserId(this, this.state.searchSirenId);
      console.log("this.state.userId: ", this.state.userId);
      // 在获取到userId后，自动获取该用户的所有日志
      if (this.state.userId) {
        await this.fetchUserLogs(this.state.userId);
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  // 添加处理回车键的方法
  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.searchPatient();
    }
  };

  fetchUserLogs = async userId => {
    // 这里需要在SimulateAction中实现相应的action
    const { fetchPatientLogs } = this.props;
    try {
      await fetchPatientLogs(userId);
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  toggleFolder = folderPath => {
    const { expandedFolders } = this.state;
    const newExpandedFolders = new Set(expandedFolders);

    if (expandedFolders.has(folderPath)) {
      newExpandedFolders.delete(folderPath);
    } else {
      newExpandedFolders.add(folderPath);
    }

    this.setState({ expandedFolders: newExpandedFolders });
  };

  formatLogContent = content => {
    if (!content) return "";

    return content.split("\n").map((line, index) => {
      // 匹配日志格式 [timestamp][level][platform]
      const match = line.match(
        /^(\[.*?\])(\[(?:INFO|ERROR|WARN|DEBUG)\])(\[(?:IOS|ANDROID)\])(.*)/
      );
      if (match) {
        return (
          <div key={index}>
            <span className="timestamp">{match[1]}</span>
            <span className={`level-${match[2].slice(1, -1)}`}>{match[2]}</span>
            <span className="platform">{match[3]}</span>
            {match[4]}
          </div>
        );
      }
      return <div key={index}>{line}</div>;
    });
  };

  toggleDrawer = () => {
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen
    }));
  };

  render() {
    const pageHeight = document.body.clientHeight - 200;
    const { getLogFromS3 } = this.props;
    const { drawerOpen } = this.state;

    // 添加一个函数来组织日志文件的层次结构
    const organizeLogsByFolder = logKeys => {
      const structure = {};
      logKeys.forEach(key => {
        const parts = key.split("/");
        let current = structure;
        parts.forEach((part, index) => {
          if (index === parts.length - 1) {
            current[part] = key; // 存储完整的路径作为值
          } else {
            current[part] = current[part] || {};
            current = current[part];
          }
        });
      });
      return structure;
    };

    // 递归渲染函数以支持展开/收起
    const renderFileTree = (structure, path = "", level = 0) => {
      return Object.entries(structure).map(([key, value], i) => {
        const currentPath = path ? `${path}/${key}` : key;
        const indent = { marginLeft: `${level * 20}px` };

        if (typeof value === "string") {
          // 文件节点
          return (
            <p
              key={i}
              style={indent}
              onClick={() => getLogFromS3(this, value)}
              className="log-file"
            >
              <Icon name="file" /> {key}
            </p>
          );
        } else {
          // 文件夹节点添加箭头图标
          const isExpanded = this.state.expandedFolders.has(currentPath);
          return (
            <div key={i}>
              <p
                style={indent}
                className="log-folder"
                onClick={() => this.toggleFolder(currentPath)}
              >
                <Icon name={isExpanded ? "chevron down" : "chevron right"} />
                <Icon name={isExpanded ? "folder open" : "folder"} />
                {key}
              </p>
              <div style={{ display: isExpanded ? "block" : "none" }}>
                {renderFileTree(value, currentPath, level + 1)}
              </div>
            </div>
          );
        }
      });
    };

    const fileStructure = organizeLogsByFolder(this.props.logKeys);

    return (
      <div className="device-list-container">
        <Modal
          isOpen={this.state.modalIsOpen || this.state.isLoading}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>

        <div className="drawer-container">
          {/* 抽屉式侧边栏 */}
          <div className={`drawer ${drawerOpen ? "open" : ""}`}>
            <div className="search_patient_container">
              <span className="search_label search_email_label">SirenId:</span>
              <Input
                placeholder="Search"
                type="text"
                onChange={this.searchSirenIdChanged.bind(this)}
                onKeyPress={this.handleKeyPress}
                value={this.state.searchSirenId || ""}
              />
              <Icon
                name="search"
                size="small"
                className="search_icon"
                onClick={this.searchPatient}
              />
            </div>
            <div className="patientLog_log_text">
              {renderFileTree(fileStructure)}
            </div>
          </div>

          {/* 抽屉切换按钮 */}
          <div
            className="drawer-toggle"
            onClick={this.toggleDrawer}
            style={{ left: drawerOpen ? "300px" : "0" }}
          >
            <Icon name={drawerOpen ? "chevron left" : "chevron right"} />
          </div>

          {/* 主内容区域 */}
          <div className={`main-content ${drawerOpen ? "drawer-open" : ""}`}>
            <pre className="log-content">
              {this.formatLogContent(this.state.log)}
            </pre>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  logKeys: state.boxInfoStore.logKeys
});
const mapDispatchToProp = dispatch => ({
  getPatientUserId: (self, sirenId) =>
    dispatch(getPatientUserId(self, sirenId)),
  getLogFromS3: (self, key) => dispatch(getLogFromS3(self, key)),
  fetchPatientLogs: userId => dispatch(fetchPatientLogs(userId))
});
export default connect(mapStateToProp, mapDispatchToProp)(PatientLog);
