import { CloseOutlined } from "@ant-design/icons";
import { Modal as AntdModal, Select, Tabs } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { changeNewPatient } from "../../action/addAction";
import FootCondition from "../../report/footCondition";
import FootTreatment from "../../report/footTreatment";
import PatientMedicalHistory from "../../report/patientMedicalHistory";
import { phoneWithCountryFormat } from "../../tools/phoneFormat";
import ButtonSuccess from "../home/buttonSuccess";
import "./edit-patient.css";
import "./editpatient.css";
import EnrollmentTab from "./EnrollmentTab";
import ProfileTab from "./ProfileTab";

const { Option } = Select;
const { TabPane } = Tabs;

class EditProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      diagnosisCodes: []
    };
  }

  validateForm = email => {
    const reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  };

  formatPhone(phone) {
    if (!phone) {
      return phone;
    }
    return phone.replace(/[\(\)\-\s]+/g, "");
  }

  handleOpen = () => {
    if (_.isEmpty(this.props.userInfo)) {
      AntdModal.error({
        title: "Error",
        content: `Please confirm you have got this patient's info.`,
        centered: true
      });
      return;
    }
    this.setState({
      open: true,
      confirmSuccess: false,
      diagnosisCodes: this.props.userInfo.diagnosisCodes
        ? this.props.userInfo.diagnosisCodes.split("_")
        : []
    });
  };

  submit = () => {
    let editInput = {
      id: this.props.userInfo.id
    };

    const enrollmentData = this.getEnrollmentData();
    let transform = {
      siteId: enrollmentData.site.siteId,
      siteName: enrollmentData.site.name,
      doctorId: enrollmentData.doctorId,
      LPN: enrollmentData.LPN,
      patientHasSmartPhone: enrollmentData.patientHasSmartPhone,
      patientIRB: enrollmentData.patientIRB
    };
    if (!this.props.editNewPatient) {
      transform.diagnosisCodes = this.state.diagnosisCodes.join("_");
    }
    if (!_.isEmpty(enrollmentData.dispenseSize)) {
      transform.dispenseSize = enrollmentData.dispenseSize;
    }
    if (!_.isEmpty(enrollmentData.dispenseColor)) {
      transform.dispenseColor = enrollmentData.dispenseColor;
    }
    if (this.getProfileData) {
      // if user didn't open profile tab, it won't load
      const profileData = this.getProfileData();
      Object.assign(transform, {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        birthday: _.isEmpty(profileData.dob)
          ? ""
          : profileData.dob.format("YYYY-MM-DD"),
        phone: this.formatPhone(profileData.phone),
        homePhone: this.formatPhone(profileData.homePhone),
        email: profileData.email,
        gender: profileData.gender,
        patientId: profileData.patientID,
        patientMBI: profileData.MBI,
        patientResidence: profileData.residence,
        dispenseAddress: profileData.shipAddress,
        dispenseAddress2: profileData.dispenseAddress2,
        dispenseCity: profileData.shipCity,
        dispenseState: profileData.shipState,
        dispenseZip: profileData.shipZip,
        enableApp: profileData.contactByPhone,
        enableEmail: profileData.contactByEmail,
        enableSms: profileData.contactByMessage,
        contactName: profileData.secondaryName,
        contactPhone: this.formatPhone(profileData.secondaryPhone),
        secondaryEmail: profileData.secondaryEmail,
        relation: profileData.secondaryRelation,
        authSecondaryContact: profileData.authSecondary ? "Yes" : "No"
      });

      if (!_.isEmpty(phoneWithCountryFormat(editInput.phone))) {
        AntdModal.warning({
          title: "Complete Field",
          content: "Please enter a valid cell phone number",
          centered: true
        });
        return;
      }
    }

    // remove empty value
    // transform = _.omitBy(transform, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

    Object.assign(editInput, transform);

    if (!_.isEmpty(editInput.email)) {
      if (!this.validateForm(editInput.email)) {
        AntdModal.warning({
          title: "Oops",
          content: "Invalid email format.",
          centered: true
        });
        return;
      }
    }

    if (!_.isEmpty(editInput.secondaryEmail)) {
      if (!this.validateForm(editInput.secondaryEmail)) {
        AntdModal.warning({
          title: "Oops",
          content: "Invalid Emergency Contact Email format.",
          centered: true
        });
        return;
      }
    }

    // check if modified
    let modified = false;
    for (const key in editInput) {
      if (Object.hasOwnProperty.call(editInput, key)) {
        const element = editInput[key];
        if (this.props.userInfo[key] !== element) {
          console.log(
            `modify key: ${key}, pre:${this.props.userInfo[key]}, next:${editInput[key]}`
          );
          modified = true;
          break;
        }
      }
    }

    if (modified) {
      this.props.changeNewPatient(editInput).then(() => {
        if (this.props.editNewPatient) {
          this.setState({
            confirmSuccess: true
          });
          if (typeof this.props.changeSuccess === "function") {
            // do success animation, then do callback
            setTimeout(() => {
              // this.props.changeSuccess(patient);
            }, 2000);
          }
        } else {
          this.setState({
            open: false
          });
          if (this.props.loadNewEditValue) {
            this.props.loadNewEditValue();
          }
        }
      });
    } else {
      this.setState({
        open: false
      });
    }
  };

  close = () => {
    this.setState({
      open: false,
      confirmSuccess: false
    });
  };

  parseData(patientInfo) {
    if (!patientInfo) {
      return {};
    }
    let diagnosisCodes = [];
    if (patientInfo.diagnosisCodes) {
      diagnosisCodes = patientInfo.diagnosisCodes.split("_");
    }
    let medicalCondition = [];
    if (patientInfo.medicalCondition) {
      medicalCondition = patientInfo.medicalCondition.split(",");
    }
    let footSpecific = [];
    if (patientInfo.footSpecific) {
      footSpecific = patientInfo.footSpecific.split(",");
    }
    let footAmputation = [];
    if (patientInfo.footAmputation) {
      footAmputation = patientInfo.footAmputation.split(",");
    }
    return {
      diagnosisCodes,
      medicalCondition,
      footSpecific,
      footAmputation
    };
  }

  render() {
    const {
      diagnosisCodes,
      medicalCondition,
      footSpecific,
      footAmputation
    } = this.parseData(this.props.userInfo);
    return (
      <Modal
        trigger={
          this.props.editNewPatient ? (
            <Button
              onClick={this.handleOpen}
              size="mini"
              style={{ display: "none" }}
            >
              Edit
            </Button>
          ) : (
            <span
              onClick={this.handleOpen}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                whiteSpace: "nowrap"
              }}
            >
              View Full Profile
            </span>
          )
        }
        open={this.state.open}
      >
        <Modal.Header>
          {this.props.editNewPatient ? "Edit Patient" : "Patient Profile"}
          <CloseOutlined style={{ float: "right" }} onClick={this.close} />
        </Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Tabs
              style={{ height: 600, overflowY: "auto" }}
              className="sr-edit-patient"
            >
              <TabPane tab="Enroll" key="1">
                <EnrollmentTab
                  userInfo={this.props.userInfo}
                  onSiteChange={site => {
                    this.setState({
                      site
                    });
                  }}
                  injectGetMethod={fn => {
                    this.getEnrollmentData = fn;
                  }}
                />
              </TabPane>
              <TabPane tab="Profile" key="profile">
                <ProfileTab
                  userInfo={this.props.userInfo}
                  site={this.state.site}
                  injectGetMethod={fn => {
                    this.getProfileData = fn;
                  }}
                />
              </TabPane>
              {!this.props.editNewPatient && (
                <TabPane tab="Medical" key="3">
                  <div style={{ height: "auto" }}>
                    <div className="Patient_group">
                      <div style={{ marginLeft: 40, marginTop: -20 }}>
                        <PatientMedicalHistory
                          readOnly
                          readOnlyHint
                          medicalCondition={medicalCondition}
                          footSpecific={footSpecific}
                          footAmputation={footAmputation}
                          editFootMedical={key => {
                            this.props.editFootMedical(key);
                            this.close();
                          }}
                        />
                      </div>
                      <div
                        className="diagnosis_codes"
                        style={{ marginLeft: 40, marginTop: 30, width: "90%" }}
                      >
                        <div>
                          <span className="patient_note_label">
                            ICD-10 Codes
                          </span>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <Select
                            size="large"
                            mode="tags"
                            style={{ width: "100%" }}
                            placeholder="Click to add ICD-10 codes"
                            onChange={value => {
                              this.setState({ diagnosisCodes: value });
                            }}
                            optionLabelProp="value"
                            value={this.state.diagnosisCodes}
                          >
                            {this.props.ICD10Codes.map(code => (
                              <Option value={code} key={code}>
                                {code}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div
                        className="diagnosis_codes"
                        style={{ marginLeft: 40, marginTop: 30, width: "90%" }}
                      >
                        <FootCondition
                          type="PROFILE"
                          editFootMedical={key => {
                            this.props.editFootMedical(key);
                            this.close();
                          }}
                        />
                        <FootTreatment
                          type="PROFILE"
                          editFootMedical={key => {
                            this.props.editFootMedical(key);
                            this.close();
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ clear: "both" }} />
                  </div>
                </TabPane>
              )}
            </Tabs>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          {this.props.editNewPatient ? (
            <>
              <Button
                color="green"
                onClick={this.submit}
                style={{
                  display: this.state.confirmSuccess ? "none" : "inline-block"
                }}
              >
                <Icon name="checkmark" />
                Submit
              </Button>
              <Button basic color="red" onClick={this.close}>
                <Icon name="remove" />
                {this.state.confirmSuccess ? "Close" : "Cancel"}
              </Button>
            </>
          ) : (
            <div>
              <Button color="green" onClick={this.submit}>
                <Icon name="checkmark" />
                OK
              </Button>
            </div>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  ICD10Codes: state.app.ICD10Codes.map(item => item.code)
});
const mapDispatchToProp = dispatch => ({
  changeNewPatient: editInput => dispatch(changeNewPatient(editInput))
});
export default connect(mapStateToProp, mapDispatchToProp)(EditProfileInfo);
