import {
  MinusOutlined,
  PlusOutlined,
  QuestionCircleFilled
} from "@ant-design/icons";
import {
  Collapse,
  Tabs,
  notification,
  Button as AntdButton,
  Divider,
  Affix,
  Dropdown,
  Menu,
  message,
  Popover,
  Drawer,
  Table,
  Avatar,
  Tooltip,
  Input,
  Select
} from "antd";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import _, { filter, isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { Control } from "react-keeper";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Button, Form, Icon, Radio } from "semantic-ui-react";
import { dismissReviewTask, getAppointmented } from "../../action/addAction";
import { updateNotification } from "../../action/notificationAction";
import {
  getDoctorDetails,
  getPatientBySirenId
} from "../../action/patientAction";
import { getlistClinicSites, getlistStaff } from "../../action/registerAction";
import commonStyles from "../../common/CommonStyles";
import Progress from "../../home/progress";
import CalendarChart from "../../medicaldetails/calendarRange";
import CalendarTag from "../../shoe-tag/calendarTag";
import CalendarPillBottle from "../../pill-bottle/calendarPillBottle";
import Status from "../../medicaldetails/status";
import Tracker99457 from "../../report/Tracker99457";
import SockStatusHome from "../../sockstatus/Equipment";
import { formatNumber } from "../../tools/phoneFormat";
import EditProfileInfo from "../account/EditProfileInfo2";
import Calendar1 from "./calendar";
import "./DocMedicalDetails.css";
import PatientSym from "./PatientSym";
import Photo from "./Photo";
import "./reviewSuccess.css";
import SirenNotes from "./SirenNotes";
import WeightScale from "../../patient-info/weight-scale";
import ActivityLog from "./ActivityLog";
import TagWornCalendar from "../../shoe-tag/tagWornCalendar";
import TagActivation from "../../shoe-tag/tagActivaton";
import { formatReviewTasks } from "../../tools/review-task-type-format";
import ReviewTaskDataUpdatePop from "./reviewTaskDataUpdatePop";
import Prescription from "./PrescriptionList";
import { createNote } from "../../action/DoctorNoteAction";
import DocumentsList from "../../documents/documents-list";
import StopWatch from "../../common/StopWatch";
import SharedStopWatchLogManager from "../../tools/stopWatchLogManager";
import PatientDetailUrlContext from "./PatientDetailUrlContext";
import SlowStep from "./SlowStep";
import sharedWs from "../../tools/sharedWs";
import TimeWornWithOutShoes from "../../medicaldetails/time-worn-without-shoes";
import AddBox from "./add-box/add-box";
import AddHub from "./add-hub/add-hub";
import CompleteOnboarding from "./complete-onboarding/complete-onboarding";
import { patientListAPI } from "../../graphql/API";

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;
class PatientDetail extends Component {
  constructor(props) {
    super(props);

    const params = this.parseUrlParams();
    this.updateUrlParam = param => {
      console.log("update param notify:", param);
      const nextParam = Object.assign({}, this.state.urlContext.param, param);
      this.setState({
        urlContext: {
          param: nextParam,
          updateParam: this.updateUrlParam
        }
      });

      // update url
      const patientList = Control.state && Control.state.patientList;
      let [path, paramStr] = Control.path.split("?");
      // there is some bug in Control, which may not get query params
      if (!paramStr) {
        paramStr = window.location.href.split("?")[1];
      }
      if (paramStr.includes("/")) {
        path = `${path}?${paramStr.split("/")[0]}/`;
      } else {
        path = `${path}?`;
      }
      Control.replace(
        `${path}id=${btoa(nextParam.id)}&userId=${nextParam.userId}&startDate=${
          nextParam.startDate
        }&endDate=${nextParam.endDate}&tab=${nextParam.tab}`,
        { patientList }
      );
    };

    this.state = {
      urlContext: {
        param: params,
        updateParam: this.updateUrlParam
      },
      id: "",
      date: new Date(),
      userId: "",
      modalIsOpen: false,
      rating: 0,
      StartDate: moment().format("YYYY-MM-DD"),
      EndDate: moment().format("YYYY-MM-DD"),
      sirenId: "",
      enableNotification: false,
      lastNotification: [],
      comment: "",
      value: "",
      showSuccessAlert: false,
      showFailedAlert: false,
      activeTab: params.tab || "1",
      dateUpdateTaskOnly: false,
      within3DaysTask: false,
      canAddDMT: false,
      onboardingTaskOnly: false,
      multipleOnboardingTask: false,
      drawerVisible: false,
      stopWatchLogs: [],
      viewers: [],
      searchPatientData: [],
      inputSearch: ""
    };
    this.program_device = [];
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  parseUrlParams() {
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    params.id = atob(params.id).replace(" ", "+");
    if (!params.tab) {
      params.tab = "1";
    }

    return params;
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleRadio = (e, { value }) => {
    this.setState({ value: value });
  };
  handleCommentsChange(value) {
    this.setState({ comment: value });
  }
  closeReviewFail = () => {
    this.setState({
      showFailedAlert: false
    });
  };
  closeReviewSuccess = () => {
    this.setState({
      showSuccessAlert: false
    });
  };

  openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: msg
    });
  };

  dismissReviewTaskStatus = async () => {
    let inputData = {
      id: this.state.urlContext.param.id,
      userId: this.state.urlContext.param.userId
    };
    this.props.dismissReviewTask(inputData, this);
  };

  addOnboardingNote = async () => {
    let user = await Auth.currentAuthenticatedUser();
    for (const device of this.program_device) {
      const input = {
        id: this.state.urlContext.param.id,
        author: `${user.username}`,
        authorId: `${user.username}`,
        title: `Clinical onboarding task completed: ${
          device === "Siren Socks"
            ? "RPM - Siren Socks"
            : device === "Siren Move"
            ? "RTM - Siren Move"
            : device
        }`
      };
      await this.props.createNote(input);
    }
    this.program_device = [];
  };

  saveInfo = async () => {
    let user = await Auth.currentAuthenticatedUser();
    const { getAppointmented } = this.props;
    let inputData = {
      id: this.state.urlContext.param.id,
      userId: this.state.urlContext.param.userId,
      doctor: user.username
    };
    if (this.state.value === "") {
      alert("Must be selected by submitting a button");
      return;
    }
    if (this.state.value && this.state.value === "radio_one") {
      Object.assign(inputData, { appointmented: true });
    }
    if (this.state.value && this.state.value === "radio_two") {
      Object.assign(inputData, { appointmented: false });
    }
    if (this.state.value && this.state.value === "radio_three") {
      Object.assign(inputData, { retakPhoto: true });
    }
    if (this.state.comment && this.state.comment !== "") {
      Object.assign(inputData, {
        comment: this.state.comment
      });
    }
    if (this.props.doctorDetails.category.includes("Photo Review")) {
      Object.assign(inputData, { authorTitle: "Photo Review" });
    } else if (this.props.doctorDetails.category.includes("Symptoms")) {
      Object.assign(inputData, { authorTitle: "Symptom Review" });
    } else if (this.props.doctorDetails.category.includes("Medical Review")) {
      Object.assign(inputData, { authorTitle: "Medical Review" });
    } else if (this.props.doctorDetails.category.includes("Data Update")) {
      Object.assign(inputData, { authorTitle: "Data Update" });
    }
    if (
      this.props.doctorDetails &&
      this.props.doctorDetails.photoTask &&
      this.props.doctorDetails.photoTask.taskTime !== ""
    ) {
      Object.assign(inputData, {
        taskTime: this.props.doctorDetails.photoTask.taskTime
      });
    }
    getAppointmented(inputData, this);
  };

  onChangeDateRange = DateRangeDate => {
    this.setState({ DateRangeDate });
    this.setState({ ifOpen: true });
  };

  returnHome = () => {
    if (window.history.length > 1) {
      Control.go(-1);
      return;
    }
    Control.go(
      this.url.includes("BiMonthly")
        ? "/BiMonthly"
        : this.url.includes("FavoriteList")
        ? "/FavoriteList"
        : this.url.includes("IRBList")
        ? "/IRBList"
        : this.url.includes("MonitoringReport")
        ? "/MonitoringReport"
        : this.url.includes("PCMReport")
        ? "/PCMReport"
        : this.url.includes("MonitoringNeedsReview")
        ? "/MonitoringNeedsReview"
        : this.url.includes("PCMNeedsReview")
        ? "/PCMNeedsReview"
        : this.url.includes("MonitoringReviewed")
        ? "/MonitoringReviewed"
        : this.url.includes("PCMReviewed")
        ? "/PCMReviewed"
        : this.url.includes("RecordingReport")
        ? "/RecordingReport"
        : this.url.includes("RecordingNeedsReview")
        ? "/RecordingNeedsReview"
        : this.url.includes("RecordingReviewed")
        ? "/RecordingReviewed"
        : this.url.includes("ManagementSummary")
        ? "/ManagementSummary"
        : this.url.includes("SirenStaffing")
        ? "/SirenStaffing"
        : "/"
    );
  };

  refreshNewSockStatus = date => {
    const patientList = Control.state && Control.state.patientList;
    let [path, paramStr] = Control.path.split("?");
    // there is some bug in Control, which may not get query params
    if (!paramStr) {
      paramStr = window.location.href.split("?")[1];
    }
    if (paramStr.includes("/")) {
      path = `${path}?${paramStr.split("/")[0]}/`;
    } else {
      path = `${path}?`;
    }
    console.log("path: ", path);
    Control.replace(
      `${path}id=${btoa(this.state.id)}&userId=${
        this.state.userId
      }&startDate=${date}&endDate=${date}&tab=1`,
      { patientList }
    );
    this.setState(
      {
        urlContext: {
          param: {
            id: this.state.id,
            userId: this.state.userId,
            startDate: date,
            endDate: date,
            tab: "1"
          },
          updateParam: this.updateUrlParam
        },
        activeTab: "1"
      },
      this.fetchInitData
    );
  };

  searchPatientBySirenId = sirenId => {
    console.log("input search: ", sirenId);
    if (isEmpty(sirenId)) {
      return;
    }
    this.setState({ showDropDown: false });
    const { getPatientBySirenId } = this.props;
    getPatientBySirenId(sirenId, this).then(data => {
      console.log("this.state: ", this.state.id, this.state.userId);
      const patientList = Control.state && Control.state.patientList;
      let [path, paramStr] = Control.path.split("?");
      // there is some bug in Control, which may not get query params
      if (!paramStr) {
        paramStr = window.location.href.split("?")[1];
      }
      if (paramStr.includes("/")) {
        path = `${path}?${paramStr.split("/")[0]}/`;
      } else {
        path = `${path}?`;
      }
      console.log("path: ", path);
      const today = moment().format("YYYY-MM-DD");
      Control.replace(
        `${path}id=${btoa(this.state.id)}&userId=${
          this.state.userId
        }&startDate=${today}&endDate=${today}&tab=${this.state.activeTab}`,
        { patientList }
      );
      this.setState(
        {
          urlContext: {
            param: {
              id: this.state.id,
              userId: this.state.userId,
              startDate: today,
              endDate: today,
              tab: this.state.activeTab
            },
            updateParam: this.updateUrlParam
          }
        },
        this.fetchInitData
      );
      // const { getDoctorDetails } = this.props;
      // getDoctorDetails(
      //   this.state.id,
      //   this.state.userId,
      //   this,
      //   this.state.StartDate,
      //   this.state.EndDate
      // );
    });
  };

  handleSearch = inputText => {
    this.setState({ inputSearch: inputText });
  };

  onInputKeyDown = event => {
    if (event.keyCode === 13 || event.which === 13) {
      if (/^[0-9]+$/.test(this.state.inputSearch)) {
        console.log("input text is number ", this.state.inputSearch);
        this.searchPatientBySirenId(this.state.inputSearch);
      } else {
        console.log("input text is string ", this.state.inputSearch);
        this.searchPatientByName(this.state.inputSearch);
      }
    }
  };

  handleChange = value => {
    console.log("handle change ", value);
    this.searchPatientBySirenId(value);
  };

  searchPatientByName = name => {
    const filter = {
      or: [
        { firstName: { wildcard: `*${name}*` } },
        { lastName: { wildcard: `*${name}*` } }
      ]
    };
    const sort = { field: "sirenId", direction: "desc" };
    const DefaultPagination = {
      page: 0,
      size: 20
    };
    // this.setState({ modalIsOpen: true });
    patientListAPI("", filter, sort, DefaultPagination)
      .then(data => {
        let patients = data.data.lambdaSearchPatients.items;
        // console.log(`search patient by name: ${JSON.stringify(patients)}`);
        if (patients && patients.length > 0) {
          this.setState({ searchPatientData: patients });
        }
      })
      .catch(error => {
        console.log(`search patient by name error`);
      })
      .finally(() => {
        this.setState({ modalIsOpen: false });
      });
  };

  searchPatient = inputText => {
    if (/^[0-9]+$/.test(inputText)) {
      this.searchPatientBySirenId(inputText);
    } else {
      this.searchPatientByName(inputText);
    }
  };

  handleTopMenuClick = e => {
    const patientList = Control.state && Control.state.patientList;

    if (!patientList) {
      message.info("Only work for Consumer or Siren Staffing");
      return;
    }

    const index = patientList.findIndex(
      p => p.userId === this.state.urlContext.param.userId
    );
    if (index === -1) {
      console.warn(
        "inconsistant state, patient list does not include current patient"
      );
      return;
    }
    const { key } = e;
    let nextPatient;
    if (key === "next") {
      if (index === patientList.length - 1) {
        message.info("This is the last patient, please go to the next page");
        return;
      }
      nextPatient = patientList[index + 1];
    } else {
      if (index === 0) {
        message.info("This is the first patient, please go to the next page");
        return;
      }
      nextPatient = patientList[index - 1];
    }

    let [path, paramStr] = Control.path.split("?");
    // there is some bug in Control, which may not get query params
    if (!paramStr) {
      paramStr = window.location.href.split("?")[1];
    }
    if (paramStr.includes("/")) {
      path = `${path}?${paramStr.split("/")[0]}/`;
    } else {
      path = `${path}?`;
    }
    // date must be today. if not today, the patient may have no data that date
    const today = moment().format("YYYY-MM-DD");

    Control.replace(
      `${path}id=${btoa(nextPatient.id)}&userId=${
        nextPatient.userId
      }&startDate=${today}&endDate=${today}&tab=${this.state.activeTab}`,
      { patientList }
    );
    this.setState(
      {
        urlContext: {
          param: {
            id: nextPatient.id,
            userId: nextPatient.userId,
            startDate: today,
            endDate: today,
            tab: this.state.activeTab
          },
          updateParam: this.updateUrlParam
        }
      },
      this.fetchInitData
    );

    // navigate by 'next patient' button, end previous log
    SharedStopWatchLogManager.endLog(
      this.state.urlContext.param.userId,
      this.stopWatch.state.seconds
    );

    sharedWs.sendViewPatientMsg(this.state.urlContext.param.userId, "leave");
  };

  loadNewEditValue = () => {
    const { getDoctorDetails } = this.props;
    getDoctorDetails(
      this.state.urlContext.param.id,
      this.state.urlContext.param.userId,
      this,
      this.state.StartDate,
      this.state.EndDate
    );
  };

  componentDidMount() {
    const { getlistStaff, getlistClinicSites } = this.props;
    getlistStaff();
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }

    this.fetchInitData();
    this.viewPatientRegisterId = sharedWs.registerViewPatientEvent(
      this.handleViewPatientEvent
    );
    document.addEventListener("refreshMedicalDetail", this.fetchInitData);
    window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
  }

  componentWillUnmount() {
    SharedStopWatchLogManager.endLog(
      this.state.urlContext.param.userId,
      this.stopWatch.state.seconds
    );
    sharedWs.sendViewPatientMsg(this.state.urlContext.param.userId, "leave");
    sharedWs.unregisterViewPatientEvent(this.viewPatientRegisterId);
    document.removeEventListener("refreshMedicalDetail", this.fetchInitData);
    window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
  }

  handleWindowBeforeUnload = e => {
    SharedStopWatchLogManager.endLog(
      this.state.urlContext.param.userId,
      this.stopWatch.state.seconds
    );
    SharedStopWatchLogManager.saveLogLocally();
  };

  fetchInitData = e => {
    const { id, userId, startDate, endDate } = this.state.urlContext.param;

    this.url = window.location.href;

    // if e is not undefined, this function is triggered by event 'refreshMedicalDetail', then do not start log
    if (!e) {
      // fetch time logs
      this.setState({
        stopWatchLogs: []
      });
      SharedStopWatchLogManager.fetchAllLogs(userId).then(data => {
        if (data) {
          this.setState({
            stopWatchLogs: data.items
          });
        }
      });
      // add time log
      SharedStopWatchLogManager.startLog(userId);

      sharedWs.sendViewPatientMsg(userId, "enter");
    }

    const DateRangeDate_endDate = endDate;
    let currentYear = DateRangeDate_endDate.split("-")[0];
    let currentMonth = DateRangeDate_endDate.split("-")[1];
    let StartDate = moment(currentYear + "-" + currentMonth + "-01")
      .add(-6, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
    this.setState({ id, userId, StartDate, EndDate });
    window.location.href.includes("support") ||
    window.location.href.includes("admin") ||
    window.location.href.includes("TrialHome")
      ? this.setState({ showAppoint: true })
      : this.setState({ showAppoint: false });

    this.props.getDoctorDetails(id, userId, this, StartDate, EndDate);
  };

  handleViewPatientEvent = data => {
    for (const userId in data) {
      if (userId === this.state.urlContext.param.userId) {
        this.setState({
          viewers: data[userId]
        });
        break;
      }
    }
  };

  showTimerLog = () => {
    this.setState({
      drawerVisible: true
    });
  };

  download = () => {
    this.setState({ modalIsOpen: true });
    const self = this;

    html2canvas(
      document.getElementById(
        self.state.activeTab === "1"
          ? "user_detail_medical"
          : "siren_move_detail"
      ),
      {
        useCORS: true,
        allowTaint: false,
        scale: 2
      }
    )
      .then(function(canvas) {
        const baseInfoHeight = canvas.height;
        let pdf = new jsPDF("p", "mm", "a4"); //A4纸，纵向
        let ctx = canvas.getContext("2d"),
          a4w = 190,
          a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
          imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
          // imgHeight = canvas.height, //按A4显示比例换算一页图像的像素高度(+1是为了防止画布大小正好等于图片大小时溢出导致crash)
          renderedHeight = 0;
        pdf.page = 1;
        while (renderedHeight < baseInfoHeight) {
          let page = document.createElement("canvas");
          page.width = canvas.width;
          page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页
          //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
          page
            .getContext("2d")
            .putImageData(
              ctx.getImageData(
                0,
                renderedHeight,
                canvas.width,
                Math.min(imgHeight, canvas.height - renderedHeight)
              ),
              0,
              0
            );
          pdf.setFontSize(10);
          pdf.text(204, 294, String(pdf.page)); //print number bottom right
          pdf.page++;
          pdf.addImage(
            page.toDataURL("image/jpeg", 1.0),
            "JPEG",
            10,
            10,
            a4w,
            Math.min(a4h, (a4w * page.height) / page.width)
          ); //添加图像到页面，保留10mm边距
          renderedHeight += imgHeight;
          if (renderedHeight <= baseInfoHeight) pdf.addPage(); //如果后面还有内容，添加一个空页
        }

        pdf.save(
          self.state.activeTab === "1"
            ? "medical-details.pdf"
            : "siren-move-detail.pdf"
        );
        self.setState({ modalIsOpen: false });
      })
      .catch(err => {
        console.log(
          `download ${
            self.state.activeTab === "1"
              ? "medical-details.pdf"
              : "siren-move-detail.pdf"
          } error, ${err}`
        );
        self.setState({ modalIsOpen: false });
      });
  };

  onStarClick = async () => {
    if (this.state.rating === 1) {
      this.setState({ rating: 0 });
      let user = await Auth.currentAuthenticatedUser();
      Auth.userAttributes(user).then(attribute => {
        const favorite = filter(attribute, { Name: "custom:favorite" });
        // console.log("remove favorite: ", favorite);
        if (favorite.length > 0 && !isEmpty(favorite[0].Value)) {
          let favoriteValue = favorite[0].Value.split("_");
          // console.log("before remove favoriteValue: ", favoriteValue);
          if (favoriteValue.indexOf(this.state.sirenId) >= 0) {
            favoriteValue.splice(favoriteValue.indexOf(this.state.sirenId), 1);
            let newfavoriteValue = favoriteValue.join("_");
            // console.log("after remove favoriteValue: ", newfavoriteValue);
            Auth.updateUserAttributes(user, {
              "custom:favorite": newfavoriteValue
            })
              .then(data => {
                // console.log("updateUserAttributes: ", data);
              })
              .catch(error => {
                console.log("updateUserAttributes error: ", error);
              });
          }
        }
      });
    } else {
      this.setState({ rating: 1 });
      let user = await Auth.currentAuthenticatedUser();
      Auth.userAttributes(user).then(attribute => {
        const favorite = filter(attribute, { Name: "custom:favorite" });
        // console.log("add favorite: ", favorite);
        if (favorite.length > 0) {
          let favoriteValue = favorite[0].Value.split("_");
          // console.log(
          //   "favorite.length > 0, before add favoriteValue: ",
          //   favoriteValue
          // );
          if (favoriteValue.indexOf(this.state.sirenId) < 0) {
            favoriteValue.push(this.state.sirenId);
            let newfavoriteValue = favoriteValue.join("_");
            // console.log(
            //   "favorite.length > 0, after add favoriteValue: ",
            //   newfavoriteValue
            // );
            Auth.updateUserAttributes(user, {
              "custom:favorite": newfavoriteValue
            })
              .then(data => {
                // console.log("updateUserAttributes: ", data);
              })
              .catch(error => {
                console.log("updateUserAttributes error: ", error);
              });
          }
        } else {
          let favoriteValue = [];
          favoriteValue.push(this.state.sirenId);
          let newfavoriteValue = favoriteValue.join("_");
          // console.log("favorite.length = 0, after add favoriteValue: ", newfavoriteValue);
          Auth.updateUserAttributes(user, {
            "custom:favorite": newfavoriteValue
          })
            .then(data => {
              // console.log("updateUserAttributes: ", data);
            })
            .catch(error => {
              console.log("updateUserAttributes error: ", error);
            });
        }
      });
    }
  };

  getDoctorName = doctorId => {
    let doctorInfo = filter(this.props.listStaff, { email: doctorId });
    if (doctorInfo.length > 0) {
      if (
        doctorInfo[0].isPrescribing &&
        !doctorInfo[0].firstName.includes("Dr.")
      ) {
        return (
          "Dr. " +
          (doctorInfo[0].firstName ? doctorInfo[0].firstName : "") +
          " " +
          (doctorInfo[0].lastName ? doctorInfo[0].lastName : "")
        );
      }
      return (
        (doctorInfo[0].firstName ? doctorInfo[0].firstName : "") +
        " " +
        (doctorInfo[0].lastName ? doctorInfo[0].lastName : "")
      );
    }
  };

  //no data update or data update with other
  otherReviewTaskWithOrWithoutDataUpdate = () => {
    return (
      <div>
        <div
          style={{ backgroundColor: "#F7F7F7", color: "#3F3F3F", padding: 5 }}
        >
          Please review <strong>ALL tasks</strong> on the left before pressing
          the button.
        </div>
        <Form>
          <Form.Field>
            <div className="radio_group_title" style={{ marginTop: 10 }}>
              Recommendation
            </div>
            <Radio
              label="I recommend the patient come in for an office visit as soon as possible"
              name="radioGroup"
              style={{
                marginLeft: 0,
                color: "#243340",
                fontSize: 16
              }}
              value="radio_one"
              checked={this.state.value === "radio_one"}
              onChange={this.handleRadio}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="Continue to monitor/keep existing appointment"
              name="radioGroup"
              style={{
                marginLeft: 0,
                color: "#243340",
                fontSize: 16
              }}
              value="radio_two"
              checked={this.state.value === "radio_two"}
              onChange={this.handleRadio}
            />
          </Form.Field>
        </Form>
        <textarea
          placeholder="Message for the patient"
          value={this.state.comment}
          onChange={(e, data) => {
            this.handleCommentsChange(e.target.value);
          }}
          className="radio_textarea"
          style={{
            width: "100%",
            fontSize: 16,
            color: "#243340"
          }}
        />
        <div
          className="replace_button_box"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            float: "right",
            marginTop: 0
          }}
        >
          <Button
            color="green"
            className="replace_button_save"
            onClick={this.saveInfo.bind(this)}
            style={{
              marginBottom: 20,
              backgroundColor: "#7C35AF",
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 12,
              paddingBottom: 12
            }}
          >
            Submit recommendation
          </Button>
        </div>
      </div>
    );
  };

  //data update only within 3 days
  dataUpdateOnlyWithin3days = () => {
    return (
      <div>
        <div
          style={{ backgroundColor: "#F7F7F7", color: "#3F3F3F", padding: 5 }}
        >
          Please review <strong>ALL tasks</strong> on the left before pressing
          the button.
        </div>
        <Form>
          <Form.Field>
            <div className="radio_group_title" style={{ marginTop: 10 }}>
              Recommendation
            </div>
            <Radio
              label="I recommend the patient come in for an office visit as soon as possible"
              name="radioGroup"
              style={{
                marginLeft: 0,
                color: "#243340",
                fontSize: 16
              }}
              value="radio_one"
              checked={this.state.value === "radio_one"}
              onChange={this.handleRadio}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="Continue to monitor/keep existing appointment"
              name="radioGroup"
              style={{
                marginLeft: 0,
                color: "#243340",
                fontSize: 16
              }}
              value="radio_two"
              checked={this.state.value === "radio_two"}
              onChange={this.handleRadio}
            />
          </Form.Field>
        </Form>
        <textarea
          placeholder="Message for the patient"
          value={this.state.comment}
          onChange={(e, data) => {
            this.handleCommentsChange(e.target.value);
          }}
          className="radio_textarea"
          style={{
            width: "100%",
            fontSize: 16,
            color: "#243340"
          }}
        />
        <div
          className="replace_button_box"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 0,
            float: "right"
          }}
        >
          <Button
            className="replace_button_save"
            onClick={this.dismissReviewTaskStatus}
            style={{
              marginBottom: 20,
              float: "right",
              border: "solid",
              borderWidth: 1,
              borderRadius: 5,
              backgroundColor: "#FFFFFF",
              borderColor: "#7C35AF",
              color: "#7C35AF",
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 12,
              paddingBottom: 12
            }}
          >
            Dismiss this task
          </Button>
          <Button
            color="green"
            className="replace_button_save"
            onClick={this.saveInfo.bind(this)}
            style={{
              marginBottom: 20,
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 12,
              paddingBottom: 12
            }}
          >
            Submit recommendation
          </Button>
        </div>
      </div>
    );
  };

  //data update only over 3 days
  dataUpdateOnlyOver3days = () => {
    return (
      <div>
        <div style={{ color: "#243340", fontSize: 16, height: 250 }}>
          No recommendation needed at this time.
        </div>
        <Button
          className="replace_button_save"
          onClick={this.dismissReviewTaskStatus}
          style={{
            marginBottom: 20,
            float: "right",
            border: "solid",
            borderWidth: 1,
            borderRadius: 5,
            backgroundColor: "#FFFFFF",
            borderColor: "#7C35AF",
            color: "#7C35AF",
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12
          }}
        >
          Dismiss this task
        </Button>
      </div>
    );
  };

  //onboarding task only
  onboardingTaskOnly = () => {
    return (
      <div>
        <div
          style={{ backgroundColor: "#F7F7F7", color: "#3F3F3F", padding: 5 }}
        >
          Please review <strong>ALL tasks</strong> on the left before pressing
          the button.
        </div>
        <div
          style={{
            color: "#6F7381",
            fontSize: 18,
            marginTop: 10,
            fontWeight: "bold"
          }}
        >
          Welcome call
        </div>
        <div
          style={{ color: "#3F3F3F", fontSize: 16, height: 200, marginTop: 10 }}
        >
          {this.state.multipleOnboardingTask
            ? "Multiple programs or devices have been added for this patient. You can start using the DMT to record details."
            : "A new program or device has been added for this patient. You can start using the DMT to record details."}
        </div>
        <Button
          className="replace_button_save"
          onClick={() => {
            this.dismissReviewTaskStatus();
            this.addOnboardingNote();
          }}
          style={{
            marginBottom: 20,
            float: "right",
            backgroundColor: "#7C35AF",
            color: "#fff",
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 12,
            paddingBottom: 12
          }}
        >
          OK, got
        </Button>
      </div>
    );
  };

  renderReviewTasks = () => {
    const allReviewTasks = formatReviewTasks(
      this.props.doctorDetails.reviewTask
    );
    this.program_device = [];
    return (
      <div>
        {allReviewTasks.map((p, i) => {
          if (p.type === "DATA_UPDATE") {
            return (
              <div
                key={"reviewTask" + i}
                style={{
                  marginTop: 15,
                  color: "#243340",
                  fontSize: 16
                }}
              >
                <ReviewTaskDataUpdatePop
                  status={p.status}
                  oldStatus={p.footStatusChange.oldStatus}
                  newStatus={p.footStatusChange.newStatus}
                  oldStatusDate={p.oldStatusDate}
                  newStatusDate={p.newStatusDate}
                  diff={p.footStatusChange.diff}
                  taskTime={p.taskTime}
                />
                <AntdButton
                  type="link"
                  style={{
                    color: "#7C35AF",
                    display: this.state.canAddDMT ? "block" : "none",
                    marginLeft: 43
                  }}
                  onClick={() => {
                    this.setState({
                      activeTab: "2"
                    });
                  }}
                >
                  Go to DMT to add addendum
                </AntdButton>
              </div>
            );
          }
          const taskStatus = p.tasks.map(s => {
            return s.status;
          });
          this.program_device = _.uniq(
            _.compact([
              ...this.program_device,
              ...p.tasks.map(u => {
                if (u.status === "Onboarding") {
                  return u.device || u.program;
                }
                return null;
              })
            ])
          );
          return (
            <div key={"DMT" + i}>
              <div
                key={"reviewTask" + i}
                style={{
                  marginTop: 15,
                  color: "#243340",
                  fontSize: 15,
                  display: "flex"
                }}
              >
                <div style={{ width: 40 }}>
                  <strong>{moment(p.taskTime).format("M/D")}</strong>
                </div>
                <div style={{ marginLeft: 20 }}>
                  {p.tasks.map((t, i) => {
                    return (
                      <div style={{ marginTop: i > 0 ? 15 : 0 }} key={i}>
                        {t.status === "Medical Review" ? (
                          <div style={{ display: "flex" }}>
                            <strong>{t.status}</strong>{" "}
                            {moment(t.date).format("MM/DD") !==
                              moment(p.taskTime).format("MM/DD") && (
                              <p>
                                (generated on {moment(t.date).format("MM/DD")})
                              </p>
                            )}
                          </div>
                        ) : (
                          <strong>{t.status}</strong>
                        )}
                        {t.program && <div>Program: {t.program}</div>}
                        {t.device && <div>Device: {t.device}</div>}
                      </div>
                    );
                  })}
                </div>
              </div>
              {taskStatus.includes("Onboarding") && (
                <AntdButton
                  type="link"
                  style={{
                    color: "#7C35AF",
                    display: this.state.canAddDMT ? "block" : "none",
                    marginLeft: 43
                  }}
                  onClick={() => {
                    this.setState({
                      activeTab: "2"
                    });
                  }}
                >
                  Go to DMT to record details
                </AntdButton>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  renderProfileBar = () => {
    const userName = !isEmpty(this.props.doctorDetails)
      ? this.props.doctorDetails.firstName +
        " " +
        this.props.doctorDetails.lastName
      : "";
    const items = [
      // {
      //   label: "Site ID",
      //   value: "siteId"
      // },
      {
        label: "Site Name",
        value: "siteName"
      },
      {
        label: "Siren ID",
        value: "sirenId"
      },
      {
        label: "DOB",
        value: profile => {
          return (
            profile.birthday && moment(profile.birthday).format("MM/DD/YYYY")
          );
        }
      },
      {
        label: (
          <>
            {this.props.doctorDetails.phone &&
            formatNumber(this.props.doctorDetails.phone)
              ? "Cell"
              : "Home"}
          </>
        ),
        value: profile => {
          return (
            (profile.phone && formatNumber(profile.phone)) ||
            (profile.homePhone && formatNumber(profile.homePhone)) ||
            (profile.id && formatNumber(profile.id))
          );
        }
      },
      {
        label: "Smartphone",
        value: profile => {
          return profile.patientHasSmartPhone
            ? profile.patientHasSmartPhone
            : "No";
        }
      },
      {
        label: "Doctor",
        value: profile => {
          return profile.doctorId && this.getDoctorName(profile.doctorId);
        }
      }
    ];
    return (
      <div className="scroll_bar">
        <div style={{ flex: "auto", display: "flex", alignItems: "center" }}>
          <strong
            style={{
              fontSize: 16,
              marginLeft: 64,
              maxWidth: 200,
              textOverflow: "ellipsis"
            }}
            title={userName}
          >
            {userName}
          </strong>
          <Divider type="vertical" style={{ margin: "0 20px" }} />
          {items.map(item => (
            <React.Fragment key={item.label}>
              <div>
                <label>{item.label}: </label>
                <span style={{ whiteSpace: "nowrap" }}>
                  {this.props.doctorDetails &&
                    (typeof item.value === "function"
                      ? item.value(this.props.doctorDetails)
                      : this.props.doctorDetails[item.value])}
                </span>
              </div>
              <Divider type="vertical" style={{ margin: "0 20px" }} />
            </React.Fragment>
          ))}
          <EditProfileInfo
            userInfo={this.props.doctorDetails}
            loadNewEditValue={this.loadNewEditValue}
            editFootMedical={key => {
              let path = window.location.hash.substring(1);
              const index = path.indexOf("&tab=");
              if (index === -1) {
                path = path + "&tab=" + key;
              } else {
                path = path.substring(0, index) + "&tab=" + key;
              }

              Control.replace(path, Control.state);
              this.setState({
                activeTab: key
              });
            }}
          />
          {/*<Search*/}
          {/*  placeholder="input search text"*/}
          {/*  allowClear*/}
          {/*  onSearch={this.searchPatient}*/}
          {/*  style={{ width: 200, marginLeft: 20 }}*/}
          {/*/>*/}

          <Select
            showSearch
            value={this.state.inputSearch}
            placeholder={"Search sirenId or  user name"}
            style={{ width: 200, marginLeft: 20 }}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            notFoundContent={null}
            onInputKeyDown={this.onInputKeyDown}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ textAlign: "left" }}
          >
            {this.state.searchPatientData.map(item => (
              <Option key={item.sirenId} value={`${item.sirenId}`}>
                {item.firstName + " " + item.lastName}
              </Option>
            ))}
          </Select>
        </div>
        <Popover
          content={<a onClick={this.showTimerLog}>View Stopwatch Logs</a>}
          title="Stop watch"
          trigger="hover"
        >
          <StopWatch
            key={this.state.urlContext.param.id}
            ref={ref => (this.stopWatch = ref)}
            case="start"
            style={{
              marginLeft: 20,
              marginRight: 20,
              zIndex: this.props.DMTModalStatus === "open" ? 2000 : 1
            }}
          />
          {""}
        </Popover>
      </div>
    );
  };

  renderTimerLog() {
    const columns = [
      {
        title: "Date",
        dataIndex: "reviewTime",
        key: "date",
        render: date => <span>{moment(date).format("MM-DD-YYYY")}</span>
      },
      {
        title: "Nurse",
        dataIndex: "createdBy",
        ellipsis: true,
        width: 150
      },
      {
        title: "Time spent",
        dataIndex: "timeSpent",
        render: time => {
          if (typeof time !== "number") {
            time = this.stopWatch.state.seconds;
          }
          const min = Math.floor(time / 60);
          const sec = time % 60;
          return (
            <span>{`${min < 10 ? "0" + min : min}:${
              sec < 10 ? "0" + sec : sec
            }`}</span>
          );
        }
      },
      {
        title: "Start time",
        dataIndex: "reviewTime",
        render: date => <span>{moment(date).format("HH:mm:ss")}</span>
      },
      {
        title: "End time",
        dataIndex: "endTime",
        render: date => <span>{moment(date).format("HH:mm:ss")}</span>
      }
    ];

    return (
      <Drawer
        title="Stopwatch Log"
        placement="right"
        width={600}
        closable={false}
        onClose={() => this.setState({ drawerVisible: false })}
        visible={this.state.drawerVisible}
        style={{ zIndex: 9999 }}
      >
        <Table
          dataSource={this.state.stopWatchLogs}
          columns={columns}
          rowKey="reviewTime"
          size="small"
        />
      </Drawer>
    );
  }

  renderPatientViewers() {
    if (!this.state.viewers || this.state.viewers.length === 0) {
      return;
    }
    const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

    return (
      <div style={{ marginRight: 20 }}>
        {this.state.viewers.map((viewer, i) => (
          <Tooltip title={viewer.username} placement="top" key={i}>
            <Avatar
              style={{
                backgroundColor: ColorList[i % 4]
              }}
            >
              {viewer.shortname}
            </Avatar>
          </Tooltip>
        ))}
      </div>
    );
  }

  render() {
    return (
      <PatientDetailUrlContext.Provider value={this.state.urlContext}>
        <Affix offsetTop={54} style={{ marginTop: -10 }}>
          {this.renderProfileBar()}
        </Affix>
        <div style={{ position: "absolute", top: 120, right: 24, zIndex: 5 }}>
          <div style={{ display: "flex" }}>
            {this.renderPatientViewers()}
            <Dropdown.Button
              onClick={this.returnHome}
              overlay={
                <Menu onClick={this.handleTopMenuClick}>
                  <Menu.Item key="next">Next patient</Menu.Item>
                  <Menu.Item key="prev">Previous patient</Menu.Item>
                </Menu>
              }
            >
              Return to list
            </Dropdown.Button>
          </div>
        </div>
        {this.renderTimerLog()}
        <Tabs
          defaultActiveKey="1"
          activeKey={this.state.activeTab}
          style={{
            textAlign: "left"
          }}
          onTabClick={key => {
            let path = window.location.hash.substring(1);
            const index = path.indexOf("&tab=");
            if (index === -1) {
              path = path + "&tab=" + key;
            } else {
              path = path.substring(0, index) + "&tab=" + key;
            }

            Control.replace(path, Control.state);
            this.setState({
              activeTab: key
            });
          }}
        >
          <TabPane tab="Medical" key="1">
            <div style={{ textAlign: "center" }}>
              <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={commonStyles}
                transitionEnterTimeout={10000}
                transitionLeaveTimeout={10000}
              >
                <Progress />
              </Modal>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 20
                }}
              >
                <Icon
                  size="big"
                  color={this.state.rating ? "yellow" : null}
                  name="star"
                  title="add to favorite"
                  onClick={this.onStarClick}
                />
                <div>
                  <AddBox />
                  <AddHub />
                  <CompleteOnboarding
                    sirenId={String(this.props.doctorDetails.sirenId)}
                  />
                </div>
                <div />
              </div>
              <div className="medical_recommendation_container">
                <div
                  style={{
                    width: "100%",
                    marginBottom:
                      this.props.doctorDetails &&
                      this.props.doctorDetails.category &&
                      this.props.doctorDetails.category.length > 0 &&
                      (this.props.doctorDetails.category.includes(
                        "Medical Review"
                      ) ||
                        this.props.doctorDetails.category.includes(
                          "Photo Review"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Symptoms"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Data Update"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Onboarding"
                        ))
                        ? 20
                        : 0,
                    display:
                      this.props.doctorDetails &&
                      this.props.doctorDetails.category &&
                      this.props.doctorDetails.category.length > 0 &&
                      (this.props.doctorDetails.category.includes(
                        "Medical Review"
                      ) ||
                        this.props.doctorDetails.category.includes(
                          "Photo Review"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Symptoms"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Data Update"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Onboarding"
                        ))
                        ? "block"
                        : "none"
                  }}
                >
                  <div className="title">Review Tasks</div>
                  <div className="topRecommend">
                    <div className="choice_box" style={{ display: "flex" }}>
                      <div className="radio_box">
                        <div
                          style={{
                            overflowY: "auto",
                            marginBottom: 20,
                            height: 320
                          }}
                        >
                          {this.renderReviewTasks()}
                        </div>
                        {/*<AntdButton*/}
                        {/*  type="link"*/}
                        {/*  style={{*/}
                        {/*    color: "#a600ff",*/}
                        {/*    display: this.state.canAddDMT ? "block" : "none",*/}
                        {/*    marginLeft: -15*/}
                        {/*  }}*/}
                        {/*  onClick={() => {*/}
                        {/*    this.setState({*/}
                        {/*      activeTab: "2"*/}
                        {/*    });*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  Go to DMT to add addendum*/}
                        {/*</AntdButton>*/}
                      </div>
                      <div
                        style={{
                          margin: "10px 10px 10px 20px",
                          border: "1px solid #a5b3bf"
                        }}
                      />
                      <div className="comment_box">
                        {this.state.onboardingTaskOnly
                          ? this.onboardingTaskOnly()
                          : this.state.dateUpdateTaskOnly
                          ? this.state.within3DaysTask
                            ? this.dataUpdateOnlyWithin3days()
                            : this.dataUpdateOnlyOver3days()
                          : this.otherReviewTaskWithOrWithoutDataUpdate()}
                      </div>
                      <div className="clear_both" />
                    </div>
                  </div>
                </div>
              </div>

              <div id="user_detail_medical">
                <div>
                  <div style={{ display: "flex", gap: 12 }}>
                    <div style={{ flex: "3 3" }}>
                      {this.props.doctorDetails.sirenId ? (
                        <SirenNotes
                          sirenId={this.props.doctorDetails.sirenId}
                        />
                      ) : null}
                      <PatientSym />
                    </div>
                    <div style={{ flex: "3 3" }}>
                      <div className="title">
                        COMPLIANCE CALENDAR
                        <Popover
                          content={
                            <div>
                              <p style={{ margin: 10 }}>
                                <b>Calendar Key</b>
                              </p>
                              <div
                                style={{
                                  padding: 10,
                                  backgroundColor: "#f3f3f5"
                                }}
                              >
                                <p>
                                  <b>Purple:</b> 30+ mins of wear{" "}
                                </p>
                              </div>
                            </div>
                          }
                        >
                          <QuestionCircleFilled
                            style={{ fontSize: 20, float: "right", margin: 4 }}
                          />
                        </Popover>
                      </div>
                      <Calendar1 staff={true} />
                    </div>
                    <div style={{ flex: "2 2" }}>
                      <div className="title">FOOT WARNING STATUS</div>
                      <Status staff={true} />
                    </div>
                  </div>
                  {!_.isEmpty(this.props.doctorDetails) &&
                  this.props.weightScale.length > 0 ? (
                    <Collapse
                      bordered={true}
                      expandIconPosition={"right"}
                      expandIcon={({ isActive }) => (
                        <div style={{ color: "white", fontSize: 18 }}>
                          {isActive ? <MinusOutlined /> : <PlusOutlined />}
                        </div>
                      )}
                      className="title"
                      style={{
                        marginTop: 12,
                        height: "auto",
                        boarderRadius: 0
                      }}
                      defaultActiveKey={
                        this.props.weightScale.length > 0 ? 1 : 0
                      }
                    >
                      <Collapse.Panel
                        header={
                          <div
                            className="title"
                            style={{
                              width: "100%"
                            }}
                          >
                            WEIGHT SCALE
                          </div>
                        }
                        key="1"
                        className="site-collapse-custom-panel"
                        showArrow={true}
                      >
                        {this.props.weightScale.length > 0 ? (
                          <WeightScale
                            id={this.state.urlContext.param.id}
                            userId={this.state.urlContext.param.userId}
                          />
                        ) : (
                          <div>No data</div>
                        )}
                      </Collapse.Panel>
                    </Collapse>
                  ) : null}
                  <Collapse
                    bordered={true}
                    expandIconPosition={"right"}
                    expandIcon={({ isActive }) => (
                      <div style={{ color: "white", fontSize: 18 }}>
                        {isActive ? <MinusOutlined /> : <PlusOutlined />}
                      </div>
                    )}
                    className="title"
                    style={{ marginTop: 12, height: "auto", boarderRadius: 0 }}
                  >
                    <Collapse.Panel
                      header={
                        <div
                          className="title"
                          style={{
                            width: "100%"
                          }}
                        >
                          PHOTO
                        </div>
                      }
                      key="1"
                      className="site-collapse-custom-panel"
                      showArrow={true}
                    >
                      <Photo staff={true} />
                    </Collapse.Panel>
                  </Collapse>
                  {/*<Collapse*/}
                  {/*  bordered={true}*/}
                  {/*  expandIconPosition={"right"}*/}
                  {/*  expandIcon={({ isActive }) => (*/}
                  {/*    <div style={{ color: "white", fontSize: 18 }}>*/}
                  {/*      {isActive ? <MinusOutlined /> : <PlusOutlined />}*/}
                  {/*    </div>*/}
                  {/*  )}*/}
                  {/*  className="title"*/}
                  {/*  style={{ marginTop: 12, height: "auto", boarderRadius: 0 }}*/}
                  {/*>*/}
                  {/*  <Collapse.Panel*/}
                  {/*    header={*/}
                  {/*      <div*/}
                  {/*        className="title"*/}
                  {/*        style={{*/}
                  {/*          width: "100%"*/}
                  {/*        }}*/}
                  {/*      >*/}
                  {/*        WITHOUT SHOES*/}
                  {/*      </div>*/}
                  {/*    }*/}
                  {/*    key="1"*/}
                  {/*    className="site-collapse-custom-panel site-collapse-custom-panel-overflow-visible"*/}
                  {/*    showArrow={true}*/}
                  {/*  >*/}
                  {/*    <TimeWornWithOutShoes />*/}
                  {/*  </Collapse.Panel>*/}
                  {/*</Collapse>*/}
                  <Collapse
                    bordered={true}
                    expandIconPosition={"right"}
                    expandIcon={({ isActive }) => (
                      <div style={{ color: "white", fontSize: 18 }}>
                        {isActive ? <MinusOutlined /> : <PlusOutlined />}
                      </div>
                    )}
                    className="title"
                    style={{ marginTop: 12, height: "auto", boarderRadius: 0 }}
                  >
                    <Collapse.Panel
                      header={
                        <div
                          className="title"
                          style={{
                            width: "100%"
                          }}
                        >
                          PATIENT DATA
                        </div>
                      }
                      key="1"
                      className="site-collapse-custom-panel site-collapse-custom-panel-overflow-visible"
                      showArrow={true}
                    >
                      <CalendarChart />
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </TabPane>
          {this.props.dev_env && (
            <TabPane tab="Siren Move" key="5">
              <div id="siren_move_detail">
                <Modal
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  style={commonStyles}
                  transitionEnterTimeout={10000}
                  transitionLeaveTimeout={10000}
                >
                  <Progress />
                </Modal>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 20
                  }}
                >
                  <Icon
                    size="big"
                    color={this.state.rating ? "yellow" : null}
                    name="star"
                    title="add to favorite"
                    onClick={this.onStarClick}
                  />
                  <Button basic onClick={this.download}>
                    <Icon name="download" />
                    Download Profile
                  </Button>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="info" style={{ flex: 0.45 }}>
                    <TagActivation />
                  </div>
                  <div className="info" style={{ flex: 0.3 }}>
                    <TagWornCalendar />
                  </div>
                </div>
                <Collapse
                  defaultActiveKey={"1"}
                  bordered={true}
                  expandIconPosition={"right"}
                  expandIcon={({ isActive }) => (
                    <div style={{ color: "white", fontSize: 18 }}>
                      {isActive ? <MinusOutlined /> : <PlusOutlined />}
                    </div>
                  )}
                  className="title"
                  style={{ marginTop: 12, height: "auto", boarderRadius: 0 }}
                >
                  <Collapse.Panel
                    header={
                      <div
                        className="title"
                        style={{
                          width: "100%",
                          textAlign: "center"
                        }}
                      >
                        SIREN MOVE DATA
                      </div>
                    }
                    key="1"
                    className="site-collapse-custom-panel site-collapse-custom-panel-overflow-visible"
                    showArrow={true}
                  >
                    <CalendarTag />
                  </Collapse.Panel>
                </Collapse>
              </div>
            </TabPane>
          )}
          {this.props.dev_env && (
            <TabPane tab="Pill Bottle" key="6">
              <div id="pill_bottle_detail">
                <Collapse
                  defaultActiveKey={"1"}
                  bordered={true}
                  expandIconPosition={"right"}
                  expandIcon={({ isActive }) => (
                    <div style={{ color: "white", fontSize: 18 }}>
                      {isActive ? <MinusOutlined /> : <PlusOutlined />}
                    </div>
                  )}
                  className="title"
                  style={{ marginTop: 12, height: "auto", boarderRadius: 0 }}
                >
                  <Collapse.Panel
                    header={
                      <div
                        className="title"
                        style={{
                          width: "100%",
                          textAlign: "center"
                        }}
                      >
                        PILL BOTTLE DATA
                      </div>
                    }
                    key="1"
                    className="site-collapse-custom-panel site-collapse-custom-panel-overflow-visible"
                    showArrow={true}
                  >
                    <CalendarPillBottle />
                  </Collapse.Panel>
                </Collapse>
              </div>
            </TabPane>
          )}
          {process.env.REACT_APP_ENV === "dev" && (
            <TabPane tab="Slow Step" key="slowStep">
              <SlowStep />
            </TabPane>
          )}
          {/*<TabPane tab="Activity" key="4">*/}
          {/*  <ActivityLog />*/}
          {/*</TabPane>*/}
          <TabPane tab="Data Monitoring Tracker" key="2">
            <Tracker99457
              patientId={this.state.urlContext.param.id}
              patientUserId={this.state.urlContext.param.userId}
              loadNewEditValue={this.loadNewEditValue}
            />
          </TabPane>
          <TabPane tab="Equipment" key="3">
            <SockStatusHome
              editNewSockStatus={data => {
                console.log("data: ", data);
                this.refreshNewSockStatus(data);
              }}
            />
          </TabPane>
          <TabPane tab="Documents" key="7">
            <DocumentsList />
          </TabPane>
          {/*<TabPane tab="Prescription" key="prescription">*/}
          {/*  <Prescription userId={this.state.urlContext.param.userId} />*/}
          {/*</TabPane>*/}
        </Tabs>
      </PatientDetailUrlContext.Provider>
    );
  }
}
const mapStateToProp = state => ({
  userGroups: state.authGroupStore.session.userGroups,
  doctorDetails: state.patientsListStore.doctorDetails,
  weightScale: state.patientsListStore.weightScale,
  listStaff: state.patientsListStore.listStaff,
  clinicSites: state.patientsListStore.clinicSites,
  dev_env: state.patientsListStore.dev_env,
  DMTModalStatus: state.patientsListStore.DMTModalStatus
});

const mapDispatchToProp = dispatch => ({
  getDoctorDetails: (id, userId, self, startDate, endDate) =>
    dispatch(getDoctorDetails(id, userId, self, startDate, endDate)),
  getlistStaff: () => dispatch(getlistStaff()),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  updateNotification: (editInput, self) =>
    dispatch(updateNotification(editInput, self)),
  getAppointmented: (inputData, self) =>
    dispatch(getAppointmented(inputData, self)),
  dismissReviewTask: (inputData, self) =>
    dispatch(dismissReviewTask(inputData, self)),
  createNote: input => dispatch(createNote(input)),
  getPatientBySirenId: (sirenId, self) =>
    dispatch(getPatientBySirenId(sirenId, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(PatientDetail);
