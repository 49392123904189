import React, { Component } from "react";
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Dropdown,
  Modal,
  Message
} from "semantic-ui-react";
import ButtonSuccess from "../../../home/buttonSuccess";
import _, { isEmpty } from "lodash";
import { searchHubList } from "../../../graphql/API";
import { DatePicker, Modal as AntdModal, notification, Select } from "antd";
import { connect } from "react-redux";
import { registerHub } from "../../../action/boxAction";
import moment from "moment";
import { updateProgramStatus } from "../../../action/programStatusAction";

const { Option } = Select;
const dateFormat = "ddd, Do MMM YYYY";
const PAUSED_REASON = [
  { name: "Comfort (too hot to wear)", value: "comfort_hot" },
  { name: "Internet (no cellular or WiFi)", value: "technical_connectivity" },
  { name: "Medical (in hospital/surgery)", value: "medical_hospital" },
  { name: "Medical (other)", value: "medical_other" },
  { name: "Needs replacement hub", value: "replacement_hub" },
  { name: "Needs replacement socks", value: "replacement_socks" },
  { name: "Not responsive", value: "not_responsive" },
  { name: "Size/Color not in stock", value: "out_of_stock" },
  { name: "Other (specify)", value: "other" }
];
const OFF_SERVICE_REASON = [
  { name: "Comfort (allergy)", value: "comfort_allergy" },
  {
    name: "Comfort (lifestyle (Color/Size/Style))",
    value: "comfort_lifestyle"
  },
  { name: "Comfort (too hot to wear)", value: "comfort_hot" },
  { name: "Comfort (size not available)", value: "comfort_size_not_available" },
  { name: "Comfort (other)", value: "comfort_other" },
  { name: "Deceased", value: "deceased" },
  { name: "Medical (in hospital/surgery)", value: "medical_hospital" },
  { name: "Medical (other)", value: "medical_other" },
  { name: "Not responsive", value: "not_responsive" },
  {
    name: "Program (didn't know socks were ordered)",
    value: "program_lack_of_knowledge"
  },
  {
    name: "Program (non-participation family/caregiver)",
    value: "program_non_participation_family"
  },
  {
    name: "Program (non-participation patient)",
    value: "program_non_participation_patient"
  },
  {
    name: "Program end (clinic decision)",
    value: "program_end_clinic"
  },
  {
    name: "Program end (Siren decision)",
    value: "program_end_siren"
  },
  {
    name: "Technical (App too complicated/Not tech savvy)",
    value: "technical_app"
  },
  { name: "Technical (no cellular or WiFi)", value: "technical_connectivity" },
  { name: "Off Service", value: "off_service" },
  { name: "Other (specify)", value: "other" }
];

const CANCELLED_REASON = [
  { name: "Deceased", value: "deceased" },
  { name: "Medical (in hospital/surgery)", value: "medical_hospital" },
  { name: "Medical (other)", value: "medical_other" },
  { name: "Not responsive", value: "not_responsive" },
  {
    name: "Program (didn't know socks were ordered)",
    value: "program_lack_of_knowledge"
  },
  { name: "Program (does not see value)", value: "program_value" },
  {
    name: "Technical (App too complicated/Not tech savvy)",
    value: "technical_app"
  },
  { name: "Technical (no cellular or WiFi)", value: "technical_connectivity" },
  { name: "Other (specify)", value: "other" }
];
class CompleteOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null,
      program: "RPM",
      programStatus: "",
      sirenId: "",
      pausedProgramReason: "",
      offServiceProgramReason: "",
      cancelledReason: "",
      endDate: moment().format("YYYY-MM-DD")
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null,
      sirenId: this.props.sirenId
    });
  };

  close = () => {
    this.setState({
      open: false,
      hubId: "",
      authCode: "",
      confirmSuccess: false,
      hasConfirm: false,
      loading: false,
      errorMsg: null,
      programStatus: "",
      endDate: moment().format("YYYY-MM-DD")
    });
    if (typeof this.props.onClose === "function") {
      this.props.onClose(true);
    }
  };

  changeProgramStatusReason = value => {
    if (this.state.programStatus === "NOT_ENROLLED") {
      this.setState({ offServiceProgramReason: value });
    } else if (this.state.programStatus === "PAUSED") {
      this.setState({ pausedProgramReason: value });
    } else {
      this.setState({ cancelledProgramReason: value });
    }
  };

  changeProgramStatus = value => {
    this.setState({ programStatus: value });
  };

  onChangeSirenId = event => {
    this.setState({ sirenId: event.target.value });
  };

  clear = () => {
    this.setState({ programStatus: "", open: false });
  };

  submit = () => {
    if (_.isEmpty(this.state.sirenId)) {
      this.warning(`SirenId can't be empty.`);
      return;
    }
    if (_.isEmpty(this.state.programStatus)) {
      this.warning(`Program Status can't be empty.`);
      return;
    }

    const UpdateProgramStatusInput = {
      input: {
        sirenId: parseInt(this.state.sirenId),
        program: this.state.program,
        programStatus: this.state.programStatus
      }
    };
    if (this.state.programStatus === "NOT_ENROLLED") {
      if (_.isEmpty(this.state.endDate)) {
        AntdModal.warning({
          title: "EndDate Error",
          content: "Please select endDate.",
          centered: true
        });
        return;
      }
      if (_.isEmpty(this.state.offServiceProgramReason)) {
        AntdModal.warning({
          title: "EndDate Error",
          content: "Please select NOT Enrolled Reason.",
          centered: true
        });
        return;
      }
      Object.assign(UpdateProgramStatusInput.input, {
        endDate: moment(this.state.endDate).format("MM/DD/YYYY"),
        offServiceProgramReason: this.state.offServiceProgramReason
      });
    }
    if (this.state.programStatus === "PAUSED") {
      if (_.isEmpty(this.state.pausedProgramReason)) {
        AntdModal.warning({
          title: "Error",
          content: "Please select Paused Reason.",
          centered: true
        });
        return;
      }
      Object.assign(UpdateProgramStatusInput.input, {
        pausedProgramReason: this.state.pausedProgramReason
      });
    }
    if (this.state.programStatus === "CANCELLED") {
      if (_.isEmpty(this.state.cancelledProgramReason)) {
        AntdModal.warning({
          title: "Error",
          content: "Please select Cancelled Reason.",
          centered: true
        });
        return;
      }
      Object.assign(UpdateProgramStatusInput.input, {
        cancelledProgramReason: this.state.cancelledProgramReason
      });
    }
    updateProgramStatus(UpdateProgramStatusInput)
      .then(() => {
        notification.success({
          message: "Set program status successfully"
        });
        this.setState({ open: false });
      })
      .catch(e => {
        notification.error({
          message: "System Error",
          description: JSON.stringify(e)
        });
        this.setState({ open: false });
      });
  };

  warning = content => {
    AntdModal.warning({
      title: "Warning",
      content,
      centered: true
    });
  };

  onDateChanged = date => {
    if (date) {
      this.setState({ endDate: moment(date).format("YYYY-MM-DD") });
    }
  };

  render() {
    const { open } = this.state;

    return (
      <Modal
        trigger={
          <Button
            size="small"
            onClick={this.handleOpen}
            style={{ marginLeft: 20 }}
          >
            Complete Onboarding
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Complete Onboarding</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <div
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  fontWeight: "bold",
                  marginRight: 10,
                  marginLeft: 100
                }}
              >
                SirenId:
              </div>
              <Input
                style={{
                  width: 250,
                  float: "left",
                  marginTop: 5,
                  marginLeft: 100
                }}
                value={this.state.sirenId}
                placeholder="Ex.288"
                onChange={this.onChangeSirenId}
              />
              <div
                style={{
                  textAlign: "left",
                  marginTop: 60,
                  fontWeight: "bold",
                  marginRight: 10,
                  width: 250,
                  marginLeft: 100
                }}
              >
                Program Status:
              </div>
              <Select
                style={{ float: "left", marginTop: 5, marginLeft: 100 }}
                className="selectStyle"
                onChange={this.changeProgramStatus}
                value={this.state.programStatus}
                showSearch
                optionFilterProp="children"
              >
                <Option value="" />
                <Option value="PENDING">Pending</Option>
                <Option value="ENROLLED">Enrolled</Option>
                <Option value="PAUSED">Paused</Option>
                <Option value="NOT_ENROLLED">Not Enrolled</Option>
                <Option value="CANCELLED">Cancelled</Option>
              </Select>
              <div
                style={{
                  marginTop: 60,
                  display:
                    this.state.programStatus === "NOT_ENROLLED"
                      ? "block"
                      : "none"
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    marginRight: 10,
                    marginLeft: 100
                  }}
                >
                  EndDate:
                </div>
                <DatePicker
                  onChange={this.onDateChanged}
                  value={moment(this.state.endDate)}
                  format={dateFormat}
                  style={{
                    width: 250,
                    marginTop: 10,
                    float: "left",
                    marginLeft: 100
                  }}
                  allowClear={false}
                />
              </div>
              <div
                style={{
                  marginTop: 60,
                  display:
                    this.state.programStatus === "NOT_ENROLLED" ||
                    this.state.programStatus === "PAUSED" ||
                    this.state.programStatus === "CANCELLED"
                      ? "block"
                      : "none"
                }}
              >
                <div
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    marginRight: 10,
                    width: 250,
                    marginLeft: 100
                  }}
                >
                  {this.state.programStatus === "NOT_ENROLLED"
                    ? "Not Enrolled Reason:"
                    : this.state.programStatus === "PAUSED"
                    ? "Paused Reason:"
                    : "Cancelled Reason:"}
                </div>
                <Select
                  style={{ float: "left", marginTop: 5, marginLeft: 100 }}
                  className="selectStyle"
                  onChange={this.changeProgramStatusReason}
                  value={
                    this.state.programStatus === "NOT_ENROLLED"
                      ? this.state.offServiceProgramReason
                      : this.state.programStatus === "PAUSED"
                      ? this.state.pausedProgramReason
                      : this.state.cancelledProgramReason
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  {this.state.programStatus === "NOT_ENROLLED"
                    ? OFF_SERVICE_REASON.map(p => {
                        return <Option value={p.value}>{p.name}</Option>;
                      })
                    : this.state.programStatus === "PAUSED"
                    ? PAUSED_REASON.map(p => {
                        return <Option value={p.value}>{p.name}</Option>;
                      })
                    : CANCELLED_REASON.map(p => {
                        return <Option value={p.value}>{p.name}</Option>;
                      })}
                </Select>
              </div>
              <div
                style={{
                  textAlign: "left",
                  marginTop: 80,
                  fontWeight: "bold",
                  marginRight: 10,
                  width: 250,
                  marginLeft: 120
                }}
              >
                <Button
                  loading={this.state.loading}
                  color="green"
                  onClick={this.submit}
                >
                  <Icon name="checkmark" />
                  OK
                </Button>
                <Button
                  color="red"
                  style={{ marginLeft: 20 }}
                  onClick={this.clear}
                >
                  <Icon name="remove" />
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </Modal.Content>
        {/*<Modal.Actions>*/}
        {/*  <Button*/}
        {/*    color="green"*/}
        {/*    onClick={this.submit}*/}
        {/*    style={{*/}
        {/*      display: this.state.confirmSuccess ? "none" : "inline-block"*/}
        {/*    }}*/}
        {/*    className="okButton"*/}
        {/*  >*/}
        {/*    <Icon name="checkmark" />*/}
        {/*    Add*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    basic*/}
        {/*    color="red"*/}
        {/*    onClick={e => this.close()}*/}
        {/*    className="removeButton"*/}
        {/*  >*/}
        {/*    <Icon name="remove" />*/}
        {/*    {this.state.confirmSuccess ? "Close" : "Cancel"}*/}
        {/*  </Button>*/}
        {/*</Modal.Actions>*/}
      </Modal>
    );
  }
}
const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(CompleteOnboarding);
