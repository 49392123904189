import { Auth } from "aws-amplify";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Input, Modal, TextArea } from "semantic-ui-react";
import { createNote } from "../../action/DoctorNoteAction";
import ButtonSuccess from "../../home/buttonSuccess";
import { PushpinOutlined, PushpinFilled } from "@ant-design/icons";
import "./AddText.css";

class Add extends Component {
  constructor(props) {
    super(props);
    this.submit.bind(this);
    this.state = {
      open: false,
      confirmSuccess: false,
      inputTerm: {},
      pinned: false,
      id: "",
      userId: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const id = atob(params.id).replace(" ", "+");
    const userId = params.userId;
    this.setState({ userId, id });
  }

  handleOpen = async () => this.setState({ open: true, confirmSuccess: false });
  close = () => this.setState({ open: false, confirmSuccess: false });
  handleInputChange(Input, value) {
    let inputTerm = Object.assign({}, this.state.inputTerm);
    inputTerm[Input] = value;
    this.setState({ inputTerm: inputTerm });
  }
  handlePinClick = () => {
    this.setState(state => ({
      pinned: !state.pinned
    }));
  };
  async submit() {
    const { createNote } = this.props;

    const user = await Auth.currentAuthenticatedUser();

    this.setState({ open: false });
    const input = {
      id: this.state.id,
      author: `${user.username}`
    };
    if (this.state.inputTerm.note && this.state.inputTerm.note !== "") {
      Object.assign(input, { note: this.state.inputTerm.note });
    }
    if (this.state.inputTerm.title && this.state.inputTerm.title !== "") {
      Object.assign(input, { title: this.state.inputTerm.title });
    }
    input.pinned = this.state.pinned ? "Yes" : "No";
    createNote(input, this);
  }
  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <button className="note_button" onClick={this.handleOpen}>
            ADD
          </button>
        }
        open={open}
      >
        <Modal.Header>
          NEW NOTE
          <div
            style={{ float: "right" }}
            onClick={this.handlePinClick}
            title={this.state.pinned ? "Pinned" : "Unpinned"}
          >
            {this.state.pinned ? <PushpinFilled /> : <PushpinOutlined />}
          </div>
        </Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <Input
                    onChange={(e, data) => {
                      this.handleInputChange("title", e.target.value);
                    }}
                    placeholder="Title"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width="16">
                  <TextArea
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleInputChange("note", e.target.value);
                    }}
                    placeholder="Write your comments here .."
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={async e => await this.submit()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
          >
            <Icon name="checkmark" />
            Submit
          </Button>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails
});
const mapDispatchToProp = dispatch => ({
  createNote: (input, self) => dispatch(createNote(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(Add);
