import { Modal as AntdModal, Popconfirm, Select } from "antd";
import _, { isEmpty } from "lodash";
import moment from "moment-timezone";
import React, { Component } from "react";
import { Control } from "react-keeper";
import Modal from "react-modal";
import { connect } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { Button, Grid, Icon, Label, Segment, Table } from "semantic-ui-react";
import {
  clearSockStatus,
  getDoctorDetails,
  getSockHomeStatus
} from "../action/patientAction";
import { updateSensorStatus } from "../action/sockAction";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import "../share/text.css";
import { converFlow } from "../tools/flow-conversion";
import { uppercase } from "../tools/stringProcess";
import Disable from "./Disable";
import HubUnlink from "./hubunlink";
import RemoteConnect from "./remoteConnect";
import Ok from "./Ok";
import "./sockstatus.css";
import Warn from "./Warn";
import UnRegisteredShoeTag from "../shoe-tag/unregisterShoeTag";
import UnRegisteredPillBottle from "../pill-bottle/unregisterPillBottle";
import Unlink from "./unlink";
import { activeSimInfo, deactiveSimInfo, updateFleet } from "../graphql/API";
import UnlinkBox from "./unlinkBox";
import SockDFU from "./SockDFU";

const { Option } = Select;
class Equipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      urlPassId: "",
      userId: "",
      timeZone: "",
      sirenId: "",
      hubType: "",
      open: false,
      expandedRows: [],
      sockHomeStatus: {},
      patientList: [],
      path: ""
    };
    this.onConfirm = this.onConfirm.bind(this);
  }
  componentWillMount() {
    const patientList = Control.state && Control.state.patientList;
    let [path, paramStr] = Control.path.split("?");
    const { clearSockStatus } = this.props;
    clearSockStatus();
    this.setState({
      url: window.location.href,
      timeZone: null,
      patientList,
      path
    });
  }
  componentDidMount() {
    if (this.props.doctorDetails && this.props.doctorDetails.sirenId) {
      const { getSockHomeStatus } = this.props;
      const sirenId = this.props.doctorDetails.sirenId;
      this.setState({
        sirenId: sirenId,
        userId: this.props.doctorDetails.userId
      });
      getSockHomeStatus(sirenId, this);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.doctorDetails.sirenId !== this.props.doctorDetails.sirenId) {
      const { getSockHomeStatus } = this.props;
      const sirenId = nextProps.doctorDetails.sirenId;
      this.setState({
        sirenId: sirenId,
        userId: nextProps.doctorDetails.userId
      });
      getSockHomeStatus(sirenId, this);
    }
  }
  gotoBatteryPage(sock) {
    Control.go(
      "/SockBattery?SirenSock/serialNo=" +
        sock.serialNo +
        "&sirenId=" +
        this.state.sirenId
    );
  }

  gotoTagBatteryPage = serialNo => {
    Control.go(
      "/TagBatteryEquipment?serialNo=" +
        serialNo +
        "&sirenId=" +
        this.state.sirenId
    );
  };

  onConfirm = (userId, serialNo) => {
    const { updateSockSensorStatus } = this.props;
    let UpdateSockStatusInput = {
      userId,
      serialNo,
      sensorStatus: "OK",
      statusDetails: "{}"
    };
    this.setState({ open: true });
    updateSockSensorStatus(
      this.state.id,
      UpdateSockStatusInput,
      this,
      this.state.sirenId
    );
  };

  statusDetailsKeys = statusDetails => {
    let parts = "";
    Object.keys(statusDetails).forEach(key => {
      if (parts === "") {
        parts = parts + key + ": " + statusDetails[key];
      } else {
        parts = parts + "," + key + ": " + statusDetails[key];
      }
    });
    return parts;
  };

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  renderItemCaret(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return <Icon name="caret down" />;
    } else {
      return <Icon name="caret right" />;
    }
  }

  updateFleetInfo = async (sid, fleetSid) => {
    AntdModal.confirm({
      title: "Change Fleet",
      content: `Will change fleet to ${
        fleetSid === "HFa1ab5b7b7b18434d8d15410506948dd3"
          ? "us-prod-all"
          : "us-prod-wo-Verizon"
      }?`,
      centered: true,
      onOk: async () => {
        const { getSockHomeStatus } = this.props;
        const simInfo = await updateFleet(sid, fleetSid);
        if (simInfo.data.updateFleet) {
          getSockHomeStatus(this.state.sirenId, this);
        }
      },
      onCancel: () => {}
    });
  };

  handleFleetChanged = async (fleetSid, sid) => {
    console.log("fleetSid: ", fleetSid, ", sid: ", sid);
    if (!_.isEmpty(fleetSid)) {
      this.updateFleetInfo(sid, fleetSid);
    }
  };

  disableKORE = async (hubId, sid) => {
    const { getSockHomeStatus } = this.props;
    const simInfo = await deactiveSimInfo(sid);
    if (simInfo.data.deactiveSim) {
      getSockHomeStatus(this.state.sirenId, this);
    }
  };

  enableKORE = async (hubId, sid) => {
    AntdModal.confirm({
      title: "Enable KORE",
      content: "This will incur some cost!",
      centered: true,
      onOk: async () => {
        const { getSockHomeStatus } = this.props;
        const simInfo = await activeSimInfo(sid);
        if (simInfo.data.activeSim) {
          getSockHomeStatus(this.state.sirenId, this);
        }
      },
      onCancel: () => {}
    });
  };

  renderHubItem = (hub, i) => {
    const clickCallback = () => this.handleRowClick(i);
    let tableRow = [
      <Table.Row
        key={"hub-list-" + i}
        onClick={
          hub.hubId.includes("H4G") || hub.hubId.includes("H2G")
            ? clickCallback
            : undefined
        }
      >
        <Table.Cell
          style={{
            paddingLeft:
              !hub.hubId.includes("H4G") && !hub.hubId.includes("H2G") ? 30 : 10
          }}
        >
          {hub.hubId.includes("H4G") || hub.hubId.includes("H2G")
            ? this.renderItemCaret(i)
            : null}
          {hub.hubId}
        </Table.Cell>
        <Table.Cell>{hub.authcode}</Table.Cell>
        <Table.Cell>
          {hub.sku ? hub.sku : hub.hubId.includes("H4G") ? "HCUBR" : ""}
        </Table.Cell>
        <Table.Cell
          style={{
            display: this.state.hubType === "Wifi" ? "none" : "table-cell"
          }}
        >
          {hub.csq ? hub.csq : ""}
        </Table.Cell>
        <Table.Cell>{hub.fwVersion ? hub.fwVersion : ""}</Table.Cell>
        <Table.Cell>
          {_.isEmpty(hub.lastSeen)
            ? ""
            : moment.tz(hub.lastSeen, 0).format("MM/DD/YYYY HH:mm:ss")}
        </Table.Cell>
        <Table.Cell>
          {hub.hubLastSeen
            ? moment
                .tz(hub.hubLastSeen, this.props.sockHomeStatus.userTimezone)
                .format("MM/DD/YYYY HH:mm:ss")
            : ""}
        </Table.Cell>
        <Table.Cell>
          <div onClick={e => e.stopPropagation()}>
            <HubUnlink
              item={this.props.sockHomeStatus}
              serial={hub.hubId}
              id={this.state.id}
            />
            {hub.hubId.indexOf("H4G") === -1 ? null : (
              <RemoteConnect
                item={this.props.sockHomeStatus}
                serial={hub.hubId}
                id={this.state.id}
              />
            )}
            {hub.hubId.indexOf("H4G") === -1 ? null : hub.status !==
              "active" ? (
              <Button
                style={{ marginLeft: 20 }}
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  this.enableKORE(hub.hubId, hub.sid);
                }}
              >{`Enable KORE`}</Button>
            ) : (
              <Button
                style={{ marginLeft: 20 }}
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  this.disableKORE(hub.hubId, hub.sid);
                }}
              >{`Disable KORE`}</Button>
            )}
            {hub.hubId.indexOf("H4G") === -1 ? null : (
              // <Button
              //   style={{ marginLeft: 20 }}
              //   size="small"
              //   onClick={e => {
              //     e.stopPropagation();
              //     this.updateFleetInfo(hub.sid, hub.fleetSid);
              //   }}
              // >{`Reset Connectivity`}</Button>
              <div style={{ marginTop: 20 }}>
                <label style={{ marginRight: 10, fontWeight: "bold" }}>
                  Fleet:
                </label>
                <Select
                  // className="selectStyle"
                  style={{ width: 200, fontWeight: "bold" }}
                  onChange={e => this.handleFleetChanged(e, hub.sid)}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={hub.fleetSid || ""}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="" />
                  <Option value="HFa1ab5b7b7b18434d8d15410506948dd3">
                    us-prod-all
                  </Option>
                  <Option value="HFf356f123d5c98ac4a440149429921c40">
                    us-prod-wo-Verizon
                  </Option>
                </Select>
              </div>
            )}
          </div>
        </Table.Cell>
      </Table.Row>
    ];

    if (this.state.expandedRows.includes(i)) {
      tableRow.push(
        <Table.Row key={"row-expanded-" + i}>
          <Table.Cell colSpan="14">{this.renderItemDetails(hub)}</Table.Cell>
        </Table.Row>
      );
    }

    return tableRow;
  };

  renderItemDetails = item => {
    return (
      <Table style={{ backgroundColor: "#f9f9f9" }}>
        <Table.Header>
          <Table.Row>
            <Table.Cell>CCID</Table.Cell>
            {/*<Table.Cell>Usage Flow</Table.Cell>*/}
            <Table.Cell>Data Usage in last 30 days</Table.Cell>
            <Table.Cell>SIM Status</Table.Cell>
            <Table.Cell>Data Total Billed</Table.Cell>
            <Table.Cell>Sync Time</Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{item.koreccid ? item.koreccid : ""}</Table.Cell>
            {/*<Table.Cell>*/}
            {/*  {item.useageFlow ? converFlow(item.useageFlow * 1024) : ""}*/}
            {/*</Table.Cell>*/}
            <Table.Cell>
              {item.totalFlow ? converFlow(parseFloat(item.totalFlow)) : ""}
            </Table.Cell>
            <Table.Cell>{item.status}</Table.Cell>
            <Table.Cell>
              {item.dataTotalBilled
                ? parseFloat(item.dataTotalBilled).toFixed(2)
                : ""}
            </Table.Cell>
            <Table.Cell>
              {item.syncTime
                ? moment(item.syncTime).format("MM/DD/YYYY HH:mm:ss")
                : ""}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  gotoDoctorDetailPage(id, userId, date) {
    if (!date) {
      date = moment(date).format("YYYY-MM-DD");
    }
    // Control.go(
    //   "/MedicalDetailsHome?id=" +
    //     btoa(id) +
    //     "&userId=" +
    //     userId +
    //     "&startDate=" +
    //     date +
    //     "&endDate=" +
    //     date +
    //     "&tab=1",
    //   {
    //     patientList: this.state.patientList.map(p => ({
    //       id: p.id,
    //       userId: p.userId
    //     }))
    //   }
    // );

    Control.replace(
      `${this.state.path}?id=${btoa(
        id
      )}&userId=${userId}&startDate=${date}&endDate=${date}&tab=1`,
      { patientList: this.state.patient }
    );
    this.props.getDoctorDetails(id, userId, this, date, date);
  }

  render() {
    let allItemRows = [];
    this.state.sockHomeStatus &&
      this.state.sockHomeStatus.hasOwnProperty("hubs") &&
      this.state.sockHomeStatus.hubs.forEach((item, index) => {
        console.log("hub item: ", item);
        const perItemRows = this.renderHubItem(item, index);
        allItemRows = allItemRows.concat(perItemRows);
      });
    return (
      <div className="sockHome-container">
        <Modal
          isOpen={this.state.open}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="label_sirenId_box">
          <Label as="a" ribbon className="label_sirenId">
            SirenId: {this.state.sirenId}
          </Label>
        </div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <div className="seg-title">
                  <Label as="a" color="red" ribbon>
                    Sock status:
                  </Label>
                </div>
                <Table celled className="result-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>BoxId</Table.HeaderCell>
                      <Table.HeaderCell>SerialNo</Table.HeaderCell>
                      <Table.HeaderCell>SKU</Table.HeaderCell>
                      <Table.HeaderCell>Foot</Table.HeaderCell>
                      <Table.HeaderCell>Battery</Table.HeaderCell>
                      <Table.HeaderCell>Firmware Version</Table.HeaderCell>
                      <Table.HeaderCell>LastSeen</Table.HeaderCell>
                      <Table.HeaderCell>DataLastSeen</Table.HeaderCell>
                      <Table.HeaderCell>SensorStatus</Table.HeaderCell>
                      <Table.HeaderCell>StatusDetails</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Days Used</Table.HeaderCell>
                      <Table.HeaderCell>Connected Date</Table.HeaderCell>
                      <Table.HeaderCell />
                      <Table.HeaderCell />
                      <Table.HeaderCell />
                      <Table.HeaderCell />
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.sockHomeStatus &&
                      this.state.sockHomeStatus.hasOwnProperty("socks") &&
                      this.state.sockHomeStatus.socks.map((sock, i) => {
                        const daysUsed = moment().diff(
                          moment(sock.createdAt),
                          "days"
                        );
                        let tableRow = (
                          <Table.Row
                            key={"sock-list-" + i}
                            className={sock.registered ? "reSock" : "noResock"}
                          >
                            <Table.Cell>{sock.boxId}</Table.Cell>
                            <Table.Cell>{sock.serialNo}</Table.Cell>
                            <Table.Cell>{sock.sku}</Table.Cell>
                            <Table.Cell>{sock.foot}</Table.Cell>
                            <Table.Cell
                              className="linkText"
                              onClick={() => this.gotoBatteryPage(sock)}
                            >
                              {sock.battery}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.fwVersion === "0.1" ? "-" : sock.fwVersion}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.lastSeen
                                ? moment
                                    .tz(
                                      sock.lastSeen,
                                      this.state.sockHomeStatus.userTimezone
                                    )
                                    .format("MM/DD/YYYY HH:mm:ss")
                                : ""}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.dataLastSeen
                                ? moment(
                                    sock.dataLastSeen.replace("Z", "")
                                  ).format("MM/DD/YYYY HH:mm:ss")
                                : ""}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.sensorStatus &&
                                uppercase(sock.sensorStatus)}
                              {sock.sensorStatus &&
                              uppercase(sock.sensorStatus) &&
                              uppercase(sock.sensorStatus) === "WARN" ? (
                                <Popconfirm
                                  title={`Change the SensorStatus of "${sock.serialNo}" to "OK"?`}
                                  onConfirm={() => {
                                    this.onConfirm(
                                      this.state.userId,
                                      sock.serialNo
                                    );
                                  }}
                                  // onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                  style={{ marginLeft: 10 }}
                                >
                                  <Icon as="i" name="edit" className="edit" />
                                </Popconfirm>
                              ) : null}
                            </Table.Cell>
                            <Table.Cell>
                              {!isEmpty(sock.statusDetails) ? (
                                sock.statusDetails.includes("brokenTime") ? (
                                  <span
                                    className="spanonclick"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.props.editNewSockStatus(
                                        moment
                                          .tz(
                                            JSON.parse(sock.statusDetails)[
                                              "brokenTime"
                                            ],
                                            this.props.sockHomeStatus
                                              .userTimezone
                                          )
                                          .format("YYYY-MM-DD")
                                      );
                                    }}
                                  >
                                    {this.statusDetailsKeys(
                                      JSON.parse(sock.statusDetails)
                                    )}
                                  </span>
                                ) : (
                                  this.statusDetailsKeys(
                                    JSON.parse(sock.statusDetails)
                                  )
                                )
                              ) : (
                                ""
                              )}
                            </Table.Cell>
                            <Table.Cell>{sock.status}</Table.Cell>
                            <Table.Cell>{daysUsed}</Table.Cell>
                            <Table.Cell>
                              {moment(sock.createdAt).format("MM/DD/YYYY")}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.registered ? (
                                <Ok
                                  id={this.state.id}
                                  item={this.state.sockHomeStatus}
                                  serialNo={sock.serialNo}
                                  sirenId={this.state.sirenId}
                                />
                              ) : null}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.registered ? (
                                <Warn
                                  id={this.state.id}
                                  item={this.state.sockHomeStatus}
                                  serialNo={sock.serialNo}
                                  sirenId={this.state.sirenId}
                                />
                              ) : null}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.registered ? (
                                <div style={{ display: "flex" }}>
                                  <Unlink
                                    item={this.state.sockHomeStatus}
                                    serialNo={sock.serialNo}
                                    boxId={sock.boxId}
                                    id={this.state.id}
                                    sirenId={this.state.sirenId}
                                  />
                                  <Disable
                                    id={this.state.id}
                                    item={this.state.sockHomeStatus}
                                    serialNo={sock.serialNo}
                                    sirenId={this.state.sirenId}
                                  />
                                </div>
                              ) : null}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.registered ? (
                                // <Unlink
                                //   item={this.state.sockHomeStatus}
                                //   serialNo={sock.serialNo}
                                //   id={this.state.id}
                                //   sirenId={this.state.sirenId}
                                // />
                                <div style={{ display: "flex" }}>
                                  <UnlinkBox
                                    item={this.state.sockHomeStatus}
                                    boxId={sock.boxId}
                                    id={this.state.id}
                                  />
                                </div>
                              ) : null}
                            </Table.Cell>
                            <Table.Cell>
                              <SockDFU
                                serialNo={sock.serialNo}
                                userId={this.state.userId}
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                        return tableRow;
                      })}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <div className="seg-title">
                  <Label as="a" color="red" ribbon>
                    HubList:
                  </Label>
                </div>
                <Table
                  celled
                  className="result-table"
                  style={{
                    display:
                      this.props.sockHomeStatus &&
                      this.props.sockHomeStatus.hubs &&
                      this.props.sockHomeStatus.hubs.length === 0
                        ? "none"
                        : "table"
                  }}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>HubId</Table.HeaderCell>
                      <Table.HeaderCell>Authcode</Table.HeaderCell>
                      <Table.HeaderCell>SKU</Table.HeaderCell>
                      <Table.HeaderCell>CSQ</Table.HeaderCell>
                      <Table.HeaderCell>Firmware Version</Table.HeaderCell>
                      <Table.HeaderCell>DataLastSeen</Table.HeaderCell>
                      <Table.HeaderCell>HubLastSeen</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{allItemRows}</Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          {this.props.dev_env && (
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment>
                  <div className="seg-title">
                    <Label as="a" color="red" ribbon>
                      Siren Move Information:
                    </Label>
                  </div>
                  <Table celled className="result-table">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Siren Move Id</Table.HeaderCell>
                        <Table.HeaderCell>AuthCode</Table.HeaderCell>
                        <Table.HeaderCell>MAC</Table.HeaderCell>
                        <Table.HeaderCell>Battery</Table.HeaderCell>
                        <Table.HeaderCell>Last Seen</Table.HeaderCell>
                        <Table.HeaderCell>Data Last Seen</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.sockHomeStatus &&
                        this.props.sockHomeStatus.hasOwnProperty("shoeTags") &&
                        this.props.sockHomeStatus.shoeTags.map((shoeTag, i) => {
                          let tableRow = (
                            <Table.Row key={"hub-list-" + i}>
                              <Table.Cell>{shoeTag.tagId}</Table.Cell>
                              <Table.Cell>{shoeTag.authCode}</Table.Cell>
                              <Table.Cell>{shoeTag.mac}</Table.Cell>
                              <Table.Cell
                                className="linkText"
                                onClick={() =>
                                  this.gotoTagBatteryPage(
                                    shoeTag.mac.replace(/:/g, "")
                                  )
                                }
                              >
                                {shoeTag.battery}
                              </Table.Cell>
                              <Table.Cell>
                                {shoeTag.lastSeen
                                  ? moment
                                      .tz(shoeTag.lastSeen, this.state.timeZone)
                                      .format("MM/DD/YYYY HH:mm:ss")
                                  : ""}
                              </Table.Cell>
                              <Table.Cell>
                                {shoeTag.dataLastSeen
                                  ? moment
                                      .tz(
                                        shoeTag.dataLastSeen,
                                        this.state.timeZone
                                      )
                                      .format("MM/DD/YYYY HH:mm:ss")
                                  : ""}
                              </Table.Cell>
                              <Table.Cell>
                                <UnRegisteredShoeTag
                                  item={shoeTag}
                                  onChange={result => {
                                    this.props.getSockHomeStatus(
                                      this.state.sirenId,
                                      this
                                    );
                                  }}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                          return tableRow;
                        })}
                    </Table.Body>
                  </Table>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          )}
          {this.props.dev_env && (
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment>
                  <div className="seg-title">
                    <Label as="a" color="red" ribbon>
                      Pill Bottle Information:
                    </Label>
                  </div>
                  <Table celled className="result-table">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Pill Bottle Id</Table.HeaderCell>
                        <Table.HeaderCell>AuthCode</Table.HeaderCell>
                        <Table.HeaderCell>MAC</Table.HeaderCell>
                        <Table.HeaderCell>Battery</Table.HeaderCell>
                        <Table.HeaderCell>Last Seen</Table.HeaderCell>
                        <Table.HeaderCell>Data Last Seen</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.sockHomeStatus &&
                        this.props.sockHomeStatus.hasOwnProperty(
                          "pillBottles"
                        ) &&
                        this.props.sockHomeStatus.pillBottles.map(
                          (pillBottle, i) => {
                            let tableRow = (
                              <Table.Row key={"hub-list-" + i}>
                                <Table.Cell>
                                  {pillBottle.pillBottleId}
                                </Table.Cell>
                                <Table.Cell>{pillBottle.authCode}</Table.Cell>
                                <Table.Cell>{pillBottle.mac}</Table.Cell>
                                <Table.Cell
                                  className="linkText"
                                  // onClick={() =>
                                  //   this.gotoTagBatteryPage(
                                  //     shoeTag.mac.replace(/:/g, "")
                                  //   )
                                  // }
                                >
                                  {pillBottle.battery}
                                </Table.Cell>
                                <Table.Cell>
                                  {pillBottle.lastSeen
                                    ? moment
                                        .tz(
                                          pillBottle.lastSeen,
                                          this.state.timeZone
                                        )
                                        .format("MM/DD/YYYY HH:mm:ss")
                                    : ""}
                                </Table.Cell>
                                <Table.Cell>
                                  {pillBottle.dataLastSeen
                                    ? moment
                                        .tz(
                                          pillBottle.dataLastSeen,
                                          this.state.timeZone
                                        )
                                        .format("MM/DD/YYYY HH:mm:ss")
                                    : ""}
                                </Table.Cell>
                                <Table.Cell>
                                  <UnRegisteredPillBottle
                                    item={pillBottle}
                                    onChange={result => {
                                      this.props.getSockHomeStatus(
                                        this.state.sirenId,
                                        this
                                      );
                                    }}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            );
                            return tableRow;
                          }
                        )}
                    </Table.Body>
                  </Table>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <div className="seg-title">
                  <Label as="a" color="red" ribbon>
                    App Information:
                  </Label>
                </div>
                <Table celled className="result-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>AppVersion</Table.HeaderCell>
                      <Table.HeaderCell>DeviceName</Table.HeaderCell>
                      <Table.HeaderCell>OS</Table.HeaderCell>
                      <Table.HeaderCell>OS Version</Table.HeaderCell>
                      <Table.HeaderCell>Last Login</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.sockHomeStatus &&
                      this.props.sockHomeStatus.hasOwnProperty("appInfo") &&
                      this.props.sockHomeStatus.appInfo.map((appInfo, i) => {
                        let tableRow = (
                          <Table.Row key={"hub-list-" + i}>
                            <Table.Cell>{appInfo.appVersion}</Table.Cell>
                            <Table.Cell>{appInfo.deviceName}</Table.Cell>
                            <Table.Cell>{appInfo.deviceOS}</Table.Cell>
                            <Table.Cell>{appInfo.devcieOSVersion}</Table.Cell>
                            {/*<Table.Cell>{appInfo.deviceId}</Table.Cell>*/}
                            <Table.Cell>
                              {appInfo.createdAt
                                ? moment
                                    .tz(
                                      appInfo.createdAt,
                                      this.props.sockHomeStatus.userTimezone
                                    )
                                    .format("MM/DD/YYYY HH:mm:ss")
                                : ""}
                            </Table.Cell>
                          </Table.Row>
                        );
                        return tableRow;
                      })}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  sockHomeStatus: state.patientsListStore.sockHomeStatus,
  doctorDetails: state.patientsListStore.doctorDetails,
  dev_env: state.patientsListStore.dev_env
});

const mapDispatchToProp = dispatch => ({
  getSockHomeStatus: (sirenId, self) =>
    dispatch(getSockHomeStatus(sirenId, self)),
  clearSockStatus: () => dispatch(clearSockStatus()),
  updateSockSensorStatus: (id, input, self, sirenId) =>
    dispatch(updateSensorStatus(id, input, self, sirenId)),
  getDoctorDetails: (id, userId, self, startDate, endDate) =>
    dispatch(getDoctorDetails(id, userId, self, startDate, endDate))
});
export default connect(mapStateToProp, mapDispatchToProp)(Equipment);
