import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Calendar from "react-calendar";
import { connect } from "react-redux";
import {
  celToFahrenheit,
  celToFahrenheitDelta
} from "../../action/chartAction";
import { getFootStatus, getMinuteWorn } from "../../action/patientAction";
import "./calendar.css";
import PatientDetailUrlContext from "./PatientDetailUrlContext";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Popover } from "antd";

class ComplianceCalendar extends Component {
  static contextType = PatientDetailUrlContext;

  state = {
    wornDates: []
  };

  componentDidUpdate() {
    if (this.oldContext !== this.context) {
      this.oldContext = this.context;
      const param = this.context.param;

      const { getFootStatus } = this.props;
      const startDate = moment(param.endDate)
        .startOf("month")
        .add(-6, "days")
        .format("YYYY-MM-DD");
      const endDate = moment(param.endDate)
        .endOf("month")
        .add(5, "days")
        .format("YYYY-MM-DD");
      getMinuteWorn(param.id, param.userId, startDate, endDate).then(data => {
        this.setState({
          wornDates: data
        });
      });

      getFootStatus(param.id, param.userId, param.startDate, param.endDate);
    }
  }

  turnChange = ({ activeStartDate, view }) => {
    const startDate = moment(activeStartDate)
      .add(-6, "days")
      .format("YYYY-MM-DD");
    const endDate = moment(activeStartDate)
      .add(35, "days")
      .format("YYYY-MM-DD");
    getMinuteWorn(
      this.context.param.id,
      this.context.param.userId,
      startDate,
      endDate
    ).then(data => {
      this.setState({
        wornDates: data
      });
    });
  };

  onChange = date => {
    const { getFootStatus } = this.props;
    const startDate = moment(date).format("YYYY-MM-DD");
    const endDate = moment(date).format("YYYY-MM-DD");
    // getFootStatus in `componentDidUpdate`
    // getFootStatus(this.context.param.id, this.context.param.userId, startDate, endDate);

    this.context.updateParam({
      startDate,
      endDate
    });
  };

  // everytime return a new function, so Calender will recalculate
  calendarClassName = patientWorn => ({ date, view }) => {
    if (!patientWorn) {
      return;
    }
    let renderDate = moment(date).format("YYYY-MM-DD");
    const dispensedDay = this.props.doctorDetails.dispensedDay
      ? moment(this.props.doctorDetails.dispensedDay).format("YYYY-MM-DD")
      : moment(this.props.doctorDetails.createdAt).format("YYYY-MM-DD");
    if (renderDate < dispensedDay) {
      return "";
    }
    const month = date.getMonth();
    let activemonth = new Date().getMonth();
    for (let index = 0; index < patientWorn.length; index++) {
      const data = patientWorn[index];
      // patientWorn is acending ordered
      if (data.date > renderDate) {
        return;
      }
      if (data.date === renderDate && data.minuteWorn >= 30) {
        if (activemonth === month) {
          return "timeMore";
        } else {
          return "timeMoreLast";
        }
      }
    }
  };

  formatTemp(temp) {
    if (temp == null) {
      return "N/A";
    }
    if (this.props.tempUnit === "Celsius") {
      return temp.toFixed(1) + "℃";
    } else {
      return celToFahrenheit(temp) + "℉";
    }
  }

  formatTempDelta(temp) {
    if (temp == null) {
      return "N/A";
    }
    if (this.props.tempUnit === "Celsius") {
      return temp.toFixed(1) + "℃";
    } else {
      return celToFahrenheitDelta(temp) + "℉";
    }
  }

  renderSummaryHeader() {
    const KeyList = [
      {
        term: "Avg Delta:",
        exp:
          "The difference between the left and right foot’s average temperature. "
      },
      {
        term: "Avg Delta = Left Avg Foot Temp - Right Avg Foot Temp",
        subExp: [
          <div>
            A positive Avg Delta means the Left Avg Foot Temp is higher than the
            right.
            <br />
            (ex. Avg Delta = 2.5℉)
          </div>,
          <div>
            A negative Avg Delta means the Right Avg Foot Temp is higher than
            the left.
            <br />
            (ex. Avg Delta = -2.5℉)
          </div>
        ]
      },
      {
        term: "Avg 7-Day Delta: ",
        exp:
          "The average Delta from the past 7 days, based on the selected date."
      },
      {
        term: "Up Arrow: ",
        exp: "Indicates an increase compared to the previous measurement.",
        subExp: [
          "Example: Avg 7-Day Delta: 81℉ ↑ ",
          "The average Delta from the past 7 days is 81℉. The Avg 7-Day Delta has increased in comparison to the Avg 7-Day Delta range prior. The 7-Day range will depend on the date selected."
        ]
      },
      {
        term: "Down Arrow: ",
        exp: "Indicates a decrease compared to the previous measurement.",
        subExp: [
          "Example: Avg Left Foot Temp: 81℉ ↓ ",
          "The Avg Left Foot Temp is 81℉. The Avg Left Foot Temp has decreased from the previous date."
        ]
      }
    ];
    return (
      <div className="title">
        PATIENT SUMMARY
        <Popover
          placement="left"
          content={
            <div style={{ maxWidth: 600 }}>
              <p style={{ margin: 10, fontSize: 16 }}>
                <b>Patient Summary Key</b>
              </p>
              <div style={{ padding: 10, backgroundColor: "#f3f3f5" }}>
                {KeyList.map((item, i) => (
                  <div style={{ marginTop: i == 0 ? 10 : 36 }} key={item.term}>
                    <div>
                      <b>{item.term}</b>
                    </div>
                    {item.exp && <p>{item.exp}</p>}
                    {item.subExp ? (
                      <ul style={{ marginLeft: 20 }}>
                        {item.subExp.map((exp, index) => (
                          <li key={index}>{exp}</li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          }
        >
          <QuestionCircleFilled
            style={{ fontSize: 20, float: "right", margin: 4 }}
          />
        </Popover>
      </div>
    );
  }

  renderSummary() {
    if (moment(this.context.param.endDate).isSameOrAfter(moment(), "date")) {
      return (
        <p style={{ padding: "20px 10px" }}>
          Patient Summary is calculated at the end of day when all patient data
          is uploaded.
        </p>
      );
    }
    if (_.isEmpty(this.props.footOverview)) {
      return (
        <div
          style={{
            textAlign: "left",
            padding: 10,
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-around"
          }}
        >
          <div>
            <p>Time Worn: -</p>
            <p>Total Steps: -</p>
            <p>Avg Delta: -</p>
            <p>Avg 7-Day Delta: -</p>
          </div>
          <div>
            <p>Avg Left Foot Temp: -</p>
            <p>Avg Right Foot Temp: -</p>
            <p>Last 7-Day Avg Left Foot Temp: -</p>
            <p>Last 7-Day Avg Right Foot Temp: -</p>
          </div>
        </div>
      );
    }

    const {
      leftAvg,
      rightAvg,
      leftPrevAvg,
      rightPrevAvg,
      leftAvg7,
      rightAvg7,
      leftPrevAvg7,
      rightPrevAvg7
    } = this.props.footOverview;

    let leftChange = "",
      rightChange = "",
      leftChange7 = "",
      rightChange7 = "";
    if (leftAvg && leftPrevAvg) {
      const delta = leftAvg - leftPrevAvg;
      if (delta != 0) {
        leftChange = delta > 0 ? "↑" : "↓";
      }
    }
    if (rightAvg && rightPrevAvg) {
      const delta = rightAvg - rightPrevAvg;
      if (delta != 0) {
        rightChange = delta > 0 ? "↑" : "↓";
      }
    }

    if (leftAvg7 && leftPrevAvg7) {
      const delta = leftAvg7 - leftPrevAvg7;
      if (delta != 0) {
        leftChange7 = delta > 0 ? "↑" : "↓";
      }
    }
    if (rightAvg7 && rightPrevAvg7) {
      const delta = rightAvg7 - rightPrevAvg7;
      if (delta != 0) {
        rightChange7 = delta > 0 ? "↑" : "↓";
      }
    }

    // daily delta
    let dailyDelta,
      day7Delta,
      dailyChange = "",
      day7Change = "";
    if (leftAvg && rightAvg) {
      dailyDelta = leftAvg - rightAvg;
    }
    if (leftPrevAvg && rightPrevAvg) {
      const preDelta = leftPrevAvg - rightPrevAvg;
      if (dailyDelta) {
        if (preDelta != dailyDelta) {
          dailyChange =
            Math.abs(dailyDelta) - Math.abs(preDelta) > 0 ? "↑" : "↓";
        }
      }
    }
    // weekly delta
    if (leftAvg7 && rightAvg7) {
      day7Delta = leftAvg7 - rightAvg7;
    }
    if (leftPrevAvg7 && rightPrevAvg7) {
      const preDelta = leftPrevAvg7 - rightPrevAvg;
      if (day7Delta) {
        if (preDelta != day7Delta) {
          day7Change = Math.abs(day7Delta) - Math.abs(preDelta) > 0 ? "↑" : "↓";
        }
      }
    }

    return (
      <div
        style={{
          textAlign: "left",
          padding: 10,
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "space-around"
        }}
      >
        <div>
          <p>Time Worn: {this.props.footOverview.minuteWorn || "N/A"} mins</p>
          <p>Total Steps: {this.props.footOverview.steps || "N/A"}</p>
          <p>
            Avg Delta: {this.formatTempDelta(dailyDelta)} {dailyChange}
          </p>
          <p>
            Avg 7-Day Delta: {this.formatTempDelta(day7Delta)} {day7Change}
          </p>
        </div>
        <div>
          <p>
            Avg Left Foot Temp: {this.formatTemp(leftAvg)} {leftChange}
          </p>
          <p>
            Avg Right Foot Temp: {this.formatTemp(rightAvg)} {rightChange}
          </p>
          <p>
            Last 7-Day Avg Left Foot Temp: {this.formatTemp(leftAvg7)}{" "}
            {leftChange7}
          </p>
          <p>
            Last 7-Day Avg Right Foot Temp: {this.formatTemp(rightAvg7)}{" "}
            {rightChange7}
          </p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className="calendar_userbox2"
        style={{
          width: this.props.Developer ? "55%" : this.props.staff ? "100%" : null
        }}
      >
        <Calendar
          onChange={this.onChange}
          value={moment(this.context.param.endDate).toDate()}
          tileClassName={this.calendarClassName(this.state.wornDates)}
          minDetail="month"
          locale="en"
          onActiveDateChange={this.turnChange}
          style={{ height: "340px" }}
        />
        <div>
          {this.renderSummaryHeader()}
          {this.renderSummary()}
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  tempUnit: state.authGroupStore.profile.tempUnit,
  footOverview: state.patientsListStore.footOverview
});

const mapDispatchToProp = dispatch => ({
  getFootStatus: (id, userId, start, end) =>
    dispatch(getFootStatus(id, userId, start, end))
});
export default connect(mapStateToProp, mapDispatchToProp)(ComplianceCalendar);
