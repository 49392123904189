import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  changeSiteStatus,
  checkPatients,
  getlistClinicSites,
  getlistStaff
} from "../../action/registerAction";
import { formatNumber, matchPhone } from "../../tools/phoneFormat";
import AddSite from "./AddSite";
import EditSite from "./EditSite";
import "./SiteList.css";
import { Select, Modal as AntdModal } from "antd";
import getPatchTrProps from "../../tools/reactTablePatch";
import { getEMRList } from "../../action/emrAction";
import { Button } from "semantic-ui-react";
import { getLpnStaff } from "../../action/lpnManagementAction";
import EditSiteTag from "./editSiteTag";
const { confirm } = AntdModal;
class SiteList extends Component {
  state = {
    loading: false
  };
  async componentDidMount() {
    if (this.props.listSirenStaff.length === 0) {
      this.props.getLpnStaff();
    }
    if (this.props.EMRSystemList.length === 0) {
      this.props.getEMRList();
    }
    const { getlistClinicSites, getlistStaff } = this.props;
    getlistClinicSites();
    if (this.props.listStaff.length === 0) {
      getlistStaff();
    }
  }

  confirmDelete = (siteId, serviceDate) => {
    confirm({
      title: "Confirming",
      content: (
        <div>
          Are you sure you want to delete the site <strong>{siteId}</strong>?
        </div>
      ),
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        // this.handleTempDeleteSite(siteId, serviceDate);
        this.checkLpnInSite(siteId, serviceDate);
        // this.checkStaffInSite(siteId, serviceDate);
      },
      centered: true
    });
  };

  handleTempDeleteSite = (siteId, serviceDate) => {
    const input = {
      siteId,
      status: "DELETED",
      serviceDate
    };
    this.props.changeSiteStatus(input, this);
  };

  checkLpnInSite = (siteId, serviceDate) => {
    let lpnInSite = [];
    this.props.listSirenStaff.map(item => {
      if (JSON.parse(item.primarySites).includes(siteId)) {
        lpnInSite.push(item.id);
      }
    });
    if (lpnInSite.length > 0) {
      AntdModal.warn({
        title: "Delete Site Failed",
        content: `There are nurses assigned to site ${siteId}. Remove nurse assignments to delete the site.`,
        centered: true
      });
    } else {
      this.checkStaffInSite(siteId, serviceDate);
    }
  };

  checkStaffInSite = (siteId, serviceDate) => {
    let staffInSite = [];
    this.props.listStaff.map(item => {
      if (item.siteId.split("-").includes(siteId)) {
        staffInSite.push(item);
      }
    });
    if (staffInSite.length > 0) {
      AntdModal.warn({
        title: "Delete Site Failed",
        content: `There are doctors assigned to site ${siteId}. Remove doctor assignments to delete the site.`,
        centered: true
      });
    } else {
      this.checkPatientInSite(siteId, serviceDate);
    }
  };
  checkPatientInSite = (siteId, serviceDate) => {
    const { checkPatients } = this.props;
    checkPatients(siteId, serviceDate, this);
  };

  render() {
    const isAdminOrDeveloper =
      this.props.userGroups.includes("admin") ||
      this.props.userGroups.includes("developer") ||
      this.props.userGroups.includes("support");
    const { Option } = Select;
    const trProps = getPatchTrProps(({ event: e }) => {
      //WARNING: black magic
      const buttons = e.currentTarget.getElementsByTagName("button");
      for (const button of buttons) {
        if (button.innerHTML === "Edit") {
          button.click();
          break;
        }
      }
    });
    return (
      <div className="boxlistcontainer">
        <div className="add_import_box" style={{ textAlign: "left" }}>
          <AddSite />
        </div>
        <ReactTable
          loading={this.state.loading}
          getTrProps={isAdminOrDeveloper ? trProps : undefined}
          data={
            this.props.clinicSites &&
            this.props.clinicSites.map(p => {
              const phone = !isEmpty(p.phone)
                ? formatNumber(p.phone).trim()
                : "";
              const siteAddress =
                (p.address ? p.address : "") +
                (p.city ? ", " + p.city : "") +
                (p.state ? ", " + p.state : "") +
                (p.zip ? " " + p.zip : "");
              const hubContract = p.hubContract
                ? p.hubContract.replace("Wifi", "Wi-Fi")
                : "Wi-Fi";
              return {
                ...p,
                phone,
                siteAddress,
                hubContract,
                tag: p.tag || "Active"
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Site Id",
              accessor: "siteId",
              filterMethod: (filter, row) =>
                String(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Name",
              accessor: "name",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.name
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Address",
              accessor: "siteAddress",
              sortable: false,
              filterMethod: (filter, row) =>
                row[filter.id].includes(filter.value)
            },
            // {
            //   Header: "NPI",
            //   accessor: "siteNPI",
            //   sortable: false,
            //   filterMethod: (filter, row) =>
            //     row._original.siteNPI &&
            //     matchPhone(row[filter.id]).includes(filter.value)
            // },
            // {
            //   style: { cursor: "pointer" },
            //   accessor: "EMRSystem",
            //   Header: "EMR System",
            //   filterMethod: (filter, row) => {
            //     if (filter.value === "all") {
            //       return true;
            //     } else {
            //       return row[filter.id] === filter.value;
            //     }
            //   },
            //   Filter: ({ filter, onChange }) => (
            //     <Select
            //       className="select_common_style"
            //       onChange={onChange}
            //       style={{ width: "100%" }}
            //       value={filter ? filter.value : "all"}
            //       filterOption={(input, option) =>
            //         option.props.children
            //           .toLowerCase()
            //           .indexOf(input.toLowerCase()) >= 0
            //       }
            //       showSearch
            //       optionFilterProp="children"
            //     >
            //       <Option value="all">All</Option>
            //       {this.props.EMRSystemList.map(p => {
            //         return (
            //           <Option value={p.name} key={p.name}>
            //             {p.name}
            //           </Option>
            //         );
            //       })}
            //     </Select>
            //   )
            // },
            {
              Header: "Phone",
              accessor: "phone",
              filterMethod: (filter, row) =>
                row._original.phone &&
                matchPhone(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Contact",
              accessor: "contact",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.contact
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            // {
            //   Header: "Service Day Type",
            //   accessor: "serviceDate",
            //   filterMethod: (filter, row) => {
            //     let rowfilterfullname = row._original.serviceDate
            //       ? row[filter.id].toLowerCase()
            //       : "";
            //     let filterfullname = filter.value.toLowerCase();
            //     return rowfilterfullname.includes(filterfullname);
            //   }
            // },
            {
              style: { cursor: "pointer" },
              accessor: "IRBConsent",
              Header: "Registry Consent",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Not Approved") {
                  return row[filter.id] === "Not Approved";
                }
                if (filter.value === "Approved") {
                  return row[filter.id] === "Approved";
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Approved">Approved</Option>
                  <Option value="Not Approved">Not Approved</Option>
                </Select>
              )
            },
            // {
            //   style: { cursor: "pointer" },
            //   accessor: "hubContract",
            //   Header: "Hub Contract",
            //   filterMethod: (filter, row) => {
            //     if (filter.value === "all") {
            //       return true;
            //     }
            //     if (filter.value === "Wifi") {
            //       return row[filter.id] === "Wi-Fi";
            //     }
            //     if (filter.value === "4G/Wifi") {
            //       return row[filter.id] === "4G/Wi-Fi";
            //     }
            //   },
            //   Filter: ({ filter, onChange }) => (
            //     <Select
            //       className="select_common_style"
            //       onChange={onChange}
            //       style={{ width: "100%" }}
            //       value={filter ? filter.value : "all"}
            //       filterOption={(input, option) =>
            //         option.props.children
            //           .toLowerCase()
            //           .indexOf(input.toLowerCase()) >= 0
            //       }
            //       showSearch
            //       optionFilterProp="children"
            //     >
            //       <Option value="all">All</Option>
            //       <Option value="Wifi">Wi-Fi</Option>
            //       <Option value="4G/Wifi">4G/Wi-Fi</Option>
            //     </Select>
            //   )
            // },
            {
              style: { cursor: "pointer" },
              accessor: "onStaffing",
              Header: "Staffing",
              Cell: props => (
                <div>
                  {props.value === "Yes" ? "On Staffing" : "Not On Staffing"}
                </div>
              ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                return row[filter.id] === filter.value;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="No">Not On Staffing</Option>
                  <Option value="Yes">On Staffing</Option>
                </Select>
              )
            },
            {
              Header: "On Service Patients",
              accessor: "onServicePatients",
              sortable: true,
              filterMethod: (filter, row) =>
                row._original.onServicePatients &&
                matchPhone(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Active Patients",
              accessor: "activePatients",
              sortable: true,
              filterMethod: (filter, row) =>
                row._original.activePatients &&
                matchPhone(row[filter.id]).includes(filter.value)
            },
            {
              style: { cursor: "pointer" },
              accessor: "tag",
              Header: "Tag",
              Cell: props => (
                <div onClick={e => e.stopPropagation()}>
                  {props.value}
                  <EditSiteTag
                    tag={props.value}
                    serviceDate={props.original.serviceDate}
                    siteId={props.original.siteId}
                  />
                </div>
              ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                return row[filter.id] === filter.value;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Demo">Demo</Option>
                  <Option value="Trial">Trial</Option>
                </Select>
              )
            },
            {
              Header: "Actions",
              accessor: "",
              minWidth: 110,
              sortable: false,
              filterable: false,
              show: isAdminOrDeveloper,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <Button
                      size="mini"
                      className="deleteButton"
                      onClick={() => {
                        this.confirmDelete(
                          props.original.siteId,
                          props.original.serviceDate
                        );
                      }}
                    >
                      Delete
                    </Button>

                    <div
                      className="add_import_box"
                      style={{ textAlign: "left" }}
                    >
                      <EditSite newSite={props.original} />
                    </div>
                  </div>
                );
              }
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "siteId",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  clinicSitesDeleted: state.patientsListStore.clinicSitesDeleted,
  listStaff: state.patientsListStore.listStaff,
  EMRSystemList: state.patientsListStore.EMRSystemList,
  userGroups: state.authGroupStore.session.userGroups,
  listSirenStaff: state.patientsListStore.listSirenStaff
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getlistStaff: () => dispatch(getlistStaff()),
  getEMRList: () => dispatch(getEMRList()),
  changeSiteStatus: (input, self) => dispatch(changeSiteStatus(input, self)),
  checkPatients: (siteId, serviceDate, self) =>
    dispatch(checkPatients(siteId, serviceDate, self)),
  getLpnStaff: () => dispatch(getLpnStaff())
});
export default connect(mapStateToProp, mapDispatchToProp)(SiteList);
