import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  changeSiteStatus,
  getlistDeletedClinicSites
} from "../../action/registerAction";
import { formatNumber, matchPhone } from "../../tools/phoneFormat";
import DeleteSite from "./DeleteSite";
import "./SiteList.css";
import { Select, Modal as AntdModal } from "antd";
import moment from "moment";
import { Button } from "semantic-ui-react";
const { confirm } = AntdModal;
class SiteDeletedList extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    const { getlistDeletedClinicSites } = this.props;
    getlistDeletedClinicSites();
  }

  confirmReinstate = (siteId, serviceDate) => {
    confirm({
      title: "Confirming",
      content: (
        <div>
          Are you sure you want to reinstate the site <strong>{siteId}</strong>?
        </div>
      ),
      cancelText: "No",
      onCancel: () => {},
      okText: "Yes",
      onOk: () => {
        this.handleTempReinstateSite(siteId, serviceDate);
      },
      centered: true
    });
  };

  handleTempReinstateSite = (siteId, serviceDate) => {
    const input = {
      siteId,
      status: "REINSTATE",
      serviceDate
    };
    this.props.changeSiteStatus(input, this);
  };

  render() {
    const { Option } = Select;
    const isAdmin = this.props.userGroups.includes("admin");
    return (
      <div className="boxlistcontainer">
        <ReactTable
          loading={this.state.loading}
          data={
            this.props.clinicSitesDeleted &&
            this.props.clinicSitesDeleted.map(p => {
              const phone = !isEmpty(p.phone)
                ? formatNumber(p.phone).trim()
                : "";
              const siteAddress =
                (p.address ? p.address : "") +
                (p.city ? ", " + p.city : "") +
                (p.state ? ", " + p.state : "") +
                (p.zip ? " " + p.zip : "");
              const hubContract = p.hubContract
                ? p.hubContract.replace("Wifi", "Wi-Fi")
                : "Wi-Fi";
              const deletedAt = p.deletedAt
                ? moment(p.deletedAt).format("MM/DD/YYYY")
                : "";
              return {
                ...p,
                phone,
                siteAddress,
                hubContract,
                deletedAt
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) {
              return false;
            }
            return row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          }}
          columns={[
            {
              Header: "Site Id",
              accessor: "siteId"
            },
            {
              Header: "Name",
              accessor: "name",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.name
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Address",
              accessor: "siteAddress",
              sortable: false
            },
            // {
            //   style: { cursor: "pointer" },
            //   accessor: "EMRSystem",
            //   Header: "EMR System",
            //   filterMethod: (filter, row) => {
            //     if (filter.value === "all") {
            //       return true;
            //     } else {
            //       return row[filter.id] === filter.value;
            //     }
            //   },
            //   Filter: ({ filter, onChange }) => (
            //     <Select
            //       className="select_common_style"
            //       onChange={onChange}
            //       style={{ width: "100%" }}
            //       value={filter ? filter.value : "all"}
            //       filterOption={(input, option) =>
            //         option.props.children
            //           .toLowerCase()
            //           .indexOf(input.toLowerCase()) >= 0
            //       }
            //       showSearch
            //       optionFilterProp="children"
            //     >
            //       <Option value="all">All</Option>
            //       {this.props.EMRSystemList.map(p => {
            //         return (
            //           <Option value={p.name} key={p.name}>
            //             {p.name}
            //           </Option>
            //         );
            //       })}
            //     </Select>
            //   )
            // },
            {
              Header: "Phone",
              accessor: "phone",
              filterMethod: (filter, row) =>
                row._original.phone &&
                matchPhone(row[filter.id]).includes(filter.value)
            },
            {
              Header: "Contact",
              accessor: "contact",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.contact
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            // {
            //   Header: "Service Day Type",
            //   accessor: "serviceDate",
            //   filterMethod: (filter, row) => {
            //     let rowfilterfullname = row._original.serviceDate
            //       ? row[filter.id].toLowerCase()
            //       : "";
            //     let filterfullname = filter.value.toLowerCase();
            //     return rowfilterfullname.includes(filterfullname);
            //   }
            // },
            {
              style: { cursor: "pointer" },
              accessor: "IRBConsent",
              Header: "Registry Consent",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Not Approved") {
                  return row[filter.id] === "Not Approved";
                }
                if (filter.value === "Approved") {
                  return row[filter.id] === "Approved";
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Approved">Approved</Option>
                  <Option value="Not Approved">Not Approved</Option>
                </Select>
              )
            },
            // {
            //   style: { cursor: "pointer" },
            //   accessor: "hubContract",
            //   Header: "Hub Contract",
            //   filterMethod: (filter, row) => {
            //     if (filter.value === "all") {
            //       return true;
            //     }
            //     if (filter.value === "Wifi") {
            //       return row[filter.id] === "Wi-Fi";
            //     }
            //     if (filter.value === "4G/Wifi") {
            //       return row[filter.id] === "4G/Wi-Fi";
            //     }
            //   },
            //   Filter: ({ filter, onChange }) => (
            //     <Select
            //       className="select_common_style"
            //       onChange={onChange}
            //       style={{ width: "100%" }}
            //       value={filter ? filter.value : "all"}
            //       filterOption={(input, option) =>
            //         option.props.children
            //           .toLowerCase()
            //           .indexOf(input.toLowerCase()) >= 0
            //       }
            //       showSearch
            //       optionFilterProp="children"
            //     >
            //       <Option value="all">All</Option>
            //       <Option value="Wifi">Wi-Fi</Option>
            //       <Option value="4G/Wifi">4G/Wi-Fi</Option>
            //     </Select>
            //   )
            // },
            {
              style: { cursor: "pointer" },
              accessor: "onStaffing",
              Header: "Staffing",
              Cell: props => (
                <div>
                  {props.value === "Yes" ? "On Staffing" : "Not On Staffing"}
                </div>
              ),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                return row[filter.id] === filter.value;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="No">Not On Staffing</Option>
                  <Option value="Yes">On Staffing</Option>
                </Select>
              )
            },
            {
              Header: "Deleted At",
              accessor: "deletedAt"
            },
            {
              Header: "Deleted By",
              accessor: "deletedBy"
            },
            {
              Header: "Actions",
              accessor: "",
              minWidth: 180,
              sortable: false,
              filterable: false,
              show: isAdmin,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <DeleteSite
                      siteItem={props.original.siteId}
                      siteName={props.original.name}
                    />
                    <Button
                      size="mini"
                      onClick={() => {
                        this.confirmReinstate(
                          props.original.siteId,
                          props.original.serviceDate
                        );
                      }}
                    >
                      ReInstate
                    </Button>
                  </div>
                );
              }
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "siteId",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  clinicSitesDeleted: state.patientsListStore.clinicSitesDeleted,
  EMRSystemList: state.patientsListStore.EMRSystemList,
  userGroups: state.authGroupStore.session.userGroups
});
const mapDispatchToProp = dispatch => ({
  getlistDeletedClinicSites: () => dispatch(getlistDeletedClinicSites()),
  changeSiteStatus: (input, self) => dispatch(changeSiteStatus(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(SiteDeletedList);
