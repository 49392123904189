import { Button, Select } from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../share/common.css";
import getPatchTrProps from "../tools/reactTablePatch";
import "./biMonthly.css";
import "./report.css";
import "./selectComponent.css";

const { Option } = Select;

export default class ReportCurrentMonthBase extends Component {
  constructor(props) {
    super(props);

    // parse url param
    let siteId = [];
    const queryParam = window.location.href.split("?")[1];
    if (queryParam) {
      let params = queryString.parse(queryParam);
      siteId = params.siteId.split("_");
    }

    this.state = {
      modalIsOpen: false,
      tableData: [],
      selectSites: siteId
    };

    this.startDate = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    this.endDate = moment()
      .endOf("month")
      .format("YYYY-MM-DD");
  }

  componentDidMount() {
    const { searchCurrentMonthPatients, getlistClinicSites } = this.props;
    getlistClinicSites();
    if (this.state.selectSites.length > 0) {
      this.openModal();

      const billingInputValue = {
        siteId: this.state.selectSites.join("_"),
        startDate: this.startDate,
        endDate: this.endDate
      };
      searchCurrentMonthPatients(billingInputValue)
        .then(data => {
          this.setState({
            tableData: data
          });
        })
        .catch(e => {
          console.log("searchCurrentMonthPatients error: ", e);
        })
        .finally(() => {
          this.closeModal();
        });
    }
  }

  searchUsers = () => {
    if (this.state.selectSites.length === 0) {
      return;
    }
    this.setState({
      selectedPatients: []
    });
    const { searchCurrentMonthPatients } = this.props;
    const sites = this.state.selectSites.join("_");
    const billingInputValue = {
      siteId: sites,
      startDate: this.startDate,
      endDate: this.endDate
    };
    this.openModal();
    searchCurrentMonthPatients(billingInputValue)
      .then(data => {
        this.setState({
          tableData: data
        });

        let currentPath = Control.path;
        if (currentPath.includes("?")) {
          currentPath = currentPath.split("?")[0];
        }
        Control.replace(
          currentPath +
            "?siteId=" +
            sites +
            "&startDate=" +
            this.startDate +
            "&endDate=" +
            this.endDate
        );
      })
      .finally(() => {
        this.closeModal();
      });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  gotoDoctorDetailPage(userId, id, date) {
    let currentRoute = Control.path;
    if (currentRoute.includes("?")) {
      currentRoute = currentRoute.split("?")[0];
    }
    // remove leading '/'
    currentRoute = currentRoute.substring(1);

    Control.go(
      `/MedicalDetailsHome?${currentRoute}/id=` +
        btoa(id) +
        "&userId=" +
        userId +
        "&startDate=" +
        date +
        "&endDate=" +
        date
    );
  }

  handleResetFilter = () => {
    this.tableInstance.setState({
      sorted: [],
      filtered: [],
      resized: []
    });
    this.setState({
      tableData: [],
      selectSites: []
    });

    let currentPath = Control.path;
    if (currentPath.includes("?")) {
      currentPath = currentPath.split("?")[0];
    }
    Control.replace(currentPath);
  };

  renderHeader() {
    return React.createElement(this.headerClass, {
      onResetFilter: this.handleResetFilter
    });
  }

  renderActionBar() {
    return (
      <div
        className="search_patient_container"
        style={{ display: "flex", alignItems: "center" }}
      >
        <span className="search_label">SiteID:</span>
        <Select
          mode="multiple"
          style={{ minWidth: 200 }}
          placeholder="Please select sites"
          value={this.state.selectSites}
          onChange={value => {
            this.setState({
              selectSites: value
            });
          }}
          onSelect={value => {
            // pre-select all child sites
            const nextSites = new Set(this.state.selectSites);
            this.props.clinicSites
              .map(site => site.siteId)
              .filter(site => site.startsWith(value))
              .forEach(site => nextSites.add(site));

            // special rule. Bay Area
            if (value === "0136") {
              nextSites.add("0001");
              nextSites.add("0002");
              nextSites.add("0028");
              nextSites.add("0041");
            }
            this.setState({
              selectSites: [...nextSites]
            });
          }}
          optionLabelProp="value"
          optionFilterProp="children"
        >
          {this.props.clinicSites.map(site => (
            <Option key={site.siteId} value={site.siteId}>
              {`${site.siteId} - ${site.name}`}
            </Option>
          ))}
        </Select>
        <Button
          onClick={this.searchUsers}
          style={{ marginLeft: 20 }}
          disabled={this.state.modalIsOpen}
        >
          Search
        </Button>
      </div>
    );
  }

  // subclass should override
  renderTableProps() {
    return { data: [], columns: [] };
  }

  renderTable() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.userId,
        rowInfo.original.id,
        moment().format("YYYY-MM-DD")
      );
    });

    const { data, columns } = this.renderTableProps();

    return (
      <ReactTable
        loading={this.state.modalIsOpen}
        getTrProps={trProps}
        data={data}
        filterable
        defaultFilterMethod={(filter, row) => {
          if (!row[filter.id]) {
            return false;
          }
          return row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        }}
        columns={columns}
        defaultPageSize={20}
        className="-striped -highlight"
        minRows={0}
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderActionBar()}
        {this.renderTable()}
      </div>
    );
  }
}

ReportCurrentMonthBase.propTypes = {
  clinicSites: PropTypes.array.isRequired,
  getlistClinicSites: PropTypes.func.isRequired,
  searchCurrentMonthPatients: PropTypes.func.isRequired,
  tableDefaultState: PropTypes.object.isRequired,
  updateTableDefaultState: PropTypes.func.isRequired
};
