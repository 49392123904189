import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Modal,
  Spin
} from "antd";
import React from "react";
import {
  getlistClinicSites,
  getlistStaff,
  createPupPatient
} from "../../../action/registerAction";
import { connect } from "react-redux";
import _ from "lodash";
import ReactPhoneInput from "react-phone-input-2";
import "./index.css";
import { validatePhone } from "../../../tools/phoneFormat";
import { checkBirthdayValid } from "../../../tools/dateFormat";
import moment from "moment-timezone";

const GenderList = ["Female", "Male", "Other", "Prefer not to say"];
const PositionList = ["L", "R", "B", "T"];
const { Option } = Select;
class CreatePatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      firstNameStatus: "success",
      firstName: "",
      lastNameStatus: "success",
      lastName: "",
      dob: "",
      dobStatus: "success",
      phone: "",
      phoneStatus: "success",
      homePhone: "",
      homePhoneStatus: "success",
      email: "",
      emailStatus: "success",
      gender: "",
      patientID: "",
      serviceType: "PU",
      site: "",
      siteStatus: "success",
      doctorId: "",
      doctorName: "",
      doctorStatus: "success",
      MBI: "",
      room: "",
      position: "",
      notes: ""
    };
  }

  componentDidMount() {
    if (this.props.clinicSites.length === 0) {
      this.setState({
        siteStatus: "validating"
      });
      this.props.getlistClinicSites().then(() => {
        this.autoFillSiteAndDoctor();
      });
    } else {
      this.autoFillSiteAndDoctor();
    }
    if (this.props.listStaff.length === 0) {
      this.setState({
        doctorStatus: "validating"
      });
      this.props.getlistStaff().then(() => {
        this.autoFillSiteAndDoctor();
      });
    } else {
      this.autoFillSiteAndDoctor();
    }
  }

  autoFillSiteAndDoctor() {
    if (this.props.clinicSites.length > 0) {
      this.setState({
        site: this.props.clinicSites[0],
        siteStatus: "success"
      });
    }
    if (this.props.listStaff.length > 0 && this.props.clinicSites.length > 0) {
      const site = this.props.clinicSites[0];
      const tmp = this.props.listStaff.filter(
        item =>
          item.isPrescribing && item.siteId.split("-").includes(site.siteId)
      );
      if (tmp.length > 0) {
        const doctor = this.props.listStaff.find(
          staff => staff.email === tmp[0].email
        );
        this.setState({
          doctorId: tmp[0].email,
          doctorName: `${doctor.firstName} ${doctor.lastName}`,
          doctorStatus: "success"
        });
      }
    }
  }

  onSelectSite = siteId => {
    this.setState({
      site: this.props.clinicSites.find(s => s.siteId === siteId),
      siteStatus: "success"
    });
    // auto fill doctor
    const tmp = this.props.listStaff.filter(
      item => item.isPrescribing && item.siteId.split("-").includes(siteId)
    );
    if (tmp.length > 0) {
      const doctor = this.props.listStaff.find(
        staff => staff.email === tmp[0].email
      );
      this.setState({
        doctorId: tmp[0].email,
        doctorName: `${doctor.firstName} ${doctor.lastName}`,
        doctorStatus: "success"
      });
    }
  };

  validateForm() {
    let pass = true;
    if (!this.state.firstName) {
      this.setState({
        firstNameStatus: "error"
      });
      pass = false;
    }
    if (!this.state.lastName) {
      this.setState({
        lastNameStatus: "error"
      });
      pass = false;
    }
    if (!this.state.dob) {
      this.setState({
        dobStatus: "error"
      });
      pass = false;
    }
    if (!validatePhone(this.state.phone)) {
      this.setState({
        phoneStatus: "error"
      });
      pass = false;
    }
    if (this.state.homePhone && !validatePhone(this.state.homePhone)) {
      this.setState({
        homePhoneStatus: "error"
      });
      pass = false;
    }
    if (!checkBirthdayValid(this.state.dob)) {
      pass = false;
    }
    if (this.state.email && !this.validateEmail(this.state.email)) {
      this.setState({
        emailStatus: "error"
      });
      pass = false;
    }

    return pass;
  }

  validateEmail(email) {
    const reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  }

  formatPhone(phone) {
    return phone.replace(/[\(\)\-\s]+/g, "");
  }

  handleSubmit = () => {
    if (this.validateForm()) {
      console.log("site info: ", this.state.site);
      let input = {
        serviceType: this.state.serviceType,
        id: this.formatPhone(this.state.phone),
        phone: this.formatPhone(this.state.phone),
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        siteId: this.state.site.siteId,
        siteName: this.state.site.name,
        clinicIRB: this.state.site.IRBConsent || "Not Approved",
        birthday: this.state.dob.format("YYYY-MM-DD"),
        doctorId: this.state.doctorId,
        doctorName: this.state.doctorName,
        userTimezone: moment.tz.guess(),
        accountStatus: "PAUSED",
        pausedReason: "Account - Pending New order shipment",
        accountServiceStatus: "NOT_ON_SERVICE",
        lastTurnTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
        room: this.state.room,
        position: this.state.position,
        pupNotes: this.state.notes
      };
      // console.log(formData);
      this.props.createPupPatient(input, this);
    }
  };

  render() {
    return (
      <Spin spinning={this.state.loading} wrapperClassName="page-loading">
        <div
          id="new-account"
          style={{ width: 800, margin: "auto", marginTop: 40 }}
        >
          <Form>
            <div className="form-section">
              <Divider orientation="left">Patient Information</Divider>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item
                    label="First Name"
                    required
                    className="form-item"
                    style={{ textAlign: "left" }}
                    validateStatus={this.state.firstNameStatus}
                    help={
                      this.state.firstNameStatus === "error"
                        ? "Please input FirstName"
                        : ""
                    }
                  >
                    <Input
                      size="large"
                      value={this.state.firstName}
                      onChange={e =>
                        this.setState({
                          firstName: e.target.value,
                          firstNameStatus: "success"
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Last Name"
                    required
                    className="form-item"
                    style={{ textAlign: "left" }}
                    validateStatus={this.state.lastNameStatus}
                    help={
                      this.state.lastNameStatus === "error"
                        ? "Please input LastName"
                        : ""
                    }
                  >
                    <Input
                      size="large"
                      value={this.state.lastName}
                      onChange={e =>
                        this.setState({
                          lastName: e.target.value,
                          lastNameStatus: "success"
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Date of Birth (DOB)"
                    required
                    className="form-item"
                    style={{ textAlign: "left" }}
                    validateStatus={this.state.dobStatus}
                    help={
                      this.state.dobStatus === "error"
                        ? "Please input Birthday"
                        : ""
                    }
                  >
                    <DatePicker
                      size="large"
                      onChange={date =>
                        this.setState({
                          dob: date,
                          dobStatus: "success"
                        })
                      }
                      value={_.isEmpty(this.state.dob) ? null : this.state.dob}
                      format="MM-DD-YYYY"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <div style={{ clear: "both" }}></div>
                <Col span={8}>
                  <Form.Item
                    label="Site Name"
                    required
                    hasFeedback
                    validateStatus={this.state.siteStatus}
                    help={
                      this.state.siteStatus === "error"
                        ? "Please select site"
                        : ""
                    }
                    className="form-item"
                    style={{ textAlign: "left" }}
                  >
                    <Select
                      size="large"
                      onChange={this.onSelectSite}
                      value={this.state.site && this.state.site.siteId}
                      showSearch
                      optionFilterProp="children"
                    >
                      {this.props.clinicSites.map(site => (
                        <Option value={site.siteId} key={site.siteId}>
                          {`${site.siteId} - ${site.name}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Staffing"
                    required
                    className="form-item"
                    style={{ textAlign: "left" }}
                  >
                    <Input
                      size="large"
                      disabled
                      value={
                        this.state.site
                          ? this.state.site.onStaffing === "Yes"
                            ? "On Staffing"
                            : "Not On Staffing"
                          : ""
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Doctor"
                    required
                    hasFeedback
                    validateStatus={this.state.doctorStatus}
                    help={
                      this.state.doctorStatus === "error"
                        ? "Please select doctor"
                        : ""
                    }
                    className="form-item"
                    style={{ textAlign: "left" }}
                  >
                    <Select
                      size="large"
                      value={this.state.doctorId}
                      onChange={value => {
                        const doctor = this.props.listStaff.find(
                          staff => staff.email === value
                        );
                        this.setState({
                          doctorId: value,
                          doctorName: `${doctor.firstName} ${doctor.lastName}`,
                          doctorStatus: "success"
                        });
                      }}
                      showSearch
                      optionFilterProp="children"
                    >
                      {this.props.listStaff
                        .filter(item =>
                          this.state.site
                            ? item.isPrescribing &&
                              item.siteId
                                .split("-")
                                .includes(this.state.site.siteId)
                            : false
                        )
                        .map(doctor => (
                          <Option value={doctor.email} key={doctor.email}>
                            {doctor.isPrescribing &&
                            !doctor.firstName.includes("Dr.")
                              ? "Dr. " +
                                doctor.firstName +
                                " " +
                                doctor.lastName
                              : doctor.firstName + " " + doctor.lastName}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <div style={{ clear: "both" }}></div>
                <Col span={8}>
                  <Form.Item
                    label="Username"
                    required
                    className="form-item"
                    style={{ textAlign: "left" }}
                    validateStatus={this.state.phoneStatus}
                    help={
                      this.state.phoneStatus === "error"
                        ? "Invalid phone number"
                        : ""
                    }
                  >
                    <ReactPhoneInput
                      buttonClass="phone-button"
                      inputClass="phone-input"
                      inputExtraProps={{
                        name: "Cell phone",
                        required: true,
                        autoFocus: false
                      }}
                      defaultCountry={"us"}
                      value={this.state.phone}
                      onChange={value => {
                        this.setState({
                          phone: value,
                          phoneStatus: "success"
                        });
                      }}
                      disableAreaCodes
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Cell Phone"
                    className="form-item"
                    style={{ textAlign: "left" }}
                  >
                    <ReactPhoneInput
                      inputClass="phone-input"
                      buttonClass="phone-button"
                      inputExtraProps={{
                        autoFocus: false
                      }}
                      defaultCountry={"us"}
                      value={this.state.phone}
                      onChange={value =>
                        this.setState({
                          phone: value,
                          phoneStatus: "success"
                        })
                      }
                      disableAreaCodes
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Home Phone"
                    className="form-item"
                    style={{ textAlign: "left" }}
                    validateStatus={this.state.homePhoneStatus}
                    help={
                      this.state.homePhoneStatus === "error"
                        ? "Invalid phone number"
                        : ""
                    }
                  >
                    <ReactPhoneInput
                      inputClass="phone-input"
                      buttonClass="phone-button"
                      inputExtraProps={{
                        autoFocus: false
                      }}
                      defaultCountry={"us"}
                      value={this.state.homePhone}
                      onChange={value => this.setState({ homePhone: value })}
                      disableAreaCodes
                    />
                  </Form.Item>
                </Col>
                <div style={{ clear: "both" }}></div>
                <Col span={8}>
                  <Form.Item
                    label="Room"
                    className="form-item"
                    help={
                      this.state.emailStatus === "error"
                        ? "Invalid email format"
                        : ""
                    }
                  >
                    <Input
                      size="large"
                      onChange={e => this.setState({ room: e.target.value })}
                      value={this.state.room}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Position" className="form-item">
                    <Select
                      size="large"
                      onChange={value => this.setState({ position: value })}
                      value={this.state.position}
                      filterOption={(input, option) =>
                        option.props.value
                          .toLocaleLowerCase()
                          .indexOf(input.toLocaleLowerCase()) >= 0
                      }
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value="" key="-1">
                        &nbsp;
                      </Option>
                      {PositionList.map((v, i) => (
                        <Option value={v} key={i}>
                          {v}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Notes" className="form-item">
                    <Input
                      size="large"
                      placeholder=""
                      onChange={e => this.setState({ notes: e.target.value })}
                      value={this.state.notes}
                    />
                  </Form.Item>
                </Col>
                <div style={{ clear: "both" }}></div>
                <Col span={8}>
                  <Form.Item
                    label="Email"
                    className="form-item"
                    validateStatus={this.state.emailStatus}
                    help={
                      this.state.emailStatus === "error"
                        ? "Invalid email format"
                        : ""
                    }
                  >
                    <Input
                      size="large"
                      onChange={e => this.setState({ email: e.target.value })}
                      value={this.state.email}
                      type="email"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Gender" className="form-item">
                    <Select
                      size="large"
                      onChange={value => this.setState({ gender: value })}
                      value={this.state.gender}
                      filterOption={(input, option) =>
                        option.props.value
                          .toLocaleLowerCase()
                          .indexOf(input.toLocaleLowerCase()) >= 0
                      }
                      showSearch
                      optionFilterProp="children"
                    >
                      <Option value="" key="-1">
                        &nbsp;
                      </Option>
                      {GenderList.map((v, i) => (
                        <Option value={v} key={i}>
                          {v}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="MBI" className="form-item">
                    <Input
                      size="large"
                      placeholder="Medicare Beneficiary Identifier"
                      onChange={e => this.setState({ MBI: e.target.value })}
                      value={this.state.MBI}
                    />
                  </Form.Item>
                </Col>
                <div style={{ clear: "both" }}></div>
              </Row>
            </div>
          </Form>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Button
              size="large"
              style={{
                marginRight: 12,
                color: "#7C35AF",
                borderColor: "#7C35AF"
              }}
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </div>
          <Divider orientation="left" />
        </div>
      </Spin>
    );
  }
}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites,
  listStaff: state.patientsListStore.listStaff
});

const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getlistStaff: () => dispatch(getlistStaff()),
  createPupPatient: (input, self) => dispatch(createPupPatient(input, self))
});

export default connect(mapStateToProp, mapDispatchToProp)(CreatePatient);
