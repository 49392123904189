export const COLOR = {
  PENDING: "#F1BE0A",
  Pending: "#F1BE0A",
  ON_SERVICE: "#73D1AE",
  NOT_ON_SERVICE: "#6F7381",
  CANCELLED_ONBOARDING: "#6F7381",
  ACTIVE: "#73D1AE",
  IDLE: "#AEC3BB",
  PAUSED: "#F1BE0A",
  Paused: "#F1BE0A"
};

export const PROGRAM_DESC = {
  PENDING: "Pending Onboarding",
  Pending: "Pending Onboarding",
  ON_SERVICE: "On Service",
  NOT_ON_SERVICE: "Not On Service",
  CANCELLED_ONBOARDING: "Cancelled Onboarding",
  ACTIVE: "Active",
  IDLE: "Idle",
  PAUSED: "Paused",
  Paused: "Paused"
};

export const DEVICE_DESC = {
  ACTIVE: "Active",
  Active: "Active",
  IDLE: "Idle",
  Idle: "Idle",
  PAUSED: "Paused",
  Paused: "Paused",
  NOT_ON_SERVICE: "Cancelled"
};

// export const ProgramCategory = ["CCM", "PCM", "RPM", "RTM"];
export const ProgramCategory = ["RPM"];

export const ProgramOptions = ProgramCategory.flatMap(p => {
  return [
    {
      color: COLOR.Pending,
      value: `${p}:Pending`,
      text: `${p}: Pending Onboarding`,
      tagText: p
    },
    {
      color: COLOR.ON_SERVICE,
      value: `${p}:ON_SERVICE`,
      text: `${p}: On Service`,
      tagText: p
    },
    {
      color: COLOR.PAUSED,
      value: `${p}:Paused`,
      text: `${p}: Paused`,
      tagText: p
    },
    {
      color: COLOR.NOT_ON_SERVICE,
      value: `${p}:NOT_ON_SERVICE`,
      text: `${p}: Off Service`,
      tagText: p
    },
    {
      color: COLOR.CANCELLED_ONBOARDING,
      value: `${p}:CANCELLED_ONBOARDING`,
      text: `${p}: Cancelled Onboarding`,
      tagText: p
    }
  ];
});

// export const DeviceCategory = ["Siren Socks", "Siren Move"];
export const DeviceCategory = ["Siren Socks"];

export const DeviceOptions = DeviceCategory.flatMap(p => {
  const shortName = {
    "Siren Socks": "Socks",
    "Siren Move": "Move"
  };
  const Device2Program = {
    "Siren Socks": "RPM",
    "Siren Move": "RTM"
  };
  return [
    {
      color: COLOR.ACTIVE,
      value: `${Device2Program[p]}:ACTIVE`,
      text: `${p}: Active`,
      tagText: shortName[p]
    },
    {
      color: COLOR.IDLE,
      value: `${Device2Program[p]}:IDLE`,
      text: `${p}: Idle`,
      tagText: shortName[p]
    },
    // {
    //   color: COLOR.PAUSED,
    //   value: `${Device2Program[p]}:PAUSED`,
    //   text: `${p}: Paused`,
    //   tagText: shortName[p]
    // },
    {
      color: COLOR.NOT_ON_SERVICE,
      value: `${Device2Program[p]}:NOT_ON_SERVICE`,
      text: `${p}: Cancelled`,
      tagText: shortName[p]
    }
  ];
});
