import { Select, Input } from "antd";
import { find } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { addGroup } from "../action/addAction";
import { getGroups } from "../action/patientAction";
import ButtonSuccess from "./buttonSuccess";
import "./selectGroup.css";
import { Auth } from "aws-amplify";
import { changeBilledPatient } from "../action/bliMonthyReportAction";
import moment from "moment";

const { TextArea } = Input;
class ChangePuPatientRoom extends Component {
  constructor(props) {
    super(props);
    this.submit.bind(this);
    this.state = {
      open: false,
      room: "",
      confirmSuccess: false,
      userId: "",
      id: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleOpen = async () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      room: this.props.room
    });
  };
  close = () => this.setState({ open: false, confirmSuccess: false });
  handleChange(value) {
    this.setState({
      room: value
    });
  }
  submit() {
    const { changeBilledPatient } = this.props;
    const input = {
      userId: this.props.userId,
      id: this.props.id,
      room: this.state.room
    };
    changeBilledPatient(input, this.props.from, this).then(() => {
      this.props.updatePatientRoomInfo({
        id: this.props.id,
        room: this.state.room
      });
    });
  }
  render() {
    const { Option } = Select;
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Icon as="i" name="edit" className="edit" onClick={this.handleOpen} />
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>Modify PU Room</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="8">
                  <label>Room#</label>
                  <TextArea
                    value={this.state.room}
                    onChange={e => this.setState({ room: e.target.value })}
                    placeholder="PU Room"
                    autoSize={{ minRows: 3, maxRows: 20 }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.submit()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            OK
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.close()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  listPatients: state.patientsListStore.listPatients,
  filterGroup: state.patientsListStore.filterGroup,
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  changeBilledPatient: (input, from, self) =>
    dispatch(changeBilledPatient(input, from, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(ChangePuPatientRoom);
