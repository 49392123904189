import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import AWS from "aws-sdk";
import _ from "lodash";
import moment from "moment";
import _api from "../graphql/_api";
import { compareValue } from "../tools/compare";
import message from "../tools/message-info";
import * as TYPES from "../types/types";
import { formatReviewTasks } from "../tools/review-task-type-format";
import { getSimInfo } from "../graphql/API";
import { notification } from "antd";

export function getGroups() {
  return async dispatch => {
    const newEvent = await API.graphql(graphqlOperation(_api.listUserGroups()));
    if (newEvent) {
      let groupArray = _.clone(newEvent.data.listUserGroups);
      let groupArraySort = [];
      _.forEach(groupArray, function (items, i) {
        if (items === "TRIAL_SILENCE") {
          groupArray.splice(i, 1);
        }
      });
      groupArraySort = groupArray.sort(compareValue);
      dispatch(changeGroups(groupArraySort));
    }
  };
}

export function getBillingReportGroups(self) {
  return async dispatch => {
    const newEvent = await API.graphql(graphqlOperation(_api.listUserGroups()));
    if (newEvent) {
      let groupArray = _.clone(newEvent.data.listUserGroups);
      let groupArraySort = [];
      _.forEach(groupArray, function (items, i) {
        if (items === "TRIAL_SILENCE") {
          groupArray.splice(i, 1);
        }
      });
      groupArraySort = groupArray.sort(compareValue);
      if (groupArraySort.length > 0) {
        self.setState({
          siteId: [groupArraySort[0]],
          selectValues: [groupArraySort[0]]
        });
        self.searchUsers();
      }
      dispatch(changeGroups(groupArraySort));
    }
  };
}

export function changeGroups(filterGroup) {
  return {
    type: TYPES.FILTER_GROUP,
    text: filterGroup
  };
}

/**
 * search patients
 *
 * @param self
 * @param sirenId
 * @returns {Function}
 */
function checkBe(obj) {
  if (!(typeof obj == "object")) {
    return;
  }
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && _.isEmpty(obj[key])) {
      delete obj[key];
    }
  }
  return obj;
}

export function searchGlobalPatientsInfo(searchStr, self) {
  return async dispatch => {
    try {
      const filter = {
        group: {
          ne: "TRIAL_SILENCE"
        },
        initializedStr: {
          eq: "Yes"
        },
        accountStatus: {
          ne: "DELETED"
        }
      };
      const newEvent = await API.graphql(
        graphqlOperation(_api.searchPatientsConnection(), {
          searchStr,
          filter,
          limit: 1000
        })
      );
      if (newEvent) {
        dispatch(
          changePatientsListState(newEvent.data.lambdaSearchPatients.items)
        );
      }
    } catch (e) {
      console.log("searchGlobalPatientsInfo error: ", e);
    }
  };
}

export function searchPatientConnection(searchStr, self, nextToken) {
  return async dispatch => {
    try {
      let filter = {
        group: {
          ne: "TRIAL_SILENCE"
        },
        initializedStr: {
          eq: "Yes"
        },
        accountStatus: {
          ne: "DELETED"
        }
      };
      let sort = {
        field: "sirenId",
        direction: "desc"
      };
      const newEvent = await API.graphql(
        graphqlOperation(_api.searchPatientsConnection(), {
          searchStr,
          filter,
          sort,
          limit: 1500,
          nextToken
        })
      );
      if (newEvent) {
        let patientList = newEvent.data.lambdaSearchPatients.items;
        self.setState({
          modalIsOpen: false,
          currentPage: nextToken === 0 ? 1 : self.state.currentPage,
          nextToken: parseInt(newEvent.data.lambdaSearchPatients.nextToken),
          totalPatients: newEvent.data.lambdaSearchPatients.total,
          pages: Math.ceil(
            newEvent.data.lambdaSearchPatients.total / self.state.pageSize
          )
        });
        let listPatients = _.clone(self.props.listPatients);
        const newListPatients = [...listPatients, ...patientList];
        if (nextToken === 0) {
          dispatch(changePatientsListState(patientList));
        } else {
          dispatch(changePatientsListState(newListPatients));
        }
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("searchPatientConnection error: ", e);
    }
  };
}

export async function searchGlobalFavoriteListInfo(searchStr, sirenIds) {
  if (sirenIds.length === 0) {
    return;
  }
  const sirenIdFilter = sirenIds.map(p => {
    return { sirenId: { eq: p } };
  });
  const filter = {
    group: {
      ne: "TRIAL_SILENCE"
    },
    initializedStr: {
      eq: "Yes"
    },
    accountStatus: {
      ne: "DELETED"
    },
    or: sirenIdFilter
  };
  const newEvent = await API.graphql(
    graphqlOperation(_api.searchPatientsConnection(), {
      searchStr,
      filter,
      limit: 300
    })
  );

  return newEvent.data.lambdaSearchPatients.items;
}

export function getTrialList(self) {
  return async dispatch => {
    const newEvent = await API.graphql(
      graphqlOperation(_api.searchPatientsConnection(), {
        filter: {
          group: {
            eq: "TRIAL_SILENCE"
          },
          initializedStr: {
            eq: "Yes"
          }
        },
        limit: 1000
      })
    );
    if (newEvent) {
      dispatch(changeTrialListState(newEvent.data.lambdaSearchPatients.items));
    }
  };
}

function changeTrialListState(trialuser) {
  return {
    type: TYPES.TRIAL_USER,
    text: trialuser
  };
}

export function deleteNewPatients(id) {
  return API.graphql(
    graphqlOperation(_api.deleteRegisteredPatient(), { input: { id } })
  );
}

function titleCase(str) {
  if (str === null) {
    return "";
  } else {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }
}

export function getPatientBySirenId(sirenId, self) {
  return async dispatch => {
    try {
      self.openModal();
      const newEvent = await API.graphql(
        graphqlOperation(_api.getPatientBySirenId(), { sirenId })
      );
      console.log("newEvent: ", newEvent);
      if (newEvent && newEvent.data.getPatientBySirenId) {
        const patient = newEvent.data.getPatientBySirenId;
        self.setState({ id: patient.id, userId: patient.userId });
        console.log(patient.id, patient.userId);
        // const { getDoctorDetails } = self.props;
        // getDoctorDetails(
        //   patient.id,
        //   patient.userId,
        //   self,
        //   self.state.StartDate,
        //   self.state.EndDate
        // );
      } else {
        self.closeModal();
        notification.error({
          message: "System Error",
          description: `No such patient or SID: ${sirenId} is invalid, please check.`,
          duration: 0
        });
      }
    } catch (e) {
      console.log(`get patient by siren id error: `, JSON.stringify(e));
      notification.error({
        message: "System Error",
        description: `No such patient or SID: ${sirenId} is invalid, please check.`,
        duration: 0
      });
    }
  };
}

export function getDoctorDetails(id, userid, self, start, end) {
  return async dispatch => {
    try {
      dispatch(changeDoctorDetails({}));
      dispatch(changeSymtoms({}));
      dispatch(changeWeightScale({}));
      let patientSymptoms = [];
      let patientSymptomsApp = [];
      const patient = await API.graphql(
        graphqlOperation(_api.docDetail(), { id, userid, start, end })
      );
      if (patient && !_.isEmpty(patient.data.getPatient)) {
        let lastNotification = [];
        let enableNotification =
          patient.data.getPatient.enableApp ||
          patient.data.getPatient.enableSms ||
          patient.data.getPatient.enableEmail;
        if (enableNotification) {
          if (patient.data.getPatient.enableApp) {
            lastNotification.push("App");
          }
          if (patient.data.getPatient.enableSms) {
            lastNotification.push("SMS");
          }
          if (patient.data.getPatient.enableEmail) {
            lastNotification.push("Email");
          }
        } else {
          if (patient.data.getPatient.lastNotification) {
            lastNotification = patient.data.getPatient.lastNotification.split(
              "-"
            );
          }
        }
        const allReviewTasks = formatReviewTasks(
          patient.data.getPatient.reviewTask
        );
        const dataUpdateTask = _.filter(allReviewTasks, function (t) {
          return t.type === "DATA_UPDATE";
        });
        const within3DaysDataUpdate = _.filter(dataUpdateTask, function (t) {
          return t.daysDelay <= 3;
        });
        const otherTasks = _.flatten(
          allReviewTasks
            .filter(t => t.type !== "DATA_UPDATE")
            .map(p => {
              return [...p.tasks];
            })
        );
        const onboardingTasks = otherTasks.filter(
          t => t.status === "Onboarding"
        );
        self.setState({
          openModal: false,
          sirenId: String(patient.data.getPatient.sirenId),
          userId: patient.data.getPatient.userId,
          enableNotification,
          lastNotification,
          dateUpdateTaskOnly:
            allReviewTasks.length > 0 &&
            dataUpdateTask.length === allReviewTasks.length,
          within3DaysTask: within3DaysDataUpdate.length > 0,
          canAddDMT: dataUpdateTask.length > 0 || onboardingTasks.length > 0,
          onboardingTaskOnly:
            otherTasks.length > 0 &&
            onboardingTasks.length ===
            otherTasks.length + dataUpdateTask.length,
          multipleOnboardingTask: onboardingTasks.length > 1
        });
        let obj = {};
        obj.userId = patient.data.getPatient.userId;
        obj.timestamp = moment(patient.data.getPatient.createdAt).format(
          "YYYY-MM-DD"
        );
        obj.author = _.isEmpty(patient.data.getPatient.doctorId)
          ? ""
          : patient.data.getPatient.doctorId;
        obj.title = "Patient Intake Details";
        obj.note = "Patient Intake Details";
        dispatch(changeInitializedNote([obj]));
        //patient info
        const newPatientInfo = _.clone(patient.data.getPatient);
        newPatientInfo.sex = titleCase(newPatientInfo.sex);
        const afterReplace = newPatientInfo;
        dispatch(changeDoctorDetails(afterReplace));
        if (afterReplace.weightScale && afterReplace.weightScale.length > 0) {
          dispatch(changeWeightScale(afterReplace.weightScale));
        }
        //symptoms
        let footSymptomByDMT = _.filter(
          patient.data.getPatient.footSymptoms,
          function (item) {
            return item.source === "DMT";
          }
        );
        let footSymptomByApp = _.filter(
          patient.data.getPatient.footSymptoms,
          function (item) {
            return item.source !== "DMT";
          }
        );
        _(footSymptomByDMT)
          .groupBy(item => {
            return _.split(item.reportTime, "T")[0];
          })
          .flatMap((value, key) => {
            let symptomType = undefined;
            const symptomTypes = value.map(itemSub => {
              if (!itemSub.symptomType) {
                symptomType = itemSub.footSymptom ? itemSub.footSymptom : "";
                return symptomType;
              } else {
                symptomType = caseConversion(itemSub.symptomType);
                return symptomType;
              }
            });
            patientSymptoms.push({
              reportTime: key,
              symptomType: symptomTypes
                .map(p => {
                  return p;
                })
                .join(", "),
              source: "DMT"
            });
          })
          .uniq()
          .value();

        _(footSymptomByApp)
          .groupBy(item => {
            return _.split(item.reportTime, "T")[0];
          })
          .flatMap((value, key) => {
            let symptomType = undefined;
            const symptomTypes = value.map(itemSub => {
              if (!itemSub.symptomType) {
                symptomType = itemSub.footSymptom ? itemSub.footSymptom : "";
                return symptomType;
              } else {
                symptomType = caseConversion(itemSub.symptomType);
                return symptomType;
              }
            });
            patientSymptomsApp.push({
              reportTime: key,
              symptomType: symptomTypes
                .map(p => {
                  return p;
                })
                .join(", "),
              source: "APP"
            });
          })
          .uniq()
          .value();
        const symptoms = _.orderBy(
          [...patientSymptoms, ...patientSymptomsApp],
          ["reportTime"],
          ["desc"]
        );
        dispatch(
          changeSymtoms({
            footSymptoms: symptoms
          })
        );
        dispatch(updateCalendarData(patient.data.getPatient));
        //find favorite status
        let user = await Auth.currentAuthenticatedUser();
        Auth.userAttributes(user).then(attribute => {
          const favorite = _.filter(attribute, { Name: "custom:favorite" });
          if (favorite.length > 0 && !_.isEmpty(favorite[0].Value)) {
            let favoriteValue = favorite[0].Value.split("_");
            if (
              favoriteValue.indexOf(String(patient.data.getPatient.sirenId)) >=
              0
            ) {
              self.setState({ rating: 1 });
            }
          }
        });
      } else {
        console.log("response is ", patient);
      }
    } catch (err) {
      self.setState({ openModal: false });
      console.log("getDoctorDetails error: ", err);
    } finally {
      self.closeModal();
    }
  };
}

export function getWeightScaleInfo(id, userid, self) {
  return async dispatch => {
    try {
      const patient = await API.graphql(
        graphqlOperation(_api.getWeightScale(), { id, userid })
      );
      if (patient) {
        dispatch(changeWeightScale(patient.data.getPatient.weightScale));
      }
      // console.log("patient: ", patient);
    } catch (e) {
      console.log(`getWeightScaleInfo error: `, e);
    }
  };
}

export function changeDoctorDetails(doctorDetails) {
  return {
    type: TYPES.DOCTOR_DETAILS,
    text: doctorDetails
  };
}

export function changeInitializedNote(initializedNote) {
  return {
    type: TYPES.INITIALIZED_NOTE,
    text: initializedNote
  };
}

export function getRegisterUser(self) {
  return async dispatch => {
    const newEvent = await API.graphql(graphqlOperation(_api.boxPatients()));
    if (newEvent) {
      dispatch(changeRegisterUser(newEvent.data.listPatients.items));
    }
  };
}

function changeRegisterUser(chooseuser) {
  return {
    type: TYPES.CHOOSE_USER,
    text: chooseuser
  };
}

export function changePatientsListState(patientsList) {
  return {
    type: TYPES.PATIENTS_LIST,
    text: patientsList
  };
}

export function caseConversion(patient) {
  if (patient === "BURN") {
    return "Burn";
  } else if (patient === "BLISTER") {
    return "Blister";
  } else if (patient === "BRUISING") {
    return "Bruising";
  } else if (patient === "BUG_BITE") {
    return "Bug bite";
  } else if (patient === "CALLOUS") {
    return "Callous";
  } else if (patient === "LOSS_OF_SENSATION") {
    return "Loss of sensation";
  } else if (patient === "NUMBNESS") {
    return "Numbness";
  } else if (patient === "OPEN_WOUND") {
    return "Open wound";
  } else if (patient === "REDNESS") {
    return "Redness";
  } else if (patient === "SWELLING") {
    return "Swelling";
  } else if (patient === "INCREASED_PAIN") {
    return "Increased Pain";
  } else if (patient === "INGROWN_TOENAILS") {
    return "Ingrown toenails";
  } else if (patient === "COLOR_TOENAILS") {
    return "Color changes with toenails";
  }
}

export function changeSymtoms(symtomDatas) {
  return {
    type: TYPES.FOOT_SYMTOMS,
    text: symtomDatas
  };
}

function changeWeightScale(weightScale) {
  return {
    type: TYPES.WEIGHT_SCALE,
    text: weightScale
  };
}

export function getFootStatus(id, userid, start, end) {
  return async dispatch => {
    try {
      dispatch(changeFootStatus({}, {}, {}));
      const patient = await API.graphql(
        graphqlOperation(_api.getFoot(), { id, userid, start, end })
      );
      // get last item
      if (patient) {
        let footStatus = {},
          footStatusCleaned = {},
          footOverview = {};
        if (
          patient.data.getPatient.footStatuses &&
          patient.data.getPatient.footStatuses.length > 0
        ) {
          footStatus =
            patient.data.getPatient.footStatuses[
            patient.data.getPatient.footStatuses.length - 1
            ];
        }
        if (
          patient.data.getPatient.footStatusesCleaned &&
          patient.data.getPatient.footStatusesCleaned.length > 0
        ) {
          footStatusCleaned =
            patient.data.getPatient.footStatusesCleaned[
            patient.data.getPatient.footStatusesCleaned.length - 1
            ];
        }
        if (patient.data.getPatient.footOverview) {
          footOverview = patient.data.getPatient.footOverview;
        }
        dispatch(changeFootStatus(footStatus, footStatusCleaned, footOverview));
      }
    } catch (e) {
      console.log("get foot status error: ", e);
    }
  };
}

function changeFootStatus(footStatus, cleanedStatus, footOverview) {
  return {
    type: TYPES.FOOT_STATUS,
    text: footStatus,
    cleanedStatus,
    footOverview
  };
}

export async function getFootPhotos(userid) {
  const credentials = await Auth.currentCredentials();
  AWS.config.credentials = Auth.essentialCredentials(credentials);
  // console.log("AWS.config.credentials: ", AWS.config.credentials)

  const photosList = await Storage.list("photoFoot/", {
    identityId: userid,
    level: "private"
  });

  const showPhotosProp = photosList.reverse().slice(0, 10);
  console.log("aws photos:", showPhotosProp);
  const promises = showPhotosProp.map(p =>
    Storage.get(p.key, {
      identityId: userid,
      level: "private"
    })
  );

  const photos = await Promise.all(promises);

  return photos.map((result, index) => {
    const item = showPhotosProp[index];
    const key = item.key.split("/");
    const footdire = key[key.length - 1].split(".jpg")[0];
    let obj = {
      date:
        footdire === "left" || footdire === "right"
          ? key[1]
          : moment(
            parseInt(footdire.split("-")[footdire.split("-").length - 1])
          ).format("YYYY-MM-DD HH:mm:ss"),
      uri: result,
      foot: footdire
    };

    return obj;
  });
}

export function getImage(userid, self) {
  return async dispatch => {
    AWS.config.credentials = Auth.essentialCredentials(
      await Auth.currentCredentials()
    );
    // console.log("AWS.config.credentials: ", AWS.config.credentials);
    try {
      Storage.list("photoFoot/", {
        identityId: userid,
        level: "private"
      })
        .then(result => {
          // let currentLeft = null;
          // let currentRight = null;
          let historyLeft = null;
          let historyRight = null;
          let history = [];
          let historyDate = [];
          result
            .reverse()
            .slice(0, 10)
            .map(item => {
              Storage.get(item.key, {
                identityId: userid,
                level: "private"
              }).then(result => {
                const key = item.key.split("/");
                const footdire = item.key.split("/")[2].split(".jpg")[0];
                let obj = {
                  date: key[1],
                  uri: result,
                  foot: footdire
                };
                if (historyDate.indexOf(key[1]) < 0) {
                  if (item.key.includes("left")) {
                    historyLeft = obj;
                    history.push({ left: historyLeft });
                    historyDate.push(key[1]);
                    self.setState({ images: history });
                  } else {
                    historyRight = obj;
                    history.push({ right: historyRight });
                    historyDate.push(key[1]);
                    self.setState({ images: history });
                  }
                } else {
                  if (item.key.includes("left")) {
                    historyLeft = obj;
                    history[history.length - 1].left = historyLeft;
                    self.setState({ images: history });
                  } else {
                    historyRight = obj;
                    history[history.length - 1].right = historyRight;
                    self.setState({ images: history });
                  }
                }
              });
              return true;
            });
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log("getImage error: ", err);
    }
  };
}

function compareRegistered(a, b) {
  const akey = a.registered + a.boxId;
  const bkey = b.registered + b.boxId;
  if (akey > bkey) return -1;
  if (akey > bkey) return 1;
  return 0;
}

export function getSockStatus(id, userid, self) {
  return async dispatch => {
    try {
      self.setState({ open: true });
      const patient = await API.graphql(
        graphqlOperation(_api.getSock(), { id, userid })
      );
      console.log(patient);
      if (patient) {
        patient.data.getPatient.socks = patient.data.getPatient.socks.sort(
          compareRegistered
        );
        self.setState({
          open: false,
          sirenId: patient.data.getPatient.sirenId,
          timeZone: patient.data.getPatient.userTimezone,
          sockStatus: patient.data.getPatient
        });
        if (
          patient.data.getPatient.hubs &&
          patient.data.getPatient.hubs.length > 0
        ) {
          for (const item of patient.data.getPatient.hubs) {
            if (item.hubId.startsWith("H4G")) {
              const obj = {};
              const simInfo = await getSimInfo(item.ccid);
              let str = simInfo.data.getSimInfo;
              if (!_.isEmpty(str)) {
                str = str.substr(1);
                str = str.substr(0, str.length - 1);
                str.split(",").map(p => {
                  const [key, value] = p.split("=");
                  Object.assign(
                    obj,
                    JSON.parse(`{"${key.replace(/\s+/g, "")}":"${value}"}`)
                  );
                });
                // console.log("simInfo: ", simInfo.data.getSimInfo);
                // console.log(obj);
                item.sid = obj.sid;
                item.status = obj.status;
              }
            }
          }
        }
        if (
          patient.data.getPatient.hasOwnProperty("appInfo") &&
          patient.data.getPatient.appInfo.length > 0
        ) {
          patient.data.getPatient.appInfo = patient.data.getPatient.appInfo.sort(
            function (a, b) {
              return new moment(b.createdAt) - new moment(a.createdAt);
            }
          );
          dispatch(changeSockStatus(patient.data.getPatient));
          self.setState({
            sockStatus: patient.data.getPatient
          });
        } else {
          dispatch(changeSockStatus(patient.data.getPatient));
          self.setState({
            sockStatus: patient.data.getPatient
          });
        }
      }
    } catch (err) {
      self.setState({ open: false });
      console.log("getSockStatus error: ", err);
    }
  };
}

export function clearSockStatus() {
  return dispatch => {
    dispatch(changeSockStatus({}));
  };
}

function changeSockStatus(sockStatus) {
  return {
    type: TYPES.SOCK_STATUS,
    text: sockStatus
  };
}

export function getSockHomeStatus(sirenId, self) {
  return async dispatch => {
    try {
      self.setState({ open: true });
      const patient = await API.graphql(
        graphqlOperation(_api.getSockBySirenId(), { sirenId })
      );
      if (patient) {
        patient.data.getPatientBySirenId.socks = patient.data.getPatientBySirenId.socks.sort(
          compareRegistered
        );
        self.setState({
          open: false,
          sockHomeStatus: patient.data.getPatientBySirenId
        });
        if (
          patient.data.getPatientBySirenId.hubs &&
          patient.data.getPatientBySirenId.hubs.length > 0
        ) {
          for (const item of patient.data.getPatientBySirenId.hubs) {
            if (item.hubId.startsWith("H4G")) {
              const obj = {};
              const simInfo = await getSimInfo(item.ccid);
              let str = simInfo.data.getSimInfo;
              if (!_.isEmpty(str)) {
                str = str.substr(1);
                str = str.substr(0, str.length - 1);
                str.split(",").map(p => {
                  const [key, value] = p.split("=");
                  Object.assign(
                    obj,
                    JSON.parse(`{"${key.replace(/\s+/g, "")}":"${value}"}`)
                  );
                });
                // console.log("simInfo: ", simInfo.data.getSimInfo);
                console.log(obj);
                item.sid = obj.sid;
                item.status = obj.status;
                item.fleetSid = obj.fleet_sid;
              }
            }
          }
        }
        if (
          patient.data.getPatientBySirenId.hasOwnProperty("appInfo") &&
          patient.data.getPatientBySirenId.appInfo.length > 0
        ) {
          patient.data.getPatientBySirenId.appInfo = patient.data.getPatientBySirenId.appInfo.sort(
            function (a, b) {
              return new moment(b.createdAt) - new moment(a.createdAt);
            }
          );
          dispatch(changeSockHomeStatus(patient.data.getPatientBySirenId));
          self.setState({
            sockHomeStatus: patient.data.getPatientBySirenId
          });
        } else {
          dispatch(changeSockHomeStatus(patient.data.getPatientBySirenId));
          self.setState({
            sockHomeStatus: patient.data.getPatientBySirenId
          });
        }
      }
    } catch (err) {
      self.setState({ open: false });
      console.log("getSockHomeStatus error: ", err);
    }
  };
}
function changeSockHomeStatus(sockHomeStatus) {
  return {
    type: TYPES.SOCK_HOME_STATUS,
    text: sockHomeStatus
  };
}

export function comFootHealth(footHealth, dispatch) {
  var pastSymp = [];
  var past1Symp = [];
  var currentSymp = [];
  var footSymptoms = [];
  var foot1Symptoms = [];
  var foot2Symptoms = [];
  var foot3Symptoms = [];
  var maxLength = 0;
  if (footHealth.hasOwnProperty("pastSymptoms")) {
    var pastSymptomsObj = JSON.parse(footHealth.pastSymptoms);
    pastSymp = pastSymptomsSelect(pastSymptomsObj);
  }
  if (footHealth.hasOwnProperty("past1yearSymptoms")) {
    var past1yearSymptomsObj = JSON.parse(footHealth.past1yearSymptoms);
    past1Symp = past1yearSymptomsSelect(past1yearSymptomsObj);
  }
  if (footHealth.hasOwnProperty("currentSymptoms")) {
    var currentSymptomsObj = JSON.parse(footHealth.currentSymptoms);
    currentSymp = currentSymptomsSelect(currentSymptomsObj);
  }
  if (pastSymp.length > maxLength) {
    maxLength = pastSymp.length;
  }
  if (past1Symp.length > maxLength) {
    maxLength = past1Symp.length;
  }
  if (currentSymp.length > maxLength) {
    maxLength = currentSymp.length;
  }
  for (var i = 0; i < maxLength; i++) {
    if (pastSymp.length > i) {
      foot1Symptoms.push({ pastSymptoms: pastSymp[i] });
    } else {
      foot1Symptoms.push({ pastSymptoms: "" });
    }
    if (past1Symp.length > i) {
      foot2Symptoms.push({ past1Symptoms: past1Symp[i] });
    } else {
      foot2Symptoms.push({ past1Symptoms: "" });
    }
    if (currentSymp.length > i) {
      foot3Symptoms.push({ currentSymp: currentSymp[i] });
    } else {
      foot3Symptoms.push({ currentSymp: "" });
    }
  }

  for (var k = 0; k < maxLength; k++) {
    let obj = {};
    obj.pastSymptoms = foot1Symptoms[k];
    obj.past1Symptoms = foot2Symptoms[k];
    obj.currentSymp = foot3Symptoms[k];
    footSymptoms.push(obj);
  }
  if (maxLength === 0) {
    if (footHealth !== null) {
      let obj = {};
      obj.pastSymptoms = {};
      obj.past1Symptoms = {};
      obj.currentSymp = {};
      footSymptoms.push(obj);
    }
  }
  dispatch(changePatientStatusDetail(footSymptoms));
}

function changePatientStatusDetail(footSymptoms) {
  return {
    type: TYPES.PATIENTS_STATUS_DETAIL,
    text: footSymptoms
  };
}

function pastSymptomsSelect(pastSymptoms) {
  var pastSymptomsArr = [];
  if (pastSymptoms != null) {
    if (pastSymptoms.hasOwnProperty("openWound")) {
      pastSymptomsArr.push("open wound");
    }
    if (pastSymptoms.hasOwnProperty("charcot")) {
      pastSymptomsArr.push("charcot");
    }
    if (pastSymptoms.hasOwnProperty("toeAmputation")) {
      pastSymptomsArr.push("toe amputation");
    }
    if (pastSymptoms.hasOwnProperty("partialAmputation")) {
      pastSymptomsArr.push("partial amputation");
    }
    if (pastSymptoms.hasOwnProperty("fullAmputation")) {
      pastSymptomsArr.push("full amputation");
    }
  }
  return pastSymptomsArr;
}

function past1yearSymptomsSelect(past1yearSymptoms) {
  var past1yearSymptomsArr = [];
  if (past1yearSymptoms != null) {
    if (past1yearSymptoms.hasOwnProperty("hammerToe")) {
      past1yearSymptomsArr.push("hammer toe");
    }
    if (past1yearSymptoms.hasOwnProperty("bunion")) {
      past1yearSymptomsArr.push("bunion");
    }
    if (past1yearSymptoms.hasOwnProperty("footOrToeFracture")) {
      past1yearSymptomsArr.push("foot or toe fracture");
    }
  }
  return past1yearSymptomsArr;
}

function currentSymptomsSelect(currentSymptoms) {
  var currentSymptomsArr = [];
  if (currentSymptoms != null) {
    if (currentSymptoms.hasOwnProperty("burn")) {
      currentSymptomsArr.push("burn");
    }
    if (currentSymptoms.hasOwnProperty("blister")) {
      currentSymptomsArr.push("blister");
    }
    if (currentSymptoms.hasOwnProperty("bugBite")) {
      currentSymptomsArr.push("bug bite");
    }
    if (currentSymptoms.hasOwnProperty("callous")) {
      currentSymptomsArr.push("callous");
    }
    if (currentSymptoms.hasOwnProperty("sprain/Strain")) {
      currentSymptomsArr.push("sprain/strain");
    }
  }
  return currentSymptomsArr;
}

export function convertAppointmentType(appointType) {
  if (appointType === "HOSPITAL_VISIT") {
    return "Hospital visit";
  }
  if (appointType === "FOLLOW_UP_APPOINTMENT") {
    return "Follow-up appointment";
  }
  if (appointType === "REGULAR_CHECK_UP") {
    return "Regular check-up";
  }
  if (appointType === "URGENT_CARE_VISIT") {
    return "Urgent care";
  }
  if (appointType === "OTHER") {
    return "Other - open field";
  }
}

export function changeCalendarData(id, userId, startDate, endDate) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.getCalendarData(), {
          id,
          userId,
          startDate,
          endDate,
          limit: 100
        })
      );
      if (newEvent) {
        dispatch(updateCalendarData(newEvent.data.getPatient));
      }
    } catch (err) {
      console.log("getCalendarData error: ", err);
    }
  };
}

function updateCalendarData(calendardata) {
  return {
    type: TYPES.CALENDAR_DATA,
    text: calendardata
  };
}

export function changeLoadingChartsState(value) {
  return {
    type: TYPES.LOADING_CHARTS,
    text: value
  };
}

export function updateFootStatus(id, date, status, self) {
  return async dispatch => {
    try {
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateFootStatuses(), {
          input: { id, date, status }
        })
      );
      if (newEvent) {
        dispatch(changeLoadingChartsState(false));
        changeCheckedMonthStatus(self, dispatch, date, status);
        self.setState({ open: false });
      }
    } catch (err) {
      dispatch(changeLoadingChartsState(false));
      console.log("updateFootStatus error: ", err);
    }
  };
}

function changeCheckedMonthStatus(self, dispatch, date, status) {
  let footStatusData = self.props.calendardata;
  let calendarData = _.clone(footStatusData);
  let statusDate = [];
  _.forEach(calendarData.footStatuses, function (item) {
    statusDate.push(item.date);
  });
  if (statusDate.includes(date)) {
    _.forEach(calendarData.footStatuses, function (itemStatus) {
      if (itemStatus.date === date) {
        itemStatus.overall = status;
      }
    });
  } else {
    calendarData.footStatuses.push({
      date,
      overall: status,
      reviewStatus: null
    });
  }
  dispatch(updateCalendarData(calendarData));
}

export function getPhotoReviews(inputData, self) {
  return async dispatch => {
    try {
      const photoReview = await API.graphql(
        graphqlOperation(_api.getPhotoReview(), inputData)
      );
      if (photoReview) {
        if (photoReview.data.getPatient.hasOwnProperty("photoTask")) {
          self.setState({
            open: true,
            imageUrls: photoReview.data.getPatient.photoTask.imageUrls,
            taskTime: photoReview.data.getPatient.photoTask.taskTime
          });
        }
      }
    } catch (err) {
      console.log("getPhotoReview error: ", err);
    }
  };
}

export function updatePhotoState(input, self) {
  return async dispatch => {
    try {
      const updatePhotoState = await API.graphql(
        graphqlOperation(_api.updatePhotoStatus(), { input })
      );
      if (updatePhotoState) {
        self.setState({
          open: false,
          photoReviewStatus: self.state.retake ? "RETAKE" : "RESOLVED"
        });
      }
    } catch (err) {
      console.log("updatePhotoState error: ", err);
    }
  };
}

export function getImageFromS3(self, userId) {
  return dispatch => {
    let yDate = new Date();
    yDate.setDate(yDate.getDate() - 1);
    Storage.list("photoFoot/" + moment().format("YYYY-MM-DD"), {
      level: "private",
      identityId: userId
    }).then(res => {
      if (res.length === 0) {
        getYesterdayPhoto(yDate, userId, self);
      } else {
        res.map(item => {
          if (item.key.indexOf("left") >= 0) {
            return getLeftPhoto(item.key, self, userId, new Date());
          } else {
            return getRightPhoto(item.key, self, userId, new Date());
          }
        });
      }
    });
  };
}

function getYesterdayPhoto(dateTime, userId, self) {
  Storage.list("photoFoot/" + moment(dateTime).format("YYYY-MM-DD"), {
    level: "private",
    identityId: userId
  }).then(res => {
    if (res.length > 0) {
      res.map(item => {
        if (item.key.indexOf("left") >= 0) {
          return getLeftPhoto(item.key, self, userId, dateTime);
        } else {
          return getRightPhoto(item.key, self, userId, dateTime);
        }
      });
    }
  });
}

function getLeftPhoto(key, self, userId, dateTime) {
  Storage.get(key, {
    level: "private",
    identityId: userId
  })
    .then(res => {
      self.setState({ leftImageUrl: res });
    })
    .catch(err => {
      console.log("errLeft: ", err);
    });
}

function getRightPhoto(key, self, userId, dateTime) {
  Storage.get(key, {
    level: "private",
    identityId: userId
  }).then(res => {
    self.setState({ rightImageUrl: res });
  });
}

export function changelistClinicSites(clinicSites) {
  return {
    type: TYPES.CLINIC_SITES,
    text: clinicSites
  };
}

export function fetchCalendarDate(changeFootDate) {
  return async dispatch => {
    try {
      if (changeFootDate) {
        dispatch(updatechangeFootDate(changeFootDate));
      }
    } catch (err) {
      console.log("getCalendarData error: ", err);
    }
  };
}

function updatechangeFootDate(changeFootDate) {
  return {
    type: TYPES.FOOT_DATA,
    text: changeFootDate
  };
}

export async function getMinuteWorn(id, userid, startDate, endDate) {
  const result = await API.graphql(
    graphqlOperation(_api.PatientMinuteWorn(), {
      id,
      userid,
      startDate,
      endDate
    })
  );

  return result.data.getPatient.timeWornByDay;
}

export function resetNewPatientPwd(newPwdInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.resetPatientPassword(), newPwdInput)
      );
      if (newEvent.data.resetPatientPassword.status) {
        self.setState({ newPwd: "", open: false });
      } else {
        message.info(newEvent.data.resetPatientPassword.message);
      }
    } catch (err) {
      console.log("resetNewPatientPwd error: ", err);
    }
  };
}

export function getListInvoicePatients(billingInputValue, self) {
  return async dispatch => {
    try {
      self.setState({ modalIsOpen: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.listInvoicePatients(), billingInputValue)
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        dispatch(
          changeListInvoicePatient(
            _(newEvent.data.listInvoicePatient.items)
              .sortBy(p => p.month + String(p.siteId) + String(p.sirenId))
              .value()
          )
        );
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("getListInvoicePatients error: ", e);
    }
  };
}

function changeListInvoicePatient(listInvoicePatient) {
  return {
    type: TYPES.INVOICE_PATIENT,
    text: listInvoicePatient
  };
}

export function changeDMTModalStatus(status) {
  return {
    type: TYPES.DMT_MODAL_STATUS,
    status
  };
}

export async function deleteFootPhoto(userId, imageUrl) {
  try {
    const key = `photoFoot/${imageUrl
      .split("?")[0]
      .split("photoFoot/")
      .pop()}`;
    await Storage.remove(key, {
      level: "private",
      identityId: userId
    });
    console.log("deleteFootPhoto", key);
    return true;
  } catch (error) {
    console.error("Delete photo failed:", error);
    throw error;
  }
}
