// eslint-disable
// this is an auto generated file. This will be overwritten

export const createPatient = `mutation CreatePatient($input: CreatePatientInput!) {
  createPatient(input: $input) {
    id
    sirenId
    email
    phone
    fullName
    footStatus
    shippingAddr
    medicalHistory
    wearingTime
    steps
    avgTemp
    alerts
    doctorReviewRequest
    doctorReviewComment
  }
}
`;
export const updatePatient = `mutation UpdatePatient($input: UpdatePatientInput!) {
  updatePatient(input: $input) {
    id
    sirenId
    email
    phone
    fullName
    footStatus
    shippingAddr
    medicalHistory
    wearingTime
    steps
    avgTemp
    alerts
    doctorReviewRequest
    doctorReviewComment
  }
}
`;
export const updateNewRegisterPatient = `mutation UpdateNewPatient($input: UpdateNewPatientInput!) {
  updateNewPatient(input: $input)
}
`;
export const deletePatient = `mutation DeletePatient($input: DeletePatientInput!) {
  deletePatient(input: $input) {
    id
    sirenId
    email
    phone
    fullName
    footStatus
    shippingAddr
    medicalHistory
    wearingTime
    steps
    avgTemp
    alerts
    doctorReviewRequest
    doctorReviewComment
  }
}
`;
export const updateSockStatus = `mutation updateSockStatus($input:UpdateSockStatusInput!) {
  updateSockStatus(input: $input) {
    userId
    status
    serialNo
    sensorStatus
    statusDetails
  }
}
`;

export const updateSensorStatus = `mutation updateSensorStatus($input:UpdateSensorStatusInput!) {
  updateSensorStatus(input: $input) {
    userId
    status
    serialNo
    sensorStatus
    statusDetails
  }
}
`;

export const updateSockDFU = `mutation updateSockDFU($input:SockDFU){
  updateSockDFU(input: $input)
}`;

export const setUserGroup = `mutation setUserGroup($input:SetUserGroupInput!) {
  setUserGroup(input: $input)
}
`;
export const sendNotification = `mutation sendNotification($input: SendNotificationInput!) {
  sendNotification(input:$input)
}
`;
export const updateTrialPatient = `mutation updateTrialPatient($input: UpdateTrialPatientInput!) {
  updateTrialPatient(input:$input)
}
`;

export const createSirenNote = `mutation createSirenNote($input: CreateSirenNoteInput!){
  createSirenNote(input: $input){
     id
     timestamp
     author
     note
     title
     pinned
  }
}`;
export const updateSirenNote = `mutation updateSirenNote($input: UpdateSirenNoteInput!){
  updateSirenNote(input: $input){
     id
     timestamp
     author
     note
     title
     pinned
  }
}`;
export const deleteSirenNote = `mutation deleteSirenNote($input: DeleteSirenNoteInput!){
  deleteSirenNote(input: $input){
     id
     timestamp
  }
}`;

export const setComplianceReviewed = `mutation setComplianceReviewed($input:SetCompliacneReviewedInput!) {
  setComplianceReviewed(input: $input) {
    complianceReviewed
  }
}
`;
export const setComplianceReviewReq = `mutation setComplianceReviewReq($input:SetCompliacneReviewReqInput!) {
  setComplianceReviewReq(input: $input) {
    complianceReviewReq
    userId
  }
}
`;
export const registerAccount = `mutation createPatient($input: CreatePatientInput!) {
  createPatient(input: $input) {
    status
    message
  }
}
`;

export const registerDoctorAccount = `mutation createDoctor($input: CreateDoctorInput!) {
  createDoctor(input: $input) {
    status
    message
  }
}`;
export const registerAdminAccount = `mutation createAdminDoctor($input: CreateAdminDoctorInput!) {
  createAdminDoctor(input: $input) {
    status
    message
  }
}`;
export const updateDoctor = `mutation updateDoctor($input: UpdateDoctorInput!) {
  updateDoctor(input: $input) {
    status
    message
  }
}`;
export const createTrialDoctor = `mutation createTrialDoctor($input: CreateDoctorInput!) {
  createTrialDoctor(input: $input) {
    status
    message
  }
}`;
export const sendTestTask = `mutation sendTestTask($input:TestTaskInput!) {
  sendTestTask(input: $input)
}`;

export const sendSimulateData = `mutation sendSimulateData($input:SimulateDataInput!) {
  sendSimulateData(input: $input)
}`;

export const createClinicSiteAccount = `mutation createClinicSite($input: CreateClinicSiteInput!) {
  createClinicSite(input: $input) {
    siteId
    name
    address
    phone
    fax
    contact
    serviceDate
    autoDoctorReport
    city
    zip
    state
    siteNPI
    IRBConsent
    EMRSystem
    onStaffing
  }
}`;
export const updateClinicSite = `mutation updateClinicSite($input: UpdateClinicSiteInput!) {
  updateClinicSite(input: $input) {
    siteId
  }
}`;
export const createTrialSiteAccount = `mutation createTrialClinicSite($input: CreateClinicSiteInput!) {
  createTrialClinicSite(input: $input) {
    siteId
    name
    abbr
  }
}`;
export const deleteClinicSite = `mutation deleteClinicSite($input: DeleteClinicSiteInput!) {
  deleteClinicSite(input: $input) {
    siteId
  }
}`;
export const deleteTrialSite = `mutation deleteTrialClinicSite($input:DeleteClinicSiteInput!){
  deleteTrialClinicSite(input: $input){
    siteId
  }
}`;
export const deleteDoctor = `mutation deleteDoctor($input: DeleteDoctorInput!) {
  deleteDoctor(input: $input) {
   status
   message
  }
}
`;
export const deleteRegisteredPatient = `mutation deleteRegisteredPatient($input:DeleteRegisteredPatientInput!){
  deleteRegisteredPatient(input: $input){
    status
    message
  }
}
`;
export const deleteTrialDoctor = `mutation deleteTrialDoctor($input:DeleteDoctorInput!){
  deleteTrialDoctor(input: $input){
    status
    message
  }
}
`;
export const sendAppLink = `mutation sendAppLink($input: SendAppLinkInput!) {
  sendAppLink(input: $input)
}
`;
export const updateServiceStatus = `mutation updateAccountServiceStatus($input:UpdateAccountServiceStatusInput!) {
  updateAccountServiceStatus(input: $input)
}
`;
export const updateAccountBillingStatus = `mutation updateAccountBillingStatus($input:UpdateAccountBillingStatusInput!) {
  updateAccountBillingStatus(input: $input)
}
`;
export const updateSpecialCaseStatus = `mutation updateSpecialCaseStatus($input:UpdateSpecialCaseStatusInput!) {
  updateSpecialCaseStatus(input: $input)
}`;
export const updateAccountStatus = `mutation updateAccountStatus($input:UpdateAccountStatusInput!) {
  updateAccountStatus(input: $input)
}
`;
export const updateDeviceStatus = `mutation updateDeviceStatus($input:UpdateDeviceStatusInput!) {
  updateDeviceStatus(input: $input)
}
`;
export const sendDoctorReport = `mutation sendDoctorReport($input: SendReportInput!) {
  sendDoctorReport(input: $input)
}
`;
export const resetAdminPassword = `mutation resetAdminPassword($input: ResetPasswordInput!) {
  resetAdminPassword(input: $input) {
    status
    message
  }
}`;
export const resetStaffPassword = `mutation resetStaffPassword($input: ResetPasswordInput!) {
  resetStaffPassword(input: $input) {
    status
    message
  }
}`;
export const resetPatientPassword = `mutation resetPatientPassword($input: ResetPasswordInput!) {
  resetPatientPassword(input: $input) {
    status
    message
  }
}`;
export const updateBilledPatient = `mutation updateBilledPatient($input: UpdateBilledPatientInput!) {
  updateBilledPatient(input: $input)
}`;
export const UpdateNewPatient = `mutation UpdateNewPatient($input: UpdateNewPatientInput!) {
  updateNewPatient(input: $input)
}`;
export const updateAutoDoctorReport = `mutation updateAutoDoctorReport($input: UpdateAutoDoctorReportInput!){
  updateAutoDoctorReport(input: $input){
    status
    message
  }
}`;
export const updateBillingStatus = `mutation updateBilledStatus($input: UpdateBilledStatusInput!) {
  updateBilledStatus(input: $input)
}`;

export const updateAutoDoctorReportItem = `mutation updateAutoDoctorReportItem($input: UpdateAutoDoctorReportItemInput!){
  updateAutoDoctorReportItem(input: $input){
    status
    message
  }
}`;

export const createRpmTracker = `mutation createRpmTracker($input: CreateRpmTrackerInput!){
  createRpmTracker(input: $input){
    status
     message
  }
}`;

export const updateRpmTracker = `mutation updateRpmTracker($input: UpdateRpmTrackerInput!){
  updateRpmTracker(input: $input){
     status
     message
  }
}`;

export const updateAddendum = `mutation updateAddendum($input: UpdateRpmTrackerInput!){
  updateAddendum(input: $input){
     status
     message
  }
}`;

export const createRpmNoteTemplate = `mutation createRpmNoteTemplate($input: CreateRpmNoteTemplateInput!){
  createRpmNoteTemplate(input: $input)
}`;

export const updateRpmNoteTemplate = `mutation updateRpmNoteTemplate($input: UpdateRpmNoteTemplateInput!){
  updateRpmNoteTemplate(input: $input)
}`;

export const deleteRpmNoteTemplate = `mutation deleteRpmNoteTemplate($id: String!){
  deleteRpmNoteTemplate(id: $id) {
    id
  }
}`;

export const remoteConnect = `mutation remoteConnect($input: RemoteConnectInput){
  remoteConnect(input: $input)
}`;

export const generateBillingReport = `mutation generateBillingReport($input: GenerateBillingReportInput!) {
  generateBillingReport(input: $input)
}`;

export const generateVaZipReport = `mutation generateVaZipReport($input: GenerateBillingReportInput!) {
  generateVaZipReport(input: $input)
}`;
export const generateMonthlySummary = `mutation generateMonthlySummary($input: GenerateMonthlySummary!) {
  generateMonthlySummary(input: $input)
}`;
export const generateMonthlySiteSummary = `mutation generateMonthlySiteSummary($input: GenerateMonthlySiteSummary!) {
  generateMonthlySiteSummary(input: $input)
}`;
export const updateBilled99457Status = `mutation updateBilled99457Status($input: UpdateBilledStatusInput!) {
  updateBilled99457Status(input: $input)
}
`;
export const updateBilled99454Status = `mutation updateBilled99454Status($input: UpdateBilledStatusInput!) {
  updateBilled99454Status(input: $input)
}`;
export const updateBilled99453Status = `mutation updateBilled99453Status($input: UpdateBilled99453StatusInput!) {
  updateBilled99453Status(input: $input)
}`;
export const deleteRpmTracker = `mutation deleteRpmTracker($input:DeleteRpmTrackerInput!){
  deleteRpmTracker(input: $input){
    status
    message
  }
}
`;
export const upgradeApp = `mutation upgradeApp($input: UpgradeAppInput){
  upgradeApp(input: $input)
}`;
export const updateAdminStaff = `mutation updateAdminStaff($input: UpdateAdminStaffInput!) {
  updateAdminStaff(input: $input) {
    status
    message
  }
}`;
export const updateAdminStaffFilter = `mutation updateAdminStaffFilter($input: UpdateAdminStaffInput!) {
  updateAdminStaffFilter(input: $input) {
    status
    message
  }
}`;
export const deleteAdminStaff = `mutation deleteAdminStaff($input: DeleteDoctorInput!) {
  deleteAdminStaff(input: $input) {
   status
   message
  }
}
`;

export const updatePatientNotification = `mutation updatePatientNotification($input: UpdatePatientNotificationInput!) {
  updatePatientNotification(input: $input)
}`;

export const setAppointmented = `mutation setAppointmented($input: SetAppointmentedInput!) {
  setAppointmented(input: $input) {
    userId
  }
}`;

export const dismissReviewTask = `mutation dismissReviewTask($input: DismissReviewTaskInput!) {
  dismissReviewTask(input: $input) {
    userId
  }
}`;

export const updateLpnForPatients = `mutation \($input: UpdateLpnForPatientInput!) {
  updateLpnForPatient(input: $input)
}`;

export const assignLpnForPatients = `mutation assignLpnForPatient($input: AssignLpnForPatientInput!) {
  assignLpnForPatient(input: $input)
}`;

export const updateProgramStatus = `mutation updateProgramStatus($input: UpdateProgramStatusInput!) {
  updateProgramStatus(input: $input){
    status
    message
  }
}`;

export const removeLpnForPatients = `mutation removeLpnForPatient($input: RemoveLpnForPatientInput!) {
  removeLpnForPatient(input: $input)
}`;

export const createEMR = `mutation createEMR($input: CreateEMRInput!){
  createEMR(input: $input){
     name
     createdAt
     updatedAt
  }
}`;

export const deleteEMR = `mutation deleteEMR($input: DeleteEMRInput!){
  deleteEMR(input: $input){
     name
     createdAt
     updatedAt
  }
}`;

export const createFootSymptom = `mutation createFootSymptom($input: CreateFootSymptomInput!){
  createFootSymptom(input: $input){
     userId
     reportTime
     footSymptom
     foot
     footArea
  }
}`;

export const createFootCondition = `mutation createFootCondition($input: CreateFootConditionInput!){
  createFootCondition(input: $input){
     userId
     reportTime
     footCondition
     foot
     footArea
     startTime
     endTime
     followUpTime
     source
  }
}`;

export const updateFootCondition = `mutation updateFootCondition($input: UpdateFootConditionInput!){
  updateFootCondition(input: $input){
     status
     message
  }
}`;

export const createFootTreatment = `mutation createFootTreatment($input: CreateFootTreatmentInput!){
  createFootTreatment(input: $input){
     userId
     reportTime
     footTreatment
     foot
     startTime
     endTime
     source
  }
}`;

export const updateFootTreatment = `mutation updateFootTreatment($input: UpdateFootTreatmentInput!){
  updateFootTreatment(input: $input){
     status
     message
  }
}`;

export const updateHubStatus = `mutation updateHubStatus($input: UpdateHubStatusInput!){
  updateHubStatus(input: $input){
     hubId
     canRegistered
     canRegisteredReason
  }
}`;

export const updateBoxStatus = `mutation updateBoxStatus($input: UpdateBoxStatusInput!){
  updateBoxStatus(input: $input){
     id
     canRegistered
     canRegisteredReason
  }
}`;

export const updateTagSource = `mutation updateTagSource($input: UpdateTagSourceInput!){
  updateTagSource(input: $input){
    status
    message
  }
}`;

export const updateSockBox = `mutation updateSockBox($input: UpdateSockBoxInput!){
  updateSockBox(input: $input){
     status
     message
  }
}`;

export const createShoeTag = `mutation createShoeTag($input: CreateShoeTagInput!) {
  createShoeTag(input: $input) {
    status
    message
  }
}`;

export const createPillBottle = `mutation createPillBottle($input: CreatePillBottleInput!) {
  createPillBottle(input: $input) {
    status
    message
  }
}`;

export const markSockData = `mutation markSockData($input: MarkSockDataInput!) {
  markSockData(input: $input)
}`;

export const createPrescription = `mutation createPrescription($input: PrescriptionInput!) {
  createPrescription(input: $input) {
    userId
    createdBy
  }
}`;

export const updatePatientSingleFoot = `mutation updatePatientSingleFoot($id: String!, $singleFoot: String!) {
  updatePatientSingleFoot(id: $id, singleFoot: $singleFoot) {
    userId
  }
}`;

export const createCarePlan = `mutation createCarePlan($input: CarePlanInput!){
  createCarePlan(input: $input){
    createdAt
    userId
    name
    author
    authorId
    location
    month
  }
}`;

export const deleteCarePlan = `mutation deleteCarePlan($input: CarePlanInput!){
  deleteCarePlan(input: $input){
    createdAt
    userId
    name
    author
    authorId
    location
    month
  }
}`;

export const createICD10Code = `mutation createICD10Code($code: String!){
  createICD10Code(code: $code){
    code
  }
}`;

export const deleteICD10Code = `mutation deleteICD10Code($code: String!){
  deleteICD10Code(code: $code){
    code
  }
}`;

export const createMedicalCondition = `mutation createMedicalCondition($condition: String!, $value: String, $chronicType: String){
  createMedicalCondition(condition: $condition, value: $value, chronicType: $chronicType){
    condition
  }
}`;

export const editMedicalCondition = `mutation editMedicalCondition($condition: String!, $value: String, $chronicType: String){
  editMedicalCondition(condition: $condition, value: $value, chronicType: $chronicType){
    condition
  }
}`;

export const deleteMedicalCondition = `mutation deleteMedicalCondition($condition: String!){
  deleteMedicalCondition(condition: $condition){
    condition
  }
}`;

export const updateBilledPCMStatus = `mutation updateBilledPCMStatus($input: UpdateBilledStatusInput!) {
  updateBilledPCMStatus(input: $input)
}
`;
export const createDoctorReviewHistory = `mutation createDoctorReviewHistory($input: DoctorReviewHistoryInput!){
  createDoctorReviewHistory(input: $input){
    userId
  }
}`;

export const createPupPatient = `mutation createPupPatient($input: CreatePatientInput!) {
  createPupPatient(input: $input) {
    status
    message
  }
}
`;

export const activeSimInfo = `mutation activeSim($sid: String!){
    activeSim(sid: $sid)
}`;

export const updateFleet = `mutation updateFleet($sid: String!, $fleetSid: String!){
    updateFleet(sid: $sid, fleetSid: $fleetSid)
}`;

export const deactiveSimInfo = `mutation deactiveSim($sid: String!){
    deactiveSim(sid: $sid)
}`;

export const uploadPdf = `mutation uploadPdf($fileName: String!){
  uploadPdf(fileName: $fileName) {
    firstname
    lastname
    address
    addressline2
    city
    state
    zip
    phone
    purchaseNo
    facilityName
    shoeSize
    color
  }
}
`;
