import React from "react";
import "./monthly-calendar.css";
import moment from "moment";

class MonthCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear:
        props.value && props.value[0] ? props.value[0].year() : moment().year(),
      selectedMonths: props.value || [],
      isOpen: false,
      isDragging: false,
      dragStartMonth: null
    };
    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mouseup", this.handleMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mouseup", this.handleMouseUp);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        selectedMonths: this.props.value || [],
        currentYear:
          this.props.value && this.props.value[0]
            ? this.props.value[0].year()
            : this.state.currentYear
      });
    }
  }

  handleClickOutside = event => {
    if (
      this.calendarRef.current &&
      !this.calendarRef.current.contains(event.target)
    ) {
      this.setState({ isOpen: false });
    }
  };

  toggleCalendar = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  months = [
    [
      { name: "Jan", value: 0 },
      { name: "Feb", value: 1 },
      { name: "Mar", value: 2 }
    ],
    [
      { name: "Apr", value: 3 },
      { name: "May", value: 4 },
      { name: "Jun", value: 5 }
    ],
    [
      { name: "Jul", value: 6 },
      { name: "Aug", value: 7 },
      { name: "Sep", value: 8 }
    ],
    [
      { name: "Oct", value: 9 },
      { name: "Nov", value: 10 },
      { name: "Dec", value: 11 }
    ]
  ];

  handleYearChange = delta => {
    this.setState(prev => ({
      currentYear: prev.currentYear + delta
    }));
  };

  handleMonthClick = monthValue => {
    const clickedDate = moment()
      .year(this.state.currentYear)
      .month(monthValue);
    let newSelectedMonths = [];

    switch (this.props.selectionMode) {
      case "multiRow":
        if (
          this.state.selectedMonths.length === 0 ||
          this.state.selectedMonths.length === 2
        ) {
          newSelectedMonths = [clickedDate];
        } else {
          const startMonth = this.state.selectedMonths[0];
          const endMonth = clickedDate;
          if (endMonth.isBefore(startMonth)) {
            newSelectedMonths = [endMonth, startMonth];
          } else {
            newSelectedMonths = [startMonth, endMonth];
          }
          this.setState({ isOpen: false });
        }
        break;

      case "singleRow":
        if (
          this.state.selectedMonths.length === 0 ||
          this.state.selectedMonths.length === 2
        ) {
          newSelectedMonths = [clickedDate];
        } else {
          const startMonth = this.state.selectedMonths[0];
          const endMonth = clickedDate;
          if (
            Math.floor(startMonth.month() / 3) === Math.floor(monthValue / 3)
          ) {
            if (endMonth.isBefore(startMonth)) {
              newSelectedMonths = [endMonth, startMonth];
            } else {
              newSelectedMonths = [startMonth, endMonth];
            }
          } else {
            newSelectedMonths = [clickedDate];
          }
        }
        break;

      case "single":
        newSelectedMonths = [clickedDate];
        this.setState({ isOpen: false });
        break;

      default:
        newSelectedMonths = [clickedDate];
        this.setState({ isOpen: false });
    }

    this.setState({ selectedMonths: newSelectedMonths });
    this.props.onChange && this.props.onChange(newSelectedMonths);
  };

  isSelected = monthValue => {
    return this.state.selectedMonths.some((date, index) => {
      const isEndpoint =
        index === 0 || index === this.state.selectedMonths.length - 1;
      return (
        date &&
        date.year() === this.state.currentYear &&
        date.month() === monthValue &&
        isEndpoint
      );
    });
  };

  isRangeEndpoint = monthValue => {
    const { selectedMonths } = this.state;
    if (selectedMonths.length === 1) {
      const monthDate = moment()
        .year(this.state.currentYear)
        .month(monthValue);
      return {
        isStart: monthDate.isSame(selectedMonths[0], "month"),
        isEnd: monthDate.isSame(selectedMonths[0], "month"),
        isSingle: true
      };
    }
    if (selectedMonths.length !== 2) return false;

    const monthDate = moment()
      .year(this.state.currentYear)
      .month(monthValue);
    const [start, end] = selectedMonths;

    const isSameMonth = start.isSame(end, "month");
    return {
      isStart: monthDate.isSame(start, "month"),
      isEnd: monthDate.isSame(end, "month"),
      isSingle: false,
      isSameMonth
    };
  };

  isInRange = monthValue => {
    const { selectedMonths } = this.state;
    if (selectedMonths.length === 1) {
      const monthDate = moment()
        .year(this.state.currentYear)
        .month(monthValue);
      return monthDate.isSame(selectedMonths[0], "month");
    }
    if (selectedMonths.length !== 2) return false;

    const monthDate = moment()
      .year(this.state.currentYear)
      .month(monthValue);
    const [start, end] = selectedMonths;
    return (
      monthDate.isSameOrAfter(start, "month") &&
      monthDate.isSameOrBefore(end, "month")
    );
  };

  isInSameRow = monthValue => {
    if (this.state.selectedMonths.length === 0) return false;
    return (
      Math.floor(this.state.selectedMonths[0].month() / 3) ===
      Math.floor(monthValue / 3)
    );
  };

  formatDisplayValue = () => {
    if (this.state.selectedMonths.length === 0) {
      return this.props.placeholder || "Please select month range";
    }
    return this.state.selectedMonths
      .map(date => date.format("MMM YYYY"))
      .join(" ~ ");
  };

  handleMouseDown = monthValue => {
    if (this.props.selectionMode === "multiRow") {
      this.setState({
        isDragging: true,
        dragStartMonth: monthValue
      });
    }
  };

  handleMouseMove = monthValue => {
    if (this.state.isDragging && this.state.dragStartMonth !== null) {
      const startDate = moment()
        .year(this.state.currentYear)
        .month(this.state.dragStartMonth);
      const currentDate = moment()
        .year(this.state.currentYear)
        .month(monthValue);

      let newSelectedMonths;
      if (currentDate.isBefore(startDate)) {
        newSelectedMonths = [currentDate, startDate];
      } else {
        newSelectedMonths = [startDate, currentDate];
      }

      this.setState({ selectedMonths: newSelectedMonths });
    }
  };

  handleMouseUp = () => {
    if (this.state.isDragging) {
      this.setState({
        isDragging: false,
        dragStartMonth: null
      });
      if (this.state.selectedMonths.length === 2) {
        this.props.onChange && this.props.onChange(this.state.selectedMonths);
      }
    }
  };

  handleClick = monthValue => {
    if (!this.state.isDragging) {
      this.handleMonthClick(monthValue);
    }
  };

  render() {
    return (
      <div className="month-picker-container" ref={this.calendarRef}>
        <div className="month-picker-input" onClick={this.toggleCalendar}>
          {this.formatDisplayValue()}
        </div>
        {this.state.isOpen && (
          <div className="month-calendar">
            <div className="calendar-header">
              <button onClick={() => this.handleYearChange(-1)}>&lt;</button>
              <span>{this.state.currentYear}</span>
              <button onClick={() => this.handleYearChange(1)}>&gt;</button>
            </div>
            <div className="calendar-body">
              {this.months.map((row, rowIndex) => (
                <div key={rowIndex} className="month-row">
                  {row.map(month => {
                    const isHighlighted =
                      this.props.selectionMode === "singleRow" &&
                      this.isInSameRow(month.value);
                    const endpoint = this.isRangeEndpoint(month.value);
                    const endpointClass = endpoint.isStart
                      ? endpoint.isSameMonth
                        ? "start-endpoint same-month"
                        : "start-endpoint"
                      : endpoint.isEnd
                      ? "end-endpoint"
                      : "";
                    return (
                      <div
                        key={month.name}
                        className={`month-cell 
                          ${this.isSelected(month.value) ? "selected" : ""} 
                          ${this.isInRange(month.value) ? "in-range" : ""} 
                          ${
                            endpoint.isStart || endpoint.isEnd
                              ? "range-endpoint"
                              : ""
                          }
                          ${endpoint.isSingle ? "single-endpoint" : ""}
                          ${endpointClass}
                          ${isHighlighted ? "highlighted" : ""}`}
                        onMouseDown={() => this.handleMouseDown(month.value)}
                        onMouseMove={() => this.handleMouseMove(month.value)}
                        onMouseUp={this.handleMouseUp}
                        onClick={() => this.handleClick(month.value)}
                      >
                        {month.name}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

MonthCalendar.defaultProps = {
  selectionMode: "default",
  placeholder: "Please select month range"
};

export default MonthCalendar;
