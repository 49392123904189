import { QuestionCircleFilled } from "@ant-design/icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Checkbox, Modal as AntdModal, Select } from "antd";
import "antd/dist/antd.css";
import { Auth } from "aws-amplify";
import _ from "lodash";
import moment from "moment-timezone";
import qs from "qs";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Button, Popup } from "semantic-ui-react";
import {
  getAllListClinicSites,
  getlistClinicSites,
  getlistSummaryStaff
} from "../action/registerAction";
import aws_exports from "../aws-exports-siren";
import DateInput from "../common/DateInput";
import {
  getManagementSummaryStat,
  listManagementSummaryPatients
} from "../graphql/API";
import "../share/common.css";
import message from "../tools/message-info";
import { formatNumber } from "../tools/phoneFormat";
import getPatchTrProps, {
  DateFormat,
  InputEnterKeyTriggerFilterComponent,
  transformPatientTableParams
} from "../tools/reactTablePatch";
import "./biMonthly.css";
import "./selectComponent.css";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { removeAllSpace } from "../tools/stringProcess";

const { Option } = Select;

const OVERALL_PATIENTS = require("../img/Patients.png");
const NEW_PATIENTS = require("../img/New_Patients.png");
const ACCOUNT_ACTIVATED = require("../img/Account_Activated.png");
const SOCK_DISPENSED = require("../img/Socks_Dispensed.png");
const REGISTERED = require("../img/Registered.png");
const OPEN_NEW_WINDOW = require("../img/open_new_window.png");

class ManagementSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billingYear: new Date().getFullYear(),
      billingMonth: new Date().getMonth() + 1,
      reportPeriod: "allMonth",
      selectedSites: [],
      selectedDoctors: [],
      selectedPatients: [],
      selectFiltersValues: [
        "SiteID",
        "SirenID",
        "PatientID",
        "Doctor",
        "SiteName",
        "App Activated",
        "Registered Status",
        "Socks Dispensed Date",
        "First Name",
        "Last Name",
        "DOB",
        "Email",
        "Cell Phone",
        "Medical Status",
        "App Last Login",
        "Hub Type",
        "Hub Last Seen",
        "Hub Data Last Seen"
      ],
      ifOpen: false,
      startDate: "",
      EndDate: "",
      summaryStat: {},
      patientData: [],
      patientLoading: false,
      patientTotalSize: 0,
      rowClickedData: null
    };
    this.makeRequestInstant = true;
    this.tableInstance = null;
  }
  componentDidMount() {
    const {
      getlistSummaryStaff,
      getAllListClinicSites,
      getlistClinicSites
    } = this.props;
    getlistSummaryStaff();
    if (this.props.allClinicSites.length === 0) {
      getAllListClinicSites();
    }
    getlistClinicSites();
  }
  handleCheckAll = e => {
    const checked = e.target.checked;
    let selectedPatients = [];
    if (checked) {
      selectedPatients = this.state.patientData.map(p => p.sirenId);
    }
    this.setState({
      selectedPatients
    });
  };
  handleCheck = (e, data) => {
    let selectedPatients = [...this.state.selectedPatients];
    if (e.target.checked) {
      selectedPatients.push(data.row.sirenId);
    } else {
      selectedPatients = selectedPatients.filter(p => p !== data.row.sirenId);
    }

    this.setState({
      selectedPatients
    });
  };
  handleClear() {
    const { resetAllSirenIdReport } = this.props;
    resetAllSirenIdReport();
  }

  onSelectDoctorChange = value => {
    this.setState({
      selectedDoctors: value
    });
  };

  searchUsers = () => {
    this.setState({
      selectedPatients: []
    });

    this.tableInstance.state.page = 0;
    this.tableInstance.fireFetchData();
  };

  sendReport(list) {
    let selectSirenID = "";
    selectSirenID = list.join("_");
    Control.go(
      "/UserReportTable?siteId=" +
        this.state.siteId +
        "&sirenIds=" +
        selectSirenID +
        "&year=" +
        this.state.billingYear +
        "&month=" +
        this.state.billingMonth +
        "&reportPeriod=" +
        this.state.reportPeriod
    );
  }

  async downloadReport() {
    try {
      AntdModal.warn({
        title: "Downloading Report",
        content:
          "To download the report, disable your pop-up blocker. It will take a few minutes for us to generate your report. You may continue using the Siren Dashboard and the download will start automatically when it is ready.",
        centered: true,
        onOk: async () => {
          await this.startDownloading();
        },
        onCancel: () => {}
      });
    } catch (e) {
      console.log("download pdf error: ", e);
    }
  }
  async startDownloading() {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    if (this.state.selectedPatients.length === 0) {
      message.info(
        "Please select at least one patient record to download the Management Summary."
      );
      return;
    }
    const endpointInfo = _.filter(aws_exports.aws_cloud_logic_custom, {
      name: "dev-pdf-exporter"
    });
    const pdfEndpoint = endpointInfo[0].endpoint;
    let downloadUrl = null;
    const checkedAll =
      this.state.selectedPatients.length === this.state.patientData.length;
    if (checkedAll) {
      downloadUrl = `${pdfEndpoint}/admin/allManagementSummary?siteIds=${encodeURIComponent(
        qs.stringify(this.state.selectedSites)
      )}&token=${token}&startDate=${this.state.startDate}&endDate=${
        this.state.EndDate
      }&overallCount=${this.state.summaryStat.overall}&newPatientCount=${
        this.state.summaryStat.total
      }&activatedCount=${this.state.summaryStat.appActivation}&dispensedCount=${
        this.state.summaryStat.sockDispensed
      }&registeredCount=${
        this.state.summaryStat.registered
      }&generatedDate=${moment().format("YYYY-MM-DD")}`;
    } else {
      downloadUrl = `${pdfEndpoint}/admin/managementSummary?ids=${encodeURIComponent(
        qs.stringify(this.state.selectedPatients)
      )}&token=${token}&startDate=${this.state.startDate}&endDate=${
        this.state.EndDate
      }&overallCount=${this.state.summaryStat.overall}&newPatientCount=${
        this.state.summaryStat.total
      }&activatedCount=${this.state.summaryStat.appActivation}&dispensedCount=${
        this.state.summaryStat.sockDispensed
      }&registeredCount=${
        this.state.summaryStat.registered
      }&generatedDate=${moment().format("YYYY-MM-DD")}`;
    }
    window.open(downloadUrl);
  }

  onChangeDateRange = DateRangeDate => {
    this.setState({
      ifOpen: true,
      startDate: moment(DateRangeDate[0]).format("YYYY-MM-DD"),
      EndDate: moment(DateRangeDate[1]).format("YYYY-MM-DD")
    });
  };

  gotoStatisticsPage(id, userId, userTimezone) {
    Control.go(
      "/sockstatus?ManagementSummary/id=" +
        id +
        "&userId=" +
        userId +
        "&timeZone=" +
        userTimezone
    );
  }

  gotoDoctorDetailPage(id, userId, date) {
    if (!date) {
      date = moment().format("YYYY-MM-DD");
    }
    Control.go(
      "/MedicalDetailsHome?ManagementSummary/id=" +
        btoa(id) +
        "&userId=" +
        userId +
        "&startDate=" +
        date +
        "&endDate=" +
        date
    );
  }

  openPatientDetailNewWindow = (id, userId, date) => {
    if (!date) {
      date = moment().format("YYYY-MM-DD");
    }
    const goUrl = `${window.location.href.replace(
      "ManagementSummary",
      ""
    )}MedicalDetailsHome?ManagementSummary/id=${id}&userId=${userId}&startDate=${date}&endDate=${date}`;
    window.open(goUrl);
  };

  aweContextMenu = () => {
    return (
      <ContextMenu
        id="MANAGEMENT_SUMMARY"
        style={{
          backgroundColor: "rgba(45,45,52,0.9)",
          width: 241,
          borderRadius: 5
        }}
        onHide={() => {
          this.setState({ rowClickedData: null });
        }}
      >
        {/*<MenuItem*/}
        {/*  onClick={() => {*/}
        {/*    this.state.rowClickedData !== null &&*/}
        {/*      this.gotoDoctorDetailPage(*/}
        {/*        this.state.rowClickedData.id,*/}
        {/*        this.state.rowClickedData.userId,*/}
        {/*        moment().format("YYYY-MM-DD")*/}
        {/*      );*/}
        {/*    this.setState({ rowClickedData: null });*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      textAlign: "left",*/}
        {/*      color: "white",*/}
        {/*      marginLeft: 10,*/}
        {/*      marginTop: 8,*/}
        {/*      height: 30,*/}
        {/*      cursor: "pointer"*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    View detail*/}
        {/*  </div>*/}
        {/*</MenuItem>*/}
        {/*<div*/}
        {/*  style={{*/}
        {/*    marginLeft: 10,*/}
        {/*    width: 215,*/}
        {/*    height: 1,*/}
        {/*    backgroundColor: "rgba(209,209,213,0.5)"*/}
        {/*  }}*/}
        {/*/>*/}
        <MenuItem
          onClick={() => {
            this.state.rowClickedData !== null &&
              this.openPatientDetailNewWindow(
                this.state.rowClickedData.id,
                this.state.rowClickedData.userId,
                moment().format("YYYY-MM-DD")
              );
            this.setState({ rowClickedData: null });
          }}
        >
          <div
            style={{
              textAlign: "left",
              color: "white",
              marginLeft: 10,
              marginTop: 8,
              height: 30,
              cursor: "pointer"
            }}
          >
            <img
              src={OPEN_NEW_WINDOW}
              style={{ width: 15, height: 15, marginRight: 10 }}
            />
            Open in new window
          </div>
        </MenuItem>
      </ContextMenu>
    );
  };

  reqShouldInstantWrapper = (shouldInstant, onEvent) => {
    return value => {
      this.makeRequestInstant = shouldInstant;
      return onEvent(value);
    };
  };

  reqPatientList = _.debounce(() => {
    if (!this.tableInstance) {
      return;
    }
    const { filter, sort, pagination } = transformPatientTableParams(
      this.tableInstance
    );
    if (this.state.selectedSites.length > 0) {
      if (!filter.siteId) {
        const siteFilters = this.state.selectedSites.map(id => ({
          siteId: { eq: id }
        }));
        if (filter.and) {
          filter.and.push({ or: siteFilters });
        } else {
          filter.and = [{ or: siteFilters }];
        }
      }
    }
    if (this.state.selectedDoctors.length > 0) {
      if (!filter.doctorId) {
        const doctorFilters = this.state.selectedDoctors.map(id => ({
          doctorId: { eq: id }
        }));
        if (filter.and) {
          filter.and.push({ or: doctorFilters });
        } else {
          filter.and = [{ or: doctorFilters }];
        }
      }
    }
    if (this.state.startDate && this.state.EndDate) {
      filter.createdAt = {
        range: [
          moment(this.state.startDate, DateFormat)
            .utcOffset(0, true)
            .toISOString(true),
          moment(this.state.EndDate, DateFormat)
            .utcOffset(0, true)
            .toISOString(true)
        ]
      };
    }

    this.rawReqPatientList(filter, sort, pagination);
  }, 3000);

  rawReqPatientList(filter, sort, pagination) {
    getManagementSummaryStat(filter).then(value => {
      this.setState({
        summaryStat: value
      });
    });

    this.setState({
      patientLoading: true,
      patientError: null
    });

    listManagementSummaryPatients(null, filter, sort, pagination)
      .then(data => {
        let patiens = data.data.lambdaSearchPatients.items;
        let totalSize = data.data.lambdaSearchPatients.total;

        this.setState({
          selectedPatients: [],
          patientData: patiens,
          patientTotalSize: totalSize
        });
      })
      .catch(error => {
        this.setState({
          patientError: error
        });
      })
      .finally(() => {
        this.setState({
          patientLoading: false
        });
      });
  }

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        defaultValue={filter ? filter.value : null}
        onPressEnter={this.reqShouldInstantWrapper(true, onChange)}
        onChange={this.reqShouldInstantWrapper(false, onChange)}
      />
    );
  };

  renderHelp() {
    return (
      <Popup
        trigger={<QuestionCircleFilled className="summary_icon" />}
        position="bottom center"
        hoverable
        flowing
      >
        <Popup.Header className="popHeader">Summary Terminology</Popup.Header>
        <Popup.Content>
          <div className="popBox">
            <div className="popContainer">
              <p className="popTitle">Overall Patients:</p>
              <p className="popContent">
                Total number of patients for all accessed sites. Total is not
                affected by SiteID or date range filters.
              </p>
            </div>
            <div className="popContainer">
              <p className="popTitle">New Patients:</p>
              <p className="popContent">
                Total number of new patients accounts created between selected
                dates.
              </p>
            </div>
            <div className="popContainer">
              <p className="popTitle">App Activations:</p>
              <p className="popContent">
                Total number of patients that initially logged into the Siren
                App between selected dates.
              </p>
            </div>
            <div className="popContainer">
              <p className="popTitle">Socks Dispensed:</p>
              <p className="popContent">
                Total number of patients that have been provided Siren Socks
                between selected dates.
              </p>
            </div>
            <div className="popContainer">
              <p className="popTitle">Registered:</p>
              <p className="popContent">
                Total number of patients that initially paired Siren Socks to
                their accounts between selected dates.
              </p>
            </div>
          </div>
        </Popup.Content>
      </Popup>
    );
  }

  renderSummaryStat() {
    const statData = [
      {
        icon: OVERALL_PATIENTS,
        num: this.state.summaryStat.overall,
        label: "Overall Patients"
      },
      {
        icon: NEW_PATIENTS,
        num: this.state.summaryStat.total,
        label: "New Patients"
      },
      {
        icon: ACCOUNT_ACTIVATED,
        num: this.state.summaryStat.appActivation,
        label: "App Activations"
      },
      {
        icon: SOCK_DISPENSED,
        num: this.state.summaryStat.sockDispensed,
        label: "Socks Dispensed"
      },
      {
        icon: REGISTERED,
        num: this.state.summaryStat.registered,
        label: "Registered"
      }
    ];
    return (
      <div className="summary_des_container" style={{ whiteSpace: "nowrap" }}>
        {statData.map(data => (
          <div
            className="summary_des_box"
            style={{ display: "flex" }}
            key={data.label}
          >
            <div
              style={{
                width: 50,
                height: 50,
                backgroundColor: "#B892D2",
                marginTop: 15,
                marginLeft: 10,
                border: 2,
                borderRadius: 5,
                borderColor: "rgba(0,0,0,0.02)",
                display: "flex",
                alignItems: "center"
              }}
            >
              <img
                src={data.icon}
                alt=""
                style={{
                  maxWidth: 30,
                  maxHeight: 30,
                  margin: "auto"
                }}
              />
            </div>
            <div style={{ marginTop: 15, marginLeft: 10 }}>
              <p className="summary_des_number">{data.num}</p>
              <p className="summary_des_title">{data.label}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.id,
        rowInfo.original.userId,
        rowInfo.original.lastSeen
      );
    });
    return (
      <div className="device-list-container">
        <div className="search_patient_container">
          <div
            className="tab_title_box tab_title report_des_container"
            style={{ height: 30 }}
          >
            <p className="tab_title_box">
              <strong className="tab_title" style={{ paddingLeft: 0 }}>
                Management Summary
              </strong>
            </p>
            {this.renderHelp()}
          </div>
          <div
            style={{ display: "flex", alignItems: "center", paddingLeft: 16 }}
          >
            <span className="search_label">SiteID: </span>
            <Select
              mode="multiple"
              style={{ minWidth: 200 }}
              placeholder="Please select sites"
              value={this.state.selectedSites}
              onChange={value => {
                this.setState({
                  selectedSites: value
                });
              }}
              onSelect={value => {
                // pre-select all child sites
                const nextSites = new Set(this.state.selectedSites);
                this.props.clinicSites
                  .map(site => site.siteId)
                  .filter(site => site.startsWith(value))
                  .forEach(site => nextSites.add(site));

                // special rule. Bay Area
                if (value === "0136") {
                  nextSites.add("0001");
                  nextSites.add("0002");
                  nextSites.add("0028");
                  nextSites.add("0041");
                }
                this.setState({
                  selectedSites: [...nextSites]
                });
              }}
              optionLabelProp="value"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeAllSpace(option.props.children)
                  .toLowerCase()
                  .indexOf(removeAllSpace(input).toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
            >
              {this.props.clinicSites.map(site => (
                <Option key={site.siteId} value={site.siteId}>
                  {`${site.siteId} - ${site.name}`}
                </Option>
              ))}
            </Select>
            <span className="search_label search_time_label">Doctor: </span>
            <Select
              mode="multiple"
              style={{ minWidth: 200, maxWidth: 400 }}
              placeholder="Please select doctors"
              onChange={this.onSelectDoctorChange}
            >
              {this.props.listStaff.map(staff => (
                <Option key={staff.email} value={staff.email}>
                  {staff.firstName && staff.firstName.includes("Dr.")
                    ? `${staff.firstName} ${staff.lastName}`
                    : `Dr.${staff.firstName} ${staff.lastName}`}
                </Option>
              ))}
            </Select>
            <span className="search_label search_time_label">Date Range: </span>
            <DateRangePicker
              onChange={this.onChangeDateRange}
              value={
                this.state.startDate
                  ? [
                      new Date(
                        moment(this.state.startDate).format("MM/DD/YYYY")
                      ),
                      new Date(moment(this.state.EndDate).format("MM/DD/YYYY"))
                    ]
                  : null
              }
              clearIcon=""
              minDetail="month"
              locale="en"
              isOpen={this.state.ifOpen}
              onActiveDateChange={this.turnChange}
            />
            <Button
              size="small"
              onClick={this.searchUsers}
              style={{ marginLeft: 20 }}
              className="fixedButtonHeight"
            >
              Search
            </Button>
            <Button
              onClick={() => this.downloadReport()}
              style={{ marginLeft: 20 }}
              className="fixedButtonHeight"
            >
              Download
            </Button>
          </div>
        </div>
        {this.renderSummaryStat()}
        <ContextMenuTrigger id="MANAGEMENT_SUMMARY">
          <ReactTable
            getTrProps={trProps}
            getTdProps={(state, rowInfo) => {
              return {
                onContextMenu: () => {
                  this.setState({ rowClickedData: rowInfo.original });
                }
              };
            }}
            manual
            loading={this.state.patientLoading}
            ref={ref => {
              if (ref) {
                this.tableInstance = ref;
              }
            }}
            pages={
              !this.tableInstance
                ? -1
                : Math.ceil(
                    this.state.patientTotalSize /
                      this.tableInstance.state.pageSize
                  )
            }
            filterable
            FilterComponent={InputEnterKeyTriggerFilterComponent({
              onChange: () => {
                this.makeRequestInstant = false;
              },
              onPressEnter: () => {
                this.makeRequestInstant = true;
              }
            })}
            defaultFilterMethod={(filter, row) => {
              if (!row[filter.id]) {
                return false;
              }
              return row[filter.id]
                .toString()
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            }}
            defaultSorted={[{ id: "sirenId", desc: false }]}
            onSortedChange={() => {
              this.makeRequestInstant = true;
            }}
            defaultPageSize={20}
            className="-striped -highlight"
            minRows={0}
            data={this.state.patientData}
            onFetchData={(_, instance) => {
              // there are some other callbacks, like onPageChange onPageSizeChange etc,
              // we don't care them. this callback is enough
              this.tableInstance = instance;

              this.reqPatientList();
              if (this.makeRequestInstant) {
                this.reqPatientList.flush();
              }
            }}
            resolveData={data =>
              data.map(p => {
                const appLastSeen = p.appLastSeen
                  ? p.appLastSeen.substring(0, 10)
                  : "";
                const hubLastSeen = p.hubLastSeen
                  ? p.hubLastSeen.substring(0, 10)
                  : "";
                const hubDataLastSeen = p.hubDataLastSeen
                  ? p.hubDataLastSeen.substring(0, 10)
                  : "";
                const lastSeen = p.dataLastSeen
                  ? p.dataLastSeen.split("T")[0]
                  : "";
                const phone = !_.isEmpty(p.phone)
                  ? formatNumber(p.phone).trim()
                  : "";
                const overallStatus =
                  p.overallStatus === "NORMAL" ? "GREEN" : p.overallStatus;
                return {
                  ...p,
                  appLastSeen,
                  hubLastSeen,
                  hubDataLastSeen,
                  phone,
                  lastSeen,
                  overallStatus
                };
              })
            }
            columns={[
              {
                Header: props => {
                  return (
                    <Checkbox
                      onChange={this.handleCheckAll}
                      indeterminate={
                        this.state.selectedPatients.length > 0 &&
                        this.state.selectedPatients.length <
                          this.state.patientData.length
                      }
                      checked={
                        this.state.selectedPatients.length > 0 &&
                        this.state.selectedPatients.length ===
                          this.state.patientData.length
                      }
                      name="selectAll"
                      className="billCheck_all"
                    />
                  );
                },
                accessor: "",
                maxWidth: 60,
                sortable: false,
                filterable: false,
                Cell: props => {
                  return (
                    <div onClick={e => e.stopPropagation()}>
                      <Checkbox
                        onChange={e => this.handleCheck(e, props)}
                        checked={this.state.selectedPatients.includes(
                          props.row.sirenId
                        )}
                        name="selectSite"
                      />
                    </div>
                  );
                }
              },
              {
                Header: "SiteID",
                accessor: "siteId",
                filterable: false,
                show: this.state.selectFiltersValues.includes("SiteID"),
                maxWidth: 60
              },
              {
                Header: "SiteName",
                accessor: "siteName",
                show: this.state.selectFiltersValues.includes("SiteName"),
                minWidth: 100
              },
              {
                Header: "Doctor",
                accessor: "doctorName",
                show: this.state.selectFiltersValues.includes("Doctor")
              },
              {
                Header: "SirenID",
                accessor: "sirenId",
                show: this.state.selectFiltersValues.includes("SirenID"),
                maxWidth: 60
              },
              {
                Header: "PatientID",
                accessor: "patientId",
                show: this.state.selectFiltersValues.includes("PatientID")
              },
              {
                Header: "First Name",
                accessor: "firstName",
                show: this.state.selectFiltersValues.includes("First Name")
              },
              {
                Header: "Last Name",
                accessor: "lastName",
                show: this.state.selectFiltersValues.includes("Last Name")
              },
              {
                Header: "DOB",
                accessor: "birthday",
                show: this.state.selectFiltersValues.includes("DOB"),
                Filter: this.dateInputComponent
              },
              {
                Header: "Cell Phone",
                accessor: "phone",
                show: this.state.selectFiltersValues.includes("Cell Phone"),
                maxWidth: 200
              },
              {
                Header: "Email",
                accessor: "email",
                show: this.state.selectFiltersValues.includes("Email")
              },
              {
                Header: "App Activated",
                accessor: "appInitialized",
                show: this.state.selectFiltersValues.includes("App Activated"),
                maxWidth: 100,
                Filter: ({ filter, onChange }) => (
                  <Select
                    className="select_common_style"
                    onChange={this.reqShouldInstantWrapper(true, onChange)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="all">All</Option>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                )
              },
              {
                Header: "Registered Status",
                accessor: "sockRegistered",
                show: this.state.selectFiltersValues.includes(
                  "Registered Status"
                ),
                maxWidth: 100,
                Filter: ({ filter, onChange }) => (
                  <Select
                    className="select_common_style"
                    onChange={this.reqShouldInstantWrapper(true, onChange)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="all">All</Option>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                )
              },
              {
                Header: "Socks Dispensed Date",
                accessor: "dispensedDay",
                show: this.state.selectFiltersValues.includes(
                  "Socks Dispensed Date"
                ),
                Filter: this.dateInputComponent,
                minWidth: 120
                // Cell: props => {
                //   return (
                //     <div>
                //       {props.original.dispensedDay}
                //       <ChangeDispensedDay
                //        userId={props.original.userId}
                //        id={props.original.id}
                //        dispensedDay={props.original.dispensedDay}
                //        from="BiMonthly"
                //        />
                //     </div>
                //   );
                // }
              },
              {
                style: { cursor: "pointer" },
                Header: "Medical Status",
                accessor: "overallStatus",
                show: this.state.selectFiltersValues.includes("Medical Status"),
                Cell: props => {
                  return (
                    <div onClick={e => e.stopPropagation()}>
                      <span
                        className="spanonclick"
                        onClick={() =>
                          this.gotoDoctorDetailPage(
                            props.original.id,
                            props.original.userId,
                            props.original.lastSeen
                          )
                        }
                      >
                        {props.original.overallStatus}
                      </span>
                    </div>
                  );
                },
                Filter: ({ filter, onChange }) => (
                  <Select
                    className="select_common_style"
                    onChange={this.reqShouldInstantWrapper(true, onChange)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="all">All</Option>
                    <Option value="GREEN">GREEN</Option>
                    <Option value="RED">RED</Option>
                    <Option value="YELLOW">YELLOW</Option>
                  </Select>
                )
              },
              {
                Header: "App Last Login",
                accessor: "appLastSeen",
                show: this.state.selectFiltersValues.includes("App Last Login"),
                Filter: this.dateInputComponent
              },
              {
                Header: "Hub Type",
                accessor: "hubType",
                show: this.state.selectFiltersValues.includes("Hub Type"),
                Cell: props => {
                  return (
                    <div onClick={e => e.stopPropagation()}>
                      <span
                        className="spanonclick"
                        onClick={() =>
                          this.gotoStatisticsPage(
                            props.original.id,
                            props.original.userId,
                            props.original.userTimezone
                          )
                        }
                      >
                        {props.original.hubType}
                      </span>
                    </div>
                  );
                },
                Filter: ({ filter, onChange }) => (
                  <Select
                    className="select_common_style"
                    onChange={this.reqShouldInstantWrapper(true, onChange)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    <Option value="all">All</Option>
                    <Option value="None">None</Option>
                    <Option value="Wifi">Wifi</Option>
                    <Option value="2G">2G</Option>
                    <Option value="4G">4G</Option>
                    <Option value="Wifi, 2G">Wifi, 2G</Option>
                    <Option value="Wifi, 4G">Wifi, 4G</Option>
                    <Option value="2G, 4G">2G, 4G</Option>
                    <Option value="Wifi, 2G, 4G">Wifi, 2G, 4G</Option>
                  </Select>
                )
              },
              {
                Header: "Hub Last Seen",
                accessor: "hubLastSeen",
                show: this.state.selectFiltersValues.includes("Hub Last Seen"),
                Filter: this.dateInputComponent
              },
              {
                Header: "Hub Data Last Seen",
                accessor: "hubDataLastSeen",
                show: this.state.selectFiltersValues.includes(
                  "Hub Data Last Seen"
                ),
                Filter: this.dateInputComponent
              }
            ]}
          />
        </ContextMenuTrigger>
        {this.aweContextMenu()}
      </div>
    );
  }
}

const mapStateToProp = state => ({
  allClinicSites: state.patientsListStore.allClinicSites,
  listStaff: state.patientsListStore.listStaff,
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  getAllListClinicSites: () => dispatch(getAllListClinicSites()),
  getlistSummaryStaff: () => dispatch(getlistSummaryStaff),
  getlistClinicSites: () => dispatch(getlistClinicSites())
});
export default connect(mapStateToProp, mapDispatchToProp)(ManagementSummary);
