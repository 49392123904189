/**
 *  Created by shengli.zhang on 4/27/21 1:58 PM
 */

export const MEDICAL_GENERAL = [
  { name: "Diabetes Type I", value: "Diabetes_Type_I" },
  { name: "Diabetes Type II", value: "Diabetes_Type_II" },
  { name: "PAD", value: "PAD" },
  {
    name: "Arterial Insufficiency",
    value: "Arterial_Insufficiency"
  },
  {
    name: "Venous Insufficiency",
    value: "Venous_Insufficiency"
  },
  { name: "History of Smoking", value: "History_of_Smoking" },
  {
    name: "Impaired Vision or Retinal Replacement",
    value: "Impaired_Vision_of_Retinal_Replacement"
  },
  {
    name: "Rheumatoid arthritis",
    value: "Rheumatoid_arthritis"
  },
  {
    name: "Pacemaker",
    value: "Pacemaker"
  },
  {
    name: "Raynaud's Disease",
    value: "Raynauds_Disease"
  }
];

export const FOOT_SPECIFIC = [
  {
    name: "Neuropathy - Foot",
    value_L: "Neuropathy_Foot_L",
    value_R: "Neuropathy_Foot_R"
  },
  {
    name: "Ulcers",
    value_L: "Ulcers_L",
    value_R: "Ulcers_R"
  },
  {
    name: "Charcot",
    value_L: "Charcot_L",
    value_R: "Charcot_R"
  },
  {
    name: "Rocker Bottom",
    value_L: "Rocker_Bottom_L",
    value_R: "Rocker_Bottom_R"
  },
  {
    name: "Hammer / Claw Toe",
    value_L: "Hammer_Claw_Toe_L",
    value_R: "Hammer_Claw_Toe_R"
  },
  {
    name: "Bunion",
    value_L: "Bunion_L",
    value_R: "Bunion_R"
  },
  {
    name: "Callus",
    value_L: "Callus_L",
    value_R: "Callus_R"
  },
  {
    name: "Ingrown toenail",
    value_L: "Ingrown_Toenail_L",
    value_R: "Ingrown_Toenail_R"
  },
  {
    name: "Osteoarthritis",
    value_L: "Osteoarthritis_L",
    value_R: "Osteoarthritis_R"
  }
];

export const FOOT_AMPUTATION = [
  { name: "Hallux", value_L: "1st_Toe_L", value_R: "1st_Toe_R" },
  { name: "2nd Digit", value_L: "2st_Toe_L", value_R: "2st_Toe_R" },
  { name: "3rd Digit", value_L: "3st_Toe_L", value_R: "3st_Toe_R" },
  { name: "4th Digit", value_L: "4st_Toe_L", value_R: "4st_Toe_R" },
  { name: "5th Digit", value_L: "5st_Toe_L", value_R: "5st_Toe_R" },
  { name: "TMA", value_L: "TMA_L", value_R: "TMA_R" },
  { name: "Foot", value_L: "Foot_L", value_R: "Foot_R" },
  { name: "BKA", value_L: "BKA_L", value_R: "BKA_R" },
  { name: "AKA", value_L: "AKA_L", value_R: "AKA_R" }
];

export const SYMPTOMS = [
  { name: "No new symptoms" },
  { name: "Bleeding" },
  { name: "Blister" },
  { name: "Bruising / Discoloration" },
  { name: "Bug bite" },
  { name: "Burning Sensation" },
  { name: "Callus" },
  // { name: "Color changes with toenails" },
  { name: "Drainage" },
  // { name: "Ingrown toenail" },
  { name: "Loss of sensation" },
  { name: "Numbness" },
  { name: "Open wound" },
  { name: "Pain", options: ["Increased", "Decreased"] },
  { name: "Paresthesia", options: ["Burning", "Tingling", "Numbness"] },
  { name: "Rash" },
  { name: "Redness" },
  { name: "Swelling" }
];

export const SYMPTOMS_AREA = [
  "Hallux",
  "2nd Digit",
  "3rd Digit",
  "4th Digit",
  "5th Digit",
  "MTS 1",
  "MTS 3",
  "MTS 5",
  "Arch",
  "Heel",
  "Top of the foot",
  "Bottom of the foot",
  "Medial of foot",
  "Lateral of foot",
  "Entire foot",
  "Ankle",
  "Lower Leg",
  "Calf"
];

export const FOOT = ["Both", "Left", "Right"];

export const CONDITIONS = [
  "Active Charcot",
  "Blistering",
  "Calluses",
  "Cellulitis",
  "Gout",
  "Ingrown toenail",
  "Nail fungus",
  "Redness",
  "Surgical wound",
  "Swelling",
  "Ulcer",
  "Other"
];

export const TREATMENT = [
  "Boot",
  "Cast",
  "Compression stockings/socks",
  "Diabetic shoes",
  "Foot Brace",
  "Graft/Skin substitute",
  "Hospitalization",
  "Medication",
  "Offloading insoles",
  "Post-op shoe",
  "Rehab / In-patient stay",
  "Simple dressing",
  "Wrap"
];

export const ServiceOptionList = [
  {
    option: "A",
    brief: "Reviewing the patient’s data from the Siren dashboard"
  },
  {
    option: "B",
    brief: "Pulling data from prior studies for interpretation of patient data"
  },
  {
    option: "C",
    brief:
      "Conferring care (time spent on phone, email or written communication) with other practitioners about topics related to the patient's care"
    // memo: '"Current Condition" section is required.'
  },
  // {
  //   option: "D",
  //   brief: "Coordinating care with other practitioners"
  // },
  {
    option: "D1",
    brief: "Coordinating care with other practitioners"
  },
  {
    option: "D2",
    brief: "Received doctor response"
  },
  {
    option: "E",
    brief:
      "Interactive communication with the patient about their data and care plan"
    // memo: '"Symptoms" section is required.'
  }
];

export const ServiceOptionList_PCM = [
  {
    option: "F",
    brief: "Communication with patient"
  },
  {
    option: "G",
    brief: "Establishing, implementing, revising, or monitoring the care plan"
  },
  {
    option: "H",
    brief: "Coordinating the care of other professionals and agencies"
  },
  {
    option: "I",
    brief:
      "Educating the patient or caregiver about the patient's condition, care plan, and prognosis directed by the physician"
  }
];

export const ACCOUNT_PAUSED_REASON = [
  {
    name: "🏰 Account - Pending Insurance coverage/eligibility",
    value: "Account - Pending Insurance coverage/eligibility"
  },
  {
    name: "🏰 Account - Pending New order shipment",
    value: "Account - Pending New order shipment"
  },
  {
    name: "🏰 Account - Pending Replacement shipment",
    value: "Account - Pending Replacement shipment"
  },
  {
    name: "🏰 Account - Pt Unresponsive",
    value: "Account - Pt Unresponsive"
  },
  {
    name: "🏰 Health - Hospital",
    value: "Health - Hospital"
  },
  {
    name: "🏰 Health - Pending/Recent surgery",
    value: "Health - Pending/Recent surgery"
  },
  {
    name: "🏰 Health - Open wound/sore",
    value: "Health - Open wound/sore"
  },
  {
    name: "🏰 Health - Sick/Other",
    value: "Health - Sick/Other"
  },
  {
    name: "🏰 Product - Technical Issues",
    value: "Product - Technical Issues"
  },
  {
    name: "🏰 Product - Lifestyle; Will wear in future",
    value: "Product - Lifestyle; Will wear in future"
  }
];

export const PATIENT_RESIDENCE = [
  {
    name: "Home",
    value: "Home"
  },
  {
    name: "Assisted living facility",
    value: "Assisted living facility"
  },
  {
    name: "Group home",
    value: "Group home"
  },
  {
    name: "Skilled nursing facility",
    value: "Skilled nursing facility"
  },
  {
    name: "Nursing facility",
    value: "Nursing facility"
  },
  {
    name: "Personal care home",
    value: "Personal care home"
  }
];

export const EMAIL_RECEIVED = [
  {
    name: "Medical Review (=hotspots)",
    value: "medical_review"
  },
  {
    name: "Monthly Reports",
    value: "monthly_reports"
  },
  {
    name: "Renewal Notification",
    value: "renewal_notification"
  },
  {
    name: "General Support",
    value: "general_support"
  }
];
