import { API, graphqlOperation, Storage } from "aws-amplify";
import { forEach } from "lodash";
import zlib from "react-zlib-js";
import Buffer from "react-zlib-js/buffer";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import _ from "lodash";

export function searchPatient(self, sirenId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listRegisteredPatients(), {
          filter: {
            group: {
              ne: "TRIAL_SILENCE"
            },
            sirenId: { eq: sirenId }
          },
          limit: 10
        })
      );
      if (newEvent) {
        if (
          newEvent.data.lambdaSearchPatients.items &&
          newEvent.data.lambdaSearchPatients.items.length > 0
        ) {
          self.setState({
            patientInfo: newEvent.data.lambdaSearchPatients.items[0],
            id: newEvent.data.lambdaSearchPatients.items[0].id,
            userId: newEvent.data.lambdaSearchPatients.items[0].userId,
            patient: newEvent.data.lambdaSearchPatients.items
          });
          self.handleOK();
        }
      }
    } catch (e) {
      console.log("searchPatient error: ", e);
    }
  };
}

export function getPatientUserId(self, sirenId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.searchPatientsConnection(), {
          filter: {
            sirenId: {
              eq: sirenId
            }
          },
          limit: 10
        })
      );
      if (newEvent) {
        if (
          newEvent.data.lambdaSearchPatients.items &&
          newEvent.data.lambdaSearchPatients.items.length > 0
        ) {
          self.setState({
            userId: newEvent.data.lambdaSearchPatients.items[0].userId
          });
        }
      }
    } catch (e) {
      console.log("searchPatient error: ", e);
    }
  };
}

export function getLogFromS3(self, key) {
  return dispatch => {
    self.setState({ isLoading: true });
    Storage.get(key, {
      identityId: self.state.userId,
      level: "private"
    })
      .then(async result => {
        fetch(result)
          .then(res => {
            return res.text();
          })
          .then(data => {
            // 处理日志数据，按日志开头格式分行
            const formattedLogs = data
              .split(
                /(?=\[\d{4}-\d{2}-\d{2}.*?\]\[(?:INFO|ERROR|WARN|DEBUG)\]\[(?:IOS|ANDROID)\])/
              ) // 在日志头部模式前分割
              .filter(line => line.trim()) // 移除空行
              .map(line => line.trim()) // 清理每行的空白
              .join("\n"); // 用换行符重新连接

            self.setState({
              isLoading: false,
              log: formattedLogs
            });
          })
          .catch(e => {
            self.setState({ isLoading: false });
            console.log(`get log for key ${key} failed: `, e);
          });
      })
      .catch(err => {
        self.setState({ isLoading: false });
        console.log(`get log for key ${key} failed: `, err);
      });
  };
}

function changeLogKeysState(logKeys) {
  return {
    type: TYPES.LOG_KEYS,
    text: logKeys
  };
}

export function testSimulateData(jsonData, self) {
  return async dispatch => {
    try {
      self.setState({ modalIsOpen: true });
      await API.graphql(
        graphqlOperation(_api.sendSimulateData(), {
          input: {
            simulateData: jsonData
          }
        })
      );
      self.setState({ modalIsOpen: false });
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("testSimulateData failed: ", e);
    }
  };
}

export const fetchPatientLogs = userId => {
  return async dispatch => {
    try {
      const s3Paths = ["logs/", "temperature-datas/"];
      const res = await Promise.allSettled(
        s3Paths.map(async s3Path => {
          return await Storage.list(s3Path, {
            identityId: userId,
            level: "private"
          });
        })
      );
      const result = res.map(p => {
        return p.value.reverse();
      });
      const keys = _.flatten(result);
      dispatch(changeLogKeysState(keys.map(key => key.key)));
    } catch (error) {
      console.error("Error fetching logs from S3:", error);
      throw error;
    }
  };
};
