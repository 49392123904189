import React, { Component } from "react";
import "../share/common.css";
import "./biMonthly.css";
import "./selectComponent.css";
import { DatePicker, notification, Select } from "antd";
import { connect } from "react-redux";
import ReactTable from "react-table";
import moment from "moment";
import { listMonthlySiteSummary } from "../graphql/API";
import { Button, Popup } from "semantic-ui-react";
import { v1 as uuid } from "uuid";
import { getlistClinicSites } from "../action/reportAction";
import { generateMonthlySiteSummary } from "../favorite/report-va-action";
import { getlistStaff } from "../action/registerAction";
import _ from "lodash";
import MonthCalendar from "./monthly-calendar";
import { removeAllSpace } from "../tools/stringProcess";
const { RangePicker } = DatePicker;
const { Option } = Select;

// 添加一个辅助函数来转换日期格式
const getYearMonth = monthStr => {
  const [month, year] = monthStr.split("/");
  return new Date(year, parseInt(month) - 1);
};

class SiteSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchMonth: [],
      summary: [],
      siteIds: [],
      providers: [],
      siteProviders: [],
      loadingReport: false
    };
  }

  componentWillMount() {
    const { getlistClinicSites, getlistStaff } = this.props;
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }
    if (this.props.listStaff.length === 0) {
      getlistStaff();
    }
  }

  componentDidMount() {
    this.searchMonthlySummary();
  }

  searchMonthlySummary = (siteIds, providers, startTime, endTime) => {
    this.setState({ loadingReport: true });
    listMonthlySiteSummary(siteIds, providers, startTime, endTime)
      .then(data => {
        this.setState({ loadingReport: false, summary: data });
        // console.log("data: ", data);
      })
      .catch(err => {
        // console.log("error: ", err);
        this.setState({ loadingReport: false });
        // graphQL error
        if (err.errors) {
          notification.error({
            message: "System Error",
            description: err.errors[0].message
          });
        }
      });
  };

  addThousandsSeparator = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  divideWithOneDecimal = (numerator, denominator) => {
    if (denominator === 0) {
      return "N/A";
    }
    return (numerator / denominator).toFixed(1);
  };

  handleGenerateMonthlySiteSummary = async () => {
    const input = {
      token: uuid()
    };
    if (this.state.siteIds.length > 0) {
      const siteIds = this.state.siteIds.map(p => p).join("_");
      Object.assign(input, { siteIds });
    }
    if (this.state.providers.length > 0) {
      const providers = this.state.providers.map(p => p).join(",");
      Object.assign(input, { providers });
    }
    if (this.state.searchMonth.length > 0) {
      const startTime = moment(this.state.searchMonth[0]).format("YYYY-MM");
      const endTime = moment(this.state.searchMonth[1]).format("YYYY-MM");
      Object.assign(input, { startTime, endTime });
    }
    const { generateMonthlySiteSummary } = this.props;
    generateMonthlySiteSummary(input);
  };

  handlePanelChange = (value, mode) => {
    console.log("handlePanelChange: ", value);
    this.setState({ searchMonth: value });
  };

  handleChange = value => {
    console.log("handleChange: ", value);
    const [start, end] = value;
    if (end) {
      this.setState({ searchMonth: value });
    } else {
      this.setState({ searchMonth: [start] });
    }
    // console.log(moment(start).format("YYYY-MM"), moment(end).format("YYYY-MM"));
  };

  handleSiteChange = value => {
    if (value.length === 0) {
      this.setState({ siteIds: value, providers: [], siteProviders: [] });
    } else {
      const siteProviders = _.compact(
        this.props.listStaff.map(item => {
          if (value.includes(item.siteId)) {
            return item;
          }
          return null;
        })
      );
      this.setState({ siteIds: value, providers: [], siteProviders });
    }

    console.log("handleSiteChange: ", value);
  };

  handleProviderChange = value => {
    this.setState({ providers: value });
    console.log("handleProviderChange: ", value);
  };

  onSearch = () => {
    let siteIds, providers, startTime, endTime;
    if (this.state.siteIds.length > 0) {
      siteIds = this.state.siteIds.map(p => p).join("_");
    }
    if (this.state.providers.length > 0) {
      providers = this.state.providers.map(p => p).join("_");
    }
    if (this.state.searchMonth.length > 0) {
      startTime = moment(this.state.searchMonth[0]).format("YYYY-MM");
      endTime = moment(this.state.searchMonth[0]).format("YYYY-MM");
      if (this.state.searchMonth[1]) {
        endTime = moment(this.state.searchMonth[1]).format("YYYY-MM");
      }
      this.setState({ searchMonth: [moment(startTime), moment(endTime)] });
    }
    console.log(
      "siteId, provider, startTime, endTime: ",
      siteIds,
      providers,
      startTime,
      endTime
    );
    this.searchMonthlySummary(siteIds, providers, startTime, endTime);
  };

  // 添加清除方法
  handleClear = () => {
    this.setState({
      siteIds: [],
      providers: [],
      searchMonth: null,
      summary: []
    });
  };

  render() {
    return (
      <div className="device-list-container">
        <div
          className="search_patient_container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <label>SiteName: </label>
          <Select
            mode="multiple"
            showSearch
            style={{ width: "20%", marginLeft: 10, marginRight: 10 }}
            placeholder="Please select"
            value={this.state.siteIds}
            optionFilterProp="children"
            onChange={this.handleSiteChange}
            filterOption={(input, option) => {
              const siteName = removeAllSpace(
                option.props.children.toLowerCase()
              );
              const searchText = removeAllSpace(input.toLowerCase());
              return siteName.includes(searchText);
            }}
          >
            {this.props.clinicSites.map((p, i) => {
              return (
                <Option key={"site" + i} value={p.siteId}>
                  {p.name}
                </Option>
              );
            })}
          </Select>
          <label>Provider: </label>
          <Select
            mode="multiple"
            showSearch
            style={{ width: "20%", marginLeft: 10, marginRight: 10 }}
            placeholder="Please select"
            value={this.state.providers}
            optionFilterProp="children"
            onChange={this.handleProviderChange}
            filterOption={(input, option) => {
              // 将 children 数组转换为字符串
              const providerName = removeAllSpace(
                option.props.children.join(" ").toLowerCase()
              );
              const searchText = removeAllSpace(input.toLowerCase());
              return providerName.includes(searchText);
            }}
          >
            {this.state.siteProviders.map((p, i) => {
              return (
                <Option key={"provider" + i} value={p.email}>
                  {p.firstName} {p.lastName}
                </Option>
              );
            })}
          </Select>
          <label>Period: </label>
          <MonthCalendar
            value={this.state.searchMonth}
            onChange={this.handleChange}
            selectionMode="multiRow"
            placeholder="Please select month range"
          />
          <Button
            onClick={this.onSearch}
            style={{
              marginLeft: 30,
              whiteSpace: "nowrap",
              marginTop: 20,
              marginBottom: 20
            }}
          >
            Search
          </Button>
          <Button onClick={this.handleClear} style={{ marginLeft: 10 }}>
            Clear
          </Button>
          <Button
            onClick={this.handleGenerateMonthlySiteSummary}
            style={{
              marginLeft: 10,
              whiteSpace: "nowrap",
              marginTop: 20,
              marginBottom: 20
            }}
            disabled={this.props.showGenerateVaDelay}
          >
            Generate Monthly Summary
          </Button>
          {this.props.showGenerateVaDelay ? (
            <div
              style={{
                color: "red",
                marginLeft: 20,
                marginTop: 5,
                fontWeight: "bold",
                whiteSpace: "nowrap"
              }}
            >
              {"Generating Monthly Summary in " + this.props.generateVaDelay}
            </div>
          ) : null}
        </div>
        <ReactTable
          loading={this.state.loadingReport}
          data={this.state.summary}
          filterable
          resolveData={data => {
            // 在组件中修改排序逻辑
            const sortedData = data.sort((a, b) => {
              // 首先按年份排序，然后按月份排序
              const [monthA, yearA] = a.month.split("/");
              const [monthB, yearB] = b.month.split("/");

              if (yearA !== yearB) {
                return yearB - yearA; // 年份降序
              }
              return parseInt(monthB) - parseInt(monthA); // 同年内月份降序
            });

            return sortedData.map(p => {
              const minuteworn =
                p.minuteworn && this.divideWithOneDecimal(p.minuteworn, 60);
              const medianMinuteworn =
                p.medianMinuteworn &&
                this.divideWithOneDecimal(p.medianMinuteworn, 60);
              const displayMonth = moment(p.month).format("M/YYYY");
              const month = p.month;
              const steps =
                p.steps && this.addThousandsSeparator(Math.ceil(p.steps));
              const medianSteps =
                p.medianSteps &&
                this.addThousandsSeparator(Math.ceil(p.medianSteps));
              const onServicePatients =
                p.onServicePatients && Math.ceil(p.onServicePatients);
              const pendingPatients =
                p.pendingPatients && Math.ceil(p.pendingPatients);
              const pausedPatients =
                p.pausedPatients && Math.ceil(p.pausedPatients);
              const offServicePatients =
                p.offServicePatients && Math.ceil(p.offServicePatients);
              const totalFullMonthPatients =
                (p.totalFullMonthPatients &&
                  Math.ceil(p.totalFullMonthPatients)) ||
                0;
              const totalMonthPatients =
                (p.totalMonthPatients && Math.ceil(p.totalMonthPatients)) || 0;
              const newPatients =
                totalMonthPatients - totalFullMonthPatients < 0
                  ? 0
                  : totalMonthPatients - totalFullMonthPatients;
              return {
                ...p,
                minuteworn,
                medianSteps,
                medianMinuteworn,
                displayMonth,
                month,
                steps,
                onServicePatients,
                pendingPatients,
                pausedPatients,
                offServicePatients,
                totalMonthPatients,
                totalFullMonthPatients,
                newPatients
              };
            });
          }}
          columns={[
            {
              Header: "Month",
              accessor: "displayMonth",
              sortMethod: (a, b) => {
                const [monthA, yearA] = a.split("/");
                const [monthB, yearB] = b.split("/");

                if (yearA !== yearB) {
                  return yearB - yearA;
                }
                return parseInt(monthB) - parseInt(monthA);
              }
            },
            {
              Header: "Active Patients",
              accessor: "onServicePatients"
            },
            {
              Header: "Pending Onboarding Patients",
              accessor: "pendingPatients"
            },
            {
              Header: "Paused Patients",
              accessor: "pausedPatients"
            },
            {
              Header: "Discontinued Patients",
              accessor: "offServicePatients"
            },
            {
              Header: "Days Worn",
              accessor: "days"
            },
            {
              Header: "Median Days Worn",
              accessor: "medianDays"
            },
            {
              Header: "Hours Worn",
              accessor: "minuteworn"
            },
            {
              Header: "Median Hours Worn",
              accessor: "medianMinuteworn"
            },
            {
              Header: "Steps",
              accessor: "steps"
            },
            {
              Header: "Median Steps",
              accessor: "medianSteps"
            },
            {
              Header: "Calls",
              accessor: "calls"
            },
            {
              Header: "Total Calls",
              accessor: "totalCalls"
            },
            {
              Header: "Escalated Calls",
              accessor: "esclateCalls"
            },
            {
              Header: "New Patients",
              accessor: "newPatients"
            },
            {
              Header: "Total Patients",
              accessor: "totalMonthPatients"
            }
          ]}
          defaultSorted={[{ id: "displayMonth", desc: true }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  showGenerateVaDelay: state.monthlyReportStore.showGenerateVaDelay,
  generateVaDelay: state.monthlyReportStore.generateVaDelay,
  clinicSites: state.patientsListStore.clinicSites,
  listStaff: state.patientsListStore.listStaff
});
const mapDispatchToProp = dispatch => ({
  generateMonthlySiteSummary: input =>
    dispatch(generateMonthlySiteSummary(input)),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getlistStaff: () => dispatch(getlistStaff())
});
export default connect(mapStateToProp, mapDispatchToProp)(SiteSummary);
